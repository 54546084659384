import React, { useState, useContext, useEffect } from "react";
import "../../assets/styles/Login.css";
import { NavLink, useNavigate } from "react-router-dom";
import ContextState from "../contextApi/ContextState";
import LoadingButton from "@mui/lab/LoadingButton";

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { fetchloginApiCall, openSnackbar, key } = useContext(ContextState);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // State for password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  // Function to handle form submission
  const handleSubmit = async (e) => {
    // setLoading(true);
    e.preventDefault();

    // Validation
    const errors = {};
    if (!email.trim()) errors.email = "Email/Username/Phone  is required";
    if (!password) errors.password = "Password is required";
    setFormErrors(errors);

    // If there are any errors, stop the function
    if (Object.keys(errors).length > 0) {
      // setLoading(false);
      return;
    }

    // started button loader
    setLoading(true);

    const trimmed_email = email.trim();
    const trimmed_password = password;

    let payload = {
      username: trimmed_email,
      password: trimmed_password,
    };

    try {
      // post request to generate a token
      const response = await fetchloginApiCall("user_login", "POST", payload);
      // console.log(response)
      if (response.statusCode === 200) {
        openSnackbar(response.data.message, "success");
        navigate("/");
        window.history.replaceState({}, "", "/");
      } else if (response.statusCode === 404) {
        // console.log("404 message recieved:-", response.msg.message);
        openSnackbar(response.msg.message, "error");
      } else if (response.statusCode === 401) {
        // console.log("401 message recieved:", response);
        openSnackbar(response.msg.message, "error");
      }
    } catch (error) {
      openSnackbar("some error occured", "error");
      // openSnackbar("system is temproryily down", "error")
      console.error("Error during login:", error);
    }
    setLoading(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    document.title = "Sign in - NEO";
    if (localStorage.getItem(key)) {
      navigate("/");
    }
  }, []);

  return (
    <div className="main_container d-flex justify-content-center p-4">
      {/* Row that takes full height of its parent */}
      <div className="row w-100 p-0 container justify-content-center align-items-center">
        {/* Image container visible on extra-large and extra-extra-large screens */}
        {/* <div className=" col-lg-8  d-none d-xl-flex justify-content-center align-items-center ">
          <div className="w-100 d-flex justify-content-center  ">
            <img className="" src={bgimg} alt="" />
          </div>
        </div> */}
        {/* Login container occupying 4 parts and taking full height */}
        <div className="login_form_container col-xl-4  col-lg-6  col-md-8  col-sm-10  d-flex align-items-center justify-content-center  ">
          <div className="col ">
            <div className="mb-4">
              <h3 className="title"> Welcome to Nebulaa</h3>
              <p className="desp">Please sigin to your account !</p>
            </div>
            <form
              onSubmit={handleSubmit}
              className="d-flex flex-column justify-content-center h-100"
            >
              <div className="form-group py-2 form_float">
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="username"
                />
                <label className={`labels ${email ? "float" : ""}`} htmlFor="">
                  Email/Username/Phone
                </label>
                {formErrors.email && (
                  <span className="error">{formErrors.email}</span>
                )}
              </div>
              <div className="form-group py-2 form_float input-icon-container">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control input-with-icon"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                {/* Show password icon */}
                <span
                  className={`icon-inside-input ${
                    showPassword ? "visible" : ""
                  }`}
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye-slash password_icons"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                      <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                      <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                    </svg>
                  ) : null}
                </span>
                {/* Hide password icon */}
                <span
                  className={`icon-inside-input ${
                    showPassword ? "" : "visible"
                  }`}
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? null : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye password_icons"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                    </svg>
                  )}
                </span>

                <label
                  className={`labels ${password ? "float" : ""}`}
                  htmlFor=""
                >
                  Password
                </label>
                {formErrors.password && (
                  <span className="error error_msg_password mb-2">
                    {formErrors.password}
                  </span>
                )}
              </div>

              {/*Remember me & password section */}
              <div className=" custom-media d-flex flex-row justify-content-end mb-3 custom-media ">
                {/* <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    Remember me
                  </label>
                </div> */}
                <div className="text-decoration-none mt-1">
                  <NavLink to="/forgotpassword">Forgot Password</NavLink>
                </div>
              </div>
              {/* Login Button */}
              <div className="py-2 ">
                {" "}
                <LoadingButton
                  // onClick={handleClick}
                  loading={loading}
                  // loadingPosition="start"
                  variant="outlined"
                  type="submit"
                  className={`w-100 mt-2 login_button ${
                    loading ? "while_submitting" : ""
                  }`}
                >
                  <span>Login</span>
                </LoadingButton>
              </div>
              <div className=" createnew_newtoplat d-flex flex-row  justify-content-center align-items-center  fs-6 py-3">
                <p className="m-0 space_login">New on our platform?</p>

                <NavLink to="/signup">Create an account</NavLink>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
