import React from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const FirstPageDataTable = ({ color, rows, columns }) => {
  const rowHeight = 52; // Default row height in DataGrid
  const headerHeight = 155; // Default header height in DataGrid
  const maxRows = 5; // Maximum number of rows per page
  const visibleRows = Math.min(rows.length, maxRows);
  const gridHeight = headerHeight + rowHeight * visibleRows + 52; // Added 52 for pagination height

  return (
    <Box
      sx={{
        height: `${gridHeight}px`,
        width: "100%",
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;",
        borderRadius: "8px",
      }}
      disableRowSelectionOnClick
    >
      <DataGrid
        // componentsProps={{ panel: { disablePortal: true } }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: maxRows,
            },
          },
        }}
        pageSizeOptions={[5, 10, 50, 100]}
        density="comfortable"
        disableRowSelectionOnClick
        sx={{
          // "& .MuiDataGrid-columnSeparator": {
          //   display: "none",
          // },
          "&.MuiDataGrid-root": {
            border: "none",
            borderRadius: "5px", // Adjust border radius as needed
            overflow: "hidden", // Ensure content respects the border radius
          },
          ".MuiDataGrid-columnHeader": {
            backgroundColor: color.textWhiteColor,
          },
          ".MuiDataGrid-filler": {
            backgroundColor: color.textWhiteColor,
          },
          ".MuiDataGrid-columnHeaderTitle": {
            fontFamily: "Open-Sans-Light",
            fontWeight: "bold",
            padding: "5px 0px",
            fontSize: "0.85rem",
            textTransform: "capitalize",
            color: color.taxtHeading,
          },
          ".MuiDataGrid-columnHeader--alignCenter": {
            headerAlign: "left",
          },

          ".MuiDataGrid-row": {
            border: "none", // Remove border from rows
          },
          "& .MuiDataGrid-columnHeader": {
            // backgroundColor: "rgba(234, 235, 237, 0.95)",
            color: "black",
          },
          // "& .MuiTablePagination-actions": {
          //   marginBottom: 2,
          // },
          // "& .MuiInputBase-root": {
          //   marginBottom: 1.5,
          // },
          ".MuiDataGrid-row": {
            border: "none", // Remove border from rows
            backgroundColor: color.textWhiteColor, // Set rows background color to white
          },
          ".MuiDataGrid-footerContainer": {
            backgroundColor: color.textWhiteColor, // Set footer background color to white
            borderTop: "none",
            padding: "0px ",
          },
          // "& .wrapCell": {
          //   whiteSpace: "normal !important", // Allow wrapping
          //   wordWrap: "break-word", // Break words when necessary
          //   WebkitLineClamp: 1,
          //   // WebkitBoxOrient: "vertical",
          //   // lineHeight: "1.5em", // Adjust line height if needed
          //   // paddingTop: "1rem",
          //   // overflow: "hidden !important", // Ensure visibility
          // },
        }}
      />
    </Box>
  );
};

export default FirstPageDataTable;
