import React, { useContext, useState } from "react";
import ShowTicket from "./ShowTicket";
import "../assets/styles/AllTickets.css";
import ReactPaginate from "react-paginate";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ContextState from "./contextApi/ContextState";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip as BootstrapTooltip } from "react-bootstrap";
import { CiExport } from "react-icons/ci";
import { Tooltip } from "@mui/material";

export function MyVerticallyCenteredModal(props) {
  const [deleteRemark, setDeleteRemark] = useState("");
  const { openSnackbar, fetchApi } = useContext(ContextState);
  const [deleteBtnLoading, setdeleteBtnLoading] = useState(false);

  const handleDelete = async () => {
    // openSnackbar("Ticket deleted succesfully", "success");
    let isMounted = true;
    const payload = {
      ticket_id: props.ticketnum,
      reason: deleteRemark,
    };
    setdeleteBtnLoading(true);
    if (deleteRemark.trim() !== "") {
      try {
        const response = await fetchApi(
          "delete_ticket",
          "POST",
          payload,
          isMounted
        );
        // // Check if the response is successful
        if (response.statusCode === 200) {
          // Reload the React component
          // console.log("ticket deleted succesfully");
          await props.reloadData();
          openSnackbar(response.data.message, "success");
          // resetting the delete description when ticket is sucessfully
          setDeleteRemark("");

          // openSnackbar(`Ticket ${deleteRemark} deleted succesfully`, "success");
        } else if (response.statusCode === 403) {
          openSnackbar(response.msg, "error");
        } else {
          console.error(
            "Error editing ticket information:",
            response.statusCode
          );
          openSnackbar(response.msg, "error");
        }
      } catch (error) {
        openSnackbar("some error occured while saving details", "error");
        console.error("Some Error occured:", error);
      } finally {
        setdeleteBtnLoading(false);
      }
      props.onHide();
    } else {
      openSnackbar("Remark cannot be empty", "warning");

      setdeleteBtnLoading(false);
    }

    return () => {
      isMounted = false;
    };
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="delete-modal-header">
        <Modal.Title id="contained-modal-title-vcenter text-danger">
          {/* Delete Ticket {props.ticketnum} */}
          <div>
            Delete Ticket{" "}
            <span style={{ color: "red" }}>{props.ticketnum}</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="delete-modal-body">
        <p className="mt-0">
          This action will remove all of your Ticket information. This cannot be
          undone.
        </p>
        <span>Add Reason: </span>
        <InputGroup>
          {/* <InputGroup.Text>
            <p>Add Remark</p>
          </InputGroup.Text> */}

          <Form.Control
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            // style={{ minHeight: "75px", maxHeight: "300px" }}
            value={deleteRemark} // Controlled component
            onChange={(e) => setDeleteRemark(e.target.value)} // Update deleteRemark state on input change
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleDelete}>Delete Ticket</Button> */}
        <LoadingButton
          size="large"
          onClick={handleDelete}
          endIcon={<SendIcon />}
          loading={deleteBtnLoading}
          disabled={deleteRemark.length === 0}
          loadingPosition="end"
          variant="contained"
          style={{
            padding: "5px 10px",
            fontSize: "0.85rem",
            textTransform: "capitalize",
          }}
        >
          <span className={deleteBtnLoading ? "me-4" : ""}>Delete Ticket</span>
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  );
}

function Tickets({
  ticketData,
  currentPage,
  handelCurrentPage,
  liveMachinesData,
  cardsPerPage,
  totalCardsCount,
  reloadData,
  onExportLocal,
  onDetailedExportLocal,
  moreDetailsSpinner,
}) {
  // const [currentPage, handelCurrentPage] = useState(0); // Initialize currentPage to 0
  // Calculate the index range for the current page
  // const indexOfLastCard = (currentPage + 1) * cardsPerPage;
  // const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  // const currentTickets = ticketData.slice(indexOfFirstCard, indexOfLastCard);
  const currentTickets = ticketData?.ticket_list || [];

  // console.log("Current Ticket --->", ticketData);
  // Change page
  const handlePageClick = (data) => {
    // console.log("---->", data.selected);
    handelCurrentPage(data.selected);
  };

  const [deleteModal, setdeleteModal] = useState({
    show: false,
    ticketID: null,
  });

  const handelDeleteModal = (show, ticketID) => {
    setdeleteModal((prevSate) => {
      return { show: show, ticketID: ticketID };
    });
  };

  // ticket_data['ticket_id'] = ticket[0]
  // ticket_data['issue'] = ticket[1]
  // ticket_data['description'] = ticket[2]
  // ticket_data['status'] = ticket[3]
  // ticket_data['creation_time'] = ticket[4]
  // ticket_data['resolution_time'] = ticket[5]
  // ticket_data['machines'] = ticket[6]
  // ticket_data['name'] = ticket[7]

  // const startIndex = currentPage * cardsPerPage + 1;
  // const endIndex = Math.min((currentPage + 1) * cardsPerPage, totalCardsCount);

  const getTimeStr = (ticket) => {
    const currentTime = new Date();
    const inputDate = ticket?.creation_time?.replace("GMT", "GMT+0530");
    const TicketCeratedTime = new Date(inputDate);
    // Calculate the difference in milliseconds
    const timeDifferenceInMilliseconds = currentTime - TicketCeratedTime;
    // console.log(
    //   currentTime,
    //   ticket.creation_time,
    //   TicketCeratedTime,
    //   timeDifferenceInMilliseconds,
    //   ticket.ticket_id
    // );
    let CreatedTime = "Created ";

    // if (support_state !== 'done') {
    const minutesDifference = Math.floor(
      timeDifferenceInMilliseconds / (1000 * 60)
    );

    if (timeDifferenceInMilliseconds < 0) {
      CreatedTime += 0 + " minutes ago";
    } else {
      if (minutesDifference < 60) {
        CreatedTime += minutesDifference + " minutes ago";
      } else {
        const hoursDifference = Math.floor(minutesDifference / 60);

        if (hoursDifference < 24) {
          CreatedTime += hoursDifference + " hours ago";
        } else {
          const daysDifference = Math.floor(hoursDifference / 24);

          if (daysDifference < 7) {
            CreatedTime += daysDifference + " days ago";
          } else {
            const weeksDifference = Math.floor(daysDifference / 7);

            if (weeksDifference < 4) {
              CreatedTime += weeksDifference + " weeks ago";
            } else {
              const monthsDifference = Math.floor(daysDifference / 30);

              if (monthsDifference < 12) {
                CreatedTime += monthsDifference + " months ago";
              } else {
                const yearsDifference = Math.floor(daysDifference / 365);
                CreatedTime += yearsDifference + " years ago";
              }
            }
          }
        }
      }
    }
    // console.log(currentTime,TicketCeratedTime, timeDifferenceInMilliseconds);

    let ResolvedTime = "Resolved ";
    let resolvedMinutesDifference = 0;
    if (ticket?.resolution_time) {
      // console.log("Resolve is working ----> ", ticket);
      const inputResolvedDate = ticket?.resolution_time?.replace(
        "GMT",
        "GMT+0530"
      );
      const TicketResolvedTime = new Date(inputResolvedDate);
      // Calculate the difference in milliseconds
      const resolvedtimeDifferenceInMilliseconds =
        TicketResolvedTime - TicketCeratedTime;
      // console.log(
      //   currentTime,
      //   ticket.creation_time,
      //   TicketCeratedTime,
      //   timeDifferenceInMilliseconds,
      //   ticket.ticket_id
      // );

      // if (support_state !== 'done') {
      resolvedMinutesDifference = Math.floor(
        resolvedtimeDifferenceInMilliseconds / (1000 * 60)
      );

      if (resolvedtimeDifferenceInMilliseconds < 0) {
        ResolvedTime += "in " + 0 + " minutes ";
      } else {
        if (resolvedMinutesDifference < 60) {
          ResolvedTime += "in " + resolvedMinutesDifference + " minutes ";
        } else {
          const resolvedHoursDifference = Math.floor(
            resolvedMinutesDifference / 60
          );

          if (resolvedHoursDifference < 24) {
            ResolvedTime += "in " + resolvedHoursDifference + " hours ";
          } else {
            const resolvedDaysDifference = Math.floor(
              resolvedHoursDifference / 24
            );

            if (resolvedDaysDifference < 7) {
              ResolvedTime += "in " + resolvedDaysDifference + " days ";
            } else {
              const resolvedWeeksDifference = Math.floor(
                resolvedDaysDifference / 7
              );

              if (resolvedWeeksDifference < 4) {
                ResolvedTime += "in " + resolvedWeeksDifference + " weeks ";
              } else {
                const resolvedMonthsDifference = Math.floor(
                  resolvedDaysDifference / 30
                );

                if (resolvedMonthsDifference < 12) {
                  ResolvedTime += "in " + resolvedMonthsDifference + " months ";
                } else {
                  const resolvedYearsDifference = Math.floor(
                    resolvedDaysDifference / 365
                  );
                  ResolvedTime += "in " + resolvedYearsDifference + " years ";
                }
              }
            }
          }
        }
      }

      // console.log("Resoution string ", ResolvedTime);
    }
    const hoursDifference = Math.floor(minutesDifference / 60);
    return {
      hoursDifference,
      CreatedTime,
      ResolvedTime,
      resolvedMinutesDifference,
    };
  };

  return (
    <>
      {ticketData?.ticket_list ? (
        <>
          <div className="container">
            <div className="count_heading_div text-end  mb-2 pe-0 text-nowrap d-flex ms-auto">
              <div className="ms-auto"> {ticketData?.count_heading || ""}</div>

              {/* <i
                  onClick={() => onExportLocal(ticketData?.count_heading || "")}
                ></i> */}
              {/* export details for basic data && more data */}

              {/* <span
                  className="export_all_data_btn"
                  onClick={() => onExportLocal(ticketData?.count_heading || "")}
                >
                  <CiExport />
                </span> */}

              <div
                className="dropdow dropdown_export mx-2"
                style={{ cursor: "pointer" }}
              >
                <Tooltip data-html="true" title={"Export Data"} arrow={true}>
                  <i
                    className="fa-solid fa-ellipsis-vertical  "
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ padding: "0px 3px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  ></i>
                </Tooltip>
                {/* Centered dropdown */}
                {/* </button> */}
                <ul
                  className="dropdown-menu dropdown-menu-end delete-ticket-btn-border"
                  style={{ padding: "4px 0px" }}
                >
                  <li>
                    <a
                      className="dropdown-item text-center edit-ticket-btn"
                      href="#"
                      onClick={(e) => {
                        // e.stopPropagation();
                        onExportLocal(ticketData?.count_heading || "");
                      }}
                    >
                      <span className="export_all_data_btn">
                        <CiExport className="me-2" />
                      </span>
                      Basic Details
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item text-center edit-ticket-btn"
                      href="#"
                      onClick={(e) => {
                        e.stopPropagation();
                        onDetailedExportLocal(ticketData?.count_heading || "");
                      }}
                    >
                      <span className="export_all_data_btn">
                        {moreDetailsSpinner.icon ? (
                          <CiExport className="me-2" />
                        ) : moreDetailsSpinner.spinner ? (
                          <>
                            <div
                              class="spinner-border spinner-border-sm me-2"
                              role="status"
                              style={{ borderWidth: "2px" }}
                            >
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </span>
                      More Details
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <MyVerticallyCenteredModal
              show={deleteModal.show}
              ticketnum={deleteModal.ticketID}
              onHide={() => handelDeleteModal(false)}
              reloadData={reloadData}
            />

            <div className="cards-parent">
              {/* Render ShowTicket component for each ticket */}
              {currentTickets &&
                currentTickets?.map((ticket) => {
                  const timeInfo = getTimeStr(ticket);
                  return (
                    <ShowTicket
                      key={ticket.ticket_id}
                      ticketId={ticket.ticket_id}
                      issueType={ticket.issue}
                      description={ticket.description}
                      status={ticket.status}
                      subIssueType={ticket.sub_issue}
                      liveMachinesData={liveMachinesData}
                      createdDate={
                        new Date(
                          ticket.creation_time.replace("GMT", "GMT+0530")
                        ).toDateString() || ""
                      }
                      resolvedDate={
                        ticket.resolution_time
                          ? new Date(
                              ticket.resolution_time.replace("GMT", "GMT+0530")
                            ).toDateString()
                          : ""
                      }
                      machines={ticket.machines}
                      generatedBy={ticket.assignee || "GenereatedBy"}
                      internetIssue={ticket.internet_issue}
                      // getTimeSring={timeInfo}
                      getTimeSring={timeInfo.CreatedTime} // Pass the CreatedTime property
                      resolvedTimeString={timeInfo.ResolvedTime} // Pass the ResolvedTime property
                      hoursDifference={timeInfo.hoursDifference} // Pass the hoursDifference property
                      resolvedHoursDifference={
                        timeInfo.resolvedMinutesDifference
                      } // Pass the resolvedMinutesDifference property
                      reloadData={reloadData}
                      handelDeleteModal={handelDeleteModal}
                    />
                  );
                })}
            </div>
            {/* <p>
        (Showing {startIndex}-{endIndex} of {totalCardsCount} tickets)
      </p> */}

            <div className=" pagination-head-container p-4 pe-0 me-0">
              <ReactPaginate
                pageCount={Math.ceil(totalCardsCount / cardsPerPage)}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                containerClassName={"pagination"}
                activeClassName={"active"}
                disabledClassName={"disabled"}
                renderOnZeroPageCount={null}
                forcePage={currentPage} // Ensure active page is highlighted
              />
            </div>
          </div>
        </>
      ) : (
        <div className="container no-ticket-found-div text-center count_heading_div">
          <div className="d-flex align-items-center justify-content-center flex-column">
            <i className="fa-solid fa-face-rolling-eyes fa-2xl inline-block"></i>
            <p>{ticketData?.message || ""}</p>
          </div>
        </div>
      )}
    </>
  );
}

export default Tickets;
