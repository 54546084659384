import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, IconButton, TableCell } from "@mui/material";

import "../../assets/styles/MoreDetailsdataTable.css";
import AddIcon from "@mui/icons-material/Add";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";

export default function BasicGroupingDemo({ tableStatus, data }) {
  const buttons = [
    { label: "Software", clicked: true },
    { label: "Hardware", clicked: false },
    { label: "Data Variation", clicked: false },
  ];
  const [clickedButtonIndex, setClickedButtonIndex] = useState(0);
  const [tableData, setTableData] = useState({
    Software: true,
    Hardware: false,
    Data: false,
  });

  // current date
  const currentDate = new Date().toISOString().split("T")[0];

  // console.log("tableData", tableData);

  // function to handle group button click
  const handleButtonClick = (index, label) => {
    label = label === "Data Variation" ? "Data" : label;
    setClickedButtonIndex(index);
    setTableData((prevState) => {
      const updatedState = {};
      for (const key in prevState) {
        updatedState[key] = key === label;
      }
      return updatedState;
    });
  };

  const [selectedRow, setSelectedRow] = React.useState(null);
  const [showMoreDetailsModal, setShowMoreDetailsModal] = React.useState(false);

  const handleOpen = () => {
    setShowMoreDetailsModal(true);
  };

  const handleClose = () => {
    setShowMoreDetailsModal(false);
    handleButtonClick(0, "Software");
  };

  let rows = [];
  let columns = [];
  let dataVariationRow = [];
  let dataVariationColumn = [];
  if (tableStatus.Software && data.issue_data["Software"]) {
    rows = data.issue_data["Software"];
    columns = data.issue_headers["Software"];
  } else if (tableStatus.Hardware && data.issue_data["Hardware"]) {
    rows = data.issue_data["Hardware"];
    columns = data.issue_headers["Hardware"];
  } else if (
    tableStatus.Data &&
    data.issue_data["Data Variation"] &&
    data.crops_data
  ) {
    rows = data.issue_data["Data Variation"];
    columns = data.issue_headers["Data Variation"];
    dataVariationRow = data.crops_data;
    dataVariationColumn = data.crops_column;
  }

  //   console.log("rows, columns", rows, columns);

  return (
    <>
      <Box
        sx={{
          marginBottom: 5,
          width: "100%",
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "rgba(234, 235, 237, 0.95)",
            color: "black",
          },
          "& .MuiDataGrid-row": {
            backgroundColor: "white",
            color: "black",
          },
          // "& .MuiTablePagination-actions": {
          //   marginBottom: 2,
          // },
          // "& .MuiInputBase-root": {
          //   marginBottom: 1.5,
          // },
        }}
      >
        <DataGrid
          disableRowSelectionOnClick
          autoHeight
          rows={rows?.map((row, index) => ({
            id: index + 1,
            ...row,
          }))}
          initialState={{
            ...data.initialState,
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          pageSizeOptions={[25, 50, 100]}
          columns={columns}
          //   columnGroupingModel={data?.final_columns_group}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              csvOptions: {
                fileName: `Location_Insights_Data(${currentDate})`,
              },
              printOptions: {
                fileName: `Location_Insights_Data(${currentDate})`,
              },
            },
          }}
        />
      </Box>

      {tableStatus.Data && (
        <Box
          sx={{
            marginBottom: 5,
            width: "100%",
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "rgba(234, 235, 237, 0.95)",
              color: "black",
            },
            "& .MuiDataGrid-row": {
              backgroundColor: "white",
              color: "black",
            },
            // "& .MuiTablePagination-actions": {
            //   marginBottom: 2,
            // },
            // "& .MuiInputBase-root": {
            //   marginBottom: 1.5,
            // },
          }}
        >
          <DataGrid
            autoHeight
            rows={dataVariationRow?.map((row, index) => ({
              id: index + 1,
              ...row,
            }))}
            initialState={{
              ...data.initialState,
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            pageSizeOptions={[25, 50, 100]}
            columns={dataVariationColumn}
            //   columnGroupingModel={data?.final_columns_group}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                csvOptions: {
                  fileName: `Location_Insights_Data(${currentDate})`,
                },
                printOptions: {
                  fileName: `Location_Insights_Data(${currentDate})`,
                },
              },
            }}
          />
        </Box>
      )}
    </>
  );
}
