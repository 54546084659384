import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Box,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import close from "../../assets/images/close.svg";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import ContextState from "../contextApi/ContextState";

function EmployeeDetails({ genFields, setGenFields, isSmallScreen }) {
  const [modalState, setModalState] = useState(false);
  const [area_data, setArea_data] = useState([]);
  const [prev_loc_data, setPrev_loc_data] = useState(null);
  const { fetchApi, openSnackbar } = useContext(ContextState);
  const [prev_details_modal, setPrev_details_modal] = useState(false);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");

  function handleOpenModal() {
    setGenFields((prev) => {
      return {
        ...prev,
        employeeDetails: {
          ...prev.employeeDetails,
          name: "",
          phone: "",
          email: "",
          employee_id: "",
          designation: "",
          new_emp_details: false,
        },
      };
    });

    setPrev_loc_data(null);
    setModalState(true);
  }
  function handleCloseModal(state, reason) {
    if (reason && reason === "backdropClick") {
      return;
    }
    if (state) {
      setModalState(false);
      setGenFields((prev) => {
        return {
          ...prev,
          employeeDetails: {
            ...prev.employeeDetails,
            new_emp_details: true,
          },
        };
      });
    } else {
      setModalState(false);
      setGenFields((prev) => {
        return {
          ...prev,
          employeeDetails: {
            ...prev.employeeDetails,
            new_emp_details: false,
          },
        };
      });
    }
  }

  const desg = ["TA", "MQC", "AGMQC"];
  const level = ["Region", "District", "Location"];

  const all_location_data = useSelector(
    (state) => state?.createTicketDetail?.data
  );

  const get_area_wise_data = (level) => {
    // console.log("level ---->", level);
    if (level === "Region") {
      const data_reg = Object.keys(all_location_data?.filters_data || {});
      setArea_data((prev) => [...data_reg]);
    } else if (level === "District") {
      const dist_data = Object.entries(
        all_location_data?.filters_data || {}
      )?.flatMap((item) => Object.keys(item[1]));
      setArea_data((prev) => [...dist_data]);
    } else {
      const loc_data = new Set(
        all_location_data?.machines_data?.map((items) => items.location)
      );
      setArea_data((prev) => [...loc_data]);
    }
  };

  const getting_contact_details = async () => {
    try {
      setGenFields((prev) => ({
        ...prev,
        employeeDetails: {
          ...prev.employeeDetails,
          name: "",
          phone: "",
          email: "",
          employee_id: "",
          designation: "",
          new_emp_details: false,
        },
      }));

      const payload = {
        level: genFields?.employeeDetails?.level,
        location_name: genFields?.employeeDetails?.location_name,
      };

      const response = await fetchApi(
        "get_emp_track_details",
        "POST",
        payload,
        true
      );

      if (response?.statusCode === 200) {
        setPrev_loc_data(response?.data);

        setPrev_details_modal(true);
        // Open modal if employee_details exist
        if (response.data?.employee_details) {
        } else {
          setPrev_details_modal(false);
        }
      } else {
        openSnackbar(response.msg, "error");
      }
    } catch (error) {
      openSnackbar(
        "Some error occurred while getting employee track details",
        "error"
      );
      // console.error("Internal server error:", error);
    }
  };

  useEffect(() => {
    if (genFields?.employeeDetails?.level) {
      setPrev_loc_data(null);
      setArea_data([]);
      get_area_wise_data(genFields?.employeeDetails?.level);
    }
  }, [genFields?.employeeDetails?.level]);

  const handleRemoveNewDetails = () => {
    setPrev_details_modal(false);
    setGenFields((prev) => {
      return {
        ...prev,
        employeeDetails: {
          ...prev.employeeDetails,
          name: "",
          phone: "",
          email: "",
          employee_id: "",
          designation: "",
          new_emp_details: false,
          level: "",
          location_name: "",
          prev_details: false,
        },
      };
    });
  };

  const handleOnChangePrevDetails = (data) => {
    setGenFields((prev) => {
      return {
        ...prev,
        employeeDetails: {
          ...prev.employeeDetails,
          name: data?.Name,
          phone: data?.Phone,
          email: data?.Email,
          employee_id: data["Employee Id"],
          designation: data?.Designation,
        },
      };
    });
  };

  return (
    <>
      <div className="w-100">
        <div
          style={{
            width: "100%",
            gap: 25,
            display: "flex",
            marginTop: "25px",
            flexDirection: isSmallScreen ? "column" : "row",
          }}
          className="me-auto"
        >
          <FormControl
            fullWidth
            sx={{
              "& .MuiOutlinedInput-input": {
                padding: "13px",
              },
            }}
          >
            <InputLabel id="demo-simple-select-label">Choose Level</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={genFields?.employeeDetails?.level}
              label="Choose Level"
              onChange={(event) => {
                setGenFields((prev) => {
                  return {
                    ...prev,
                    employeeDetails: {
                      ...prev.employeeDetails,
                      level: event.target.value,
                      location_name: "",
                    },
                  };
                });
              }}
            >
              {level.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Autocomplete
            sx={{
              "& .MuiAutocomplete-inputRoot": {
                padding: "5px",
              },
            }}
            className="w-100"
            disablePortal
            size="medium"
            id="combo-box-demo"
            options={area_data}
            value={genFields?.employeeDetails?.location_name}
            renderInput={(params) => <TextField {...params} label="Location" />}
            onChange={(event, newValue) => {
              if (newValue) {
                setGenFields((prev) => ({
                  ...prev,
                  employeeDetails: {
                    ...prev.employeeDetails,
                    location_name: newValue,
                  },
                }));
              }

              setPrev_loc_data(null);
            }}
          />
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "20px",
          paddingBottom: "20px",
          // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          justifyContent: "space-between",
        }}
      >
        <div className="">
          <Button
            className="me-2"
            variant="outlined"
            disabled={
              genFields?.employeeDetails?.level &&
              genFields?.employeeDetails?.location_name
                ? false
                : true
            }
            onClick={getting_contact_details}
          >
            Get Details
          </Button>

          <Button
            className="ms -2"
            variant="outlined"
            onClick={handleOpenModal}
            disabled={
              genFields?.employeeDetails?.level &&
              genFields?.employeeDetails?.location_name &&
              prev_loc_data
                ? false
                : true
            }
          >
            Add New Details
          </Button>
        </div>

        {/* message for data  */}
        {prev_loc_data?.message ? (
          <div className="mt-4">
            <p className="mb-0 fs-5">{prev_loc_data?.message}</p>
          </div>
        ) : null}

        {/* Displaying new contact details */}
        {(genFields?.employeeDetails?.new_emp_details ||
          genFields?.employeeDetails?.prev_details) && (
          <div
            style={{
              display: "flex",
              marginTop: "25px",
              flexDirection: "column",
              padding: "12px 16px",
              border: "1px solid #e5e6e8",
              borderRadius: "8px",
            }}
          >
            <div
              className="d-flex justify-content-between pb-2"
              style={{
                color: "#4c4e64e6",
                borderBottom: "1px solid rgb(245, 245, 245)",
              }}
            >
              <p className="mb-0">
                {genFields?.employeeDetails?.new_emp_details
                  ? "New Details"
                  : "Previous Details"}
              </p>
              <div
                onClick={() => {
                  handleRemoveNewDetails();
                }}
              >
                <img
                  src={close}
                  alt="close"
                  style={{ width: "15px", cursor: "pointer" }}
                />
              </div>
            </div>
            <div className="d-flex my-1">
              <div className="raise_display_width"> Name</div>{" "}
              <div className="raise_display_details">
                <span className="me-2">:</span>
                {genFields?.employeeDetails?.name}
              </div>
            </div>
            <div className="d-flex mb-1">
              <div className="raise_display_width "> Contact</div>{" "}
              <div className="raise_display_details">
                <span className="me-1">:</span>{" "}
                {genFields?.employeeDetails?.phone}
              </div>
            </div>
            <div className="d-flex mb-1">
              <div className="raise_display_width"> Email</div>{" "}
              <div className="raise_display_details">
                <span className="me-2">:</span>
                {genFields?.employeeDetails?.email}
              </div>
            </div>
            <div className="d-flex mb-1 ">
              <div className="raise_display_width"> Employee Id </div>{" "}
              <div className="raise_display_details">
                <span className="me-1">:</span>{" "}
                {genFields?.employeeDetails?.employee_id}
              </div>
            </div>
            <div className="d-flex mb-1">
              <div className="raise_display_width"> Designation</div>{" "}
              <div className="raise_display_details">
                <span className="me-2">:</span>
                {genFields?.employeeDetails?.designation}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Modal for New Details Entry */}
      <Modal
        open={modalState}
        onClose={handleCloseModal}
        style={{
          position: "fixed",
          overflowY: "scroll",
          height: "100%",
        }}
      >
        <Box
          style={{
            position: "relative",
            zIndex: "1111",
            right: "0",
            top: "16%",
            left: "0",
            margin: isMediumScreen ? "10px" : "auto",
            borderRadius: "10px",
            backgroundColor: "#ffffff",
            maxWidth: "1300px",
            height: "auto",
          }}
        >
          {/* Modal Header */}
          <div
            style={{ padding: "16px 24px", borderBottom: "1px solid #f5f5f5" }}
          >
            <div className="d-flex justify-content-between">
              <h5 className="m-0">Add New Details</h5>
              <div
                onClick={() => {
                  handleCloseModal(false);
                }}
              >
                <img
                  src={close}
                  alt="close"
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </div>
            </div>
          </div>
          {/* Modal Body */}
          <div style={{ padding: "0px 24px" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "20px",
                paddingBottom: "20px",
                // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "100%",
                  gap: 25,
                  display: "flex",
                  marginTop: "25px",
                  flexDirection: isSmallScreen ? "column" : "row",
                }}
              >
                <TextField
                  value={genFields.employeeDetails.name}
                  fullWidth
                  id="outlined-basic"
                  label="Employee Name"
                  variant="outlined"
                  onChange={(event) => {
                    setGenFields((prev) => {
                      return {
                        ...prev,
                        employeeDetails: {
                          ...prev.employeeDetails,
                          name: event.target.value,
                        },
                      };
                    });
                  }}
                />
                <TextField
                  value={genFields.employeeDetails.phone}
                  fullWidth
                  id="outlined-basic"
                  label="Contact"
                  type="number"
                  variant="outlined"
                  onChange={(event) => {
                    setGenFields((prev) => {
                      return {
                        ...prev,
                        employeeDetails: {
                          ...prev.employeeDetails,
                          phone: event.target.value,
                        },
                      };
                    });
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  gap: 25,
                  display: "flex",
                  marginTop: "25px",
                  flexDirection: isSmallScreen ? "column" : "row",
                }}
              >
                <TextField
                  value={genFields.employeeDetails.email}
                  fullWidth
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  onChange={(event) => {
                    setGenFields((prev) => {
                      return {
                        ...prev,
                        employeeDetails: {
                          ...prev.employeeDetails,
                          email: event.target.value,
                        },
                      };
                    });
                  }}
                />
                <TextField
                  value={genFields.employeeDetails.employee_id}
                  fullWidth
                  id="outlined-basic"
                  label="Employee ID"
                  variant="outlined"
                  onChange={(event) => {
                    setGenFields((prev) => {
                      return {
                        ...prev,
                        employeeDetails: {
                          ...prev.employeeDetails,
                          employee_id: event.target.value,
                        },
                      };
                    });
                  }}
                />
              </div>

              <div
                style={{
                  width: isSmallScreen ? "100%" : "49%",
                  gap: 25,
                  display: "flex",
                  marginTop: "25px",
                  flexDirection: isSmallScreen ? "column" : "row",
                }}
                className="me-auto"
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Designation
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={genFields?.employeeDetails?.designation}
                    label="Designation"
                    variant="outlined"
                    onChange={(event) => {
                      setGenFields((prev) => {
                        return {
                          ...prev,
                          employeeDetails: {
                            ...prev.employeeDetails,
                            designation: event.target.value,
                          },
                        };
                      });
                    }}
                    size="large"
                  >
                    {desg.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          {/* Modal Footer */}
          <div className="px-4 py-3">
            <Button
              variant="contained"
              className="d-block ms-auto"
              onClick={() => {
                handleCloseModal(true);
              }}
              disabled={
                genFields?.employeeDetails?.name &&
                genFields?.employeeDetails?.phone
                  ? false
                  : true
              }
            >
              Add
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Modal for Previous data cards */}

      <Modal
        open={prev_details_modal}
        style={{
          position: "fixed",
          overflowY: "scroll",
          height: "100%",
        }}
      >
        <Box
          style={{
            position: "relative",
            zIndex: "1111",
            right: "0",
            top: "16%",
            left: "0",
            margin: "auto",
            borderRadius: "10px",
            backgroundColor: "#ffffff",
            maxWidth: "1300px",
            height: "auto",
          }}
        >
          {/* Modal Header */}
          <div
            style={{ padding: "16px 24px", borderBottom: "1px solid #f5f5f5" }}
          >
            <div className="d-flex justify-content-between">
              <h5 className="m-0">Add Previous Details</h5>
              <div
                onClick={() => {
                  setPrev_details_modal(false);
                  setGenFields((prev) => {
                    return {
                      ...prev,
                      employeeDetails: {
                        ...prev.employeeDetails,
                        name: "",
                        phone: "",
                        email: "",
                        employee_id: "",
                        designation: "",
                        new_emp_details: false,
                      },
                    };
                  });
                }}
              >
                <img
                  src={close}
                  alt="close"
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </div>
            </div>
          </div>
          {/* Modal Body */}
          <div style={{ padding: "0px 24px" }}>
            {prev_loc_data?.employee_details && (
              <div
                className="prev_data_cards"
                style={{
                  width: "100%",
                  alignItems: "center",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                {prev_loc_data?.employee_details?.map((item, index) => (
                  <div
                    className={`prev_details_cards d-flex flex-column ${
                      item?.Name == genFields?.employeeDetails?.name
                        ? "selected_prev_details"
                        : ""
                    }`}
                    style={{
                      padding: "12px 16px",
                      border: "1px solid #e5e6e8",
                      borderRadius: "8px",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                    onClick={() => {
                      handleOnChangePrevDetails(item);
                    }}
                  >
                    {/* <div
                      className="d-flex justify-content-between pb-2"
                      style={{
                        color: "#4c4e64e6",
                        borderBottom: "1px solid rgb(245, 245, 245)",
                      }}
                    >
                      <p className="mb-0">New Details</p>
                    </div> */}
                    <div className="d-flex my-1">
                      <div className="raise_display_width"> Name</div>{" "}
                      <div className="raise_display_details">
                        <span className="me-2">:</span>
                        {item?.Name !== null ? item?.Name : "-"}
                      </div>
                    </div>
                    <div className="d-flex mb-1">
                      <div className="raise_display_width "> Contact</div>{" "}
                      <div className="raise_display_details">
                        <span className="me-1">:</span>{" "}
                        {item?.Phone !== null ? item?.Phone : " -"}
                      </div>
                    </div>
                    <div className="d-flex mb-1">
                      <div className="raise_display_width"> Email</div>{" "}
                      <div className="raise_display_details">
                        <span className="me-2">:</span>
                        {item?.Email !== null ? item?.Email : " -"}
                      </div>
                    </div>
                    <div className="d-flex mb-1 ">
                      <div className="raise_display_width"> Employee Id </div>{" "}
                      <div className="raise_display_details">
                        <span className="me-2">:</span>{" "}
                        {item?.["Employee Id"] !== null
                          ? item?.["Employee Id"]
                          : " -"}
                      </div>
                    </div>
                    <div className="d-flex mb-1">
                      <div className="raise_display_width"> Designation</div>{" "}
                      <div className="raise_display_details">
                        <span className="me-2">:</span>
                        {item?.Designation !== null ? item?.Designation : " -"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* Modal Footer */}
          <div className="px-4 py-3">
            <Button
              variant="contained"
              className="d-block ms-auto"
              onClick={() => {
                setPrev_details_modal(false);
                setGenFields((prev) => {
                  return {
                    ...prev,
                    employeeDetails: {
                      ...prev.employeeDetails,
                      prev_details: true,
                      new_emp_details: false,
                    },
                  };
                });
              }}
              disabled={
                genFields?.employeeDetails?.name &&
                genFields?.employeeDetails?.phone
                  ? false
                  : true
              }
            >
              Add
            </Button>
          </div>

          {/* modal Body */}
        </Box>
      </Modal>
    </>
  );
}

export default EmployeeDetails;
