import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import Select from "@mui/material/Select";
import "../../assets/styles/CardModal.css";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import ContextState from "../contextApi/ContextState";
import { useSelector } from "react-redux";
import ModalError from "../error/ModalError";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import close from "../../assets/images/close.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function GeneralContent({
  data,
  reloadGeneralPage,
  reloadAllTicketsData,
  refreshBtn,
  setRefreshBtn,
  setRefreshLoading,
}) {
  const { ticketId } = data;

  // const [stateO, setStateO] = useState(false)
  const [loading, setLoading] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  const {
    fetchApi,
    url,
    showLoadingModal,
    formatDate,
    ticketStatus,
    lodingModalVisiblity,
    handleSetTicketStatus,
    openSnackbar,
    showModalError,
    showModalErrorPage,
  } = useContext(ContextState);

  const filterData = useSelector(
    (state) => state?.userFilters?.data?.data || []
  );

  // console.log("efjoeinfw===", filterData);

  const [edit, setEdit] = useState(false);
  const [markResolved, setMarkResolved] = useState(false);

  const [generalData, setGeneralData] = useState(null);
  const [status, setStatus] = useState("");
  const [issueType, setIssueType] = useState("");
  const [subIssueType, setSubIssueType] = useState("");
  const [resolutionDetails, setResolutionDetails] = useState("");
  const [assignTask, setAssignTask] = useState(null);
  const [internetStatus, setInternetStatus] = useState(false);
  const [description, setDescription] = useState("");
  const [visitRequired, setVisitRequired] = useState(false);

  // state variable to store the status to enable/disable the resolved button
  const [enabled, setEnabled] = useState(false);

  // Employee Details Edit state

  const [edited_Emp_Details, setEdited_Emp_Details] = useState({
    employee_name: "",
    employee_phone: "",
    employee_email: "",
    employee_id: "",
    employee_designation: "",
    level: "",
    location_name: "",
  });

  const [loadingSpinner, setLoadingSpinner] = useState(false);
  // Employee Edit details modal statet

  const [employeeEditModal, setEmployeeEditModal] = useState({
    modalState: false,
  });
  const [area_data, setArea_data] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, setStateFunction) => {
    setStateFunction(event.target.value);
  };

  function corresponding_id(issueOrSubIssue) {
    for (const issueType in filterData.issues[0]) {
      if (issueOrSubIssue === issueType) {
        // Return the ID of the issue type
        return filterData.issues[0][issueType].id;
      }
      // Iterate over each sub-issue of the current issue type
      for (const subIssue of filterData?.issues[0][issueType].sub_issue) {
        if (issueOrSubIssue === subIssue[1]) {
          // Return the ID of the sub-issue
          return subIssue[0];
        }
      }
    }
    return null; // Return null if no match is found
  }

  function getAssigneeId(assigneeName) {
    try {
      const assigneeList = filterData.assignee_list;
      for (const assignee of assigneeList) {
        if (assignee.name === assigneeName) {
          return assignee.id;
        }
      }
      // console.error("Assignee not found in the list.");
      return null;
    } catch (error) {
      // console.error("Error parsing JSON data:", error);
      return null;
    }
  }

  const handleSubmit = async () => {
    try {
      const payload = {
        ticket_id: ticketId,
        original_data: {
          issue: corresponding_id(generalData["Issue Type"]),
          sub_issue: corresponding_id(generalData["Sub Issue"]),
          assignee: getAssigneeId(generalData["Assign To"]),
          status: generalData["Status"],
          visit_required: generalData["Visit Required"],
          description: generalData["Error Details"],
          internet_issue: generalData["Internet Issue"],
        },
        updated_data: {
          issue: corresponding_id(issueType),
          sub_issue: corresponding_id(subIssueType),
          assignee: getAssigneeId(assignTask),
          status: status,
          visit_required: visitRequired,
          description: description,
          internet_issue: internetStatus,
        },
      };

      setLoading(true);
      // console.log("payload for edit info:---", payload);
      // Send the payload to the backend route
      const response = await fetchApi(
        "edit_ticket_info",
        "POST",
        payload,
        true
      );

      // Check if the response is successful
      if (response.statusCode === 200) {
        // Reload the React component
        // console.log("ticket edited succesfully");
        openSnackbar(response.data.message, "success");
        setReloadData((prevState) => !prevState);
        reloadAllTicketsData();
        setEdit(false);
        // setComment("");
      } else if (response.statusCode === 403) {
        openSnackbar(response.msg, "error");
      } else {
        openSnackbar(response.msg, "error");
      }
    } catch (error) {
      openSnackbar("some error occured while saving details", error);
      // console.error("Internal server error:", error);
      // console.error("Error editing ticket information:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleMarkResolved = async () => {
    try {
      const payload = {
        ticket_id: ticketId,
        resolution_description: resolutionDetails,
      };
      setLoading(true);

      const response = await fetchApi(
        "mark_ticket_resolved",
        "POST",
        payload,
        true
      );
      // Check if the response is successful
      if (response.statusCode === 200) {
        // console.log("ticket edited succesfully");
        openSnackbar("ticket marked as resolved succesfully", "success");
        setReloadData((prevState) => !prevState);
        reloadAllTicketsData();
      } else if (response.statusCode === 403) {
        openSnackbar(response.msg.message, "error");
      } else {
        // console.error("Error editing ticket information:", response.statusText);
        openSnackbar("error occured while adding ticket details", "error");
      }
    } catch (error) {
      // console.error("Error editing ticket information:", error);
      openSnackbar("Error occured while adding ticket details", "error");
    } finally {
      setLoading(false);
      // showLoadingModal(false);
    }
  };

  const createTicketData = async (jsonData) => {
    if (!jsonData || !jsonData.data || !jsonData.data.gen_details) {
      // console.log("-----------------");
      return null;
    }

    const { gen_details } = jsonData.data;
    // console.log("general details", gen_details);
    const ticketData = {
      "Ticket Id": gen_details.ticket_id,
      "Created Time": formatDate(gen_details.creation_time),
      "Created By": gen_details.raised_by,
      "Error Details": gen_details.description || "",
      Status: gen_details.status || "",
      Machines: gen_details.machines.join(", "),
      "Issue Type": gen_details.issue.desc || "",
      "Sub Issue": gen_details.sub_issue.desc || "",
      "Assign To": gen_details.assignee.name,
      Level: gen_details.employee_details.level,
      "Location Name": gen_details.employee_details.location_name,
      "Employee Id": gen_details.employee_details.employee_id,
      "Employee Email": gen_details.employee_details.email,
      "Employee Name": gen_details.employee_details.name,
      "Employee Phone": gen_details.employee_details.phone,
      Designation: gen_details.employee_details.designation,
      "Resolution Details":
        gen_details.resolution_details.resolution_description || "",
      "Resolved By": gen_details?.resolution_details.resolved_by || "",
      "Resolution Time": gen_details.resolution_details.resolution_time
        ? formatDate(gen_details.resolution_details.resolution_time)
        : "",
      "Internet Issue": gen_details.internet_issue || false,
      "Visit Required": gen_details.visit_required || false,
      "Last Updated": formatDate(gen_details.last_updated) || "",
    };

    // console.log("Ticket Data modified:", ticketData);
    return ticketData;
  };

  let isMounted = true;
  
  const fetchCardData = async () => {

    if(refreshBtn){
      setRefreshLoading(true);
    }

    let payload = {
      info_type: "general_data",
      ticket_id: ticketId,
    };

    let jsonData;

    if (isMounted) {
      jsonData = await fetchApi(
        "get_ticket_info",
        "POST",
        payload,
        isMounted
      );

      if (
        jsonData.statusCode === 200 &&
        jsonData &&
        jsonData.data.gen_details
      ) {
        const ticketData = await createTicketData(jsonData);
        setGeneralData(ticketData);

        // Updating the edit employee details
        setEdited_Emp_Details((prev) => ({
          employee_name: ticketData["Employee Name"],
          employee_phone: ticketData["Employee Phone"],
          employee_email: ticketData["Employee Email"],
          employee_id: ticketData["Employee Id"],
          employee_designation: ticketData["Designation"],
          level: ticketData["Level"],
          location_name: ticketData["Location Name"],
        }));

        showModalErrorPage(null, null);
        handleSetTicketStatus(jsonData?.data.gen_details.status || "pending");
        setStatus(ticketData["Status"]);
        setIssueType(ticketData["Issue Type"]);
        setSubIssueType(ticketData["Sub Issue"]);
        setAssignTask(ticketData["Assign To"]);
        setDescription(ticketData["Error Details"]);
        setInternetStatus(ticketData["Internet Issue"]);
        setVisitRequired(ticketData["Visit Required"]);
        setEnabled(jsonData.data.enabled);
      } else if (jsonData.statusCode === 500) {
        showModalErrorPage(jsonData.statusCode, jsonData.msg);
      } else {
        // openSnackbar(jsonData.data, "error")
        showModalErrorPage(jsonData.statusCode, jsonData.msg);
      }
    }
    showLoadingModal(false);
    setRefreshLoading(false);
    setRefreshBtn(false);
  };

  useEffect(() => {
    showLoadingModal(true);

    fetchCardData();

    return () => {
      isMounted = false;
    };
  }, [reloadData, reloadGeneralPage]);

  // Function to capitalize first letter
  // const capitalizeFirstLetter = (str) => {
  //   return str.charAt(0).toUpperCase() + str.slice(1);
  // };

  let statusClass;
  switch (generalData && generalData["Status"]) {
    case "pending":
      statusClass = "pending-color";
      break;
    case "ongoing":
      statusClass = "ongoing-color";
      break;
    case "resolved":
      statusClass = "resolved-color";
      break;
    case "validation":
      statusClass = "validation-color";
      break;
    default:
      statusClass = "ongoing-color";
  }

  // Edit details function starts here............

  const saving_edit_details = async () => {
    try {
      setLoadingSpinner(true);
      const payload = {
        ticket_id: ticketId,
        level: edited_Emp_Details?.level,
        location_name: edited_Emp_Details?.location_name,
        name: edited_Emp_Details.employee_name,
        phone: edited_Emp_Details?.employee_phone,
        email: edited_Emp_Details?.employee_email,
        emp_id: edited_Emp_Details?.employee_id,
        designation: edited_Emp_Details?.employee_designation,
      };

      // console.log("payload--------->", payload);
      const response = await fetchApi(
        "edit_employee_contact_info",
        "POST",
        payload,
        true
      );

      if (response?.statusCode === 200) {
        // console.log("response--------->", response);
        openSnackbar(response?.data?.message);
        handleCloseModal();
        setReloadData((prevState) => !prevState);
        reloadAllTicketsData();
        setLoading(false);
      } else {
        setLoading(false);
        openSnackbar(response.msg);
      }
    } catch (error) {
      openSnackbar("Some error occurred while saving edit details", "error");
    } finally {
      setLoadingSpinner(false);
    }
  };

  // func for closing modal

  const handleCloseModal = (e, reason) => {
    // console.log("------->", e, reason);
    if (reason && reason === "backdropClick") {
      return;
    }
    setEmployeeEditModal((prev) => ({
      ...prev,
      modalState: false,
    }));
  };

  const desg = ["TA", "MQC", "AGMQC"];

  const get_area_wise_data = (level) => {
    console.log("level ---->", level);
    if (level === "Region") {
      const data_reg = Object.keys(filterData?.filters_data);
      setArea_data((prev) => [...data_reg]);
    } else if (level === "District") {
      const dist_data = Object.entries(filterData?.filters_data)?.flatMap(
        (item) => Object.keys(item[1])
      );
      setArea_data((prev) => [...dist_data]);
    } else {
      const loc_data = Object.values(filterData?.machines_data);
      setArea_data((prev) => [...loc_data]);
    }
  };

  return (
    <>
      {(!showModalError.status && !lodingModalVisiblity && generalData && (
        <div className="general_page_main_details mt-3">
          {generalData?.Status.toLowerCase() === "resolved" ? (
            <>
              <button className="btn btn-success mb-3">Ticket Resolved</button>

              <div className="resolved_details_border row">
                <Box
                  className="resolved_details"
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, mx: 1, width: "51ch" },
                    "@media (max-width: 930px)": {
                      "& .MuiTextField-root": { width: "100%" },
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  {Object.entries(generalData).map(
                    ([key, value]) =>
                      (key === "Resolution Time" ||
                        key === "Resolved By" ||
                        key === "Resolution Details") && (
                        <div key={key} className="">
                          <label
                            htmlFor={`outlined-read-only-input-${key}`}
                            className="basic_details_elements_label"
                          >
                            {key}
                          </label>
                          <div
                            id={`outlined-read-only-input-${key}`}
                            className="basic_details_elements_content"
                          >
                            {value
                              ? Array.isArray(value)
                                ? value.map(([machineNo, location]) => (
                                    <div key={`${machineNo}-${location}`}>
                                      {machineNo} ({location})
                                    </div>
                                  ))
                                : value
                              : "-"}
                          </div>
                        </div>
                      )
                  )}
                </Box>
              </div>
            </>
          ) : (
            <>
              <div className="combined_buttons">
                <button className={`resolved-btn ${statusClass} mb-3`}>
                  {generalData["Status"]}
                </button>
                <button
                  className="btn btn-outline-primary markResolved_button mb-3"
                  onClick={() => setMarkResolved(!markResolved)}
                  disabled={
                    generalData["Status"].toLowerCase() !== "validation" ||
                    !enabled
                  }
                >
                  Mark Resolved
                </button>
              </div>

              {markResolved && (
                <div className="resolved_details_border row ">
                  <div className="col-xl-10">
                    <Box
                      className="basic_details"
                      component="form"
                      sx={{
                        "& .MuiTextField-root": { m: 1, width: "100%" },
                        "@media (max-width: 930px)": {
                          "& .MuiTextField-root": { width: "100%" },
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Resolution Details"
                        value={resolutionDetails}
                        onChange={(e) => handleChange(e, setResolutionDetails)}
                        multiline
                        maxRows={4}
                        required
                      />
                    </Box>
                  </div>
                  <div className="col-xl-1 mark_resolved_details_btn">
                    {/* <button
                    type="button"
                    className="btn btn-outline-success edit_basic_details_button"
                    onClick={handleMarkResolved}
                    disabled={resolutionDetails === ''}
                  >
                    {loading ? 'Loading...' : 'Add'}
                  </button> */}
                    <LoadingButton
                      size="large"
                      onClick={handleMarkResolved}
                      endIcon={<SendIcon />}
                      loading={loading}
                      disabled={resolutionDetails === ""}
                      loadingPosition="end"
                      variant="contained"
                    >
                      <span>Submit</span>
                    </LoadingButton>
                  </div>
                </div>
              )}
            </>
          )}

          {/* showing basic details from here */}
          <Box
            className="basic_details"
            component="form"
            sx={{
              // "& .MuiTextField-root": { m: 0, width: "45ch" },
              "@media (max-width: 2400px)": {
                "& .MuiTextField-root": { width: "32%" },
              },
              "@media (max-width: 1145px)": {
                "& .MuiTextField-root": { width: "48%" },
              },
              "@media (max-width: 768px)": {
                "& .MuiTextField-root": { width: "48%" },
              },
              "@media (max-width: 600px)": {
                "& .MuiTextField-root": { width: "100%" },
              },
            }}
            noValidate
            autoComplete="off"
          >
            {Object.entries(generalData).map(
              ([key, value]) =>
                key !== "Resolution Time" &&
                key !== "Resolved By" &&
                key !== "Resolution Details" &&
                key !== "Internet Issue" &&
                key !== "Visit Required" && (
                  <div key={key} className="basic_details_elements">
                    <label
                      htmlFor={`outlined-read-only-input-${key}`}
                      className="basic_details_elements_label"
                    >
                      {key}
                    </label>
                    <div
                      id={`outlined-read-only-input-${key}`}
                      className="basic_details_elements_content"
                    >
                      {value
                        ? Array.isArray(value)
                          ? value.map(([machineNo, location]) => (
                              <div key={`${machineNo}-${location}`}>
                                {machineNo} ({location})
                              </div>
                            ))
                          : value
                        : "-"}
                    </div>
                  </div>
                )
            )}

            {Object.entries(generalData).map(
              ([key, value]) =>
                (key === "Internet Issue" || key === "Visit Required") && (
                  <div
                    className="form-check basic_details_elements form-switch"
                    key={key}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id={`flexSwitchCheck${key}`}
                      checked={value} // Make the switch checked or unchecked based on the value
                      disabled
                    />
                    <label className="form-check-label">{key}</label>
                  </div>
                )
            )}
          </Box>

          {/* Edit button */}
          <div className="edit_basic_details">
            <button
              type="button"
              className="btn btn-outline-primary markResolved_button m-1 "
              disabled={ticketStatus === "resolved"}
              onClick={() => setEdit(!edit)}
            >
              <Tooltip
                data-html="true"
                title={"Click here to edit general info"}
                arrow={true}
                Edit
                Details
              >
                Edit Details
              </Tooltip>
            </button>
            <button
              type="button"
              className="btn btn-outline-primary markResolved_button m-1"
              disabled={generalData["Status"].toLowerCase() === "resolved"}
              // variant="outlined"
              onClick={() => {
                setEmployeeEditModal((prev) => ({
                  ...prev,
                  modalState: true,
                }));
              }}
            >
              Edit Employee Details
            </button>
          </div>

          {/* Render edit container */}
          {edit && (
            <div className="edit-container">
              <Box
                className="basic_details"
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "48ch" },
                  "& .MuiFormControl-root": { m: 1, width: "48ch" },

                  "@media (max-width: 1671px)": {
                    "& .MuiFormControl-root": { m: 1, width: "30%" },
                  },
                  // "@media (max-width: 1671px)": {
                  //   "& .MuiTextField-root": { width: "30%" },
                  // },

                  "@media (max-width: 1150px)": {
                    "& .MuiTextField-root": { width: "45%" },
                  },
                  "@media (max-width: 1150px)": {
                    "& .MuiFormControl-root": { m: 1, width: "45%" },
                  },

                  "@media (max-width: 686px)": {
                    "& .MuiTextField-root": { width: "100%" },
                  },
                  "@media (max-width: 686px)": {
                    "& .MuiFormControl-root": { m: 1, width: "100%" },
                  },
                }}
                noValidate
                autoComplete="off"
              >
                <FormControl sx={{ m: 1, width: "45ch" }} size="medium">
                  <InputLabel id="issueType-label">Issue Type</InputLabel>
                  <Select
                    labelId="issueType-label"
                    value={issueType}
                    onChange={(e) => {
                      const selectedIssueType = e.target.value;
                      setIssueType(selectedIssueType);
                      // Update the sub-issue type based on the selected issue type
                      setSubIssueType(
                        filterData?.issues?.find(
                          (issue) => issue[selectedIssueType]
                        )?.[selectedIssueType]?.sub_issue[0][1]
                      );
                    }}
                    label="Issue Type"
                  >
                    {filterData?.issues[0] &&
                      Object.keys(filterData?.issues[0]).map(
                        (issueType, index) => (
                          <MenuItem key={index} value={issueType}>
                            {issueType}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, width: "45ch" }} size="medium">
                  <InputLabel id="subIssueType-label">
                    Sub Issue Type
                  </InputLabel>
                  <Select
                    labelId="subIssueType-label"
                    value={subIssueType}
                    onChange={(e) => handleChange(e, setSubIssueType)}
                    label="Sub Issue Type"
                  >
                    {issueType &&
                      filterData?.issues
                        ?.find((issue) => issue[issueType])
                        ?.[issueType]?.sub_issue.map((subIssue, index) => (
                          <MenuItem key={index} value={subIssue[1]}>
                            {subIssue[1]}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>

                {/* now the status is also dependent of issueType  */}
                <FormControl sx={{ m: 1, width: "45ch" }} size="medium">
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    labelId="status-label"
                    value={status}
                    onChange={(e) => handleChange(e, setStatus)}
                    label="Status"
                  >
                    {issueType &&
                      Object.entries(filterData?.states_list || {}).map(
                        ([issuetype, statuses]) =>
                          issuetype === issueType &&
                          statuses
                            .filter((status) => status !== "resolved")
                            .map((statusItem, index) => (
                              <MenuItem
                                key={`${issuetype}-${statusItem}-${index}`}
                                value={statusItem}
                              >
                                {statusItem}
                              </MenuItem>
                            ))
                      )}
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, width: "45ch" }} size="medium">
                  <InputLabel id="assignTask-label">Assign Task</InputLabel>
                  <Select
                    labelId="assignTask-label"
                    value={assignTask}
                    onChange={(e) => handleChange(e, setAssignTask)}
                    label="Assign Task"
                  >
                    {filterData?.assignee_list?.map((assignee) => (
                      <MenuItem key={assignee.id} value={assignee.name}>
                        {assignee.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  // error={}
                  // fullWidth
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={1}
                  labelId="demo-simple-select-label"
                  value={description}
                  onChange={(e) => handleChange(e, setDescription)}
                />
              </Box>

              {/* // onChange={handleCheckBoxChange} */}
              <div className="row">
                <div className="col-md-12">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={internetStatus}
                        onChange={(e) => {
                          setInternetStatus(e.target.checked);
                          // console.log("internet status block:", e.target.checked);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="No Internet"
                  />
                </div>
                <div className="col-md-12">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={visitRequired}
                        onChange={(e) => {
                          setVisitRequired(e.target.checked);
                          // console.log("visitrequired status checkbox:", e.target.checked);
                        }}
                      />
                    }
                    label="Visit Required"
                  />
                </div>
              </div>

              <div className="edit_basic_details">
                {/* <button
                type="button"
                className="btn btn-outline-success edit_basic_details_button"
                onClick={handleSubmit}
              >
                Submit
              </button> */}
                <LoadingButton
                  size="large"
                  onClick={handleSubmit}
                  endIcon={<SendIcon />}
                  loading={loading}
                  disabled={generalData.Status === "Resolved"}
                  loadingPosition="end"
                  variant="contained"
                >
                  <span>Submit</span>
                </LoadingButton>
              </div>
            </div>
          )}

          {/* modal for edit details */}

          <Modal
            open={employeeEditModal?.modalState}
            onClose={handleCloseModal}
            style={{
              position: "fixed",
              overflowY: "scroll",
              height: "100%",
              margin: "10px 0px 10px 10px",
            }}
          >
            <Box
              style={{
                position: "relative",
                zIndex: "1111",
                right: "0",
                top: "16%",
                left: "0",
                margin: "auto",
                borderRadius: "10px",
                backgroundColor: "#ffffff",
                maxWidth: "1300px",
                height: "auto",
              }}
            >
              {/* Modal Header */}
              <div
                style={{
                  padding: "16px 24px",
                  borderBottom: "1px solid #f5f5f5",
                }}
              >
                <div className="d-flex justify-content-between">
                  <h5 className="m-0">Edit Employee Details</h5>
                  <div
                    onClick={() => {
                      handleCloseModal();
                    }}
                  >
                    <img
                      src={close}
                      alt="close"
                      style={{ width: "20px", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
              {/* Modal Body */}
              <div style={{ padding: "0px 24px" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "20px",
                    // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    justifyContent: "space-between",
                  }}
                >
                  {/* Drop downs */}
                  <div
                    style={{
                      width: "100%",
                      gap: 25,
                      display: "flex",
                      marginTop: "25px",
                      flexDirection: isSmallScreen ? "column" : "row",
                    }}
                    className="me-auto"
                  >
                    <FormControl
                      fullWidth
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          padding: "15px",
                        },
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Choose Level
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={edited_Emp_Details?.level}
                        label="Choose Level"
                        onChange={(event) => {
                          setEdited_Emp_Details((prev) => ({
                            ...prev,
                            level: event.target.value,
                            location_name: "",
                          }));
                          get_area_wise_data(event.target.value);
                        }}
                      >
                        {["Region", "District", "Location"].map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Autocomplete
                      sx={{
                        "& .MuiAutocomplete-inputRoot": {
                          padding: "8px",
                        },
                      }}
                      className="w-100"
                      disablePortal
                      size="medium"
                      id="combo-box-demo"
                      options={area_data}
                      value={edited_Emp_Details?.location_name}
                      renderInput={(params) => (
                        <TextField {...params} label="Location" />
                      )}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setEdited_Emp_Details((prev) => ({
                            ...prev,
                            location_name: newValue,
                          }));
                        }
                      }}
                    />
                  </div>

                  <div
                    style={{
                      width: "100%",
                      gap: 25,
                      display: "flex",
                      marginTop: "25px",
                      flexDirection: isSmallScreen ? "column" : "row",
                    }}
                  >
                    <TextField
                      value={edited_Emp_Details?.employee_name}
                      fullWidth
                      id="outlined-basic"
                      label="Employee Name"
                      variant="outlined"
                      onChange={(event) => {
                        setEdited_Emp_Details((prev) => ({
                          ...prev,
                          employee_name: event.target.value,
                        }));
                      }}
                    />
                    <TextField
                      value={edited_Emp_Details?.employee_phone}
                      fullWidth
                      id="outlined-basic"
                      label="Contact"
                      type="number"
                      variant="outlined"
                      onChange={(event) => {
                        setEdited_Emp_Details((prev) => ({
                          ...prev,
                          employee_phone: event.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      gap: 25,
                      display: "flex",
                      marginTop: "25px",
                      flexDirection: isSmallScreen ? "column" : "row",
                    }}
                  >
                    <TextField
                      value={edited_Emp_Details?.employee_email}
                      fullWidth
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      onChange={(event) => {
                        setEdited_Emp_Details((prev) => ({
                          ...prev,
                          employee_email: event.target.value,
                        }));
                      }}
                    />
                    <TextField
                      value={edited_Emp_Details?.employee_id}
                      fullWidth
                      id="outlined-basic"
                      label="Employee ID"
                      variant="outlined"
                      onChange={(event) => {
                        setEdited_Emp_Details((prev) => ({
                          ...prev,
                          employee_id: event.target.value,
                        }));
                      }}
                    />
                  </div>

                  <div
                    style={{
                      width: isSmallScreen ? "100%" : "49%",
                      gap: 25,
                      display: "flex",
                      marginTop: "25px",
                      flexDirection: isSmallScreen ? "column" : "row",
                    }}
                    className="me-auto"
                  >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Designation
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={edited_Emp_Details?.employee_designation}
                        label="Designation"
                        variant="outlined"
                        onChange={(event) => {
                          setEdited_Emp_Details((prev) => ({
                            ...prev,
                            employee_designation: event.target.value,
                          }));
                        }}
                        size="large"
                      >
                        {desg.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              {/* Modal Footer */}
              <div className="d-flex justify-content-end px-4 py-3">
                <LoadingButton
                  size="medium"
                  endIcon={<SendIcon />}
                  loadingPosition="end"
                  variant="contained"
                  loading={loadingSpinner}
                  onClick={() => {
                    saving_edit_details();
                  }}
                  disabled={
                    edited_Emp_Details?.employee_name &&
                    edited_Emp_Details?.employee_phone
                      ? false
                      : true
                  }
                >
                  <span> Save Details</span>
                </LoadingButton>
              </div>
            </Box>
          </Modal>
        </div>
      )) ||
        (showModalError.status && <ModalError />) || <></>}
    </>
  );
}
