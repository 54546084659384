import React, { useEffect, useState, useContext } from "react";
import Dropdown from "../filters/Dropdown";
import "../../assets/styles/AllTickets.css";
import AllFilters from "../filters/AllFilters";
import ContextState from "../contextApi/ContextState";
import { useDispatch, useSelector } from "react-redux";
import { addFilters } from "../features/filtersSlice/filterSlice";
import { BeatLoader } from "react-spinners";
import Error from "../error/Error";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { OverlayTrigger } from "react-bootstrap";
import ToolTip from "react-bootstrap/Tooltip";
import { Box, CircularProgress, Tooltip, styled } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CiExport } from "react-icons/ci";
import { CommentBankSharp } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import * as XLSX from "xlsx";
const color = {
  primary: "#f7f7f9", // website background
  // secondary: "#8884d8",
  // secondary: "#826af9", // blue color in dashboard
  // secondary: "#0098e9", // blue color in dashboard
  secondary: "#666bff", // blue color in dashboard
  green: "#82ca9d", // green color in dashboard
  greenToShow: "#72e128", // green color in dashboard
  red: "#ff4d49d9", // red color in dashboard
  orange: "#fdb528", // orange color in dashboard
  navBorderBottom: "#4c4e6422",
  taxtHeadingLight: "#4c4e6499", // normal text heading light color
  taxtHeading: "#4c4e64e6", // normal text heading dark color (76,78,100,0.97)
  tableRowHeading: "#4c4e64de", // (76,78,100,0.87)
  iconBackgroundBlue: "#666bff39",
  iconBackgroundWhite: "#ffffffe6",
  iconBackgroundgreen: "#82ca9d39",
  iconBackgroundred: "#ff4d4939",
  iconBackgroundorange: "#fff3dd",
  textWhiteColor: "#ffffff",
  textDescBackgroundRegionDiv: "#5b61e5",
  locationTextHeading: "#4c4e64fc", //location heeading color
  option_1: "#8884d8",
  option_1_bg: "#8884d830",
  option_2: "#82ca9d",
  option_2_bg: "#82ca9d30",
  option_3: "#fdb528d9",
  option_3_bg: "#fdb52830",
  option_4: "#26c6f9d9",
  option_4_bg: "#26c6f930",
  upper_limit: "#ff4d49d9",
  lower_limit: "#ff4d4966",
};

const PunchRecords = () => {
  const {
    fetchApi,
    showLoading,
    showError,
    showErrorPage,
    openSnackbar,
    formatDate,
  } = useContext(ContextState);
  const dispatch = useDispatch();
  const userFilters = useSelector((state) => state.userFilters);

  const [ExportDataList, setExportDataList] = useState(null);
  // media querries
  const isMediumScreen = useMediaQuery("(max-width:992px)");
  // blur all tickets
  const [blurTickets, setblurTickets] = useState("");

  const handelPageBlur = (blur_ticket) => {
    if (blur_ticket) {
      setblurTickets("all-ticket-content-blur");
    } else {
      setblurTickets("");
    }
  };

  // loding page when data to be fetched
  const [loading, setLoading] = useState(true);

  const [allFiltersDisplay, setallFilterDisplay] = useState(false);
  // filters
  const [filters, setFilters] = useState({
    status: [],
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    machineNumber: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    sort: [],
    crop_variation: [],
    params_variation: [],
    assignee: [],
    moreFilters: [],
    // searchBox: [],
    // ticketPerPage: [],
    commitId: [],
    modelNo: [],
  });
  const [showAllfilters, setshowAllFilters] = useState({
    status: [],
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    machineNumber: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    sort: [],
    crop_variation: [],
    params_variation: [],
    assignee: [],
    moreFilters: [],
    searchBox: [],
    ticketPerPage: [],
    commitId: [],
    modelNo: [],
  });
  const [filterCount, setFilterCount] = useState({
    status: 0,
    issueType: 0,
    subIssueType: 0,
    RO: 0,
    DO: 0,
    machineNumber: 0,
    createdStartDate: 0,
    createdEndDate: 0,
    resolvedStartDate: 0,
    resolvedEndDate: 0,
    sort: 0,
    crop_variation: 0,
    params_variation: 0,
    assignee: 0,
    moreFilters: 0,
    searchBox: 0,
    ticketPerPage: 0,
    commitId: 0,
    modelNo: 0,
  });
  // Function to update filters
  const updateFilters = (Filters, clickedPageNumber, updateURL = true) => {
    // console.log("update filter 0 -->", Filters, clickedPageNumber);
    Filters = applyCoustomChecksFilter(Filters);
    setFilters(Filters);
    setCurrentPage(clickedPageNumber);
    if (updateURL) {
      updateURLParams(Filters, clickedPageNumber);
      // console.log("Url Is Updating ");
    } else {
      // console.log("Url Is Not Updating ");
    }
    updateFilterCount(Filters);
    if (getUserFilters?.issues) {
      applyIssueTypeFilters(Filters);
    }
    showAllFilters(Filters);
    fetchData(Filters, clickedPageNumber, cardsPerPage);
  };

  const updateFilterCount = (farr) => {
    for (const key in farr) {
      const count = farr[key].length;
      filterCount[key] = count;
    }
    setFilterCount(filterCount);
  };

  const updateURLParams = (params, page) => {
    const urlSearchParams = new URLSearchParams();
    Object.keys(params).forEach((key) => {
      // console.log(key, params[key]);
      if (params[key]) {
        urlSearchParams.set(key, params[key]);
      } else {
        urlSearchParams.delete(key);
      }
    });
    urlSearchParams.set("currentPage", parseInt(page) + 1);
    // urlSearchParams.set("cardsPerPage", cardsPerPage);
    const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;

    if (window.location.pathname === "/punchrecords" && !Data) {
      // Check if the state already exists
      // console.log("Replace state Wroking");
      const currentState = window.history.state;
      if (!currentState || !currentState.filters) {
        // Replace the initial state if no filters exist
        window.history.replaceState(
          {
            filters: params,
            Page: parseInt(page) + 1,
          },
          "",
          newUrl
        );
      }
    } else {
      // Push state for subsequent changes
      window.history.pushState(
        {
          filters: params,
          Page: parseInt(page) + 1,
        },
        "",
        newUrl
      );
    }
  };

  // set page parameter to render
  const [currentPage, setCurrentPage] = useState(0); // Initialize currentPage to 0
  const [totalCardsCount, settotalCardsCount] = useState(20); // Initialize total vards from backend
  // Number of cards to display per page
  const [cardsPerPage, setCardsPerPage] = useState(20);

  const handelCurrentPage = (clickedPageNumber) => {
    // console.log(
    //   "current Page ----------->",
    //   clickedPageNumber,
    //   parseInt(clickedPageNumber) + 1,
    //   currentPage
    // );
    // setCurrentPage(parseInt(clickedPageNumber) + 1);
    updateFilters(filters, parseInt(clickedPageNumber));
  };

  const [Data, setData] = useState(null);
  const fetchData = async (F, Page, PerPage, reloadData = true) => {
    if (reloadData) {
      setData(null);
      // console.log("data set to empty List - 3");

      setLoading(true);
      showLoading(true);
    }
    // data from backend
    // total number of cards
    // cards filter show how many pages perpage (cardsPerPage : 15)

    const payload = {};
    payload["page_num"] = parseInt(Page) + 1;
    if (F["createdStartDate"] && F["createdStartDate"].length) {
      payload["start_date"] = F["createdStartDate"][0];
    }
    // else {
    //   payload["start_date"] = "2024-06-01";
    // }
    if (F["createdEndDate"] && F["createdEndDate"].length) {
      payload["end_date"] = F["createdEndDate"][0];
    }
    // else {
    //   payload["end_date"] = "2024-06-28";
    // }
    if (
      F["resolvedStartDate"] &&
      F["resolvedStartDate"].length &&
      F["status"].includes("Resolved")
    ) {
      payload["resolved_from"] = F["resolvedStartDate"][0];
    }
    if (
      F["resolvedEndDate"] &&
      F["resolvedEndDate"].length &&
      F["status"].includes("Resolved")
    ) {
      payload["resolved_to"] = F["resolvedEndDate"][0];
    } else {
    }
    if (F["issueType"] && F["issueType"].length) {
      payload["issue"] = F["issueType"];
    }
    if (F["subIssueType"] && F["subIssueType"].length) {
      payload["sub_issue"] = F["subIssueType"];
    }
    if (F["status"] && F["status"].length) {
      payload["status"] = F["status"].map((e) => e.toLowerCase());
    }
    if (F["machineNumber"] && F["machineNumber"].length) {
      payload["machines"] = F["machineNumber"];
    }
    if (F["DO"] && F["DO"].length) {
      payload["districts"] = F["DO"];
    }
    if (F["RO"] && F["RO"].length) {
      payload["regions"] = F["RO"];
    }
    if (F["sort"] && F["sort"].length) {
      const sortDictManual = {
        "Creation time- Ascending": "creation_time:ASC",
        "Creation time- Descending": "creation_time:DESC",
        "Updated time- Descending": "last_updated:DESC",
      };
      const sortArr =
        (sortDictManual[filtersOptions["sortDict"][0]] &&
          F["sort"].map((item) => filtersOptions["sortDict"][item])) ||
        sortDictManual[F["sort"][0]];
      payload["Sort"] = sortArr;
    }
    if (F["crop_variation"] && F["crop_variation"].length) {
      payload["crop_variation"] = F["crop_variation"];
    }
    if (F["params_variation"] && F["params_variation"].length) {
      payload["params_variation"] = F["params_variation"];
    }
    if (F["assignee"] && F["assignee"].length) {
      payload["assignee"] = F["assignee"];
    }
    if (F["commitId"] && F["commitId"].length) {
      payload["commit_id"] = F["commitId"];
    }
    if (F["modelNo"] && F["modelNo"].length) {
      payload["model_no"] = F["modelNo"];
    }
    if (F["moreFilters"] && F["moreFilters"].length) {
      F["moreFilters"].forEach((item) => {
        payload[item] = true;
      });
    }
    if (F["searchBox"] && F["searchBox"].length && F["searchBox"][0] !== "") {
      payload["ticket_id"] = F["searchBox"][0];
    }
    if (
      F["ticketPerPage"] &&
      F["ticketPerPage"].length &&
      F["ticketPerPage"][0] !== ""
    ) {
      payload["tickets_limit"] = F["ticketPerPage"][0];
    }

    // changing paload keys

    let is_all_ticket_data_Mounted = true;
    const all_tickets_data = await fetchApi(
      "get_employees_punch_records",
      "POST",
      payload,
      is_all_ticket_data_Mounted
    );
    // console.log("all_ticket_data : ", all_tickets_data, payload);

    if (all_tickets_data.statusCode === 200) {
      showErrorPage(null, null);
      setData(all_tickets_data?.data || null);
      settotalCardsCount(all_tickets_data?.data?.total_count || 1);

      if (all_tickets_data?.data?.tickets_limit === "All") {
        setCardsPerPage(all_tickets_data?.data?.total_count || 1);
      } else {
        setCardsPerPage(all_tickets_data?.data?.tickets_limit || 1);
      }
    } else if (
      all_tickets_data.statusCode === 500 ||
      all_tickets_data.statusCode === 401
    ) {
      setData(null);
      showErrorPage(all_tickets_data.statusCode, all_tickets_data.msg);
    } else {
      setData(null);
      // showErrorPage(all_tickets_data.statusCode, all_tickets_data.msg);
      localStorage.clear();
      window.location.href = "/login";
    }

    if (reloadData) {
      setLoading(false);
      showLoading(false);
    }

    return () => {
      is_all_ticket_data_Mounted = false;
    };
  };
  // to reload the data
  async function reloadData() {
    await fetchData(filters, currentPage, cardsPerPage, false);
  }

  const handleFilterChange = (filter, filterType) => {
    setFilters((prevFilters) => {
      const currentFilters = { ...prevFilters };
      const filterList = currentFilters[filterType];

      if (filterList && filterType === "searchBox") {
        if (filter !== "") currentFilters[filterType] = [filter];
        else currentFilters[filterType] = [];
      } else if (filterList && filterList.includes(filter)) {
        currentFilters[filterType] = filterList.filter(
          (prevFilter) => prevFilter !== filter
        );
      } else if (
        filterList &&
        (filterType === "createdStartDate" ||
          filterType === "createdEndDate" ||
          filterType === "resolvedStartDate" ||
          filterType === "resolvedEndDate")
      ) {
        currentFilters[filterType] = [filter];
      } else if (
        (filterList && filterType === "sort") ||
        filterType === "ticketPerPage"
      ) {
        currentFilters[filterType] = [filter];
      } else {
        currentFilters[filterType] = [...(filterList || []), filter];
        if (filterList && filterType === "issueType") {
          currentFilters["subIssueType"] = [];
          currentFilters["status"] = [];
          currentFilters["crop_variation"] = [];
          currentFilters["params_variation"] = [];
        }
      }
      if (filterType === "RO") {
        applyROFilters(currentFilters);
      } else if (filterType === "machineNumber") {
        applyMachineNumberFilters(currentFilters);
      } else if (filterType === "DO") {
        applyDOFilters(currentFilters);
      }
      applyIssueTypeFilters(currentFilters);
      updateFilterCount(currentFilters);
      applyCropVariationFilters(currentFilters);
      // console.log(
      //   "update filter 1 -->",
      //   prevFilters,
      //   currentFilters,
      //   filter,
      //   filterType
      // );
      return currentFilters;
    });
  };

  const handleFilterReset = (filtername) => {
    // const filters = { ...filters };
    if (Object.keys(filters || {}).includes(filtername)) {
      filters[filtername] = [];
    }
    if (filtername === "moreFilters") {
      filters["searchBox"] = [];
      updateFilters["ticketPerPage"] = [];
    }
    if (filtername === "issueType") {
      filters["subIssueType"] = [];
    }
    if (filtername === "dateSelector") {
      filters["resolvedStartDate"] = [];
      filters["resolvedEndDate"] = [];
      filters["createdStartDate"] = [];
      filters["createdEndDate"] = [];
    }
    // console.log("working", filtername, filtername === "dateSelector", filters);
    setFilters(filters);
    // console.log(filtername);
    if (filtername === "RO") {
      applyROFilters(filters);
    } else if (filtername === "machineNumber") {
      applyMachineNumberFilters(filters);
    } else if (filtername === "DO") {
      applyDOFilters(filters);
    }
    // console.log(filters, "updateFilterCount");
    applyIssueTypeFilters(filters);
    updateFilterCount(filters);
  };

  const handleSaveChanges = (filtername) => {
    // console.log("sendFilters->  ", filtername);
    const filterAfterCoustomChange = applyCoustomChecksFilter(filters);
    updateFilters(filterAfterCoustomChange, 0);
    setshowAllFilters(filterAfterCoustomChange);
  };

  const handelResetAllBtn = () => {
    // console.log("working");
    for (const key in filters) {
      filters[key] = [];
    }
    // console.log("updated filter ", filters);
    updateFilters(filters, 0);

    setfiltersOptions((prevState) => ({
      ...prevState,
    }));
  };

  const handelDeleteFilterBtn = (filterType, subfilter) => {
    // console.log("working", filterType, subfilter);

    // Check if the filterKey exists in the filters state
    if (filters.hasOwnProperty(filterType)) {
      // Remove the specified filterValue from the filterKey array
      filters[filterType] = filters[filterType].filter(
        (filter) => filter !== subfilter
      );
    }
    if (filterType === "status" && subfilter === "Resolved") {
      filters["resolvedEndDate"] = [];
      filters["resolvedStartDate"] = [];
    }
    if (filterType === "issueType") {
      filters["subIssueType"] = [];
      filters["status"] = [];
      if (subfilter === "Data Variation") {
        filters["crop_variation"] = [];
        filters["params_variation"] = [];
        filters["modelNo"] = [];
        filters["commitId"] = [];
      }
    }
    if (filterType === "crop_variation") {
      filters["params_variation"] = [];
    }

    if (filterType === "RO") {
      applyROFilters(filters);
    } else if (filterType === "machineNumber") {
      applyMachineNumberFilters(filters);
    } else if (filterType === "DO") {
      applyDOFilters(filters);
    }
    updateFilters(filters, 0);
    // console.log("Deleted single filter --->", filters);
  };

  function showAllFilters(FiltersDict) {
    for (const i in FiltersDict) {
      if (FiltersDict[i].length !== 0) {
        // console.log("show filter");
        setallFilterDisplay(true);
        return;
      }
    }
    setallFilterDisplay(false);
  }

  const [getUserFilters, setgetUserFilters] = useState(null);

  // filters to show

  const [filtersOptions, setfiltersOptions] = useState({
    // status: [],
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    machineNumber: [],
    createdStartDate: [],
    createdEndDate: [],
    // resolvedStartDate: [],
    // resolvedEndDate: [],
    // sort: [],
    // sortDict: [],
    // crop_variation: [],
    // params_variation: [],
    // assignee: [],
    // commitId: [],
    // modelNo: [],
    // moreFilters: ["visit_required", "internet_issue"],
    // ticketPerPage: ["20", "50", "100", "All"],
  });
  const [filtersOptionsCopy, setfiltersOptionsCopy] = useState({
    status: [],
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    machineNumber: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    sort: [],
    sortDict: [],
    crop_variation: [],
    params_variation: [],
    assignee: [],
    commitId: [],
    modelNo: [],
    moreFilters: ["visit_required", "internet_issue"],
    ticketPerPage: ["20", "50", "100", "All"],
  });
  // filters sorting according to selected filter
  const getFilterResponse = async (response) => {
    // setLoading(true);
    // showLoading(true);
    try {
      // calling refresh token whenever loading the page
      // console.log("get_user_filters--->", response);
      if (response.statusCode === 200) {
        // console.log("get_user_filters Data Fetched Successfully");
        const data = response.data;
        // console.log("data", new Date().toLocaleString(), data);
        setgetUserFilters(data);

        // Extract RO# keys from  filters_data
        const roKeys =
          data && data.access_level === "institution"
            ? Object.keys(data?.filters_data || {})
            : [];

        // Extract DO# keys from RO objects in filters_data
        const doKeys =
          data && data.access_level === "institution"
            ? Object.values(data?.filters_data || {}).flatMap((Do) =>
                Object.keys(Do)
              )
            : data && data.access_level === "region"
            ? Object.keys(data.filters_data)
            : [];

        // Populate machineNumber array with unique machine numbers
        const uniquedoKeys = Array.from(new Set(doKeys));

        // Extract machine numbers from DO keys
        const machineNumbervalue =
          data && data.access_level === "institution"
            ? Object.values(data?.filters_data || {})
                .flatMap((ro) => Object.values(ro))
                .flatMap((doData) => doData)
                .flatMap((machine) => machine)
                .concat(Object.values(data?.ro_machines || {}))
            : data && data.access_level === "region"
            ? Object.values(data?.filters_data || {})
                .flatMap((doList) => {
                  return Object.values(doList);
                })
                .flat()
            : data && data.access_level === "district"
            ? data.filters_data
            : [];
        // Populate machineNumber array with unique machine numbers
        const uniqueMachineNumbers = Array.from(new Set(machineNumbervalue));

        // populate subissuetype in filter option
        const subIssueTypes = Object.values(data.issues[0]).flatMap((issue) =>
          issue.sub_issue.map((subIssue) => subIssue[1])
        );
        // console.log(
        //   "Sub issues : ",
        //   subIssueTypes,
        //   typeof subIssueTypes,
        //   subIssueTypes.length
        // );
        // populating sort in filters Option
        const sortArr =
          (data && data.sort_list.map((item) => item.value)) || [];
        const sortDictArr =
          (data &&
            data.sort_list.reduce((acc, item) => {
              acc[item.value] = item.id;
              return acc;
            }, {})) ||
          [];

        const assigneeType =
          (data && data.assignee_list?.map((item) => item.name)) || [];
        // console.log("assigneeType", assigneeType);
        const cropvariationTypes = data ? Object.keys(data.param_data) : [];

        let commitIdArr = data && Object.values(data.commit_id_list || {});

        commitIdArr = commitIdArr.map((commitIds, index) => {
          return commitIds.slice(0, 5);
        });

        const modelNoArr = data && Object.values(data.model_no_list || {});

        const dataParamsTypes =
          Array.from(
            new Set(
              data &&
                Object.values(data.param_data).flatMap((item) =>
                  Object.keys(item.client_params)
                )
            )
          ) || [];

        // console.log("cropvariationTypes", cropvariationTypes, dataParamsTypes);
        const statusTypes = Array.from(
          new Set(Object.values(data?.states_list || {}).flat())
        );
        const issueTypeArr = Object.keys(data?.states_list || {});
        // console.log("statusTypes:", statusTypes, issueTypeArr);

        setfiltersOptions((eachFilterToShow) => ({
          ...eachFilterToShow,
          // RO: roKeys,
          // DO: uniquedoKeys,
          // machineNumber: uniqueMachineNumbers,
          // subIssueType: subIssueTypes,
          // sortDict: sortDictArr,
          // sort: sortArr,
          // crop_variation: cropvariationTypes,
          // params_variation: dataParamsTypes,
          // assignee: assigneeType,
          // modelNo: modelNoArr,
          // commitId: commitIdArr,
          // issueType: issueTypeArr,
          // status: statusTypes,
        }));
        setfiltersOptionsCopy((eachFilterToShow) => ({
          ...eachFilterToShow,
          RO: roKeys,
          DO: uniquedoKeys,
          machineNumber: uniqueMachineNumbers,
          subIssueType: subIssueTypes,
          status: statusTypes,
          sort: sortArr,
          sortDict: sortDictArr,
          crop_variation: cropvariationTypes,
          params_variation: dataParamsTypes,
          assignee: assigneeType,
          modelNo: modelNoArr,
          commitId: commitIdArr,
          issueType: issueTypeArr,
        }));
      } else {
        // Handle non-successful responses
        // console.error("Error:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const applyROFilters = (filtersDict) => {
    const roFilters = filtersDict.RO;

    // Extract all DO keys from selected RO filters
    const filteredDO = roFilters.flatMap((ro) =>
      getUserFilters?.filters_data[ro]
        ? Object.keys(getUserFilters?.filters_data[ro] || {})
        : []
    );

    // console.log("filteredDO", filteredDO, roFilters);

    // Extract all machine numbers from selected RO filters and DO keys
    const filteredMachineNumbers = roFilters.flatMap((ro) =>
      getUserFilters?.filters_data[ro]
        ? Object.values(getUserFilters?.filters_data[ro] || {})
            .flatMap((doValues) => doValues)
            .concat(
              getUserFilters?.ro_machines[ro]
                ? getUserFilters?.ro_machines[ro]
                : []
            )
        : []
    );

    // Update filtersOptions with unique filtered DO values and machine numbers
    const uniqueDO = Array.from(new Set(filteredDO));
    const uniqueMachineNumbers = Array.from(new Set(filteredMachineNumbers));

    if (roFilters.length === 0) {
      const newFilter = { ...filters };
      newFilter["DO"] = [];
      newFilter["RO"] = [];
      newFilter["machineNumber"] = [];
      // setFilters(newFilter);
      // updateFilterCount(newFilter);
      // showAllFilters(newFilter);
      setfiltersOptions((prevState) => ({
        ...prevState,
        DO: filtersOptionsCopy["DO"],
        machineNumber: filtersOptionsCopy["machineNumber"],
      }));
    } else {
      const newFilter = { ...filters };
      newFilter["DO"] = [];
      newFilter["machineNumber"] = [];
      // console.log("uniqueMachineNumber --> ", uniqueMachineNumbers);
      // setFilters(newFilter);
      // updateFilterCount(newFilter);
      // showAllFilters(newFilter);

      setfiltersOptions((prevState) => ({
        ...prevState,
        DO: uniqueDO,
        machineNumber: uniqueMachineNumbers,
      }));
    }
  };

  const applyDOFilters = (filtersDict) => {
    const doFilters = filtersDict.DO;
    const roFilters = filtersDict.RO;
    if (getUserFilters.access_level === "institution") {
      // Find corresponding RO keys for selected DO keys
      const roKeys = [];
      Object.entries(getUserFilters?.filters_data || {}).forEach(
        ([roKey, doValues]) => {
          Object.keys(doValues).forEach((doKey) => {
            if (doFilters.includes(doKey)) {
              roKeys.push(roKey);
            }
          });
        }
      );

      // Extract all machine numbers from selected DO keys
      const filteredMachineNumbers = doFilters.flatMap((doKey) =>
        Object.values(getUserFilters.filters_data)
          .filter((ro) => ro[doKey])
          .flatMap((ro) => ro[doKey])
      );
      // Update filtersOptions with unique filtered machine numbers and corresponding RO keys
      const uniqueMachineNumbers = Array.from(new Set(filteredMachineNumbers));
      const uniqueRO = Array.from(new Set(roKeys));
      if (doFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: filtersOptionsCopy["machineNumber"],
          RO: filtersOptionsCopy["RO"],
        }));
      } else {
        // //filters["machineNumber"] = uniqueMachineNumbers;
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: uniqueMachineNumbers,
          RO: roFilters.length === 0 ? uniqueRO : prevState.RO,
        }));
      }
    } else if (getUserFilters.access_level === "region") {
      // Extract machine numbers for selected DO keys
      const filteredMachineNumbers = doFilters.reduce((acc, doKey) => {
        if (getUserFilters.filters_data[doKey]) {
          acc.push(...getUserFilters.filters_data[doKey]);
        }
        return acc;
      }, []);

      if (doFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: filtersOptionsCopy["machineNumber"],
        }));
      } else {
        // Update filtersOptions machineNumber with unique filtered machine numbers
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: Array.from(new Set(filteredMachineNumbers)),
        }));
      }
    }
  };

  const applyMachineNumberFilters = (filtersDict) => {
    const roFilters = filtersDict.RO;
    const doFilters = filtersDict.DO;
    const machineNumberFilters = filtersDict.machineNumber;
    if (getUserFilters.access_level === "institution") {
      // Extract corresponding RO and DO keys for selected machine numbers
      const filteredRODO = Object.entries(
        getUserFilters?.filters_data || {}
      ).reduce((acc, [ro, roData]) => {
        Object.entries(roData || {}).forEach(([doKey, machines]) => {
          const matchingMachines = machines.filter((machine) =>
            machineNumberFilters.includes(machine)
          );
          if (matchingMachines.length > 0) {
            acc.push({ RO: ro, DO: doKey });
          }
        });
        return acc;
      }, []);

      // Extract unique RO and DO keys
      const uniqueRO = Array.from(
        new Set(filteredRODO.map((entry) => entry.RO))
      );
      const uniqueDO = Array.from(
        new Set(filteredRODO.map((entry) => entry.DO))
      );

      // Update filtersOptions with unique RO and DO keys
      if (machineNumberFilters.length === 0) {
        // console.log(
        //   "machineNumberFilters.length",
        //   machineNumberFilters.length,
        //   filtersOptions
        // );
        setfiltersOptions((prevState) => ({
          ...prevState,
          RO: filtersOptionsCopy["RO"],
          DO: filtersOptionsCopy["DO"],
        }));
      } else {
        setfiltersOptions((prevState) => ({
          ...prevState,
          RO: roFilters.length === 0 ? uniqueRO : prevState.RO,
          DO: doFilters.length === 0 ? uniqueDO : prevState.DO,
        }));
      }
    } else if (getUserFilters.access_level === "region") {
      // Extract DO keys for selected machine numbers
      const filteredDOKeys = Object.keys(getUserFilters.filters_data).filter(
        (doKey) => {
          const machineNumbers = getUserFilters.filters_data[doKey];
          return machineNumbers.some((number) =>
            machineNumberFilters.includes(number)
          );
        }
      );

      if (machineNumberFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          DO: filtersOptionsCopy["DO"],
        }));
      } else {
        // Update filtersOptions DO with unique filtered DO keys
        setfiltersOptions((prevState) => ({
          ...prevState,
          DO:
            doFilters.length === 0
              ? Array.from(new Set(filteredDOKeys))
              : prevState.RO,
        }));
      }
    }
  };

  // const applyIssueTypeFilters = (filterDict) => {
  // Function to update filtersOptions based on selected issueType

  // Function to update filtersOptions based on selected issueType
  const applyIssueTypeFilters = (filterDict) => {
    if (filterDict?.issueType) {
      if (filterDict.issueType.length !== 0) {
        // Filter subIssueType values based on the selected issueType
        const filteredSubIssueType =
          getUserFilters?.issues?.reduce((acc, issue) => {
            const selectedIssueTypes = filterDict.issueType;
            for (const issueType of selectedIssueTypes) {
              if (issue[issueType]) {
                acc.push(
                  ...issue[issueType].sub_issue?.map((subIssue) => subIssue[1])
                );
              }
            }
            return acc;
          }, []) || [];
        // Update filtersOptions state with the filtered subIssueType values
        setfiltersOptions((prevState) => ({
          ...prevState,
          subIssueType: Array.from(
            new Set([...(filters.subIssueType || []), ...filteredSubIssueType])
          ),
        }));
      } else {
        setfiltersOptions((prevState) => ({
          ...prevState,
          subIssueType: filtersOptionsCopy.subIssueType,
        }));
      }
    }
  };

  const applyCropVariationFilters = (filterDict) => {
    // Get the list of param_data keys from filters.crop_variation
    const selectedCrops = filterDict?.crop_variation || [];

    // Filter client_params keys based on selectedCrops
    const clientParamsKeysArray = Object.entries(
      getUserFilters?.param_data || {}
    )
      .filter(([key]) => {
        // console.log(selectedCrops, key);
        return selectedCrops.includes(key);
      }) // Filter based on selectedCrops
      .flatMap(([key, value]) => Object.keys(value.client_params));

    // Update filtersOptions.params_variation
    const updatedParamsVariation = Array.from(
      new Set([...(filters.params_variation || []), ...clientParamsKeysArray])
    );

    // console.log("updatedParamsVariation", updatedParamsVariation);
    if (selectedCrops.length) {
      setfiltersOptions((prevState) => ({
        ...prevState,
        params_variation: updatedParamsVariation,
      }));
    } else {
      setfiltersOptions((prevState) => ({
        ...prevState,
        params_variation: filtersOptionsCopy["params_variation"],
      }));
    }
  };

  const applyCoustomChecksFilter = (filterDict) => {
    if (
      filterDict["status"] &&
      !filterDict["status"].some(
        (status) => status.toLowerCase() === "resolved"
      )
    ) {
      filterDict["resolvedEndDate"] = [];
      filterDict["resolvedStartDate"] = [];
    }

    if (
      filterDict?.issueType &&
      !filterDict?.issueType?.includes("Data Variation")
    ) {
      filterDict["params_variation"] = [];
      filterDict["crop_variation"] = [];
      filterDict["modelNo"] = [];
      filterDict["commitId"] = [];
    }

    if (filterDict?.issueType && filterDict?.issueType?.length === 0) {
      filterDict.subIssueType = [];
      filterDict.status = [];
    }

    if (
      filterDict?.crop_variation &&
      filterDict?.crop_variation?.length === 0
    ) {
      filterDict.params_variation = [];
    }

    return filterDict;
  };

  useEffect(() => {
    document.title = "Punch Records - NEO";
    showLoading(true);
    setData(null);
    // console.log("data set to empty List - 1");
    const params = new URLSearchParams(window.location.search);
    // console.log("prams -->", params);
    const statusFilterData =
      params.get("status") !== "" && params.get("status") !== null
        ? params.get("status").split(",")
        : [];

    const issueTypeFilterData =
      params.get("issueType") !== "" && params.get("issueType") !== null
        ? params.get("issueType").split(",")
        : [];
    const ROFilterData =
      params.get("RO") !== "" && params.get("RO") !== null
        ? params.get("RO").split(",")
        : [];
    const DOFilterData =
      params.get("DO") !== "" && params.get("DO") !== null
        ? params.get("DO").split(",")
        : [];
    const machineNumberFilterData =
      params.get("machineNumber") !== "" && params.get("machineNumber") !== null
        ? params.get("machineNumber").split(",")
        : [];
    const createdStartDateFilterData =
      params.get("createdStartDate") !== "" &&
      params.get("createdStartDate") !== null
        ? params.get("createdStartDate").split(",")
        : [];
    const createdEndDateFilterData =
      params.get("createdEndDate") !== "" &&
      params.get("createdEndDate") !== null
        ? params.get("createdEndDate").split(",")
        : [];
    const resolvedStartDateDateFilterData =
      params.get("resolvedStartDate") !== "" &&
      params.get("resolvedStartDate") !== null
        ? params.get("resolvedStartDate").split(",")
        : [];
    const resolvedEndDateFilterData =
      params.get("resolvedEndDate") !== "" &&
      params.get("resolvedEndDate") !== null
        ? params.get("resolvedEndDate").split(",")
        : [];
    const assigneeFilterData =
      params.get("assignee") !== "" && params.get("assignee") !== null
        ? params.get("assignee").split(",")
        : [];
    const commitIdFilterData =
      params.get("commitId") !== "" && params.get("commitId") !== null
        ? params.get("commitId").split(",")
        : [];
    const modelNoFilterData =
      params.get("modelNo") !== "" && params.get("modelNo") !== null
        ? params.get("modelNo").split(",")
        : [];
    const subIssueTypeFilterData =
      params.get("subIssueType") !== "" && params.get("subIssueType") !== null
        ? params.get("subIssueType").split(",")
        : [];
    const sortFilterData =
      params.get("sort") !== "" && params.get("sort") !== null
        ? params.get("sort").split(",")
        : [];
    const searchBoxFilterData =
      params.get("searchBox") !== "" && params.get("searchBox") !== null
        ? params.get("searchBox").split(",")
        : [];
    const Page =
      params.get("currentPage") !== "" && params.get("currentPage") !== null
        ? params.get("currentPage")
        : 1;
    const ticketPerPageFilterData =
      params.get("ticketPerPage") !== "" && params.get("ticketPerPage") !== null
        ? [params.get("ticketPerPage")]
        : [];
    // console.log("ticketPerPageFilterData", ticketPerPageFilterData);
    if (
      ticketPerPageFilterData.length ||
      ticketPerPageFilterData[0] === "20" ||
      ticketPerPageFilterData[0] === "50" ||
      ticketPerPageFilterData[0] === "100"
    ) {
      setCardsPerPage(parseInt(ticketPerPageFilterData[0]));
    }
    // console.log("searchBoxFilterData: ", searchBoxFilterData);
    const cropVariationFilterData =
      params.get("crop_variation") !== "" &&
      params.get("crop_variation") !== null
        ? params.get("crop_variation").split(",")
        : [];
    const paramVariationFilterData =
      params.get("params_variation") !== "" &&
      params.get("params_variation") !== null
        ? params.get("params_variation").split(",")
        : [];

    const moreFiltersFilterData =
      params.get("moreFilters") !== "" && params.get("moreFilters") !== null
        ? params.get("moreFilters").split(",")
        : [];
    // console.log("moreFiltersFilterData", moreFiltersFilterData);
    // machineNumber: [],
    // createdStartDate: [],
    // createdEndDate: [],
    // resolvedStartDate: [],
    // resolvedEndDate: [],
    // sort: [],
    // crop_variation: [],
    // params_variation: [],

    // console.log("------------->", Page);
    const initalFilters = {
      // status: statusFilterData,
      // issueType: issueTypeFilterData,
      // RO: ROFilterData,
      // DO: DOFilterData,
      // machineNumber: machineNumberFilterData,
      createdStartDate: createdStartDateFilterData,
      createdEndDate: createdEndDateFilterData,
      // resolvedStartDate: resolvedStartDateDateFilterData,
      // resolvedEndDate: resolvedEndDateFilterData,
      // sort: sortFilterData,
      // crop_variation: cropVariationFilterData,
      // params_variation: paramVariationFilterData,
      // moreFilters: moreFiltersFilterData,
      // searchBox: searchBoxFilterData,
      // ticketPerPage: ticketPerPageFilterData,
      // assignee: assigneeFilterData,
      // subIssueType: subIssueTypeFilterData,
      // commitId: commitIdFilterData,
      // modelNo: modelNoFilterData,
    };
    // console.log("insitial filter --->", initalFilters);

    let is_all_filter_data_Mounted = true;

    // if (is_all_filter_data_Mounted) {
    //   get_live_machine_data();
    // }
    // const intervalId = setInterval(async () => {
    //   await get_live_machine_data();
    // }, 45000);

    const fetchDataAndUpdateFilters = async (
      initalFilters,
      Page,
      updateURL = true
    ) => {
      const currentTime = Date.now();
      const lastDataFetchTimestamp = userFilters?.lastDataFetchTimestamp;

      if (
        !lastDataFetchTimestamp ||
        currentTime - lastDataFetchTimestamp >= 30 * 60 * 1000
      ) {
        // fetchFiltersDataApiCall
        const all_filter_data = await fetchApi(
          "get_user_filters",
          "POST",
          {},
          is_all_filter_data_Mounted
        );
        // console.log("all_filter_data in All Ticket : ", all_filter_data);

        if (all_filter_data.statusCode === 200) {
          dispatch(addFilters(all_filter_data));
          getFilterResponse(all_filter_data);
          showErrorPage(null, null);
        } else if (
          all_filter_data.statusCode === 500 ||
          all_filter_data.statusCode === 401
        ) {
          setData(null);
          showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
        } else {
          setData(null);
          // showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
          // console.log("Some Error Occured", all_filter_data);
          localStorage.clear();
          window.location.href = "/login";
        }
      } else {
        // console.log("Data fetch from redux ************", userFilters?.data);
        getFilterResponse(userFilters?.data);
      }
      // updateFilterget_live_machine_datas(initalFilters, parseInt(Page) - 1, updateURL);
      updateFilters(initalFilters, 0, false);
      setshowAllFilters(initalFilters);
      // showLoading(false);
    };
    fetchDataAndUpdateFilters(initalFilters, Page);
    // console.log("data set to empty List - 2");

    const handlePopState = () => {
      // Your logic to handle popstate event
      // console.log("mount", "User navigated back or forward");
      const previousPageURL = window.history.state || null;
      // console.log("Previous page URL:", previousPageURL);
      if (previousPageURL && previousPageURL.filters && previousPageURL.Page) {
        showLoading(true);
        setLoading(true);
        fetchDataAndUpdateFilters(
          previousPageURL.filters,
          previousPageURL.Page,
          false
        );
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      is_all_filter_data_Mounted = false;
      window.removeEventListener("popstate", handlePopState);
      // clearInterval(intervalId);
      // console.log("unmount");
    };
  }, []);

  const [refreshLoading, setRefreshLoading] = useState(false);
  const refresh_attendance = async () => {
    try {
      setRefreshLoading(true);
      // console.log("add scan", scandetails);
      const payload = {};

      const response = await fetchApi(
        "refresh_attendance",
        "POST",
        payload,
        true
      );
      // console.log("test------>", response);

      if (response?.statusCode === 200) {
        openSnackbar(response?.data?.message);
        reloadData();
        setRefreshLoading(false);
      } else {
        // console.error("Error fetching scan IDs:", response.statusCode);
        openSnackbar(response.msg, "error");
      }
    } catch (error) {
      // console.error("Internal server error:", error);
      openSnackbar("some error occured while saving details", error);
    } finally {
      setRefreshLoading(false);
    }
  };

  const renderTooltip = (props) => (
    <ToolTip id="button-tooltip" {...props}>
      Detailed <strong>Attendance Information </strong>is shown here
    </ToolTip>
  );

  const customCells = (columns) => {
    if (!columns) return [];

    return columns.map((each_col, index) => ({
      ...each_col,
      flex: isMediumScreen ? 0 : 1,
      headerAlign: index === 0 ? "left" : "center",
      align: index === 0 ? "left" : "center",
      valueGetter: (params) => {
        if (each_col.field === "punch_in" || each_col.field === "punch_out") {
          return params !== null
            ? formatDate(params)
                .split(" ")
                .slice(params.lastIndexOf(",") + 1)
                .join(" ")
            : "-";
        } else if (each_col.field === "date") {
          return params.split(" ").slice(0, -2).join(" ");
        }
        return params;
      },
    }));
  };

  const onExportLocal = (title) => {
    // const headingTop = [];
    const headings = Data?.columns?.map((each_item) => each_item.headerName);

    const heading_data = Data?.rows?.map((items) => {
      const formattedDate = items.date.split(" ").slice(0, -2).join(" ");
      const formattedPunchIn = formatDate(items.punch_in);
      const formattedPunchOut =
        items.punch_out !== null ? formatDate(items.punch_out) : "-";

      // Combine the formatted fields with the rest of the item values
      return [
        items.emp_id,
        items.emp_name,
        formattedDate,
        formattedPunchIn,
        formattedPunchOut,
      ];
    });

    const wsData = [headings, ...heading_data];

    // console.log("heading data", ...heading_data);
    // Convert the data to a worksheet
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Punch Records");

    // Generate and download the Excel file
    XLSX.writeFile(
      wb,
      `PunchRecordsExportData(${title})_${new Date()
        .toString()
        .slice(0, 24)
        .replaceAll(" ", "_")}.xlsx`
    );
  };

  return (
    <>
      {(!showError.status && (
        <>
          <Dropdown
            blurTickets={handelPageBlur}
            filters={filters}
            filterCount={filterCount}
            handleFilterChange={handleFilterChange}
            handleSaveChanges={handleSaveChanges}
            handleFilterReset={handleFilterReset}
            getUserFilters={getUserFilters}
            getFilterResponse={getFilterResponse}
            filtersOptions={filtersOptions}
            pastOneMonth={"PastOneMonth"}
            pastThreeMonth={"PastThreeMonth"}
          />
          {allFiltersDisplay && (
            <AllFilters
              filters={showAllfilters}
              handelResetAllBtn={handelResetAllBtn}
              handelDeleteFilterBtn={handelDeleteFilterBtn}
              getUserFilters={getUserFilters}
            />
          )}

          <div
            className={`${blurTickets} all-ticket-content ${
              allFiltersDisplay ? "m-top-0 p-top-0" : " "
            }`}
          >
            {(loading && (
              // {lodingVisiblity && (
              <div className="showSpinner all-ticket-spinner">
                <BeatLoader color="#2D97D4" />
              </div>
            )) || (
                <div style={{ padding: "0px 12px" }}>
                  <div className="upper-heading-container mb-0 p-0">
                    <div className="info-part">
                      <span className="px-2 mb-1 info-heading">
                        Attendance Overview
                      </span>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                      >
                        <i
                          className="bi bi-info-circle icon-color px-0"
                          id="info-icon"
                          style={{
                            fontSize: "15px",
                            lineHeight: "24px",
                            cursor: "pointer",
                          }}
                        ></i>
                      </OverlayTrigger>
                    </div>
                    <div className="show-range ms-auto">
                      <div>
                        {Data?.title}{" "}
                        <div
                          className="dropdow dropdown_export me-2 d-inline"
                          style={{ cursor: "pointer" }}
                        >
                          <Tooltip
                            data-html="true"
                            title={"Export Data"}
                            arrow={true}
                          >
                            <i
                              className="fa-solid fa-ellipsis-vertical  "
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{ padding: "0px 3px" }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            ></i>
                          </Tooltip>
                          {/* Centered dropdown */}
                          {/* </button> */}
                          <ul
                            className="dropdown-menu dropdown-menu-end delete-ticket-btn-border"
                            style={{ padding: "0px 0px" }}
                          >
                            <li>
                              <a
                                className="dropdown-item text-center edit-ticket-btn"
                                href="#"
                                onClick={(e) => {
                                  // e.stopPropagation();
                                  onExportLocal(Data?.title);
                                }}
                              >
                                <span className="export_all_data_btn m-0">
                                  <CiExport className="me-2" />
                                </span>
                                Export
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* refresh button */}

                  <div className="d-flex justify-content-end">
                    <div className="ms-auto me-1">
                      <Tooltip title="Click to Refresh Attendance Details">
                        <LoadingButton
                          style={{
                            border: "0",
                          }}
                          size="small"
                          loadingPosition="start"
                          // startIcon={<SaveIcon />}
                          variant="outlined"
                          onClick={() => {
                            refresh_attendance();
                          }}
                        >
                          {refreshLoading ? (
                            <>
                              <span className="align-self-center rotate">
                                <i class="bi bi-arrow-clockwise"></i>
                              </span>
                              <span className="ms-1"> Reloading</span>
                            </>
                          ) : (
                            <span>
                              <i class="bi bi-arrow-clockwise me-1"></i>
                              Reload
                            </span>
                          )}
                        </LoadingButton>
                      </Tooltip>
                    </div>
                  </div>
                  {Data && Data.rows.length > 0 ? (
                    <>
                      <div className="card-Div">
                        <Box sx={{ width: "100%", height: "75vh" }}>
                          <DataGrid
                            // autoHeight
                            disableRowSelectionOnClick
                            rows={Data?.rows}
                            columns={customCells(Data?.columns)}
                            // slots={{ toolbar: GridToolbar }}

                            sx={{
                              "& .MuiDataGrid-cell": {
                                display: "flex",
                                marginBottom: "0px",
                              },
                              "&.MuiDataGrid-root": {
                                border: "none",
                                borderRadius: "5px", // Adjust border radius as needed
                                overflow: "hidden", // Ensure content respects the border radius,
                                padding: "0px",
                                margin: "0px",
                                marginTop: "-10px",
                              },
                              ".MuiDataGrid-columnHeader": {
                                backgroundColor: color.textWhiteColor,
                                // justifyContent: "center",
                              },

                              ".MuiDataGrid-columnHeaderTitleContainer": {
                                // justifyContent: "center",
                              },
                              ".MuiDataGrid-filler": {
                                backgroundColor: color.textWhiteColor,
                              },
                              ".MuiDataGrid-columnHeaderTitle": {
                                fontFamily: "Open-Sans-Light",
                                fontWeight: "bold",
                                padding: "5px 0px",
                                fontSize: "0.85rem",
                                textTransform: "capitalize",
                                color: color.taxtHeading,
                              },
                              ".MuiDataGrid-columnHeader--alignCenter": {
                                headerAlign: "left",
                              },
                              ".MuiDataGrid-row": {
                                border: "none", // Remove border from rows
                                backgroundColor: color.textWhiteColor, // Set rows background color to white
                                "&:hover": {
                                  backgroundColor: color.primary, // Ensure background color remains white on hover
                                  // cursor: "pointer",
                                },
                              },
                              // ".MuiDataGrid-footerContainer": {
                              //   display:
                              //     (visibleRows < 100 && "none") || "inline-block", // Hide the pagination footer
                              // },
                              ".MuiDataGrid-cell": {
                                // backgroundColor: color.textWhiteColor, // Set background color to red for out-of-range values
                                outline: "none",
                                // display: "-webkit-box",
                                // WebkitBoxOrient: "vertical",
                                // WebkitLineClamp: 2, // Adjust the number of lines you want to show
                                // overflow: "hidden",
                                // textOverflow: "ellipsis",
                                "&:focus": {
                                  outline: "none", // Remove the blue outline when a cell is focused
                                },
                              },
                              // Custom CSS to hide the "Rows per page" component
                              ".MuiTablePagination-root": {
                                ".MuiInputBase-root": {
                                  // display: "none",
                                },

                                ".MuiTablePagination-selectLabel": {
                                  display: "none",
                                },
                                ".MuiToolbar-root": {
                                  paddingTop: "10px",
                                  // marginTop: "8px",
                                },
                              },
                              "&.selected-row": {
                                backgroundColor: "red", // Highlight color for the selected row
                                "&:hover": {
                                  backgroundColor: "red ", // Ensure highlight color remains on hover
                                },
                              },
                              ".MuiDataGrid-selectedRowCount": {
                                display: "none", // Hide the selected row count div
                              },
                            }}
                          />
                        </Box>
                      </div>
                    </>
                  ) : (
                    <div className="container no-ticket-found-div text-center count_heading_div">
                      <div className="d-flex align-items-center justify-content-center flex-column">
                        <i className="fa-solid fa-face-rolling-eyes fa-2xl inline-block"></i>
                        <p>No Punch Records found!</p>
                      </div>
                    </div>
                  )}
                </div>
              ) || (
                <div className="container no-ticket-found-div text-center count_heading_div">
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    <i className="fa-solid fa-face-rolling-eyes fa-2xl inline-block"></i>
                    <p>No Data Found</p>
                  </div>
                </div>
              )}
          </div>
        </>
      )) ||
        (showError.status && <Error />) || <Error code={500} />}
    </>
  );
};

export default PunchRecords;
