import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, IconButton, TableCell } from "@mui/material";
import { IoIosAdd } from "react-icons/io";
import "../../assets/styles/MoreDetailsdataTable.css";
import AddIcon from "@mui/icons-material/Add";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import GroupSizesColors from "./ButtonGroup";

export default function BasicGroupingDemo({ tableStatus, data }) {
  const buttons = [
    { label: "Software", clicked: true },
    { label: "Hardware", clicked: false },
    { label: "Data Variation", clicked: false },
  ];
  const [clickedButtonIndex, setClickedButtonIndex] = useState(0);
  const [tableData, setTableData] = useState({
    Software: true,
    Hardware: false,
    Data: false,
  });

  // current date
  const currentDate = new Date().toISOString().split("T")[0];

  // console.log("tableData", tableData);

  // function to handle group button click
  const handleButtonClick = (index, label) => {
    label = label === "Data Variation" ? "Data" : label;
    setClickedButtonIndex(index);
    setTableData((prevState) => {
      const updatedState = {};
      for (const key in prevState) {
        updatedState[key] = key === label;
      }
      return updatedState;
    });
  };

  const [selectedRow, setSelectedRow] = React.useState(null);
  const [showMoreDetailsModal, setShowMoreDetailsModal] = React.useState(false);

  const handleOpen = () => {
    setShowMoreDetailsModal(true);
  };

  const handleClose = () => {
    setShowMoreDetailsModal(false);
    handleButtonClick(0, "Software");
  };

  let rows = [];
  let columns = [];
  if (tableStatus.Region && data.region_table_data) {
    rows = data.region_table_data;
    columns = data.region_columns;
  } else if (tableStatus.District && data.district_table_data) {
    rows = data.district_table_data;
    columns = data.district_columns;
  } else if (tableStatus.Machine && data.machine_table_data) {
    rows = data.machine_table_data;
    columns = data.machine_columns;
  }

  // console.log("rows, columns", rows, columns);

  return (
    <>
      <Box
        sx={{
          marginBottom: 5,
          width: "100%",
          "& .MuiDataGrid-columnHeader": {
            color: "black",
            backgroundColor: "rgba(234, 235, 237, 0.95)",
          },
          "& .MuiDataGrid-row": {
            backgroundColor: "white",
            color: "black",
          },
          // "& .MuiTablePagination-actions": {
          //   marginBottom: 2,
          // },
          // "& .MuiInputBase-root": {
          //   marginBottom: 1.5,
          // },
        }}
      >
        <DataGrid
          disableRowSelectionOnClick
          autoHeight
          rows={rows}
          initialState={{
            ...data.initialState,
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          pageSizeOptions={[25, 50, 100]}
          columns={[
            {
              field: "actions",
              headerName: "",
              flex: 1,
              maxWidth: 100,
              renderCell: (params) => (
                <TableCell align="center">
                  <IoIosAdd
                    onClick={() => {
                      setSelectedRow(params.row);
                      handleOpen();
                    }}
                    style={{ cursor: "pointer" }}
                    size={24}
                  />
                </TableCell>
              ),
            },
            ...columns,
          ]}
          columnGroupingModel={data?.final_columns_group}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              csvOptions: {
                fileName: `Location_Insights_Data(${currentDate})`,
              },
              printOptions: {
                fileName: `Location_Insights_Data(${currentDate})`,
              },
            },
          }}
        />
      </Box>

      {/* modal to show detailed data  */}
      <Modal
        // fullscreen
        size="xl"
        className="modal-container"
        show={showMoreDetailsModal}
        onHide={handleClose}
        backdrop="static"
        centered
      >
        <Modal.Header className="px-3 py-2" closeButton>
          <Modal.Title className="fs-5">More Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRow && (
            <>
              <div className="upper-card-basic-info moredetail-gridstyle mb-3">
                {selectedRow &&
                  Object.entries(selectedRow).map(
                    ([key, value], i) =>
                      key !== "id" &&
                      key !== "detailed_data" && (
                        <p key={`${key}-${i}`} className="m-0">
                          <label
                            htmlFor={`outlined-read-only-input-${key}`}
                            className="basic_details_elements_label_data_table"
                          >
                            {key}
                          </label>{" "}
                          <div
                            id={`outlined-read-only-input-${key}`}
                            className="basic_details_elements_data_content"
                          >
                            {value}
                          </div>
                        </p>
                      )
                  )}
              </div>

              {/* buttons part  */}
              <div className="group-buttons p-0 m-0 mt-4">
                <GroupSizesColors
                  buttons={buttons}
                  clickedButtonIndex={clickedButtonIndex}
                  handleButtonClick={handleButtonClick}
                />
              </div>

              {Object.entries(selectedRow?.detailed_data).map(
                ([key, value], index) =>
                  (tableData.Software && key === "Software") ||
                  (tableData.Hardware && key === "Hardware") ||
                  (tableData.Data && key === "Data Variation") ? (
                    <div key={index}>
                      <Box
                        sx={{
                          marginBottom: 2,
                          marginTop: 2,
                          width: "100%",
                          "& .MuiDataGrid-columnHeader": {
                            backgroundColor: "rgba(234, 235, 237, 0.95)",
                            color: "black",
                          },
                          // "& .MuiTablePagination-actions": {
                          //   marginBottom: 2,
                          // },
                          // "& .MuiInputBase-root": {
                          //   marginBottom: 1.5,
                          // },
                        }}
                      >
                        <DataGrid
                          disableRowSelectionOnClick
                          autoHeight
                          rows={value?.map((row, index) => ({
                            id: index,
                            ...row,
                          }))}
                          initialState={{
                            ...data.initialState,
                            pagination: { paginationModel: { pageSize: 25 } },
                          }}
                          pageSizeOptions={[25, 50, 100]}
                          columns={data.issue_headers[key]}
                          slots={{ toolbar: GridToolbar }}
                          slotProps={{
                            toolbar: {
                              csvOptions: {
                                fileName: `Location_Insights_More_Info(${currentDate})`,
                              },
                              printOptions: {
                                fileName: `Location_Insights_More_Info(${currentDate})`,
                              },
                            },
                          }}
                        />
                      </Box>
                    </div>
                  ) : null
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
