import React, { useContext, useEffect, useState } from "react";
import "../../assets/styles/CardModal.css";
import ContextState from "../contextApi/ContextState";
import ShowPullDataTable from "./ShowPullDataTable";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import SendIcon from "@mui/icons-material/Send";
import ModalError from "../error/ModalError";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddScanIds from "./AddScanIds";

const DataPool = ({
  data,
  reloadDataPullPage,
  refreshBtn,
  setRefreshBtn,
  setRefreshLoading,
}) => {
  const {
    url,
    formatDate,
    lodingModalVisiblity,
    showLoadingModal,
    fetchApi,
    ticketStatus,
    openSnackbar,
    showModalError,
    showModalErrorPage,
  } = useContext(ContextState);
  const { ticketId, status } = data;
  const navigate = useNavigate();
  const filterData = useSelector(
    (state) => state?.userFilters?.data?.data || []
  );
  const [edit, setEdit] = useState(false);

  // this is a local state variable maintaining the onchange of all 5 inputs
  const [dataPullDetail, setDataPullDetail] = useState({
    machineNumber: "",
    file_type: "",
    crop_type: "",
    date: "",
    lot_id: "",
    isOpen: false,
    fetch_api: false,
    pull_data_state: true,
    selected_scan_id: [],
  });

  // this is a global list where addition and deletion of scanid's could be done
  const [dataPullDetailList, setDataPullDetailList] = useState([]);

  // previous comments data that is coming from backend
  const [pullDataBackend, setPullDataBackend] = useState({
    tableColumns: [],
    pull_scan_ids: [],
    machines_data: [],
  });

  // this state variable is to reload the page after some edit done on it
  const [reloadData, setReloadData] = useState(false);

  // this loading variable is set for loading of buttons on click
  const [loading, setLoading] = React.useState(false);

  // function to update the state of scanDetails state variables
  const handleInputChange = (event, field) => {
    setDataPullDetail((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));
  };

  // function to add individual scan data
  const handleScanDetail = () => {
    // console.log("dataPullDetail--------->", dataPullDetail);
    const dateObject = dayjs(dataPullDetail.date.$d);

    // Format the date as YYYYMMDD
    const formattedDate = dateObject.format("YYYYMMDD");

    const crop_type = dataPullDetail.crop_type.split("_")[0];
    const formattedScanId = `${crop_type}.${dataPullDetail.machineNumber}-${formattedDate}-${dataPullDetail.lot_id}`;

    // console.log("-------->", formattedScanId);
    // Check if the scan_id already exists in the list
    const isScanIdExists = dataPullDetailList.some(
      (element) => element.formattedScanId === formattedScanId
    );
    if (!isScanIdExists) {
      const newDataPullDetail = {
        machineNumber: dataPullDetail.machineNumber,
        file_type: dataPullDetail.file_type,
        formattedScanId: formattedScanId,
      };

      setDataPullDetailList([...dataPullDetailList, newDataPullDetail]);
      setDataPullDetail({
        machineNumber: "",
        file_type: "",
        crop_type: "",
        date: "",
        lot_id: "",
      });
    } else {
      openSnackbar("Scan Id already Added!", "warning");
    }
  };
  // console.log(dataPullDetailList);

  // function to delete these elements as well
  const handleDeleteElement = (index) => {
    // Remove the element at the specified index from the list
    const updatedList = [...dataPullDetailList];
    updatedList.splice(index, 1);
    setDataPullDetailList(updatedList);
  };

  // function to add pull data in db
  const handleAddPulldatainDB = async () => {
    try {
      let tempPullData = [];

      // Iterate over each element in dataPullDetailList
      dataPullDetailList.forEach((element) => {
        // Handle the case when file_type is 'Both'
        if (element.file_type.toLowerCase() === "both") {
          // Add two elements for 'images' and 'results' respectively
          tempPullData.push({
            machine_code: element.machineNumber,
            folder_type: "images",
            scan_id: element.formattedScanId,
          });
          tempPullData.push({
            machine_code: element.machineNumber,
            folder_type: "results",
            scan_id: element.formattedScanId,
          });
        } else {
          // Add the element as is
          tempPullData.push({
            machine_code: element.machineNumber,
            folder_type: element.file_type,
            scan_id: element.formattedScanId,
          });
        }
      });

      // Create a list of dictionaries with the required structure
      let pullData = tempPullData.map(
        ({ machine_code, folder_type, scan_id }) => ({
          machine_code,
          folder_type,
          scan_id,
        })
      );

      // Create RESCAN versions of scan IDs
      let pullDataWithRescan = pullData.map(
        ({ machine_code, folder_type, scan_id }) => ({
          machine_code,
          folder_type,
          scan_id: "RESCAN." + scan_id,
        })
      );

      // Concatenate the original pullData and pullDataWithRescan lists
      let finalPullData = pullData.concat(pullDataWithRescan);

      // console.log(finalPullData);

      setLoading(true);

      let payload = {
        ticket_id: ticketId,
        pull_data: finalPullData,
      };

      // Send the payload to the backend route
      const response = await fetchApi(
        "add_ticket_pull_data",
        "POST",
        payload,
        true
      );

      if (response.statusCode === 200) {
        // console.log("pull data added successfully");
        openSnackbar(response.data.message, "success");
        // setComment([]);
        setReloadData((prevState) => !prevState);
        setDataPullDetailList([]);
        setEdit(false);
      } else {
        // console.log("Error occured during edit ticket information:", error);
        openSnackbar(response.msg, "error");
      }
    } catch (error) {
      // console.log("Error occured during edit ticket information:", error);
      openSnackbar("Some error occured while adding Pull data", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    showLoadingModal(true);

    const fetchCardData = async () => {
      let payload = {
        info_type: "pull_data",
        ticket_id: ticketId,
      };
      let jsonData;

      if (refreshBtn) {
        setRefreshLoading(true);
      }

      if (isMounted) {
        jsonData = await fetchApi("get_ticket_info", "POST", payload, true);

        //console.log("pull data recieved successfully:", jsonData.data);
        if (jsonData.statusCode === 200 && jsonData && jsonData.data) {
          if (isMounted) {
            setPullDataBackend((prevState) => ({
              ...prevState,
              tableColumns: jsonData.data.columns || [],
              pull_scan_ids: jsonData.data.pull_scan_ids || [],
              machines_data: jsonData.data.machines_data,
            }));
          }
        } else {
          // openSnackbar(jsonData.data, "error")
          showModalErrorPage(jsonData.statusCode, jsonData.msg);
        }
      }
      showLoadingModal(false);
      setRefreshLoading(false);
      setRefreshBtn(false);
    };

    fetchCardData();
    // showLoadingModal(false);
  }, [reloadData, reloadDataPullPage]);

  const handleOpenScanids_modal = () => {
    setDataPullDetail((prev) => ({ ...prev, isOpen: true, fetch_api: true }));
  };
  const handleCloseScanids_modal = () => {
    setDataPullDetail((prev) => ({ ...prev, isOpen: false, fetch_api: false }));
  };

  const set_selected_scan_ids = (ids) => {
    // console.log("---->", ids);
    setDataPullDetail((prev) => ({
      ...prev,
      isOpen: false,
    }));
    const newDataPullDetailList = ids.map((item, index) => ({
      machineNumber: dataPullDetail.machineNumber,
      file_type: dataPullDetail.file_type,
      formattedScanId: item,
    }));

    setDataPullDetailList((prevList) => [
      ...prevList,
      ...newDataPullDetailList,
    ]);
    // setDataPullDetail({
    //   machineNumber: "",
    //   file_type: "",
    //   crop_type: "",
    //   date: "",
    //   lot_id: "",
    // });
  };

  return (
    <>
      {(!showModalError.status && !lodingModalVisiblity && (
        <div className="comment-section mt-3">
          <div className=" mx-4 row">
            <div className="mb-2">
              <button
                // id="saveTicketBtn"
                type="button"
                className="btn btn-outline-primary markResolved_button"
                disabled={ticketStatus === "resolved"}
                onClick={() => setEdit(!edit)}
              >
                <Tooltip
                  data-html="true"
                  title={"Click here to add data pull info"}
                  arrow={true}
                >
                  Pull Data
                </Tooltip>
              </button>
            </div>

            {edit && (
              <>
                <div className="data-pull-box mt-2">
                  <div className=" edit-data-variation-header px-2">
                    <header>Add Pull Data</header>
                    <button
                      className="btn btn-outline-primary add-pull-data-button"
                      // variant="primary"
                      onClick={handleScanDetail}
                      disabled={
                        dataPullDetail.machineNumber === "" ||
                        dataPullDetail.file_type === "" ||
                        dataPullDetail.crop_type === "" ||
                        dataPullDetail.date === "" ||
                        dataPullDetail.lot_id === ""
                      }
                    >
                      Add
                    </button>
                  </div>

                  <Box
                    className="basic_details"
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "35ch" },
                      "@media (max-width: 900px)": {
                        "& .MuiTextField-root": { width: "100%" },
                      },
                      "@media (max-width: 900px)": {
                        "& .MuiFormControl-root": { m: 1, width: "100%" },
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <FormControl sx={{ m: 1, width: "35ch" }} size="medium">
                      <InputLabel id="modal-machine-label">Machine</InputLabel>
                      <Select
                        labelId="modal-machine-label"
                        value={dataPullDetail.machineNumber}
                        onChange={(e) => handleInputChange(e, "machineNumber")}
                        label="Machine"
                      >
                        {pullDataBackend.machines_data?.length &&
                          pullDataBackend.machines_data.map((item, index) => (
                            <MenuItem key={index} value={item["code"]}>
                              {item["code"]}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, width: "35ch" }} size="medium">
                      <InputLabel id="modal-crop-label">File Type</InputLabel>
                      <Select
                        labelId="modal-file_type-label"
                        value={dataPullDetail.file_type}
                        onChange={(e) => handleInputChange(e, "file_type")}
                        label="File Type"
                      >
                        <MenuItem key={1} value={"images"}>
                          Images
                        </MenuItem>
                        <MenuItem key={2} value={"results"}>
                          Results
                        </MenuItem>
                        <MenuItem key={3} value={"both"}>
                          Both
                        </MenuItem>
                      </Select>
                    </FormControl>

                    {/* <FormControl sx={{ m: 1, width: "35ch" }} size="medium">
                      <InputLabel id="modal-crop-label">Crop Type</InputLabel>
                      <Select
                        labelId="modal-crop-label"
                        value={dataPullDetail.crop_type}
                        onChange={(e) => handleInputChange(e, "crop_type")}
                        label="Crop Type"
                      >
                        <MenuItem key={2} value={"RICE"}>
                          RICE
                        </MenuItem>
                        <MenuItem key={1} value={"PADDY"}>
                          PADDY
                        </MenuItem>
                        <MenuItem key={3} value={"RICEPB"}>
                          RICEPB
                        </MenuItem>
                        <MenuItem key={4} value={"WHEAT"}>
                          WHEAT
                        </MenuItem>
                      </Select>
                    </FormControl> */}

                    <FormControl sx={{ m: 1, width: "35ch" }} size="medium">
                      <InputLabel id="crop-label">Crop</InputLabel>
                      <Select
                        labelId="crop-label"
                        value={dataPullDetail.crop_type}
                        onChange={(e) => handleInputChange(e, "crop_type")}
                        label="Crop"
                      >
                        {filterData?.param_data &&
                          Object.keys(filterData.param_data).map(
                            (crop, index) => (
                              <MenuItem key={index} value={crop}>
                                {crop}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          className="date-Picker "
                          label="Date"
                          value={dataPullDetail.date}
                          onChange={(date) =>
                            handleInputChange(
                              { target: { value: date } },
                              "date"
                            )
                          }
                          sx={{
                            padding: "8px",
                            margin: "4px",
                            width: "35ch",
                            "& .MuiInputBase-input": {
                              color: "#4c4e64de",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#4c4e64b3",
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>

                    <TextField
                      id="outlined-multiline-flexible"
                      label="Lot Id"
                      value={dataPullDetail.lot_id}
                      onChange={(e) => handleInputChange(e, "lot_id")}
                      multiline
                      maxRows={4}
                      required
                    />

                    <Button
                      variant="outlined"
                      // style={{
                      //   alignSelf: "center",
                      //   // cursor: "pointer",
                      //   color: "#3b71ca",
                      //   border: "1px solid #3b71ca",
                      //   backgroundColor: "#fff",
                      //   padding: "4px 8px",
                      //   boxShadow: "none",
                      //   cursor: "pointer",
                      // }}
                      // className="more_details_hover"
                      className="my-3"
                      disabled={
                        (dataPullDetail?.date &&
                          dataPullDetail?.file_type &&
                          dataPullDetail?.crop_type &&
                          dataPullDetail?.machineNumber) === "" ||
                        dataPullDetail?.lot_id
                          ? true
                          : false
                      }
                      onClick={() => {
                        // e.stopPropagation();
                        handleOpenScanids_modal();
                        // hanldeFetchScanIds();
                      }}
                    >
                      Select Scan Id
                    </Button>
                  </Box>

                  {/* Render the list of elements */}
                  {/* <header>Added elements</header> */}
                  <div className="data-variation-elements-container mt-3">
                    {dataPullDetailList.map((element, index) => (
                      <div key={index} className="data-variation-elements">
                        {element.machineNumber}, {element.file_type},{" "}
                        {element.formattedScanId}
                        {/* Delete button */}
                        <span
                          className="px-2 cross-btn-data-variation"
                          variant="outlined"
                          onClick={() => handleDeleteElement(index)}
                        >
                          x
                        </span>
                      </div>
                    ))}
                  </div>

                  {/* finally add all the elements */}
                  <div className="mt-3">
                    <LoadingButton
                      size="large"
                      onClick={handleAddPulldatainDB}
                      endIcon={<SendIcon />}
                      loading={loading}
                      disabled={dataPullDetailList.length === 0}
                      loadingPosition="end"
                      variant="contained"
                    >
                      <span>Add</span>
                    </LoadingButton>
                  </div>
                </div>
              </>
            )}
            {dataPullDetail.isOpen ? (
              <AddScanIds
                scandetails={dataPullDetail}
                handleClose={handleCloseScanids_modal}
                gettingValuesfromscanids={set_selected_scan_ids}
              />
            ) : null}
            <div className=" my-3">
              {/* show pull data */}
              {(pullDataBackend?.pull_scan_ids?.length && (
                <>
                  <figure>
                    <blockquote class="blockquote pull-data-find-div">
                      <p>
                        sftp://nebulaa@os.nebulaa.in:45/mnt/Data/Data-Storage
                      </p>
                    </blockquote>
                    <figcaption class="blockquote-footer mb-0">
                      Path to copy
                      <cite title="Source Title"> Pulling data</cite>
                    </figcaption>
                  </figure>
                  {/* data before 1 july 2024  starts*/}
                  <figure>
                    <blockquote class="blockquote pull-data-find-div">
                      <p>
                        sftp://nebulaa@os.nebulaa.in:45/media/nebulaa/DATA/data_storage_backup_before_july01'2024
                      </p>
                    </blockquote>
                    <figcaption class="blockquote-footer mb-0">
                      Path to copy
                      <cite title="Source Title"> Pulling data</cite> before
                      1'July,24
                    </figcaption>
                  </figure>
                  {/* data before 1 july 2024  ends*/}

                  <ShowPullDataTable pullDataBackend={pullDataBackend} />
                </>
              )) ||
                "No Pull Data request yet"}
            </div>
          </div>
        </div>
      )) ||
        (showModalError.status && <ModalError />) || <></>}
    </>
  );
};

export default DataPool;
