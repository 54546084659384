import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, IconButton, TableCell } from "@mui/material";

import "../../../assets/styles/MoreDetailsdataTable.css";
import { Tooltip } from "@material-ui/core";

export default function BasicGroupingDemo({ tableStatus, data }) {
  // current date
  const currentDate = new Date().toISOString().split("T")[0];

  const [selectedRow, setSelectedRow] = React.useState(null);
  const [showMoreDetailsModal, setShowMoreDetailsModal] = React.useState(false);

  const handleOpen = () => {
    setShowMoreDetailsModal(true);
  };

  const handleClose = () => {
    setShowMoreDetailsModal(false);
    // handleButtonClick(0, "SubIssue");
  };

  let rows = [];
  let columns = [];
  if (tableStatus.SubIssue && data.issue_data["Sub Issue"]) {
    rows = data.issue_data["Sub Issue"];
    columns = data.issue_headers["Sub Issue"];
  } else if (tableStatus.Part && data.issue_data["Part Replaced"]) {
    rows = data.issue_data["Part Replaced"];
    columns = data.issue_headers["Part Replaced"];
  } else if (tableStatus.Person && data.issue_data["Person Visited"]) {
    rows = data.issue_data["Person Visited"];
    columns = data.issue_headers["Person Visited"];
  }

  // console.log("rows, columns", rows, columns);

  return (
    <>
      <Box
        sx={{
          marginBottom: 5,
          width: "100%",
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "rgba(234, 235, 237, 0.95)",
            color: "black",
          },
          "& .MuiDataGrid-row": {
            backgroundColor: "white",
            color: "black",
          },
          // "& .MuiTablePagination-actions": {
          //   marginBottom: 2,
          // },
          // "& .MuiInputBase-root": {
          //   marginBottom: 1.5,
          // },
        }}
      >
        {/* <Tooltip title={"delete"} /> */}

        {/* {rows &&
          rows?.map((each_row, index) => (
            <Tooltip key={index} title={each_row["Sub Issue"]} />
          ))} */}
        <DataGrid
          disableRowSelectionOnClick
          autoHeight
          rows={rows?.map((row, index) => ({
            id: index + 1,
            ...row,
          }))}
          initialState={{
            ...data.initialState,
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          pageSizeOptions={[25, 50, 100]}
          columns={columns}
          //   columnGroupingModel={data?.final_columns_group}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              csvOptions: {
                fileName: `Location_Insights_Data(${currentDate})`,
              },
              printOptions: {
                fileName: `Location_Insights_Data(${currentDate})`,
              },
            },
          }}
        />
      </Box>
    </>
  );
}
