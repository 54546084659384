import React, { useContext, useEffect, useState } from "react";
import ContextState from "../contextApi/ContextState";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import ModalError from "../error/ModalError";

const ModelDetailsLog = ({
  data,
  reloadModelLogPage,
  refreshBtn,
  setRefreshBtn,
  setRefreshLoading,
}) => {
  const {
    fetchApi,
    formatDate,
    openSnackbar,
    lodingModalVisiblity,
    showModalError,
    showLoadingModal,
    showModalErrorPage,
  } = useContext(ContextState);
  const [showLoader, setShowLoader] = useState(false);

  const [logdata, setLogData] = useState(null);

  //   exacting the ticket id from the props
  const { ticketId } = data;

  useEffect(() => {
    let isMounted = true;
    showLoadingModal(true);
    const fetchModelDetailsLog = async () => {
      try {
        const payload = {
          ticket_id: ticketId,
        };

        if (refreshBtn) {
          setRefreshLoading(true);
        }

        const response = await fetchApi(
          "get_model_details_log",
          "POST",
          payload,
          isMounted
        );
        if (response?.statusCode === 200) {
          setLogData(response.data);
          setShowLoader((prev) => !prev);
        } else {
          showModalErrorPage(response.statusCode, response.msg);
        }
      } catch (error) {
        openSnackbar(
          "Some error occured while getting model details log",
          "error"
        );
      } finally {
        showLoadingModal(false);
        setRefreshLoading(false);
        setRefreshBtn(false);
      }
    };
    fetchModelDetailsLog();

    return () => {
      isMounted = false;
    };
  }, [reloadModelLogPage]);

  const customCells = (columns) => {
    if (!columns) return [];

    let customcells = columns.map((each_col) => {
      if (each_col.headerName === "Update Time") {
        return {
          ...each_col,
          valueGetter: (value) => {
            let timestamp = formatDate(value);
            return timestamp;
          },
        };
      } else if (each_col.headerName === "Remarks") {
        return {
          ...each_col,
          flex: 1,
        };
      } else {
        return { ...each_col };
      }
    });

    return customcells;
  };

  return (
    <>
      {" "}
      {(!showModalError.status && !lodingModalVisiblity && (
        <div style={{ margin: "25px 30px 0px" }}>
          <Box
            style={{ height: 800 }}
            sx={{
              width: "100%",
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "rgba(234, 235, 237, 0.95)",
                color: "black",
              },
              "& .MuiDataGrid-row": {
                backgroundColor: "white",
                color: "black",
              },
              "& .MuiDataGrid-selectedRowCount": {
                display: "none",
              },
              "& .MuiDataGrid-footerContainer": {
                display: "flex",
                justifyContent: "end",
              },
            }}
          >
            {logdata?.rows?.length > 0 ? (
              <DataGrid
                style={{ height: 800, boxShadow: "0 3px 12px 0 #00000026" }}
                rows={logdata?.rows || []}
                columns={customCells(logdata?.columns)}
              />
            ) : (
              <div
                className="container no-ticket-found-div text-center count_heading_div"
                style={{ height: "84%" }}
              >
                <div className="d-flex align-items-center justify-content-center flex-column">
                  <i className="fa-solid fa-face-rolling-eyes fa-2xl inline-block"></i>
                  <p>No Model Details Log Found !</p>
                </div>
              </div>
            )}
          </Box>
        </div>
      )) ||
        (showModalError.status && <ModalError />) || <></>}
    </>
  );
};

export default ModelDetailsLog;
