import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';



export default function GroupSizesColors({ handleButtonClick, buttons, clickedButtonIndex }) {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& > *': {
          mb: 0,
        },
      }}
    >
      <ButtonGroup size="large" aria-label="Large button group">
        {buttons.map((button, index) => (
          <Button
            key={index}
            onClick={() => handleButtonClick(index, button.label)}
            sx={{
              backgroundColor: clickedButtonIndex === index ? '#0097D1' : '',
              color: clickedButtonIndex === index ? '#fff' : '#000',
              transition: 'color 0.3s ease', 
              '&:hover': {
                color: '#000', 
              }
            }}
          >
            {button.label}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
}
