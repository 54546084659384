import { createContext, useState } from "react";
import CryptoJS from "crypto-js";

const ContextState = createContext({});

export const ContextStateProvider = ({ children }) => {
  let key = process.env.REACT_APP_IS_LOGIN_KEY;
  const API_URL = process.env.REACT_APP_BACKEND_SERVER;
  const accessTokenKey = process.env.REACT_APP_ACCESS_TOKEN_SECRET;
  const SECRET_VALUE = process.env.REACT_APP_SECRET_VALUE;

  const [accessToken, setaccessToken] = useState("");
  // const [responsedata, setresponsedata] = useState({});
  const [lodingVisiblity, setLoadingVisiblity] = useState(true);
  const [lodingModalVisiblity, setLoadingModalVisiblity] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [showError, setShowError] = useState({
    status: null,
    msg: null,
  });
  // state to manage modal error page
  const [showModalError, setShowModalError] = useState({
    status: null,
    msg: null,
  });

  // to maintain the status of tickets in modal
  const [ticketStatus, setTicketStatus] = useState(null);

  // snackbar part *****
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const openSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };
  // snackbar part *****

  //*********  This part is for encrypted key*********
  // Function to encrypt and set the access token
  const SetNewAccessToken = (accessToken) => {
    const encryptedToken = CryptoJS.AES.encrypt(
      accessToken,
      SECRET_VALUE
    ).toString();
    localStorage.setItem(accessTokenKey, encryptedToken);
  };

  // Function to decrypt and get the access token
  const getAccessToken = () => {
    try {
      const encryptedToken = localStorage.getItem(accessTokenKey);
      if (encryptedToken) {
        // console.log("going inside the above condition")
        const bytes = CryptoJS.AES.decrypt(encryptedToken, SECRET_VALUE);
        const originalToken = bytes.toString(CryptoJS.enc.Utf8);
        return originalToken;
      }
      // console.log("not going inside the above condition")
      return null;
    } catch (error) {
      // console.log("Unable to find access token from local storage :", error);
      localStorage.clear();
      window.location.href = "/login";
    }
  };
  //********* This part is for encrypted key*********

  // showLoading for main pages
  const showLoading = (visiblity) => {
    setLoadingVisiblity(visiblity);
  };
  // showLoading for modal pages
  const showLoadingModal = (visiblity) => {
    setLoadingModalVisiblity(visiblity);
  };

  // show error page
  const showErrorPage = (status, msg) => {
    showError.status = status;
    showError.msg = msg;
    setShowError(showError);
  };

  // show modal error page
  const showModalErrorPage = (status, msg) => {
    showModalError.status = status;
    showModalError.msg = msg;
    setShowModalError(showModalError);
  };

  // setfiltersData
  const handelSetFilterData = (fData) => {
    setFilterData(fData);
  };

  // setTicketStatus
  const handleSetTicketStatus = (statusData) => {
    setTicketStatus(statusData);
  };

  // this function is to format date into AM/PM format from GMT
  // const formatDate = (inputDate) => {
  //   // const date = new Date(dateString);
  //   // console.log("input date:", inputDate);

  //   inputDate = inputDate?.replace("GMT", "GMT+0530");
  //   const parsedDate = new Date(inputDate);
  //   // console.log("*", new Date());

  //   // Extract AM/PM designation
  //   const ampm = parsedDate.getHours() >= 12 ? "PM" : "AM";

  //   if (parsedDate.getHours() > 12) {
  //     parsedDate.setHours(parsedDate.getHours() - 12);
  //   }

  //   // Format the date to the desired string format
  //   const formattedDate =
  //     parsedDate.toDateString() +
  //     " " +
  //     parsedDate.toLocaleTimeString([], {
  //       hour: "numeric",
  //       minute: "numeric",
  //     }) +
  //     " " +
  //     ampm;

  //   // console.log("date new", inputDate, formattedDate);
  //   return formattedDate;
  // };

  const formatDate = (inputDate, inShort = false) => {
    // Replace "GMT" with "GMT+0530" if necessary
    inputDate = inputDate?.replace("GMT", "GMT+0530");
    const parsedDate = new Date(inputDate);

    if (inShort) {
      // Extract day, month, and year
      const day = parsedDate.getDate().toString().padStart(2, "0");
      const month = parsedDate.toLocaleString("default", { month: "short" });
      const year = parsedDate.getFullYear().toString().slice(-2);

      // Extract hours and minutes in 24-hour format
      const hours = parsedDate.getHours().toString().padStart(2, "0");
      const minutes = parsedDate.getMinutes().toString().padStart(2, "0");

      // Format the final date string as `09Sep'24 17:55`
      const formattedDate = `${day} ${month}'${year} ${hours}:${minutes}`;

      return formattedDate;
    }

    // Extract hours, minutes, and AM/PM designation
    const hours = parsedDate.getHours();
    const minutes = parsedDate.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    // Format the time string with uppercase AM/PM
    const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;

    // Format the date to a string
    const formattedDate = parsedDate.toDateString() + " " + formattedTime;

    return formattedDate;
  };

  // This function is to convert normal number in indian ruppee format
  const formatInIndianRupees = (number) => {
    return new Intl.NumberFormat("en-IN", {
      // style: "currency",
      // currency: "INR",
      // Optional: You can adjust the minimum and maximum fraction digits
      // minimumFractionDigits: 2,
      // maximumFractionDigits: 2,
    }).format(number);
  };

  //login api call
  const fetchloginApiCall = async (URL, method, payload) => {
    try {
      const response = await fetch(API_URL + URL, {
        method: method,
        headers: {
          "Content-Type": "text/plain",
        },
        body: JSON.stringify(payload),
        credentials: "include", //must include it to save it in cookie
      });
      const responseData = await response.json();
      const statusCode = response.status;

      if (statusCode === 200) {
        // localStorage.setItem("accessToken", responseData["access_token"]);
        SetNewAccessToken(responseData["access_token"]);
        localStorage.setItem(key, true);
        return { statusCode, data: responseData };
      } else {
        // alert("Incorrect Login Credentials!!");
        return { statusCode, msg: responseData };
      }
    } catch (error) {
      // alert("Internal server error");
      return {
        statusCode: 500,
        msg: "Internal sever error! Try after some time.",
      };
    }
  };

  // logout api call
  const fetchlogoutApiCall = async (URL, method) => {
    try {
      const response = await fetch(API_URL + URL, {
        method: method,
        // headers: headers,
        body: JSON.stringify({}),
        credentials: "include",
      });
      const responseData = await response.json();
      const statusCode = response.status;

      if (statusCode === 200) {
        localStorage.clear();
        // setaccessToken("");
        return { statusCode, data: responseData };
      } else {
        localStorage.clear();
        // alert("Some error occured!!!");
        return { statusCode, data: responseData };
      }
    } catch (error) {
      localStorage.clear();
      // alert("Internal Server error");
      return {
        statusCode: 500,
        msg: "Internal sever error! Try after some time.",
      };
    }
  };

  // refresh token api call
  const refreshTokenApiCall = async (URL, method) => {
    try {
      const response = await fetch(URL, {
        method: method,
        body: JSON.stringify({}),
        credentials: "include",
      });

      const responseData = await response.json();
      const statusCode = response.status;

      if (statusCode === 200) {
        // localStorage.setItem("accessToken", responseData["access_token"]);
        SetNewAccessToken(responseData["access_token"]);
        return { statusCode, data: responseData };
      } else {
        // alert('some error occured!!');
        localStorage.clear();
        window.location.href = "/login";
        console.error("Login failed:", responseData.msg);
        return { statusCode, data: responseData };
      }
    } catch (error) {
      localStorage.clear();
      // console.log("internal server error occured", error);
      // alert(`Internal Server error:-, ${error}`);
      return {
        statusCode: 500,
        data: "Internal sever error!!! Try after some time.",
      };
    }
  };

  //fetch filter data api call
  const fetchFiltersDataApiCall = async (URL, method, payload, isMounted) => {
    try {
      const response = await fetch(URL, {
        method: method,
        headers: {
          // Authorization: "Bearer " + localStorage.getItem("accessToken"),
          Authorization: "Bearer " + getAccessToken(),
          "Content-Type": "text/plain",
        },
        body: JSON.stringify(payload),
      });

      if (!isMounted) return;

      const responseData = await response.json();
      const statusCode = response.status;

      if (statusCode === 200) {
        return { statusCode, data: responseData };
      } else if (
        statusCode === 401 &&
        responseData.msg.toLowerCase() === "token has expired"
      ) {
        // console.log("request sent for refreshing access token");

        const resp = await refreshTokenApiCall(
          API_URL + "refresh_user_token",
          "POST"
        );
        return fetchFiltersDataApiCall(URL, method, payload, isMounted);
      } else {
        window.location.href("/login");
      }
    } catch (error) {
      localStorage.clear();
      alert("Internal Server error");
      // console.log("internal error occured", error);
      return {
        statusCode: 500,
        data: "Internal sever error!!! Try after some time.",
      };
    }
  };

  async function refreshToken(URL, methord, payload, isMounted) {
    const refreshTokenFlagKey = "refreshTokenInProgress";
    const refreshTimestampKey = "refreshTokenTimestamp";
    const refreshTimeout = 3000; // Time to wait before retrying refresh (in milliseconds)

    // Check if refresh token is already in progress
    const isRefreshing = localStorage.getItem(refreshTokenFlagKey);
    // if (isRefreshing) {
    //   // Check the timestamp to see if it's recent
    //   const lastRefreshTime = parseInt(
    //     localStorage.getItem(refreshTimestampKey)
    //   );
    //   const currentTime = new Date().getTime();
    //   if (currentTime - lastRefreshTime < refreshTimeout) {
    //     // If recent, wait and retry or notify user
    // setTimeout(fetchApi(URL, methord, payload, isMounted), refreshTimeout);
    //     return;
    //   } else {
    //     // If not recent, assume prall_filter_dataevious attempt failed and proceed
    //     localStorage.removeItem(refreshTokenFlagKey);
    //   }
    // }

    // Set refresh token in progress flag
    localStorage.setItem(refreshTokenFlagKey, true);
    localStorage.setItem(refreshTimestampKey, new Date().getTime());

    try {
      const response = await fetch(API_URL + "refresh_user_token", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({}),
      });

      const responseData = await response.json();
      const statusCode = response.status;
      // console.log("responseData", responseData, statusCode);
      // if (!response.ok) {
      //   throw new Error("Failed to refresh token");
      // }

      if (statusCode === 200) {
        const newAccessToken = responseData.access_token;

        // Update token in local storage
        // localStorage.setItem("accessToken", newAccessToken);
        SetNewAccessToken(newAccessToken);

        // Remove refresh token in progress flag
        localStorage.removeItem(refreshTokenFlagKey);

        return fetchApi(URL, methord, payload, isMounted);
      } else if (statusCode === 500) {
        // console.log("Internal Server error ", responseData);
        // window.location.href("/login")
        return { statusCode: statusCode, msg: responseData.error };
      } else if (
        statusCode === 401 &&
        responseData.message.toLowerCase() === "Expired JWT token".toLowerCase()
      ) {
        // return expired div
        showErrorPage(statusCode, responseData.error);
        return { statusCode: statusCode, msg: responseData.error };
      } else {
        // route login
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      // window.location.href("/login")
      localStorage.removeItem(refreshTokenFlagKey);
      return {
        statusCode: 500,
        msg: "Internal sever error! Try after some time.",
      };
    }
  }

  // by now use this function only
  async function fetchApi(URL, methord, payload, isMounted) {
    const accessToken = getAccessToken();

    if (!isMounted) {
      return;
    }

    try {
      const response = await fetch(API_URL + URL, {
        method: methord,
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "text/plain",
        },
        body: JSON.stringify(payload),
      });
      // console.log("Data fetched successfully:", response);

      const responseData = await response.json();
      const statusCode = response.status;
      // console.log("statusCode : ", statusCode)
      if (statusCode === 200) {
        return { statusCode, data: responseData };
      } else if (statusCode === 401 || statusCode === 422) {
        // console.log("Token has expired. Attempting to refresh access token...");
        // Call your token refresh function here
        return refreshToken(URL, methord, payload, isMounted);
      } else {
        // console.log("Unexpected error. Status code:", statusCode);
        // localStorage.clear();
        // window.location.href ="/login";
        return {
          statusCode: statusCode,
          msg: responseData.error || "Some Error Occured",
        };
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // window.location.href("/login")
      return {
        statusCode: 500,
        msg: "Internal sever error! Try after some time.",
      };
    }
  }

  const [snackState, setSnackState] = useState(false);

  // console.log("show modal error", showModalError);
  return (
    <ContextState.Provider
      value={{
        url: API_URL,
        key,
        formatDate,
        formatInIndianRupees,
        fetchloginApiCall,
        fetchFiltersDataApiCall,
        refreshTokenApiCall,
        fetchlogoutApiCall,
        accessToken,
        showLoading,
        lodingVisiblity,
        handelSetFilterData,
        filterData,
        fetchApi,
        showError,
        showErrorPage,
        showModalError,
        showModalErrorPage,
        ticketStatus,
        handleSetTicketStatus,
        snackbarOpen,
        snackbarMessage,
        snackbarSeverity,
        openSnackbar,
        closeSnackbar,
        lodingModalVisiblity,
        showLoadingModal,
        SetNewAccessToken,
      }}
    >
      {children}
    </ContextState.Provider>
  );
};

export default ContextState;
