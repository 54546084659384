import { LoadingButton } from "@mui/lab";
import { Box, Modal } from "@mui/material";
import React, { useContext, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import close from "../../../src/assets/images/close.svg";
import { Context } from "@rollbar/react";
import ContextState from "../contextApi/ContextState";

const ConfirmDeletionModal = ({
  deleteModalState,
  handleClose,
  handleReload,
  closingVisitModal,
}) => {
  const { fetchApi, openSnackbar } = useContext(ContextState);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const delete_attachment = async (attachId, ticketId) => {
    try {
      setLoadingSpinner(true);
      let endpoint;
      if (deleteModalState?.modalType === "visit_attachment") {
        endpoint = "delete_visit_attachment";
      } else {
        endpoint = "delete_ticket_attachment";
      }

      const payload = {
        ticket_id: ticketId,
        attach_id: attachId,
      };

      const response = await fetchApi(endpoint, "POST", payload, true);

      if (response?.statusCode === 200) {
        if (closingVisitModal) {
          closingVisitModal();
        }
        openSnackbar(response?.data?.message);
        // reloading of attachments
        handleReload();
        // closing of modal when response is 200
        handleClose();
      } else if (response.statusCode !== 200) {
        openSnackbar(response.msg);
      }
    } catch (error) {
      openSnackbar("Some error occured while Deletion", "error");
      console.log(error);
    } finally {
      setLoadingSpinner(false);
    }
  };
  return (
    <>
      <Modal
        open={deleteModalState?.isOpen}
        onClose={handleClose}
        style={{
          position: "fixed",
          overflowY: "scroll",
          height: "100%",
          margin: "10px 0px 10px 10px",
        }}
      >
        <Box
          style={{
            position: "relative",
            zIndex: "1111",
            right: "0",
            top: "12%",
            left: "0",
            margin: "  auto",
            overflowY: "auto",
            borderRadius: "10px",
            backgroundColor: "#ffffff",
            maxWidth: "578px",
          }}
        >
          {/* Modal Header */}
          <div
            style={{
              padding: "12px 18px",
              borderBottom: "1px solid #f2eeee",
            }}
          >
            <div className="d-flex justify-content-between">
              <p className="m-0">
                Confirm Deletion of{" "}
                <span style={{ color: "red" }}>
                  {deleteModalState?.modalType
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </span>
              </p>
              <div
                onClick={() => {
                  handleClose();
                }}
              >
                <img
                  src={close}
                  alt="close"
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </div>
            </div>
          </div>
          {/* Modal Header ends... */}

          {/* Modal Body Starts .... */}
          <div
            style={{
              padding: "8px 16px ",
            }}
          >
            This action will delete the selected{" "}
            {deleteModalState?.modalType
              ?.split("_")
              ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}{" "}
            information. This action cannot be undone.
          </div>
          {/* Modal Body ends .... */}

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              padding: "12px ",
            }}
          >
            <LoadingButton
              size="small"
              endIcon={<SendIcon />}
              loading={loadingSpinner}
              loadingPosition="end"
              variant="contained"
              onClick={() => {
                delete_attachment(
                  deleteModalState.attachId,
                  deleteModalState.ticketId
                );
              }}
            >
              <span>Delete</span>
            </LoadingButton>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmDeletionModal;
