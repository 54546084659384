import React, { useContext, useEffect, useState } from "react";
import ContextState from "../contextApi/ContextState";
import ModalError from "../error/ModalError";
import { Tooltip } from "@mui/material";
import ConfirmDeletionModal from "../modals/ConfirmDeletionModal";
const VisitsAttachments = ({
  data,
  reloadAllTicketsData,
  reloadAttachmentsPage,
  refreshBtn,
  setRefreshBtn,
  setRefreshLoading,
}) => {
  const {
    fetchApi,
    showLoadingModal,
    showModalError,
    lodingModalVisiblity,
    showModalErrorPage,
    openSnackbar,
  } = useContext(ContextState);

  const [vist_attachments, setVisit_attachments] = useState();
  const [reloadAttach, setReloadAttach] = useState(false);
  const [confirmDeletionState, setConfirmDeletionState] = useState({
    isOpen: false,
    modalType: "",
    ticketId: "",
    attachId: "",
  });

  const visit_ticket_attachments_data = async (is_visit_data_mounted) => {
    try {
      const payload = {
        visit_id: data.visit_id,
        info_type: "attachments",
      };

      if(refreshBtn){
        setRefreshLoading(true);
      }

      const jsonData = await fetchApi(
        "get_visit_ticket_info",
        "POST",
        payload,
        is_visit_data_mounted
      );

      if (jsonData.statusCode === 200) {
        // showModalErrorPage(null, null);
        setVisit_attachments(jsonData.data);
        // console.log(vist_attachments);
        // console.log(vist_attachments);
      } else if (jsonData.statusCode === 401 || jsonData.statusCode === 500) {
        showModalErrorPage(jsonData.statusCode, jsonData.msg);
      } else {
        showModalErrorPage(jsonData.statusCode, jsonData.msg);
      }
    } catch (err) {
      // console.log("some error occurred");
      showModalErrorPage("some error occurred");
    } finally {
      showLoadingModal(false);
      setRefreshLoading(false);
      setRefreshBtn(false);
      // console.log(vist_attachments);
    }
  };

  useEffect(() => {
    let isMounted = true;
    showLoadingModal(true);
    visit_ticket_attachments_data(isMounted);
    return () => {
      isMounted = false;
    };
  }, [reloadAttachmentsPage, reloadAttach]);

  // closing of modak
  const closeConfirmDelectionModal = (event, reason) => {
    if (reason && reason == "backdropClick") {
      // console.log("backdropClicked. Not closing dialog.");
      return;
    }
    setConfirmDeletionState((prev) => ({ ...prev, isOpen: false }));
  };

  // reload of attachments when delete
  const reloadAttachPage = () => {
    setReloadAttach((prev) => !prev);
  };
  return (
    <>
      {(!showModalError.status && !lodingModalVisiblity && (
        <>
          {
            <div
              className="show-attachments-content mb-lg-3 rounded"
              style={{ margin: "15px" }}
            >
              <header className="pb-2 previous-comments-header">
                Attachments
              </header>
              {(vist_attachments?.attachments?.length && (
                <div className="d-sm-flex flex-wrap gap-3">
                  {vist_attachments?.attachments &&
                    vist_attachments?.attachments?.map((attachment, index) => {
                      if (attachment?.filetype) {
                        let fileUrl;
                        const fileType = attachment?.filetype?.toLowerCase();

                        if (["png", "jpg", "jpeg", "webp"].includes(fileType)) {
                          fileUrl = `data:image/${fileType};base64,${attachment?.data}`;
                        } else if (fileType === "pdf") {
                          fileUrl = `data:application/pdf;base64,${attachment.data}`;
                        } else if (["xlsx", "xls"].includes(fileType)) {
                          fileUrl = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${attachment.data}`;
                        } else if (fileType === "csv") {
                          fileUrl = `data:text/csv;base64,${attachment.data}`;
                        } else if (fileType === "mp3") {
                          fileUrl = `data:audio/mp3;base64,${attachment.data}`;
                        } else {
                          return null;
                        }

                        return (
                          <div
                            key={index}
                            className="d-flex align-items-center data-variation-elements my-3 my-sm-0"
                          >
                            <a
                              style={{ overflowWrap: "anywhere" }}
                              href={fileUrl}
                              download={`${attachment?.filename}`}
                            >
                              {attachment?.filename}
                            </a>
                            <div className="ms-auto">
                              <div
                                className="dropdown ms-2  "
                                style={{ cursor: "pointer" }}
                              >
                                <Tooltip
                                  data-html="true"
                                  title={"Click to Delete this attachment"}
                                  arrow={true}
                                >
                                  <i
                                    className="fa-solid fa-ellipsis-vertical  "
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    style={{ padding: "0px 3px" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  ></i>
                                </Tooltip>
                                {/* Centered dropdown */}
                                {/* </button> */}
                                <ul className="dropdown-menu dropdown-menu-end delete-ticket-btn-border">
                                  <li>
                                    <a
                                      className="dropdown-item text-center delete-ticket-btn"
                                      href="#"
                                      onClick={() => {
                                        setConfirmDeletionState((prev) => ({
                                          ...prev,
                                          isOpen: true,
                                          modalType: "visit_attachment",
                                          ticketId: attachment.ticket_id,
                                          attachId: attachment.id,
                                        }));
                                      }}
                                    >
                                      Delete
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                </div>
              )) ||
                "No attachments added"}
            </div>
          }

          <ConfirmDeletionModal
            deleteModalState={confirmDeletionState}
            handleClose={closeConfirmDelectionModal}
            handleReload={reloadAttachPage}
          />
        </>
      )) ||
        (showModalError.status && <ModalError />)}
    </>
  );
};

export default VisitsAttachments;
