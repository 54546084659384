import React, { useRef, useState, useEffect, useContext } from "react";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import "primeicons/primeicons.css";
// import 'primeflex/primeflex.css';
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "./FileUpload.css";
import ContextState from "../contextApi/ContextState";
export default function FilesUpload({
  genFields,
  setGenFields,
  isSmallScreen,
}) {
  const {
    openSnackbar,
  } = useContext(ContextState);
  function findValueByKey(obj, targetKey) {
    let result = null;

    // Recursive function to traverse nested object
    function traverse(obj) {
      // Check if the current object is null or undefined
      if (obj === null || typeof obj !== "object") {
        return;
      }

      // Loop through the object's properties
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          // Check if the current key matches the target key
          if (key === targetKey) {
            result = obj[key]; // Found the value, assign it to result
            return;
          }

          // If the current property is an object, recursively call traverse
          if (typeof obj[key] === "object") {
            traverse(obj[key]);
            if (result !== null) {
              // Check if result is found
              return; // Terminate recursion if result is found
            }
          }
        }
      }
    }

    // Call the recursive function with the initial object
    traverse(obj);

    return result;
  }

  const initialFiles = [
    // Example file objects
    { name: "file1.txt", size: 1000 },
    { name: "file2.jpg", size: 2000 },
    // Add more files as needed
  ];

  const toast = useRef(null);
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);
  const allowedFileTypes = ["png", "jpg", "jpeg", "webp", "pdf", "xlsx", "xls", "csv", "mp3"];
  
  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    let files = e.files;
    const MAX_SIZE_MB = 25; 
    const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024; 
  
    // Get the last file from the files object
    const lastFile = files[files.length - 1];
  
    if (lastFile) {
      const fileSize = lastFile.size || 0;
      const fileExtension = lastFile.name.split('.').pop().toLowerCase();
  
      if (!allowedFileTypes.includes(fileExtension)) {
        openSnackbar(`File type '${fileExtension}' is not allowed. Please select a valid file type.`, "warning");
      } else if (fileSize > MAX_SIZE_BYTES) {
        openSnackbar(`Cannot add '${lastFile.name}'. File size exceeds the maximum allowed size of ${MAX_SIZE_MB}MB.`, "warning");
      } else {
        _totalSize += fileSize;
        
        // Update state with the new data
        setGenFields((prev) => ({
          ...prev,
          attachments: [...prev.attachments, lastFile],
        }));
        setTotalSize(_totalSize);
      }
    }
  };
  

  const onTemplateUpload = (e) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });
  };

  const onTemplateRemove = (file, callback) => {
    setGenFields((prev) => {
      let newData = [];
      prev.attachments.forEach((data) => {
        if (JSON.stringify(data) !== JSON.stringify(file)) {
          newData.push(data);
        }
      });
      return newData;
    });
    // console.log("onTemplateRemove")
    setTotalSize(totalSize - file.size);
    // callback();
  };

  const onTemplateClear = () => {
    setGenFields((prev) => {
      return {
        ...prev,
        attachments: [],
      };
    });
    // console.log("onTemplateClear")
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    // console.log("headerTemplate")
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 10000;
    const formatedValue =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalSize)
        : "0 B";

    return (
      <div
        className={className}
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: "0.5px solid rgba(76,78,100,0.1)",
          height: "75px",
        }}
      >
        <div>
          {chooseButton}
          {/* {uploadButton} */}
          {cancelButton}
        </div>
        <div className="flex align-items-center gap-3 ml-auto">
          {genFields.attachments.length}
          {" Files Selected"}
        </div>
      </div>
    );
  };
  const removeFile = (file) => {
    setGenFields((prev) => {
      let newData = [];
      prev.attachments.forEach((data) => {
        if (JSON.stringify(data) !== JSON.stringify(file)) {
          newData.push(data);
        }
      });
      return {
        ...prev,
        attachments: newData,
      };
    });
    // console.log("onTemplateRemove")
  };

  const emptyTemplate = () => {
    return genFields.attachments.length ? (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        {genFields.attachments.map((file, index) => (
          <div
            style={{
              marginBottom: "25px",
              width: "100%",
              overflowX: "hidden",
              minWidth: "300px",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "70%",
                height: "auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              {isSmallScreen ? (
                <></>
              ) : file.name.endsWith(".pdf") ? (
                <embed
                  alt={file.name}
                  role="presentation"
                  src={file.objectURL}
                  width={125}
                />
              ) : (
                <img
                  alt={file.name}
                  role="presentation"
                  src={file.objectURL}
                  width={125}
                />
              )}

              <div>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginLeft: "8px",
                  }}
                >
                  {file.name}
                  <Tag
                    severity="warning"
                    className="px-3 py-2 mt-2"
                    style={{ maxWidth: "150px" }}
                    value={bytesToSize(file.size)}
                  />
                </span>
              </div>
            </div>
            <Button
              type="button"
              icon="pi pi-times"
              className="p-button-outlined p-button-rounded p-button-danger m-auto rounded-btn"
              onClick={() => removeFile(file)}
            />
          </div>
        ))}
      </div>
    ) : (
      <div
        style={{
          height: "100px",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          display: "flex",
        }}
      >
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-3"
        >
          Drag and Drop Image Here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined rounded-btn",
  };
  const bytesToSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024; // 1 KB = 1024 Bytes
    const sizes = ["Bytes", "KB", "MB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const size = (bytes / Math.pow(k, i)).toFixed(2);

    return `${size} ${sizes[i]}`;
  };
  return (
    <div style={{ width: "100%" }}>
      <FileUpload
        ref={fileUploadRef}
        name="demo[]"
        url="/api/upload"
        multiple
        accept="*"
        maxFileSize={100000000}
        onUpload={onTemplateUpload}
        onSelect={onTemplateSelect}
        onError={onTemplateClear}
        onClear={onTemplateClear}
        headerTemplate={headerTemplate}
        itemTemplate={(file, props) => {
          // console.log('index-', props.index);
          if (props.index === 0) {
            return genFields.attachments.length ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                {genFields.attachments.map((file, index) => (
                  <div
                    style={{
                      marginBottom: "25px",
                      width: "45%",
                      overflowX: "hidden",
                      minWidth: "300px",
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "70%",
                        height: "auto",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {isSmallScreen ? (
                        <></>
                      ) : file.name.endsWith(".pdf") ? (
                        <embed
                          alt={file.name}
                          role="presentation"
                          src={file.objectURL}
                          width={125}
                        />
                      ) : (
                        <img
                          alt={file.name}
                          role="presentation"
                          src={file.objectURL}
                          width={125}
                        />
                      )}

                      <div>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginLeft: "8px",
                          }}
                        >
                          {file.name}
                          <Tag
                            severity="warning"
                            className="px-3 py-2 mt-2"
                            style={{ maxWidth: "150px" }}
                            value={bytesToSize(file.size)}
                          />
                        </span>
                      </div>
                    </div>
                    <Button
                      type="button"
                      icon="pi pi-times"
                      className="p-button-outlined p-button-rounded p-button-danger m-auto rounded-btn"
                      onClick={() => removeFile(file)}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div
                style={{
                  height: "100px",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  display: "flex",
                }}
              >
                <span
                  style={{
                    fontSize: "1.2em",
                    color: "var(--text-color-secondary)",
                  }}
                  className="my-3"
                >
                  Drag and Drop Image Here
                </span>
              </div>
            );
          } else {
            return null;
          }
        }}
        emptyTemplate={emptyTemplate}
        chooseOptions={chooseOptions}
        uploadOptions={uploadOptions}
        cancelOptions={cancelOptions}
        style={{
          border: "0.5px  solid rgba(76,78,100,0.1)",
          borderRadius: "8px",
        }}
        files={initialFiles}
      />
    </div>
  );
}
