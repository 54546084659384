import React, { useContext, useEffect, useState } from "react";
import "../../assets/styles/Error.css";
import ContextState from "../contextApi/ContextState";
import { BiSolidErrorAlt } from "react-icons/bi";
import { RiArrowGoBackFill } from "react-icons/ri";
import { IoReload } from "react-icons/io5";

const Error = ({ code, msg, errorType }) => {
  // const navigate = useNavigate();
  const { showError, showErrorPage, key } = useContext(ContextState);

  const showHome = () => {
    showErrorPage(null, null);
    // navigate("/");
    window.location.href = "/";
    // window.location.href = "/";
  };
  const showLogin = () => {
    showErrorPage(null, null);
    // navigate("/");
    window.location.href = "/login";
    // window.location.href = "/";
  };
  const goToLogin = () => {
    showErrorPage(null, null);
    localStorage.clear();
    window.location.href = "/login";
    // navigate("/");
  };
  const goBack = () => {
    // navigate(-1);
    window.history.back();
  };
  const reloadPage = () => {
    // navigate(-1);
    window.location.reload();
  };

  useEffect(() => {
    document.title = "Error - NEO";
    return () => {};
  }, []);

  let key_from_localStorage = localStorage.getItem(key);

  return (
    <>
      <div className="error-div">
        <div className="d-flex align-items-center justify-content-center vh-100">
          <div className="text-center">
            <h1 className="display-1 fw-bold">
              {showError.status || (code !== 204 && code)}
            </h1>
            {code && code === 404 && (
              <>
                {/* <h1 className="display-1 fw-bold">{code}</h1> */}
                <p className="fs-3">
                  <span className="text-danger">Opps!</span> Page not found.
                </p>
                <p className="lead">
                  {msg || "The page you’re looking for doesn’t exist."}
                </p>
                {/* <a onClick={showHome} className="btn btn-primary mt-5">
                  Go To Home
                </a> */}

                {key_from_localStorage ? (
                  <a onClick={goBack} className="btn btn-primary mt-5">
                    Go Back
                  </a>
                ) : (
                  <a onClick={showLogin} className="btn btn-primary mt-5">
                    Go To Login
                  </a>
                )}
              </>
            )}
            {errorType === "rollBarError" && (
              <>
                <h1 className="display-1 fw-bold text-danger">
                  <BiSolidErrorAlt />
                </h1>
                <p className="fs-3">
                  <span className="text-danger">Opps!</span> Unexpected Issue.
                </p>
                <p className="lead">
                  {msg ||
                    "A small error has occured, causing an interruption of service."}
                </p>
                {/* <a onClick={showHome} className="btn btn-primary mt-5">
                  Go To Home
                </a> */}

                <div className="d-flex flex-row flex-warp gap-3 justify-content-center mt-5">
                  <a onClick={goBack} className="btn btn-primary ">
                    <div className="d-flex justify-content-center align-items-end flex-row">
                      <span className="p-0 pe-2 m-0">
                        <RiArrowGoBackFill />
                      </span>

                      <p className="p-0 m-0">Go Back</p>
                    </div>
                  </a>
                  <a onClick={reloadPage} className="btn btn-primary ">
                    <div className="d-flex justify-content-center align-items-end flex-row">
                      <span className="p-0 pe-2  m-0">
                        <IoReload />
                      </span>
                      <p className="p-0 m-0">Reload Page</p>
                    </div>
                  </a>
                </div>
              </>
            )}
            {(code === 500 || showError.status === 500) && (
              <>
                <p className="fs-3">Internal server error 👨🏻‍💻</p>
                <p className="lead">
                  {showError.msg || "Oops, something went wrong!"}
                </p>
                <a onClick={showHome} className="btn btn-primary mt-5">
                  Go To Home
                </a>
              </>
            )}
            {showError && showError.status === 401 && (
              <>
                <p className="fs-3">User session expired! 🔐</p>

                <p className="lead">
                  You have been Logged out. Please log in again.
                </p>
                <a onClick={goToLogin} className="btn btn-primary mt-5">
                  Login
                </a>
              </>
            )}
            {code === 204 && (
              <>
                <p className="fs-3">{msg} 👨🏻‍💻</p>

                {/* <p className="lead">
                  Some error occured in Frontend!
                </p> */}
                <a onClick={showHome} className="btn btn-primary mt-5">
                  Go To Home
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Error;
