import ReactModal from "react-modal";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useContext, useState } from "react";
import "./ForgotPasswordModal.css";
import LoadingButton from "@mui/lab/LoadingButton";
import OtpInput from "react-otp-input";
import ContextState from "../contextApi/ContextState";
import { useNavigate } from "react-router-dom";

const ForgotPasswordModal = ({
  isOpen,
  onClose,
  data,
  onEdit,
  setForgot_password_data,
}) => {
  const navigate = useNavigate();
  //   console.log(data, "data");
  const { email, newpassword, confirmPassword, code, user_id } = data;

  const { openSnackbar } = useContext(ContextState);

  // console.log("email:------>", email);

  const [confirmshowPassword, setConfirmShowPassword] = useState(false);

  const [newshowPassword, setNewShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [resendCodeLoading, setResendCodeLoading] = useState(false);

  const [error, setError] = useState({
    password: false,
    code: false,
  });

  const customStyless = {
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxHeight: "100vh", // Ensure the modal height doesn't exceed the viewport height
      overflow: "auto", // Allows internal content to scroll if needed, but aim for no scrollbar
      width: "420px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    onEdit({ target: { name: "newpassword", value } });
  };

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    onEdit({ target: { name: "confirmPassword", value } });
  };
  //   console.log(email, "from child");

  const handleCodeChange = (otp) => {
    onEdit({ target: { name: "code", value: otp } });
  };

  const handleClickConfirmShowPassword = () => {
    setConfirmShowPassword(!confirmshowPassword);
  };
  const handleClickNewShowPassword = () => {
    setNewShowPassword(!newshowPassword);
  };

  const handleresendCode = async (e) => {
    try {
      e.preventDefault();

      let payload = {
        email: email,
      };

      const response = await fetch(
        process.env.REACT_APP_BACKEND_SERVER + "reset_password_mail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      // console.log("Valid email:", email);

      const responsedata = await response.json();
      const statusCode = responsedata.status_code;

      console.log("******************", responsedata, statusCode);

      if (statusCode === 200) {
        setForgot_password_data((prevState) => ({
          ...prevState,
          user_id: responsedata.user_id,
        }));
        // openModal();
        openSnackbar(responsedata.message, "success");
        // console.log("email code sended successfully");
      } else if (statusCode === 401) {
        openSnackbar(responsedata.message, "error");
        // console.log("some error occured", statusCode, responsedata);
        // alert("some error occured");
      } else {
        openSnackbar(
          "Internal server Error!, Please try again after some time",
          "error"
        );
        // console.log("some error occured", statusCode, responsedata);
        // alert("some error occured");
      }
      setLoading(false);
      // }
    } catch (error) {
      setLoading(false);
      openSnackbar("some error occured!", "error");
      // console.log("some error occured in code");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset error states
    setError({ password: false, code: false });

    // Validate password
    if (newpassword !== confirmPassword) {
      setError((prevState) => ({
        ...prevState,
        password: true,
      }));
      return; // Exit the function if passwords don't match
    }

    // Validate code
    if (code.length !== 4) {
      setError((prevState) => ({
        ...prevState,
        code: true,
      }));
      return; // Exit the function if code length is not 4
    }

    let payload = {
      email: email,
      password: newpassword,
      code: code,
      user_id: user_id,
    };

    // console.log("final payload:----", payload);

    const response = await fetch(
      process.env.REACT_APP_BACKEND_SERVER + "reset_user_password",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    setLoading(false);

    const responsedata = await response.json();
    const statusCode = responsedata.status_code;

    if (statusCode === 200) {
      // openModal();
      openSnackbar(responsedata.message, "success");
      setTimeout(() => {
        navigate("/");
      }, 1000);
      console.log("password set successfully");
    } else if (statusCode === 401) {
      openSnackbar(responsedata.message, "error");
      // console.log(
      //   "401 status message:",
      //   responsedata.message
      // );
    } else {
      console.log("some error occured", statusCode, responsedata);
      // alert(responsedata.message);
    }
  };

  return (
    <div className="forgot_modal_container">
      {" "}
      <ReactModal
        isOpen={isOpen}
        onRequestClose={(e) => {
          // Check if the click originated from the overlay
          if (e.target.className.includes("ReactModal__Overlay")) {
            return; // Prevent onRequestClose from closing the modal
          }
          onClose();
        }}
        contentLabel="Card Detail Modal"
        ariaHideApp={false}
        style={customStyless}
        // className={`forgot_modal`}
      >
        <div className="">
          <div className="d-flex flex-column forgot_modal">
            <div className="mb-3 ">
              <h5 className="forgot_title">Create a New Password</h5>
            </div>
            <div className="mb-3">
              <TextField
                id="outlined-basic"
                label="Email"
                disabled
                variant="outlined"
                className="w-100"
                value={email}
              />
            </div>
            <div className="mb-3">
              <TextField
                id="outlined-basic"
                error={error.password}
                helperText={error.password ? "Password does not match" : ""}
                label="New Password"
                variant="outlined"
                className="w-100"
                value={newpassword}
                onChange={handlePasswordChange}
                required={true}
                type={newshowPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickNewShowPassword}
                        edge="end"
                      >
                        {newshowPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="mb-4">
              <TextField
                id="outlined-basic"
                error={error.password}
                label="Confirm Password"
                variant="outlined"
                className="w-100"
                helperText={error.password ? "Password does not match" : ""}
                required={true}
                value={confirmPassword}
                type={confirmshowPassword ? "text" : "password"}
                onChange={handleConfirmPasswordChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickConfirmShowPassword}
                        edge="end"
                      >
                        {confirmshowPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="mb-4">
              {" "}
              <div className="d-flex flex-row justify-content-around align-items-center otp  ">
                <div>Code</div>
                <div>
                  <OtpInput
                    value={code}
                    error={error.password}
                    onChange={handleCodeChange}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
              </div>
              {error.code && (
                <p className="text-center error_forg_msg">Please Enter code</p>
              )}
            </div>
            <div className="ms-auto mt-2 forgot_password_buttons">
              {!loading && (
                <LoadingButton
                  loading={resendCodeLoading}
                  size="small"
                  variant="outlined"
                  // type="submit"
                  onClick={handleresendCode}
                  className="me-2 update_password_button"
                >
                  <span>Resend Code</span>
                </LoadingButton>
              )}
              <LoadingButton
                onClick={handleSubmit}
                size="small"
                loading={loading}
                variant="outlined"
                type="submit"
                // className={`w-100 forgot_btn ${
                //   loading ? "while_submitting" : ""
                // }`}
                className="update_password_button mobile_margin"
              >
                <span>{loading ? "Updating Password" : "Update Password"}</span>
              </LoadingButton>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default ForgotPasswordModal;
