import React, { useContext, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SnackbarContent,
} from "@mui/material";
import GenDetails from "./GenDetails";
import DataVariation from "./DataVariation";
import Attachments from "./Attachments";
import EmployeeDetails from "./EmployeeDetails";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";
import { addTicketDetail } from "../features/filtersSlice/filterSlice";
import ContextState from "../contextApi/ContextState";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import Error from "../error/Error";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 24,
  },
  "& .QontoStepIcon-circle": {
    width: 12,
    height: 12,
    border: "2px solid currentColor",
    borderRadius: "50%",
    // backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const RaiseTicketForm = () => {
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const TicketDetils = useSelector((state) => state.createTicketDetail);
  const [FiltersData, setFiltersData] = useState({});
  const { fetchApi, showLoading, showErrorPage } = useContext(ContextState);

  ///// useEffect

  useEffect(() => {
    document.title = "Raise Ticket - NEO";
    let is_Mounted = true;
    showLoading(true);
    const fetchDataAndUpdateFilters = async () => {
      const currentTime = Date.now();
      const lastDataFetchTimestamp = TicketDetils?.lastDataFetchTimestamp;

      if (
        !lastDataFetchTimestamp ||
        currentTime - lastDataFetchTimestamp >= 30 * 60 * 1000
      ) {
        // fetchFiltersDataApiCall
        const raise_ticket_data = await fetchApi(
          "get_create_ticket_details",
          "POST",
          {},
          is_Mounted
        );
        // console.log("all_ticket_data in All Ticket : ", raise_ticket_data);
        if (raise_ticket_data.statusCode === 200) {
          showErrorPage(null, null);
          dispatch(addTicketDetail(raise_ticket_data?.data));
          setFiltersData(raise_ticket_data?.data || null);
          setinitialLoading({
            loading: false,
            error: false,
          });
        } else if (
          raise_ticket_data.statusCode === 500 ||
          raise_ticket_data.statusCode === 401
        ) {
          showErrorPage(raise_ticket_data.statusCode, raise_ticket_data.msg);
          setinitialLoading({
            loading: false,
            error: true,
          });
        } else {
          // showErrorPage(raise_ticket_data.statusCode, raise_ticket_data.msg);
          setinitialLoading({
            loading: false,
            error: true,
          });
          localStorage.clear();
          showErrorPage(null, null);
          window.location.href = "/login";
        }
        showLoading(false);
      } else {
        // console.log("Data fetch from redux ************", TicketDetils?.data);
        setFiltersData(TicketDetils?.data || null);
        showErrorPage(null, null);
        setinitialLoading({
          loading: false,
          error: false,
        });
      }
      showLoading(false);
    };
    fetchDataAndUpdateFilters();

    return () => {
      is_Mounted = false;
    };
  }, []);

  // useEffect(() => {
  //   console.log("FiltersData --->", FiltersData);
  // }, [FiltersData]);

  const [initialLoading, setinitialLoading] = useState({
    loading: true,
    error: false,
  });
  // useEffect(() => {
  //   setTimeout(() => {
  //     setFiltersData({ key: "value" });
  //   }, 2000);
  // }, []);
  useEffect(() => {
    if (FiltersData === null) {
      setinitialLoading({
        loading: false,
        error: true,
      });
    } else if (Object.keys(FiltersData).length) {
      setinitialLoading({
        loading: false,
        error: false,
      });
    }
  }, [FiltersData]);

  // first page fields
  const [genFields, setGenFields] = useState({
    machines: [],
    issue: "",
    subIssue: "",
    visit: false,
    reporting: "",
    assignee: "",
    description: "",
    variation_data: [],
    scan_details: [],
    employeeDetails: {
      name: "",
      phone: "",
      email: "",
      employee_id: "",
      designation: "",
      new_emp_details: false,
      level: "",
      location_name: "",
      prev_details: false,
    },
    attachments: [],
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [steps, setStep] = useState({
    activeStep: 0,
    labels: [
      ["General Details", true, true],
      ["Data Variation", false, false],
      ["Attachments", true, false],
      ["Employee Details", true, false],
    ],
  });
  const [MainForm, setMainForm] = useState([]);
  const togglestep = (flag) => {
    setStep((prevSteps) => {
      const newSteps = prevSteps.labels.map((step, index) => {
        if (index === 1) {
          // Check if this is the second step
          return [step[0], flag, step[2]]; // Toggle the boolean value
        }
        return step; // Return other steps as they are
      });
      return {
        ...prevSteps,
        labels: newSteps,
      }; // Return the updated state
    });
  };
  const [fieldError, setFieldError] = useState({
    machineError: false,
    issueError: false,
    descError: false,
    assigneeError: false,
    subIssueError: false,
  });
  const gonext = () => {
    let new_selected_index = -1;
    let curentIndex = -1;
    let mError = false;
    let iError = false;
    let aError = false;
    let dError = false;
    let siError = false;
    setStep((prevSteps) => {
      let step2Flag = "";
      prevSteps.labels.map((step, index) => {
        // // console.log(step);
        if (index === 1) {
          step2Flag = step[1];
        }
        if (step[2] === true) {
          if (index === prevSteps.labels.length - 1) {
            new_selected_index = 0;
            curentIndex = prevSteps.labels.length - 1;
            return;
          } else {
            new_selected_index = index + 1;
            curentIndex = index;
            return;
          }
        }
      });
      if (new_selected_index === 1 && step2Flag === false) {
        new_selected_index = 2;
      }
      const newSteps1 = prevSteps.labels.map((step, index) => {
        if (index === new_selected_index) {
          // Check if this is the second step
          return [step[0], step[1], true]; // Toggle the boolean value
        }
        return [step[0], step[1], false]; // Return other steps as they are
      });
      if (curentIndex === 0) {
        if (genFields.machines.length === 0) {
          mError = true;
        }
        if (genFields.issue === "") {
          iError = true;
        }
        if (genFields.description === "") {
          dError = true;
        }
        if (genFields.assignee === "") {
          aError = true;
        }
        if (genFields.subIssue === "") {
          siError = true;
        }
        setFieldError({
          machineError: mError,
          issueError: iError,
          descError: dError,
          assigneeError: aError,
          subIssueError: siError,
        });
      }
      if (mError || iError || aError || dError || siError) {
        return { ...prevSteps };
      }
      return {
        ...prevSteps,
        labels: newSteps1,
        activeStep: new_selected_index,
      }; // Return the updated state
    });
    // setActiveStep(new_selected_index);
  };

  const goprev = () => {
    let new_selected_index = -1;
    setStep((prevSteps) => {
      let step2Flag = "";
      prevSteps.labels.map((step, index) => {
        // // console.log(step);
        if (index === 1) {
          step2Flag = step[1];
        }
        if (step[2] === true) {
          if (index === 0) {
            new_selected_index = prevSteps.labels.length - 1;
            return;
          } else {
            new_selected_index = index - 1;
            return;
          }
        }
      });
      if (new_selected_index === 1 && step2Flag === false) {
        new_selected_index = 0;
      }
      const newSteps1 = prevSteps.labels.map((step, index) => {
        if (index === new_selected_index) {
          // Check if this is the second step
          return [step[0], step[1], true]; // Toggle the boolean value
        }
        return [step[0], step[1], false]; // Return other steps as they are
      });
      return {
        ...prevSteps,
        labels: newSteps1,
        activeStep: new_selected_index,
      };
    });
  };
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    if (!initialLoading.error && !initialLoading.loading) {
      let tempMainForm = steps.labels.map((step, index) => {
        return step[2] ? (
          step[0] === "General Details" ? (
            <GenDetails
              genFields={genFields}
              setGenFields={setGenFields}
              machines_data={FiltersData.machines_data}
              issue_list={FiltersData.issue_list}
              assignee_list={FiltersData.assignee_list}
              togglestep={togglestep}
              fieldError={fieldError}
              isSmallScreen={isSmallScreen}
            />
          ) : step[0] === "Data Variation" ? (
            <DataVariation
              genFields={genFields}
              param_data={FiltersData.param_data}
              setGenFields={setGenFields}
              isSmallScreen={isSmallScreen}
            />
          ) : step[0] === "Attachments" ? (
            <Attachments
              genFields={genFields}
              setGenFields={setGenFields}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              isSmallScreen={isSmallScreen}
            />
          ) : (
            <EmployeeDetails
              genFields={genFields}
              setGenFields={setGenFields}
              isSmallScreen={isSmallScreen}
            />
          )
        ) : (
          <></>
        );
      });
      setMainForm(tempMainForm);
    }
  }, [steps, genFields, fieldError, isSmallScreen, initialLoading]);
  useEffect(() => {
    // console.log(genFields);
    if (genFields.machines.length !== 0) {
      setFieldError((prev) => {
        return {
          ...prev,
          machineError: false,
        };
      });
    }
    if (genFields.assignee !== "") {
      setFieldError((prev) => {
        return {
          ...prev,
          assigneeError: false,
        };
      });
    }
    if (genFields.issue !== "") {
      setFieldError((prev) => {
        return {
          ...prev,
          issueError: false,
        };
      });
    }
    if (genFields.description !== "") {
      setFieldError((prev) => {
        return {
          ...prev,
          descError: false,
        };
      });
    }
    if (genFields.subIssue !== "") {
      setFieldError((prev) => {
        return {
          ...prev,
          subIssueError: false,
        };
      });
    }
  }, [genFields]);
  useEffect(() => {
    if (genFields.issue === 3) {
      togglestep(true);
    } else {
      togglestep(false);
    }
  }, [genFields.issue]);
  const urlToFileArrayBuffer = async (fileUrl) => {
    try {
      // Fetch the file content as a blob
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      // Read the blob as an array buffer
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = () => {
          reject(new Error("Failed to read file"));
        };
        reader.readAsArrayBuffer(blob);
      });
    } catch (error) {
      console.error("Error fetching file:", error);
      return null;
    }
  };
  const submitForm = async () => {
    // setSubmitLoading(true);
    setOpen((prev) => {
      return {
        ...prev,
        submitLoading: true,
      };
    });
    let formData = {};
    const attachmentsData = await Promise.all(
      genFields.attachments.map(async (data) => {
        // console.log(data);
        // Fetch array buffer from URL
        const arrayBuffer = await urlToFileArrayBuffer(data.objectURL);
        // const base64String = btoa(String.fromCharCode.apply(null, arrayBuffer));
        const uint8Array = new Uint8Array(arrayBuffer);

        // console.log(arrayBuffer);
        // Convert array buffer to Blob
        const blob = new Blob([arrayBuffer]);
        // console.log("blob-", blob);
        // Return object with file name and FormData
        return {
          file_name: data.name,
          file_data: Array.from(uint8Array),
        };
      })
    );

    formData = {
      gen_details: {
        issue_reporting: genFields.reporting,
        machine_list: genFields.machines,
        issue: genFields.issue,
        sub_issue: genFields.subIssue === "" ? null : genFields.subIssue,
        description: genFields.description,
        user_details: {
          username:
            genFields.employeeDetails.name === ""
              ? null
              : genFields.employeeDetails.name,
          phone:
            genFields.employeeDetails.phone === ""
              ? null
              : genFields.employeeDetails.phone,
          email:
            genFields.employeeDetails.email === ""
              ? null
              : genFields.employeeDetails.email,
          employee_id:
            genFields.employeeDetails.employee_id === ""
              ? null
              : genFields.employeeDetails.employee_id,
          designation:
            genFields.employeeDetails.designation === ""
              ? null
              : genFields.employeeDetails.designation,
          level:
            genFields.employeeDetails.level === ""
              ? null
              : genFields.employeeDetails.level,
          location_name:
            genFields.employeeDetails.location_name === ""
              ? null
              : genFields.employeeDetails.location_name,
          new_emp_details:
            genFields.employeeDetails.new_emp_details === ""
              ? false
              : genFields.employeeDetails.new_emp_details,
        },
        assignee: genFields.assignee,
        visit_required: genFields.visit,
        internet_issue: false,
      },
      scan_details: genFields.scan_details,
      variation_data: genFields.variation_data,
      files: attachmentsData,
    };
    console.log("finalData-", formData);

    let isMounted = true;
    const sendRaiseTicketDataResponce = await fetchApi(
      "raise_ticket",
      "POST",
      formData,
      isMounted
    );
    // console.log(sendRaiseTicketDataResponce);
    if (sendRaiseTicketDataResponce.statusCode === 200) {
      setOpen({
        submitLoading: false,
        flag: true,
        ticket_id: sendRaiseTicketDataResponce.data.ticket_id,
      });
    } else {
      console.log("error in submit form");
      setState((prev) => {
        return { ...prev, openSnack: true };
      });
      setOpen((prev) => {
        return { ...prev, submitLoading: false };
      });
    }
    setReload(!reload);

    return () => {
      isMounted = false;
    };
  };

  const [open, setOpen] = React.useState({
    submitLoading: false,
    flag: false,
    ticket_id: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen({
        flag: false,
        ticket_id: "",
      });
    }
  };
  const [state, setState] = React.useState({
    openSnack: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, openSnack } = state;

  const handleClick = () => {
    setState((prev) => {
      return { ...prev, openSnack: true };
    });
  };
  // useEffect(() => {
  //   console.log("state changed-", state);
  // }, [state]);
  const handleCloseSnackBar = () => {
    setState({ ...state, openSnack: false });
  };

  // <div
  //   style={{
  //     position: "relative",
  //     height: "100%",
  //     width: "100%",
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //     backgroundColor: "#f3f7fd",
  //     zIndex: "1",
  //   }}
  // >
  //   <BeatLoader color="#2D97D4" />
  // </div>
  // media query
  const responsiveRaiseTicket = useMediaQuery("(max-width:1400px)");
  return (
    <div className="container">
      {initialLoading.loading ? (
        <div
          className="showSpinner all-ticket-spinner"
          style={{ height: "100vh", zIndex: 5 }}
        >
          <BeatLoader color="#2D97D4" />
        </div>
      ) : initialLoading.error ? (
        // <div>Internal server error</div>
        <Error />
      ) : (
        <div
          style={{
            marginTop: "40px",
            marginBottom: "25px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginBottom: "15px",
              color: "rgba(76,78,100,0.7)",
              fontSize: "24px",
            }}
          >
            Raise Ticket
          </div>

          <div
            style={{
              width: responsiveRaiseTicket ? "97%" : "96vw",
              maxWidth: "1200px",
              minHeight: "400px",
              backgroundColor: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "30px",
              paddingBottom: "30px",
              borderRadius: 12,
              paddingInline: isSmallScreen ? "15px" : "40px",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 16px 0px",
              justifyContent: "space-between",
            }}
          >
            {isSmallScreen ? (
              <div
                style={{ fontSize: "20px", color: "rgba(76, 78, 100, 0.7)" }}
              >
                {" "}
                {steps.labels[steps.activeStep][0]}
              </div>
            ) : (
              <Stepper
                orientation="horizontal"
                alternativeLabel
                activeStep={steps.activeStep}
                connector={<QontoConnector />}
                style={{ width: "100%", marginBottom: "20px" }}
              >
                {steps.labels.map((label) =>
                  label[1] ? (
                    <Step key={label}>
                      <StepLabel
                        StepIconComponent={QontoStepIcon}
                        style={{ fontSize: "16px", color: "#ff0000" }}
                        className="custom-label"
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ) : (
                    <></>
                  )
                )}
              </Stepper>
            )}
            {MainForm}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "20px",
              }}
            >
              {steps.activeStep === 0 ? (
                <Button variant="disabled" onClick={goprev}>
                  Back
                </Button>
              ) : (
                <Button variant="outlined" onClick={goprev}>
                  Back
                </Button>
              )}
              {steps.activeStep === steps.labels.length - 1 ? (
                <LoadingButton
                  size="small"
                  onClick={submitForm}
                  disabled={
                    genFields.employeeDetails.name === "" ||
                    genFields.employeeDetails.phone === ""
                  }
                  endIcon={<SendIcon />}
                  loading={open.submitLoading}
                  loadingPosition="end"
                  variant="contained"
                  style={{ height: "36px" }}
                >
                  <span>Submit</span>
                </LoadingButton>
              ) : (
                <Button variant="contained" onClick={gonext}>
                  Next
                </Button>
              )}
            </div>
          </div>
          <Dialog
            ClickAwayListenerProps={{ onClickAway: () => null }}
            fullScreen={fullScreen}
            open={open.flag}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle
              id="responsive-dialog-title"
              style={{
                color: "#59A148",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CheckCircleOutlineIcon style={{ marginRight: "5px" }} />
              {"Ticket Successfully Raised!"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Ticket has been successfully raised with ID #{open.ticket_id}.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {/* <Button
                autoFocus
                onClick={() => {
                  // navigate("/raiseticket");
                  navigate(window.location.ppathname, {replace: true})
                  // window.location.reload();
                }}
              >
                Raise Ticket
              </Button> */}
              <Button
                onClick={() => {
                  navigate("/allticket");
                }}
                autoFocus
                style={{ marginLeft: "15px" }}
              >
                View All Tickets
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={openSnack}
            onClose={handleCloseSnackBar}
            key={vertical + horizontal}
            backgroundColor="red"
            style={{
              backgroundColor: "red",
            }}
          >
            <SnackbarContent
              style={{ backgroundColor: "#f44336" }} // Change background color here
              message="Some internal error occurred. Please try again later."
            />
          </Snackbar>
        </div>
      )}
    </div>
  );
};

export default RaiseTicketForm;
