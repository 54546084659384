// socket.js
import { io } from "socket.io-client";

const backend_url = process.env.REACT_APP_BACKEND_SERVER;

export const socket = io(backend_url, {
  transports: ["websocket"],
  secure: true,
  withCredentials: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 5000,
});

export default socket;
