import React, { useContext, useEffect, useState } from "react";
import "../../../assets/styles/Location.css";
import {
  Button,
  LinearProgress,
  Box,
  ButtonGroup,
  linearProgressClasses,
  useMediaQuery,
} from "@mui/material";
import { useOutletContext } from "react-router-dom";
import RegionModal from "./RegionModal";
import ContextState from "../../contextApi/ContextState";
import noDataIMG from "../../../assets/images/no-data (1).png";

import socket from "../../socket/Socket"

// progress bar
import { styled } from "@mui/material/styles";
import LocationRegionCard from "./LocationRegionCard";
import { BeatLoader } from "react-spinners";
import { useSelector } from "react-redux";
const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, colorAccept, colorReject }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: colorReject,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: colorAccept,
      borderRadius: 0,
      // borderRight: "2px solid white",
    },
  })
);

const Location = () => {
  const outletContext = useOutletContext();
  // console.log("context value : ", outletContext);

  // user Details from redux
  const userData = useSelector((state) => state.userDetail);

  const {
    fetchApi,
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    closeSnackbar,
    showError,
    showErrorPage,
    formatDate,
    formatInIndianRupees,
  } = useContext(ContextState);

  const color = outletContext.color;

  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  // console.log("isSmallSmallScreen: ", isSmallSmallScreen);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  // console.log("isSmallScreen: ", isSmallScreen);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");
  // console.log("islageScreen: ", islageScreen);
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");

  const topDivWidth = {
    normal: isSmallScreen ? "280px" : "290px",
    isSmallScreen: "150px",
    isSmallSmallScreen: "250px",
  };

  const pageStyling = {
    imageBackgroundDivRadius: "25%",
    topDivwidth: "33px",

    IconWidth: "25px",
    iconBackgroundWidth: "50px",
    iconBackgroundHeight: "50px",

    monthlyScanDivDescIconWidth: "20px",
    monthlyScanDivDescIconBackgroundWidth: "40px",
    monthlyScanDivDescIconBackgroundHeight: "40px",

    location_main_icon: "19px",
    location_main_icon_background_width: "38px",
    location_main_icon_background_height: "38px",

    location_icon: "20px",
    location_icon_background_width: "38px",
    location_icon_background_height: "38px",
    location_background_borderRadius: "15%",

    locationVerticalLineHeight: "30px",
  };
  // bule shades for pie chart
  const shadesOfBlue = outletContext.shadesOfBlue;

  const LogoBGcolor = ["#6366f1", "#f04438", "#10b981"];

  const activeMachine = [
    {
      location: "Active Region",
      numerator: 6,
      denominator: 8,
    },
    {
      location: "Active District",
      numerator: 5,
      denominator: 8,
    },
    {
      location: "Active Machines",
      numerator: 11,
      denominator: 16,
    },
  ];

  const buttons = [
    { label: "Region", clicked: true },
    { label: "District", clicked: false },
    { label: "Machine", clicked: false },
  ];
  const [clickedButtonIndex, setClickedButtonIndex] = useState(0);
  const [tableData, setTableData] = useState({
    Region: true,
    District: false,
    Machine: false,
  });

  // state for modal
  const [isOpen, setIsopen] = useState(false);
  const [test, setTest] = useState(false);

  const handleButtonClick = (index, label) => {
    setClickedButtonIndex(index);
    setTableData((prevState) => {
      const updatedState = {};
      for (const key in prevState) {
        updatedState[key] = key === label;
      }
      return updatedState;
    });
  };

  const Data = outletContext.locationData;

  const [showRegionModal, setShowRegionModal] = useState(false);

  const [loading, showLoading] = useState(true);

  useEffect(() => {
    document.title = "Location - NEO";
    showLoading(true);
    let is_user_data_Mounted = true;
    const fetchDataAndUpdateFilters = async () => {
      // fetchFiltersDataApiCall;
      setTimeout(async () => {
        const all_filter_data = await fetchApi(
          "dashboard_location_overview",
          "POST",
          {},
          is_user_data_Mounted
        );
        // console.log("get_basic_details", all_filter_data);
        if (all_filter_data.statusCode === 200) {
          outletContext.handelshowFirstPageError(null, null);
          outletContext.handelLocationData(all_filter_data?.data || null);
          showLoading(false); // Moved here
          setTest((prevTest) => !prevTest);
          // console.log("all_filter_data?.data : ", all_filter_data?.data);
        } else if (
          all_filter_data.statusCode === 500 ||
          all_filter_data.statusCode === 401
        ) {
          // console.log("working");
          outletContext.handelLocationData(null);
          outletContext.handelshowFirstPageError(
            all_filter_data.statusCode,
            all_filter_data?.msg
          );
        } else {
          outletContext.handelLocationData(null);
          // showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
          // console.log("Some Error Occured", all_filter_data);
          localStorage.clear();
          window.location.href = "/login";
        }
        return;
      }, 10);
    };

    // current page 
    const curr_page = "location_overview";

    // Emit the custom 'initial_check' event when the component mounts
    if (Data) {
      // console.log(
      //   "data is already present, so doing initial check for location page..."
      // );
      socket.emit("initial_check", { curr_page });
    }

    // every 45 seconds, i will be checking if there's any data update
    const intervalId = setInterval(() => {
      // console.log("checking for updates...")
      socket.emit('check_updates', { curr_page });
    }, 45000);

    // Listen for the 'update_<page>' event from the server
    socket.on(`update_${curr_page}`, (value) => {
      if (value === true) {
        console.log(`Reload the data for ${curr_page}...`);
        fetchDataAndUpdateFilters();
      } else {
        console.log(`Don't reload the data for ${curr_page}...`);
      }
    });

    if (!Data) {
      fetchDataAndUpdateFilters();
    } else {
      showLoading(false);
    }
    return () => {
      is_user_data_Mounted = false;
      clearInterval(intervalId);
      socket.off(`update_${curr_page}`);
    };
  }, []);

  const [sendLocationDataToURL, setsendLocationDataToURL] = useState({
    region: null,
    district: null,
    machine: null,
  });

  function handelsendLocationDataToURL(id, value) {
    setsendLocationDataToURL((prevData) => {
      // ...prevData,
      // [id]: value,

      const newOpacity = {};
      for (const key in prevData) {
        newOpacity[key] = key === id ? value : null;
      }
      // console.log("newOpacity : ", newOpacity);
      return newOpacity;
    });
  }
  function handelRefreshOfTheLocationDataToURL() {
    setsendLocationDataToURL((prevData) => {
      // ...prevData,
      // [id]: value,

      const newOpacity = {};
      for (const key in prevData) {
        newOpacity[key] = null;
      }
      // console.log("newOpacity : ", newOpacity);
      return newOpacity;
    });
  }

  useEffect(() => {
    // console.log("sendLocationDataToURL: ", sendLocationDataToURL);
    setShowRegionModal(true);
  }, [sendLocationDataToURL]);

  const handleOpenmodal = (id, value) => {
    handelsendLocationDataToURL(id, value);
  };

  const handleClosemodal = () => {
    setIsopen(false);
  };

  return (
    <>
      {(loading && (
        <div
          className="showSpinner all-ticket-spinner"
          style={{ position: "relative", height: "85vh" }}
        >
          <BeatLoader color="#2D97D4" />
        </div>
      )) ||
        (Data && !Data?.message && (
          <div>
            {/* Region Card */}
            <div
              className={`m-top-bottom gap-20 mb-5 mt-3 ${
                Data?.user_info?.access_level === "institution"
                  ? "cards_ro"
                  : "cards_ro"
              }`}
              // style={{
              //   padding: "0px 12px",

              //   display: "grid",
              //   gridTemplateColumns: `${
              //     isSmallSmallScreen
              //       ? "1fr"
              //       : isSmallScreen
              //       ? "1fr"
              //       : isMediumScreen
              //       ? "1fr 1fr"
              //       : islageScreen
              //       ? "1fr 1fr 1fr"
              //       : "1fr 1fr 1fr 1fr"
              //   }`,
              //   gap: "20px",
              // }}
              // style={{ padding: "0px 0px" }}
            >
              {Data["location wise data"]?.map((each_region) => (
                <>
                  <LocationRegionCard
                    test={test}
                    color={color}
                    each_region={each_region}
                    user_info={Data?.user_info}
                    handleOpenmodal={handleOpenmodal}
                    show_accepted={Data?.show_accepted}
                    pageStyling={pageStyling}
                  />
                </>
              ))}
            </div>

            {(sendLocationDataToURL.region ||
              sendLocationDataToURL.district ||
              sendLocationDataToURL.machine) && (
              <RegionModal
                showRegionModal={showRegionModal}
                setShowRegionModal={setShowRegionModal}
                color={color}
                topDivWidth={topDivWidth}
                pageStyling={pageStyling}
                shadesOfBlue={shadesOfBlue}
                isSmallScreen={isSmallScreen}
                islageScreen={islageScreen}
                islagelargeScreen={islagelargeScreen}
                isMediumScreen={isMediumScreen}
                region_name={sendLocationDataToURL.region}
                district_name={sendLocationDataToURL.district}
                machine_name={sendLocationDataToURL.machine}
                sendLocationDataToURL={sendLocationDataToURL}
                handelRefreshOfTheLocationDataToURL={
                  handelRefreshOfTheLocationDataToURL
                }
                handelshowOverviewModalError={
                  outletContext.handelshowOverviewModalError
                }
                showOverviewModalError={outletContext.showOverviewModalError}
              />
            )}
          </div>
        )) || (
          <div className="container no-ticket-found-div text-center count_heading_div">
            <div className="d-flex align-items-center justify-content-center flex-column">
              {/* <i
                className="fa-solid fa-face-rolling-eyes fa-2xl inline-block"
                style={{ color: color.secondary }}
              ></i> */}
              <img src={noDataIMG} />
              <p>No Data Found</p>
            </div>
          </div>
        )}
    </>
  );
};

export default Location;
