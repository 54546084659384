import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Typography } from "@mui/material";
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function DataVariation({ genFields, param_data, setGenFields, isSmallScreen }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [localVariationData, setLocalvariationData] = useState({
    crop: "",
    params: [],
    paramList: [],
  });
  const [openScan, setOpenScan] = React.useState(false);
  const handleOpenScan = () => setOpenScan(true);
  const handleCloseScan = () => setOpenScan(false);
  const [localScanData, setlocalScanData] = useState({
    scan_id: "",
    machine: "",
    pull_scan: false,
    paramValues: [],
  });
  const [cropList, setCropList] = useState([]);
  useEffect(() => {
    setCropList(
      Object.keys(param_data).map((key) => {
        return {
          crop: key,
          id: param_data[key]["crop_id"],
        };
      })
    );
  }, []);
  const handleCropChange = (event) => {
    setLocalvariationData((prev) => {
      let allParams = [];
      Object.entries(param_data).map(([key, value]) => {
        if (value["crop_id"]===event.target.value) {
          allParams = Object.entries(param_data[key]["client_params"]).map(
            ([key, value]) => {
              return {
                param: key,
                id: value,
              };
            }
          );
        }
      });
      // // console.log(allParams);
      return {
        ...prev,
        crop: event.target.value,
        paramList: allParams,
        params: [],
      };
    });
  };
  const handleParamChange = (event) => {
    setLocalvariationData((prev) => {
      return {
        ...prev,
        params: event.target.value,
      };
    });
  };
  const handleCropChangeScan = (event) => {
    let allParams = [];
    setlocalScanData((prev) => {
      Object.entries(param_data).forEach(([key, value]) => {
        if (value["crop_id"] === event.target.value) {
          allParams = Object.entries(value["client_params"]).map(
            ([param, id]) => {
              return {
                param: param,
                id: id,
                manual: null,
                matt: null,
              };
            }
          );
        }
      });
      return {
        ...prev,
        crop: event.target.value,
        paramValues: allParams,
      };
    });
  };
  const handleMachineChangeScan = (event) => {
    setlocalScanData((prev) => {
      return {
        ...prev,
        machine: event.target.value,
      };
    });
  };
  useEffect(() => {
    // // console.log(localScanData);
  }, [localScanData]);
  const addParams = () => {
    let finalData = [];
    let cropName = "";
    let paramName = "";
    if (
      localVariationData.crop !=="" &&
      localVariationData.params.length !==0
    ) {
      finalData = localVariationData.params.map((param) => {
        Object.entries(param_data).forEach(([key, value]) => {
          if (value["crop_id"]===localVariationData.crop) {
            Object.entries(value["client_params"]).forEach(([key1, value1]) => {
              if (value1===param) {
                cropName = key;
                paramName = key1;
              }
            });
          }
        });
        return {
          crop_id: localVariationData.crop,
          param_id: param,
          crop: cropName,
          param: paramName,
        };
      });
    }

    setGenFields((prev) => {
      const uniqueIds = new Set(
        prev.variation_data.map((item) => item.param_id)
      );

      // Filter out objects with duplicate IDs
      const updatedVariationData = finalData.filter(
        (item) => !uniqueIds.has(item.param_id)
      );

      return {
        ...prev,
        variation_data: Array.from(
          new Set([...prev.variation_data, ...updatedVariationData])
        ),
      };
    });
    handleClose();
  };
  useEffect(() => {
    if (open === true) {
      setLocalvariationData({
        crop: "",
        params: [],
        paramList: [],
      });
    }
  }, [open]);
  useEffect(() => {
    if (openScan === true) {
      setlocalScanData({
        scan_id: "",
        machine: "",
        pull_scan: false,
        paramValues: [],
      });
    }
  }, [openScan]);
  const deleteVariationData = (data) => {
    // // console.log("Data-", data);
    setGenFields((prev) => {
      let newData = [];
      genFields.variation_data.forEach((param_data) => {
        if (param_data.param_id !== data.param_id) {
          newData.push(param_data);
        }
      });
      // // console.log("newData-", newData);
      return {
        ...prev,
        variation_data: newData,
      };
    });
  };
  const deleteScanData = (deleteIndex) => {
    // // console.log("Data-", data);
    setGenFields((prev) => {
      let newData = [];
      genFields.scan_details.forEach((param_data, index) => {
        if (index !== deleteIndex) {
          newData.push(param_data);
        }
      });
      // // console.log("newData-", newData);
      return {
        ...prev,
        scan_details: newData,
      };
    });
  };
  const addScan = () => {
    if (
      localScanData.machine !== "" &&
      localScanData.scan_id !=="" &&
      localScanData.crop !==""
    ) {
      let newScanData = {
        scan_id: localScanData.scan_id,
        machine_code: localScanData.machine,
        pull_scan: localScanData.pull_scan,
        crop_id: localScanData.crop,
        params: [],
      };
      newScanData.params = localScanData.paramValues.map((data) => {
        return {
          param_id: data.id,
          matt_value: data.matt,
          manual_value: data.manual,
        };
      });
      setGenFields((prev) => {
        return {
          ...prev,
          scan_details: [...prev.scan_details, newScanData],
        };
      });
    }

    handleCloseScan();
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "20px",
        paddingBottom: "20px",
        justifyContent: "flex-start",
        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        // justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: isSmallScreen ? 'column' : 'row',
          justifyContent: "space-between",
          width: "100%",
          gap: 25,
        }}
      >
        <div>
          <Button variant="outlined" onClick={handleOpen}>
            <AddIcon style={{ fontSize: "20px" }} />
            Param Variation
          </Button>
          <div
            style={{
              maxWidth: "400px",
              fontSize: "14px",
              color: "rgba(76,78,100,0.6)",
              marginTop: "10px",
            }}
          >
            Add crop parameters for which variations have been observed.
          </div>
        </div>
        <div>
          <Button variant="outlined" onClick={handleOpenScan}>
            <AddIcon style={{ fontSize: "20px" }} />
            Scan Detials
          </Button>
          <div
            style={{
              maxWidth: "400px",
              fontSize: "14px",
              color: "rgba(76,78,100,0.6)",
              marginTop: "10px",
            }}
          >
            Add the scan IDs along with their respective manual and MATT
            results.
          </div>
        </div>
      </div>
      {genFields.variation_data.length ? (
        <div
          style={{
            width: "100%",
            border: "1px dashed rgba(76,78,100,0.25)",
            borderRadius: "8px",
            padding: "10px 10px",
            marginTop: "15px",
          }}
        >
          <>
            <div
              style={{
                fontSize: "16px",
                color: "rgba(76,78,100,0.6)",
                marginBottom: "10px",
              }}
            >
              Parameter Variation Data
            </div>
            {genFields.variation_data.map((data) => (
              <Chip
                key={data.param_id}
                label={data.crop + " : " + data.param}
                onDelete={() => {
                  deleteVariationData(data);
                }}
                style={{ marginBottom: "10px", marginRight: "10px" }}
              />
            ))}
          </>
        </div>
      ) : (
        <></>
      )}
      {genFields.scan_details.length ? (
        <div
          style={{
            width: "100%",
            border: "1px dashed rgba(76,78,100,0.3)",
            borderRadius: "8px",
            padding: "10px 10px",
            marginTop: "15px",
          }}
        >
          <>
            <div
              style={{
                fontSize: "16px",
                color: "rgba(76,78,100,0.6)",
                marginBottom: "10px",
              }}
            >
              Scan Details
            </div>
            {genFields.scan_details.map((data, index) => (
              <Chip
                key={index}
                label={data.scan_id}
                style={{ marginBottom: "10px", marginRight: "10px" }}
                onDelete={() => {
                  deleteScanData(index);
                }}
              />
            ))}
          </>
        </div>
      ) : (
        <></>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            overflowY: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "90%",
            maxWidth: "1000px",
            // minWidth: "500px",
            transform: "translate(-50%, -50%)",
            // width: '60%',
            minHeight: 250,
            backgroundColor: "#FFFFFF",
            borderRadius: 12,
            paddingTop: "20px",
            paddingBottom: "25px",
            paddingInline: "25px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              gap: 25,
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Crop</InputLabel>
              <Select
                sx={{ borderRadius: "8px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={localVariationData.crop}
                label="Crop"
                onChange={handleCropChange}
              >
                {cropList.map((name) => (
                  <MenuItem key={name.id} value={name.id}>
                    {name.crop}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Parameter</InputLabel>
              <Select
                sx={{ borderRadius: "8px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={localVariationData.params}
                label="Parameter"
                onChange={handleParamChange}
                multiple
                MenuProps={MenuProps}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {
                      <Chip
                        key={1}
                        label={localVariationData.params.length + " selected"}
                      />
                    }
                  </Box>
                )}
              >
                {localVariationData.paramList.map((name) => (
                  <MenuItem key={name.id} value={name.id}>
                    {name.param}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ display: "flex", gap: 25, marginTop: "25px" }}>
            <Button variant="outlined" onClick={handleClose}>
              Go Back
            </Button>
            <Button variant="contained" onClick={addParams}>
              Add Params
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={openScan}
        onClose={handleCloseScan}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            overflowY: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "90%",
            maxWidth: "1000px",
            transform: "translate(-50%, -50%)",
            // width: 1000,
            minHeight: 250,
            maxHeight: 600,
            backgroundColor: "#FFFFFF",
            borderRadius: 12,
            paddingTop: "20px",
            paddingBottom: "25px",
            paddingInline: "25px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: 10,
                flexWrap:'wrap'
              }}
            >
              <FormControl >
                <InputLabel id="demo-simple-select-label">Machine</InputLabel>
                <Select
                  sx={{ borderRadius: "8px", minWidth:'300px' }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={localScanData.machine}
                  label="Machine"
                  onChange={handleMachineChangeScan}
                >
                  {genFields.machines.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl >
                <InputLabel id="demo-simple-select-label">Crop</InputLabel>
                <Select
                  sx={{ borderRadius: "8px", minWidth:'300px' }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={localScanData.crop}
                  label="Crop"
                  onChange={handleCropChangeScan}
                >
                  {cropList.map((name) => (
                    <MenuItem key={name.id} value={name.id}>
                      {name.crop}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                value={localScanData.scan_id}
                sx={{ borderRadius: "8px", minWidth:'300px' }}
                id="outlined-basic"
                label="Scan ID"
                variant="outlined"
                onChange={(event) => {
                  setlocalScanData((prev) => {
                    return {
                      ...prev,
                      scan_id: event.target.value,
                    };
                  });
                }}
              />
            </div>
            <FormControl fullWidth style={{ marginTop: "10px" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={localScanData.pull_scan}
                    onChange={(event) => {
                      setlocalScanData((prev) => {
                        return {
                          ...prev,
                          pull_scan: !prev.pull_scan,
                        };
                      });
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 600, color: "rgba(76,78,100,0.87)" }}
                  >
                    Pull Scan
                  </Typography>
                }
              />
            </FormControl>
            {localScanData.paramValues.map((data, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    gap: 25,
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      color: "rgba(76,78,100,0.7)",
                      fontSize: "16px",
                    }}
                  >
                    {data.param}
                  </div>
                  <TextField
                    value={data.matt}
                    id="outlined-basic"
                    type="number"
                    label="MATT"
                    variant="outlined"
                    onChange={(event) => {
                      setlocalScanData((prev) => {
                        // // // console.log("xxxxxx-", index);
                        let newData = prev.paramValues.map(
                          (datatemp, oriIndex) => {
                            if (oriIndex === index) {
                              return {
                                ...datatemp,
                                matt: event.target.value,
                              };
                            } else {
                              return datatemp;
                            }
                          }
                        );
                        // // // console.log("newData-", newData);
                        return {
                          ...prev,
                          paramValues: newData,
                        };
                      });
                    }}
                  />
                  <TextField
                    value={data.manual}
                    id="outlined-basic"
                    type="number"
                    label="Manual"
                    variant="outlined"
                    onChange={(event) => {
                      setlocalScanData((prev) => {
                        // // // console.log("xxxxxx-", index);
                        let newData = prev.paramValues.map(
                          (datatemp, oriIndex) => {
                            if (oriIndex === index) {
                              return {
                                ...datatemp,
                                manual: event.target.value,
                              };
                            } else {
                              return datatemp;
                            }
                          }
                        );
                        // // // console.log("newData-", newData);
                        return {
                          ...prev,
                          paramValues: newData,
                        };
                      });
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div style={{ display: "flex", gap: 25, marginTop: "25px" }}>
            <Button variant="outlined" onClick={handleCloseScan}>
              Go Back
            </Button>
            <Button variant="contained" onClick={addScan}>
              Add Scan
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DataVariation;
