import React, { useContext, useEffect, useState } from "react";
import "../../assets/styles/CardModal.css";
import ContextState from "../contextApi/ContextState";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import ModalError from "../error/ModalError";
import { Tooltip } from "@mui/material";

const Comments = ({
  data,
  reloadCommentsPage,
  refreshBtn,
  setRefreshBtn,
  setRefreshLoading,
}) => {
  const {
    formatDate,
    lodingModalVisiblity,
    showLoadingModal,
    fetchApi,
    ticketStatus,
    openSnackbar,
    showModalError,
    showModalErrorPage,
  } = useContext(ContextState);
  const { ticketId, status } = data;
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [comment, setComment] = useState([]);

  // previous comments data that is coming from backend
  const [commentsData, setCommentsData] = useState(null);

  const [reloadData, setReloadData] = useState(false);

  // this loading variable is set for loading of buttons on click
  const [loading, setLoading] = React.useState(false);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setComment(value);
  };

  const handleAddComment = async () => {
    try {
      let comments = [];
      comments.push(comment);
      const payload = {
        ticket_id: ticketId,
        comments: comments,
      };

      setLoading(true);

      // Send the payload to the backend route
      const response = await fetchApi(
        "add_ticket_comment",
        "POST",
        payload,
        true
      );
      if (response.statusCode === 200) {
        console.log("comments added succesfully");

        openSnackbar("comments added succesfully", "success");
        setReloadData((prevState) => !prevState);
        setEdit(false);
        setComment([]);
      } else {
        console.error("Error editing ticket information:", response.statusCode);
        openSnackbar(response.msg, "error");
      }
    } catch (error) {
      console.error("Error editing ticket information:", error);
      openSnackbar("some error occured while saving details", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    showLoadingModal(true);

    const fetchCardData = async () => {
      let payload = {
        info_type: "comments",
        ticket_id: ticketId,
      };

      if (refreshBtn) {
        setRefreshLoading(true);
      }

      let jsonData;
      if (isMounted) {
        jsonData = await fetchApi("get_ticket_info", "POST", payload, true);

        // console.log("comments data recieved successfully:", jsonData.data);
        if (jsonData.statusCode === 200 && jsonData && jsonData.data) {
          if (isMounted) {
            setCommentsData(jsonData.data.comments);
            showLoadingModal(false);
          }
        } else if (jsonData.statusCode === 500 || jsonData.statusCode === 401) {
          // openSnackbar(jsonData.data, "error")
          showModalErrorPage(jsonData.statusCode, jsonData.msg);
          // console.log()
        } else {
          showModalErrorPage(jsonData.statusCode, jsonData.msg);
        }
      }

      showLoadingModal(false);
      setRefreshLoading(false);
      setRefreshBtn(false);
    };

    fetchCardData();
    // showLoadingModal(false);
  }, [reloadData, reloadCommentsPage]);

  return (
    <>
      {(!showModalError.status && !lodingModalVisiblity && (
        <div className="comment-section mt-3">
          <div className="comment-box mx-4 row">
            <div className="mb-3 ">
              <button
                // id="saveTicketBtn"
                type="button"
                className="btn btn-outline-primary markResolved_button"
                disabled={ticketStatus === "resolved"}
                onClick={() => setEdit(!edit)}
              >
                <Tooltip
                  data-html="true"
                  title={"Click here to add new comment"}
                  arrow={true}
                >
                  Add new comment
                </Tooltip>
              </button>
            </div>

            {edit && (
              <>
                <label className="form-label col-12">
                  Add comments
                  <textarea
                    className="form-control"
                    name="postContent"
                    onChange={handleInputChange}
                    rows={6}
                  />
                </label>
                <div className="">
                  <LoadingButton
                    size="large"
                    onClick={handleAddComment}
                    endIcon={<SendIcon />}
                    loading={loading}
                    disabled={comment.length === 0}
                    loadingPosition="end"
                    variant="contained"
                  >
                    <span>Add</span>
                  </LoadingButton>
                </div>
              </>
            )}
          </div>

          {
            <div className="show-comments mx-4 px mb-2">
              <div className="previous-comments-header ">
                <h5 className="previous-comments-header-text">
                  Previous Comments
                </h5>
              </div>
              {(commentsData?.length &&
                commentsData.map((comment, index) => (
                  <div className=" mb-2 py-2  previous-comments" key={index}>
                    <div className="row ">
                      <div className="col-12 commented-by">
                        {comment.username}{" "}
                        <span className="comment-time-span">
                          {formatDate(comment.creation_time)}
                        </span>
                      </div>
                      <div className="row">
                        <div className="col-12 comment-text">
                          {comment.comment}
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
              <div className="col-12 comment-date">{comment.creation_time}</div>
            </div> */}
                  </div>
                ))) ||
                "No comments added yet"}
            </div>
          }
        </div>
      )) ||
        (showModalError.status && <ModalError />) || <></>}
    </>
  );
};

export default Comments;
