import React, { useContext, useEffect, useState } from "react";
import "../../assets/styles/CardModal.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContextState, { ContextStateProvider } from "../contextApi/ContextState";
import ScanDetailsModal, { git_branches } from "./ScanDetailsModal";
import ModalError from "../error/ModalError";

import ScanDetailsEditModal from "./ScanDetailsEditModal";
import { Box, Modal, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import EditDataVariationModal from "./EditDataVariationModal";
import EditModalDetailsModal from "./UpdateModelDetailsModal";
import close from "../../assets/images/close.svg";
import { Button } from "react-bootstrap";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";

const ResultContent = ({
  data,
  reloadScanDetailsPage,
  refreshBtn,
  setRefreshBtn,
  setRefreshLoading,
}) => {
  const { ticketId, status } = data;
  const {
    fetchApi,
    url,
    showLoadingModal,
    ticketStatus,
    lodingModalVisiblity,
    openSnackbar,
    showModalError,
    showModalErrorPage,
    showLoading,
  } = useContext(ContextState);
  const user_details = useSelector((state) => state?.userDetail?.data);

  const [scanidsinfo, setscanidsinfo] = useState(null);
  const [machineData, setmachineData] = useState(null);
  const [dataVariation, setdataVariation] = useState(null);
  const [modelData, setModelData] = useState(null);

  const [reloadData, setReloadData] = useState(false);

  const handleReloadData = () => {
    setReloadData((prevState) => !prevState);
  };

  let is_ticket_data_Mounted;
  const createTicket = async (is_ticket_data_Mounted) => {
    try {
      const payload = {
        ticket_id: ticketId,
        info_type: "scan_details",
      };

      if (refreshBtn) {
        setRefreshLoading(true);
      }

      const jsonData = await fetchApi(
        "get_ticket_info",
        "POST",
        payload,
        is_ticket_data_Mounted
      );
      // console.log("data fetched for machine scan info", jsonData);
      if (jsonData.statusCode === 200 && jsonData && jsonData.data) {
        if (is_ticket_data_Mounted) {
          // console.log(
          //   "jsonData?.data?.scan_details",
          //   jsonData?.data?.scan_details
          // );
          setscanidsinfo(jsonData?.data?.scan_details || {});
          setmachineData(jsonData?.data?.machines_data || []);
          setdataVariation(jsonData?.data?.variation_data || []);
          setModelData(jsonData?.data?.model_details || []);
          // showLoadingModal(false);
        }
      } else {
        openSnackbar(jsonData.msg, "error");
        showModalErrorPage(jsonData.statusCode, jsonData.msg);
      }
    } catch (error) {
      openSnackbar("Some error occured from while getting scan details");
    } finally {
      showLoadingModal(false);
      setRefreshLoading(false);
      setRefreshBtn(false);
    }
  };

  useEffect(() => {
    is_ticket_data_Mounted = true;
    showLoadingModal(true);
    createTicket(is_ticket_data_Mounted);
    return () => {
      is_ticket_data_Mounted = false;
    };
  }, [reloadData, reloadScanDetailsPage]);

  // const [showDataVariationModal, setShowDataVariationModal] = useState(false);
  // const handleDataVariationAddButtonClick = () => {
  //   setShowDataVariationModal(true);
  // };
  // const handleCloseDataVariationModal = () => {
  //   setShowDataVariationModal(false);
  // };

  const [showScanDetailsModal, setShowScanDetailsModal] = useState(false);

  const handleScanDetailsAddButtonClick = () => {
    setShowScanDetailsModal(true);
  };

  const handleCloseScanDetailsModal = (event, reason) => {
    // when click on outside of modal the modal is not closing....
    if (reason && reason == "backdropClick") {
      // console.log("backdropClicked. Not closing dialog.");
      return;
    }
    setShowScanDetailsModal(false);
  };

  // modal for edit the scan details
  const [isEditModal, setIsEditModal] = useState({
    isOpen: false,
    ticket_id: ticketId,
  });

  const handleEditModalOpen = (scan_id) => {
    // console.log("modal is opening clicked");
    setIsEditModal((prev) => ({
      ...prev,
      isOpen: true,
      selected_scan_id: scan_id,
    }));

    // console.log(isEditModal);
  };

  function handleEditModalClose(event, reason) {
    if (reason && reason == "backdropClick") {
      // console.log("backdropClicked. Not closing dialog.");
      return;
    }

    setIsEditModal((prev) => ({
      ...prev,
      isOpen: false,
      selected_scan_id: null,
    }));
  }

  // delete confirmation
  const [deleteModalState, setDeleteModalState] = useState({
    isOpen: false,
    id: "",
    modalType: "",
  });

  const [loading, setLoading] = useState(false);

  // closing for edit data variation code...
  function hanldeCloseDeletionModal(event, reason) {
    if (reason && reason == "backdropClick") {
      // console.log("backdropClicked. Not closing dialog.");
      return;
    }
    setDeleteModalState((prev) => ({ ...prev, isOpen: false }));
  }

  // deletion data for datat variation params && modal details
  const handleDeletion = async (id, type) => {
    // console.log("type------>", type);
    try {
      setLoading(true);
      const payload = {
        id: id,
        ticket_id: ticketId,
      };

      let endpoint;
      if (type === "data_variation") {
        endpoint = "delete_param_variation";
      } else {
        endpoint = "delete_model_details";
      }

      const response = await fetchApi(endpoint, "POST", payload, true);
      // console.log(response);
      if (response.statusCode === 200) {
        openSnackbar(response?.data?.message);
        // reloading data
        handleReloadData();
        // Closing modal when sucess deletion
        hanldeCloseDeletionModal();

        setDeleteModalState((prev) => ({
          ...prev,
          id: "",
          modalType: "",
        }));
      } else {
        openSnackbar(response?.msg, "error");
        hanldeCloseDeletionModal();
      }
    } catch (error) {
      console.error("Error deleting data variation params:", error);
      openSnackbar(
        "Error occurred while deletion of Data variation parameters"
      );
    } finally {
      setLoading(false);
    }
  };

  // edit section start here...
  // data variation
  const [editDataVariation, setEditDataVariation] = useState({
    isOpen: false,
    id: "",
    crop: "",
    parameter: "",
    ticket_id: ticketId,
  });
  // closing for edit data variation code...
  function handleCloseEditDataVariation(event, reason) {
    if (reason && reason == "backdropClick") {
      // console.log("backdropClicked. Not closing dialog.");
      return;
    }
    setEditDataVariation((prev) => ({ ...prev, isOpen: false }));
  }

  // modal details

  const [editModalDetails, setEditModalDetails] = useState({
    isOpen: false,
    crop: "",
    machine_code: "",
    commit_id: "",
    model_number: "",
    git_branch: "",
    remarks: "",
    ticket_id: "",
  });

  // closing for editModalDetails modal...
  function handleCloseEditModelDetails(event, reason) {
    if (reason && reason == "backdropClick") {
      // console.log("backdropClicked. Not closing dialog.");
      return;
    }
    setEditModalDetails((prev) => ({ ...prev, isOpen: false }));
  }
  return (
    <>
      {(!showModalError.status && !lodingModalVisiblity && (
        <div className="row machine-scan-details mt-3 mx-3">
          <div className="add-machine-details-button">
            <button
              type="button"
              class="btn btn-outline-primary markResolved_button"
              onClick={handleScanDetailsAddButtonClick}
              disabled={ticketStatus === "resolved"}
            >
              <Tooltip
                // data-html="true"
                title={"Click to add scan details"}
                arrow={true}
              >
                Add Scan Details
              </Tooltip>
            </button>
          </div>

          <header className="data-variation-header-text p-0">
            Data Variation
          </header>
          <div className="row data-variation  mb-3">
            <div className="data-variation-elements-container">
              {(dataVariation?.length &&
                dataVariation.map(([id, crop, paramValue]) => (
                  <div className="d-flex align-items-center data-variation-elements">
                    <div className="">
                      <strong>Crop:</strong> {crop}, <strong>Parameter:</strong>{" "}
                      {paramValue}
                    </div>
                    {/* delete for added data varation details */}
                    {ticketStatus !== "resolved" ? (
                      <div className="ms-auto">
                        <div
                          className="dropdown ms-2  "
                          style={{ cursor: "pointer" }}
                        >
                          <Tooltip
                            data-html="true"
                            title={
                              "Click to Edit or Delete Data Variation Details"
                            }
                            arrow={true}
                          >
                            <i
                              className="fa-solid fa-ellipsis-vertical  "
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{ padding: "0px 3px" }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            ></i>
                          </Tooltip>
                          {/* Centered dropdown */}
                          {/* </button> */}
                          <ul className="dropdown-menu dropdown-menu-end delete-ticket-btn-border">
                            <li>
                              <a
                                className="dropdown-item text-center edit-ticket-btn"
                                href="#"
                                onClick={() => {
                                  setEditDataVariation((prev) => ({
                                    ...prev,
                                    id: id,
                                    crop: crop,
                                    parameter: paramValue,
                                    isOpen: true,
                                  }));
                                }}
                              >
                                Edit Details
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item text-center delete-ticket-btn"
                                href="#"
                                onClick={() => {
                                  // handleDeletion(id, "data_variation");
                                  setDeleteModalState((prev) => ({
                                    ...prev,
                                    isOpen: true,
                                    id: id,
                                    modalType: "data_variation",
                                  }));
                                }}
                              >
                                Delete
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))) ||
                "No data to show"}
            </div>
          </div>

          <header className="p-0">Model Detail</header>
          <div className="data-variation row mb-3">
            <div className="data-variation-elements-container">
              {(modelData?.length &&
                modelData.map(
                  ([id, machine_code, crop, commit_id, modelNo, gitBranch]) => (
                    <div className="d-flex align-items-center data-variation-elements">
                      <div>
                        <strong>Machine No:</strong>{" "}
                        <span className="text-wrapper-class">
                          {machine_code}
                        </span>
                        , <strong>Crop:</strong>{" "}
                        <span className="text-wrapper-class">{crop}</span>,{" "}
                        <strong>Commit-Id:</strong>{" "}
                        <span className="text-wrapper-class">{commit_id}</span>,{" "}
                        <strong>Model No:</strong>{" "}
                        <span className="text-wrapper-class">{modelNo}</span>,{" "}
                        <strong>Git Branch:</strong>{" "}
                        <span className="text-wrapper-class">
                          {gitBranch === null ? " - " : gitBranch}
                        </span>
                      </div>
                      {/* delete for modal details details */}
                      {ticketStatus !== "resolved" ? (
                        <div className="ms-auto">
                          <div
                            className="dropdown ms-2  "
                            style={{ cursor: "pointer" }}
                          >
                            <Tooltip
                              data-html="true"
                              title={
                                "Click to Update Model Details or Delete Model Details"
                              }
                              arrow={true}
                            >
                              <i
                                className="fa-solid fa-ellipsis-vertical  "
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style={{ padding: "0px 3px" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              ></i>
                            </Tooltip>
                            {/* Centered dropdown */}
                            {/* </button> */}
                            <ul className="dropdown-menu dropdown-menu-end delete-ticket-btn-border">
                              <li>
                                <a
                                  className="dropdown-item text-center edit-ticket-btn"
                                  href="#"
                                  onClick={() => {
                                    setEditModalDetails((prev) => ({
                                      ...prev,
                                      isOpen: true,
                                      id: id,
                                      machine_code: machine_code,
                                      crop: crop,
                                      commit_id: commit_id,
                                      model_number: modelNo,
                                      ticket_id: ticketId,
                                    }));
                                  }}
                                >
                                  Update Model
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item text-center delete-ticket-btn"
                                  href="#"
                                  onClick={() => {
                                    // handleDeletion(id, "modal_details");
                                    setDeleteModalState((prev) => ({
                                      ...prev,
                                      isOpen: true,
                                      id: id,
                                      modalType: "model_details",
                                    }));
                                  }}
                                >
                                  Delete
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )
                )) ||
                "No data to show"}
            </div>
          </div>

          <header className="scan-detail-header-text p-0">Scan Details</header>
          <Row className="scan-detail">
            {(scanidsinfo &&
              Object.keys(scanidsinfo).length > 0 &&
              Object.keys(scanidsinfo)?.map((scanIDs, index) => (
                <Col key={index} className="col-style">
                  <div className="header mb-1 position-relative">
                    <div className="scanid_title text-center d-flex">
                      <div className="d-inline-block col-11"> {scanIDs}</div>
                      {ticketStatus !== "resolved" ? (
                        <div
                          className="d-inline-block col-1"
                          // style={{ position: "absolute", right: "4px" }}
                        >
                          <div
                            className="dropdown"
                            style={{ cursor: "pointer" }}
                          >
                            <Tooltip
                              data-html="true"
                              title={"Click to edit scan details"}
                              arrow={true}
                            >
                              <i
                                className="fa-solid fa-ellipsis-vertical  "
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style={{ padding: "0px 3px" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              ></i>
                            </Tooltip>
                            {/* Centered dropdown */}
                            {/* </button> */}
                            <ul className="dropdown-menu dropdown-menu-end delete-ticket-btn-border">
                              <li>
                                <a
                                  className="dropdown-item text-center edit-ticket-btn"
                                  href="#"
                                  onClick={(e) => {
                                    // e.stopPropagation();
                                    handleEditModalOpen(scanIDs);
                                  }}
                                >
                                  Edit Detail
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {/* for edit scan details*/}

                    {/* <div className="manual-results">(Manual Results)</div> */}
                  </div>
                  {/* <div className=""> */}
                  <div className="row mb-2">
                    <div className=" col-6 text-center text-nowrap sub-heading">
                      Param
                    </div>
                    <div className=" col-3 text-center text-nowrap sub-heading">
                      Matt
                    </div>
                    <div className=" col-3 text-center text-nowrap sub-heading">
                      Manual
                    </div>
                  </div>
                  {/* {Object.entries(scanData).map(([key, value]) => ( */}
                  {scanidsinfo[scanIDs].results?.map(
                    ([param, Matt, Manual], scanDataindex) => (
                      <div key={scanDataindex} className="row mb-2">
                        <div className="col-6 text-center Parameters">
                          {param}
                        </div>
                        <div className="col-3 text-center manual_res">
                          {Matt || "-"}
                        </div>
                        {/* i have to change this data */}
                        <div className="col-3 text-center manual_res">
                          {Manual || "-"}
                        </div>
                      </div>
                    )
                  )}
                  {/* </div> */}
                </Col>
              ))) ||
              "No data to show"}
          </Row>
        </div>
      )) ||
        (showModalError.status && <ModalError />) || (
          <div className="container no-ticket-found-div text-center count_heading_div">
            <div className="d-flex align-items-center justify-content-center flex-column">
              <i className="fa-solid fa-face-rolling-eyes fa-2xl inline-block"></i>
              <p>{"No Data Found"}</p>
            </div>
          </div>
        )}

      {/* Render Scan Details Modal */}
      <ScanDetailsModal
        handleReloadData={handleReloadData}
        machineData={machineData}
        ticketId={ticketId}
        show={showScanDetailsModal}
        handleClose={handleCloseScanDetailsModal}
      />

      <ScanDetailsEditModal
        modalState={isEditModal}
        handleClose={handleEditModalClose}
        handleReloadData={handleReloadData}
        machineData={machineData}
        ticketId={ticketId}
        allScanDetails={scanidsinfo}
      />

      {/* edit data Variation modal */}

      <EditDataVariationModal
        editDataVariation={editDataVariation}
        handleReloadData={handleReloadData}
        handleClose={handleCloseEditDataVariation}
      />

      {/* edit modal details modal */}

      <EditModalDetailsModal
        editModalDetails={editModalDetails}
        handleReloadData={handleReloadData}
        handleClose={handleCloseEditModelDetails}
      />

      {/* alert modal for deletion of data variation and model details */}

      <Modal
        open={deleteModalState?.isOpen}
        onClose={hanldeCloseDeletionModal}
        style={{
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          position: "fixed",
          overflowY: "scroll",
          height: "100%",
          margin: "10px 0px 10px 10px",
        }}
      >
        <Box
          style={{
            position: "relative",
            zIndex: "1111",
            right: "0",
            top: "12%",
            left: "0",
            margin: "  auto",
            overflowY: "auto",
            borderRadius: "10px",
            backgroundColor: "#ffffff",
            maxWidth: "578px",
          }}
        >
          {/* Modal Header */}
          <div
            style={{
              padding: "12px 18px",
              borderBottom: "1px solid #f2eeee",
            }}
          >
            <div className="d-flex justify-content-between">
              <p className="m-0">
                Confirm Deletion of{" "}
                <span style={{ color: "red" }}>
                  {deleteModalState?.modalType
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </span>
              </p>
              <div
                onClick={() => {
                  hanldeCloseDeletionModal();
                }}
              >
                <img
                  src={close}
                  alt="close"
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </div>
            </div>
          </div>
          {/* Modal Header ends... */}

          {/* Modal Body Starts .... */}

          <div
            style={{
              padding: "8px 16px ",
            }}
          >
            This action will delete the selected{" "}
            {deleteModalState?.modalType
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}{" "}
            information. This action cannot be undone.
          </div>
          {/* Modal Body ends .... */}

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              padding: "12px ",
            }}
          >
            <LoadingButton
              size="small"
              endIcon={<SendIcon />}
              loading={loading}
              loadingPosition="end"
              variant="contained"
              onClick={() => {
                handleDeletion(
                  deleteModalState.id,
                  deleteModalState?.modalType
                );
              }}
            >
              <span>Delete</span>
            </LoadingButton>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ResultContent;
