import React, { useEffect } from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import authData from "./auth.json";
import { BeatLoader } from "react-spinners";

import Error from "../error/Error";
import { useSelector } from "react-redux";
const Auth = ({ routeCode }) => {
  // user Details from redux
  const userData = useSelector((state) => state.userDetail);

  const isLoading = !userData || !userData.data;

  // console.log("isLoading------------?", isLoading);
  const activeRole = userData?.data?.user_type?.toLowerCase() || "user"; // from redux
  //   const activeRole = "support admin".toLowerCase(); // from redux
  // const activeRole = "user".toLowerCase(); // from redux
  const { allowed_roles } = authData;
  //   console.log(allowed_roles);
  const location = useLocation();
  // useEffect(() => {
  //   console.log("Auth.jsx working");
  // }, []);

  if (!userData?.data) {
    return (
      <div className="showSpinner all-ticket-spinner">
        <BeatLoader color="#2D97D4" />
      </div>
    );
  }

  return allowed_roles[activeRole].find((role) => routeCode?.includes(role)) ? (
    <Outlet />
  ) : (
    <Error code={404} />
  );
};

export default Auth;
