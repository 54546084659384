import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import ContextState from "../contextApi/ContextState";

function AddNewModel() {
  const [loading, setloading] = useState(false);
  const { fetchApi, openSnackbar } = useContext(ContextState);
  const [modelDetails, setModelDetails] = useState({
    commitId: "",
    modelNo: "",
    gitBranch: "",
  });

  const submitForm = async () => {
    try {
      let payload = {};
      if (modelDetails.modelNo !== "") {
        payload["modelNo"] = modelDetails.modelNo;
      }
      if (modelDetails.commitId !== "") {
        payload["commitId"] = modelDetails.commitId;
      }
      if (modelDetails.gitBranch !== "") {
        payload["git_branch_name"] = modelDetails.gitBranch;
      }

      setloading(true);

      // Send the payload to the backend route
      const response = await fetchApi(
        "add_new_model_detail",
        "POST",
        payload,
        true
      );
      if (response.statusCode === 200) {
        // console.log("comments added succesfully");
        setModelDetails((prev) => ({
          ...prev,
          commitId: "",
          modelNo: "",
          gitBranch: "",
        }));
        openSnackbar("new model details added", "success");
      } else {
        // console.error("Error editing ticket information:", response.statusCode);
        openSnackbar(response.msg, "error");
      }
    } catch (error) {
      // console.error("Error editing ticket information:", error);
      openSnackbar("some error occured while saving details", "error");
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    document.title = "Add Model - NEO";
  });
  return (
    <>
      <div
        style={{
          width: "95%",
          margin: "40px auto",
          maxWidth: "1200px",
          minHeight: "180px",
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          padding: " 30px",
          borderRadius: 12,
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 16px 0px",
          justifyContent: "space-between",
        }}
      >
        <div className="header mb-3" style={{ textAlign: "center" }}>
          <span className="fs-5">Add Model Details</span>
        </div>
        <div
          className="d-flex flex-column flex-md-row gap-4"
          style={{ width: "100%" }}
        >
          <TextField
            value={modelDetails.commitId}
            fullWidth
            id="outlined-basic"
            label="Commit Id"
            variant="outlined"
            onChange={(event) => {
              setModelDetails((prev) => ({
                ...prev,
                commitId: event.target.value,
              }));
            }}
          />
          <TextField
            value={modelDetails.modelNo}
            fullWidth
            id="outlined-basic"
            label="Model Number"
            variant="outlined"
            onChange={(event) => {
              setModelDetails((prev) => ({
                ...prev,
                modelNo: event.target.value,
              }));
            }}
          />
          <TextField
            value={modelDetails.gitBranch}
            fullWidth
            id="outlined-basic"
            label="Git Branch"
            variant="outlined"
            onChange={(event) => {
              setModelDetails((prev) => ({
                ...prev,
                gitBranch: event.target.value,
              }));
            }}
          />
        </div>
        <div className="mt-3" style={{ textAlign: "end" }}>
          <LoadingButton
            size="medium"
            onClick={submitForm}
            disabled={
              modelDetails?.commitId === "" &&
              modelDetails?.modelNo === "" &&
              modelDetails?.gitBranch === ""
            }
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
          >
            <span>Submit</span>
          </LoadingButton>
        </div>
      </div>
    </>
  );
}

export default AddNewModel;
