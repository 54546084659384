import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Tooltip } from "@material-ui/core";

export default function ShowTable({ tableData, headers, shadesOfBlue }) {
  const Headers = [...headers];
  // console.log(Headers);
  if (!Array.from(Headers).includes("Sub Issue")) {
    Headers.unshift("Marker", "Sub Issue");
  }

  // condition for visits overview
  if (Array.from(Headers).includes("sub_Issue")) {
    Headers.splice(1, 1);
    // console.log(Headers, "1");
  }
  if (Array.from(Headers).includes("visted_person")) {
    Headers.splice(1, 1);
    // console.log(Headers, "hewdaer2");
  }

  if (Headers?.[2] === "part_replaced") {
    // console.log(Headers);
    Headers.splice(1, 1);
  }

  const updateHeaders = Array.from(Headers).map((header, index) => {
    let label = header.charAt(0).toUpperCase() + header.slice(1);
    label = label.replace("_", " ");
    const id = header.toLowerCase();
    return {
      id,
      label,
      minWidth: 170,
      align: "center",
      format: (value) => {
        if (typeof value === "number") {
          if (header === "Percent") {
            return value.toFixed(2);
          } else {
            return value.toLocaleString("en-US");
          }
        }
        return value;
      },
      color: shadesOfBlue[index % shadesOfBlue.length],
    };
  });

  const updatedrows = tableData.map((row, index) => {
    const populateDict = { marker: "" };
    // console.log("row:",row);
    row.map((tableItems, tableItemsKeys) => {
      populateDict[Headers[tableItemsKeys + 1]?.toLowerCase()] = tableItems;
    });
    return populateDict;
  });

  // console.log({ updateHeaders: updateHeaders, updatedrows: updatedrows });
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {updateHeaders.map((column, columnIndex) => (
                <TableCell
                  key={column.id}
                  align={columnIndex ? column.align : "center"}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "#f5f5f5", // Grayish background for headers
                    whiteSpace: "nowrap", // No text wrap for headers
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {updatedrows.map((row, rowIndex) => {
              return (
                <Tooltip
                  // title={row["sub issue"]}
                  title={
                    Object.keys(row)?.[1] === "sub issue"
                      ? row["sub issue"]
                      : Object.keys(row)?.[1] === "sub_issue"
                      ? row["sub_issue"]
                      : Object.keys(row)?.[1] === "visted_person"
                      ? row["visted_person"]
                      : Object.keys(row)?.[1] === "part_replaced"
                      ? row["part_replaced"]
                      : null
                  }
                  placement="top"
                  key={rowIndex}
                  style={{
                    backgroundColor: "transparent !important",
                    fontSize: "1.1rem !important",
                  }}
                >
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    {updateHeaders.map((column, columnIndex) => {
                      const value = row[column.id];
                      return (
                        <React.Fragment key={column.id}>
                          {columnIndex === 0 && (
                            <TableCell
                              align="left"
                              style={{
                                color: column.color,
                                backgroundColor: "inherit",
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              <div className="text-center d-flex justify-content-center">
                                <div
                                  style={{
                                    backgroundColor:
                                      shadesOfBlue[
                                        rowIndex % shadesOfBlue.length
                                      ],
                                    height: "15px",
                                    width: "15px",
                                    borderRadius: "50%",
                                  }}
                                ></div>
                              </div>
                            </TableCell>
                          )}
                          {columnIndex !== 0 && (
                            <TableCell
                              align={column.align}
                              style={{
                                color:
                                  column.id === "sub issue"
                                    ? column.color
                                    : "inherit",
                              }}
                            >
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </TableRow>
                </Tooltip>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
