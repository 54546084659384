import React, { useContext } from "react";
import {
  Button,
  LinearProgress,
  ButtonGroup,
  linearProgressClasses,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import Wheat_IMG from "../../../assets/images/crop/WHEAT.1670-20240529-1-15:00:55-lower.png";
import RICEPB_C_IMG from "../../../assets/images/crop/RICEPB_C.1670-20240529-1-14:58:35-lower.png";
import RICEPB_A_IMG from "../../../assets/images/crop/RICEPB_A.1670-20240529-1-14:58:35-upper.png";
import RICE_C_IMG from "../../../assets/images/crop/RICE_C.1670-20240529-1-14:54:00-lower.png";
import RICE_A_IMG from "../../../assets/images/crop/RICE_A.1670-20240529-1-14:54:00-upper.png";
import PADDY_A_IMG from "../../../assets/images/crop/PADDY_A.1670-20240529-1-15:03:54-lower.png";
import PADDY_C_IMG from "../../../assets/images/crop/PADDY_C.1670-20240529-1-15:03:54-lower.png";

import TotalMachinesImg from "../../../assets/images/machine-blue.png";
import TotalDistrictImg from "../../../assets/images/district-blue.png";
import TotalRegionImg from "../../../assets/images/region-blue.png";

import allTicketsImg from "../../../assets/images/total-assessment-blue-3.png";
import ContextState from "../../contextApi/ContextState";

const images = {
  WHEAT: Wheat_IMG,
  RICEPB_C: RICEPB_C_IMG,
  RICEPB_A: RICEPB_A_IMG,
  RICE_C: RICE_C_IMG,
  RICE_A: RICE_A_IMG,
  PADDY_A: PADDY_A_IMG,
  PADDY_C: PADDY_C_IMG,
};

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, colorAccept, colorReject }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: colorReject,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: colorAccept,
      borderRadius: 0,
      // borderRight: "2px solid white",
    },
  })
);
const pageStyling = {
  imageBackgroundDivRadius: "25%",
  topDivwidth: "33px",

  IconWidth: "16px",
  iconBackgroundWidth: "35px",
  iconBackgroundHeight: "35px",

  monthlyScanDivDescIconWidth: "20px",
  monthlyScanDivDescIconBackgroundWidth: "40px",
  monthlyScanDivDescIconBackgroundHeight: "40px",
};

const CropCard = ({
  test,
  each_crop_data,
  color,
  handleOpenmodal,
  showAccepted = true,
}) => {
  const { formatInIndianRupees } = useContext(ContextState);

  return (
    <div
      className="card-Div"
      //   style={{
      //     borderRadius: "6px",
      //     // minHeight: "100px",
      //     backgroundColor: "#ffffff",
      //     boxShadow: "0px 0px 1px 0px gray",
      //     padding: "12px",
      //     maxHeight: "fit-content",
      //     fontWeight: "600",
      //     // minWidth: "280px",
      //   }}
    >
      {/* image container */}

      {/* <div
          className="background_location_img_dim"
          style={{
            width: "auto",
            height: "200px",
            backgroundImage: `url(${images[each_crop_data?.Crop]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            borderRadius: "5px",
          }}
        > */}

      {/* background iamge */}
      <div className="region-img">
        <img
          className="background-location-img img-fluid "
          src={images[each_crop_data?.Crop]}
          alt="Region"
        />
      </div>

      {/* title && more deatils btn */}
      <div className="d-flex justify-content-between align-items-center my-2">
        <p className="location_nums m-0">{each_crop_data?.Crop}</p>
        <div>
          <Tooltip
            data-html="true"
            title={"Click to view detailed crop data"}
            arrow={true}
          >
            <button
              style={{ color: color.taxtHeadingLight }}
              className="location_moredetails_btn"
              onClick={() => {
                // handelsendLocationDataToURL("region", each_crop_data?.Region);
                handleOpenmodal(each_crop_data);
              }}
            >
              More
              <i class="fa-solid fa-chevron-right ps-1"></i>
            </button>
          </Tooltip>
        </div>
      </div>

      {/* total assements  */}
      <div>
        <div className="py-2 pt-0">
          {/* Top Img */}
          {false && (
            <div className="d-flex justify-content-center">
              <div
                style={{
                  backgroundColor: color.iconBackgroundBlue,
                  borderRadius: "15%",
                  height: "40px",
                  width: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                // className="ms-auto"
              >
                <img
                  src={allTicketsImg}
                  alt="machine img"
                  style={{ width: "20px" }}
                />
              </div>
            </div>
          )}
          <div
            className="text-center"
            style={
              {
                // padding: "15px 10px",
              }
            }
          >
            <p
              key={test}
              className="fs-4 rotatingText-adjective-small"
              style={{
                // fontSize: "1.8rem",
                margin: "0px",
                fontWeight: "600",
                color: color.locationTextHeading,
              }}
            >
              {formatInIndianRupees(each_crop_data["Total Scans"])}
            </p>
            <p
              style={{
                fontSize: "0.9rem",
                margin: "0px",
                color: color.taxtHeading,
                // fontWeight: "600",
              }}
            >
              Total Scans
            </p>
          </div>
        </div>

        {/* break line */}
        {false && (
          <div className="d-flex justify-content-center ">
            <div
              style={{
                borderTop: "1px solid",

                borderColor: color.navBorderBottom,
                width: "95%",
                height: "1px",
              }}
            ></div>
          </div>
        )}

        {false && (
          <div className="d-flex py-3">
            <div className="col-5 text-center">
              <div className="d-flex justify-content-center py-1">
                <div
                  style={{
                    backgroundColor: color.iconBackgroundBlue,
                    borderRadius: "15%",
                    height: "40px",
                    width: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  // className="ms-auto"
                >
                  <img
                    src={TotalRegionImg}
                    alt="All Assesment img"
                    style={{ width: "20px" }}
                  />
                </div>
              </div>
              <p
                className="mb-0"
                style={{
                  fontSize: "0.9rem",
                  color: color.taxtHeading,
                }}
              >
                Region
              </p>
              <p
                className="mb-0"
                style={{
                  fontSize: "1.15rem",
                  fontWeight: "600",
                  color: color.locationTextHeading,
                }}
              >
                Amarvathi
              </p>
            </div>
            <div
              className="col-2 m-auto align-self-center "
              style={{
                width: "1px",
                height: "75px",
                backgroundColor: "#e5e6e8",
              }}
            ></div>
            <div className="col-5 text-center">
              <div className="d-flex justify-content-center py-1">
                <div
                  style={{
                    backgroundColor: color.iconBackgroundBlue,
                    borderRadius: "15%",
                    height: "40px",
                    width: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  // className="ms-auto"
                >
                  <img
                    src={TotalMachinesImg}
                    alt="machine img"
                    style={{ width: "20px" }}
                  />
                </div>
              </div>
              <p
                className="mb-0"
                style={{
                  fontSize: "0.9rem",
                  margin: "0px",
                  color: color.taxtHeading,
                }}
              >
                Total Machines
              </p>
              <p
                className="mb-0"
                style={{
                  fontSize: "1.15rem",
                  fontWeight: "600",
                  color: color.locationTextHeading,
                }}
              >
                21
              </p>
            </div>
          </div>
        )}
        {/* border */}
        {false && (
          <div className="d-flex justify-content-center ">
            <div
              style={{
                borderTop: "1px solid",

                borderColor: color.navBorderBottom,
                width: "95%",
                height: "1px",
              }}
            ></div>
          </div>
        )}
      </div>
      <div class="barChartDivDescHorizontalSeperator"></div>
      {/* top crops && top scans */}

      {(each_crop_data["Top Region"] ||
        each_crop_data["Top District"] ||
        each_crop_data["Top Location"]) && (
        <div className="location_heading_sub py-1 mt-2">
          {/* machine no for district level & machine level */}
          {each_crop_data["Top Region"] && (
            <div className="d-flex justify-content-between mb-1">
              <div
                className="location_headings"
                style={{ color: color.tableRowHeading }}
              >
                Top Region
              </div>
              <div
                key={test}
                className="location_headings_sub m-0 text-wrap rotatingText-adjective-small"
                style={{ color: color.taxtHeading }}
              >
                {each_crop_data["Top Region"]}
              </div>
            </div>
          )}
          {each_crop_data["Top District"] && (
            <div className="d-flex justify-content-between mb-1">
              <div
                className="location_headings"
                style={{ color: color.tableRowHeading }}
                >
                Top District
              </div>
              <div
                key={test}
                className="location_headings_sub  m-0  rotatingText-adjective-small"
                style={{ color: color.taxtHeading }}
              >
                {each_crop_data["Top District"]}
              </div>
            </div>
          )}
          {each_crop_data["Top Location"] && (
            <div className="d-flex justify-content-between mb-1">
              <div
                className="location_headings m-0"
                style={{ color: color.tableRowHeading }}
                >
                Top Location
              </div>
              <div
                key={test}
                className="location_headings_sub rotatingText-adjective-small"
                style={{ color: color.taxtHeading }}
              >
                {each_crop_data["Top Location"]}
              </div>
            </div>
          )}
        </div>
      )}

      {/* accepted scans progress bar */}

      {showAccepted && (
        <div className="py-1">
          <div className="">
            <div>
              <p
                className="location_headings"
                style={{
                  fontSize: "0.88rem",
                  margin: "0px 0px 0px 0px",
                  color: color.tableRowHeading,
                }}
              >
                Accepted Scans
              </p>
            </div>
          </div>
          <div className="d-flex flex-row mb-1">
            <div className="d-inline-block me-auto">
              <span
                key={test}
                style={{
                  // fontSize: "1rem",
                  color: color.tableRowHeading,
                }}
                className="location_nums rotatingText-adjective-small"
              >
                {formatInIndianRupees(each_crop_data["Total Accepted"])}
              </span>
              {/* <span
              // style={{ fontSize: "0.65rem", color: color.taxtHeadingLight }}
              className="location_headings"
            >
              {" "}
              /{formatInIndianRupees(each_region["Total Scans"])}
            </span> */}
            </div>
            <div className="d-inline-block align-self-end">
              <p
                key={test}
                className="location_nums m-0 rotatingText-adjective-small"
                style={{ color: color.taxtHeading, fontSize: "0.8rem" }}
              >
                {each_crop_data["Accepted Percent"]}%
              </p>
            </div>
          </div>
          <BorderLinearProgress
            variant="determinate"
            value={each_crop_data["Accepted Percent"]}
            colorAccept={color.green}
            colorReject={color.iconBackgroundgreen}
          />
        </div>
      )}
    </div>
  );
};

export default CropCard;
