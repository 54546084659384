import React from "react";
import "../../assets/styles/AllTickets.css";

const AllFilters = ({
  filters,
  handelResetAllBtn,
  handelDeleteFilterBtn,
  getUserFilters,
  showVisitstr,
  searchBoxStr,
  visitSearchBoxStr
}) => {
  return (
    <div className="container">
      <div className="filters-container ">
        <div className="filter-type d-flex align-items-center">
          <div className="chips  flex-center py-2 ">
            <span
              onClick={() => {
                // console.log("working");
                handelResetAllBtn();
              }}
            >
              Reset All
            </span>
          </div>
        </div>
        {Object.keys(filters)?.map((filterType, index) => (
          <div key={index} className="filter-type">
            <div className="chips-wrap">
              {filters[filterType].map((filter, filterIndex) => (
                <div key={filterIndex} className="chips">
                  <div className="chips-filter-div d-flex flex-row m-0 p-0">
                    <span className="inline-heading" title={filter}>
                      <div className="m-0 p-0 text-nowrap">
                        {(filterType === "machineNumber" &&
                          getUserFilters &&
                          `${filter} (${getUserFilters?.machines_data[filter]})`) ||
                          (filterType === "moreFilters" &&
                            getUserFilters &&
                            `${filter
                              .split("_")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}`) ||
                          filter}
                      </div>
                      <p className="text-nowrap">
                        {(filterType === "assignee" && "Support Persons") ||
                          (showVisitstr &&
                            filterType === "createdStartDate" &&
                            "visitStartFrom") ||
                          (showVisitstr &&
                            filterType === "createdEndDate" &&
                            "visitstartTo") ||
                          (showVisitstr &&
                            filterType === "resolvedStartDate" &&
                            "visitEndFrom") ||
                          (showVisitstr &&
                            filterType === "resolvedEndDate" &&
                            "visitEndTo") ||
                          (filterType === "option" &&
                            `${filterType} ${filterIndex + 1}`) ||
                          (filterType === "searchBox" && searchBoxStr) ||
                          (filterType === "visitSearchBox" && visitSearchBoxStr) ||
                          filterType}
                      </p>
                    </span>
                    <a
                      onClick={() => {
                        handelDeleteFilterBtn(filterType, filter);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                      </svg>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllFilters;
