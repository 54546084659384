import React, { useState, useEffect, useContext } from "react";
import ContextState from "../contextApi/ContextState";
import "../../assets/styles/CardModal.css";
import SendIcon from "@mui/icons-material/Send";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import FilesUpload from "../RaiseTicketForm/FilesUpload";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ModalError from "../error/ModalError";
import "react-image-lightbox/style.css";
import { LoadingButton } from "@mui/lab";
import ConfirmDeletionModal from "./ConfirmDeletionModal";

const AttachmentsContent = ({ data, reloadAttachmentsPage, refreshBtn,
  setRefreshBtn,
  setRefreshLoading, }) => {
  const theme = useTheme();
  const {
    fetchApi,
    lodingModalVisiblity,
    ticketStatus,
    openSnackbar,
    showLoadingModal,
    showModalError,
    showModalErrorPage,
  } = useContext(ContextState);
  const [edit, setEdit] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attachmentStatus, setAttachmentStatus] = useState(false);

  const handleReloadData = () => {
    setReloadData((prevState) => !prevState);
  };

  const { ticketId } = data;

  const [attachments, setAttachments] = useState([]);

  // const [lightboxOpen, setLightboxOpen] = useState(false);

  // const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  // delection modal state
  const [confirmDeletionState, setConfirmDeletionState] = useState({
    isOpen: false,
    modalType: "",
    ticketId: "",
    attachId: "",
  });

  // const openLightbox = (index) => {
  //   setSelectedImageIndex(index);
  //   // setLightboxOpen(true);
  // };

  let isMounted;

  const displayAttachments = async (ticketId, isMounted) => {
    try {
      let payload = {
        info_type: "attachments",
        ticket_id: ticketId,
      };
      showLoadingModal(true);
      
      if (refreshBtn) {
        setRefreshLoading(true);
      }

      const jsonData = await fetchApi(
        "get_ticket_info",
        "POST",
        payload,
        isMounted
      );
      if (jsonData.statusCode === 200 && jsonData && jsonData.data) {
        if (isMounted) {
          // console.log("attachments data received successfully:", jsonData.data.attachments)
          const attachmentsData = jsonData.data.attachments;
          setAttachments(attachmentsData);
          showModalErrorPage(null, null);
        }
      } else if (jsonData?.statusCode === 500 || jsonData?.statusCode === 401) {
        // openSnackbar(jsonData.data, "error")
        showModalErrorPage(jsonData.statusCode, jsonData.msg);
        // showLoadingModal(false);
      } else {
        showModalErrorPage(jsonData.statusCode, jsonData.msg);
      }
    } catch (error) {
      openSnackbar("some error occured!!!", "error");
    } finally {
      showLoadingModal(false);
      setRefreshLoading(false);
      setRefreshBtn(false);
    }
  };

  // function to convert the url to filesarraybuffer type
  const urlToFileArrayBuffer = async (fileUrl) => {
    try {
      // Fetch the file content as a blob
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      // Read the blob as an array buffer
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = () => {
          reject(new Error("Failed to read file"));
        };
        reader.readAsArrayBuffer(blob);
      });
    } catch (error) {
      console.error("Error fetching file:", error);
      return null;
    }
  };

  // function to upload the files and status
  const handleUpload = async () => {
    setLoading(true);
    console.log("attachment files:", genFields);

    let formData = {};
    const attachmentsData = await Promise.all(
      genFields.attachments.map(async (data) => {
        console.log(data);
        // Fetch array buffer from URL
        const arrayBuffer = await urlToFileArrayBuffer(data.objectURL);
        // const base64String = btoa(String.fromCharCode.apply(null, arrayBuffer));
        const uint8Array = new Uint8Array(arrayBuffer);

        console.log(arrayBuffer);
        // Convert array buffer to Blob
        const blob = new Blob([arrayBuffer]);
        // console.log("blob-", blob);
        // Return object with file name and FormData
        return {
          file_name: data.name,
          file_data: Array.from(uint8Array),
        };
      })
    );
    let stage;
    if (attachmentStatus) {
      stage = "resolved";
    } else {
      stage = "ongoing";
    }
    formData = {
      ticket_id: ticketId,
      stage: stage,
      attachment_data: attachmentsData,
    };

    // let isMounted = true;
    const response = await fetchApi(
      "add_ticket_attachment",
      "POST",
      formData,
      true
    );
    if (response && response.statusCode === 200) {
      console.log("files uploaded syccessfully", response.data);
      openSnackbar(response.data.message, "success");
      setGenFields((prevState) => ({
        ...prevState,
        attachments: [],
      }));
      setEdit(false);
      handleReloadData();
    } else if (response.statusCode === 500) {
      openSnackbar("Internal sever error!!! Try after some time.", "error");
    } else {
      openSnackbar(response.msg.message, "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    isMounted = true;
    if (isMounted) {
      displayAttachments(ticketId, isMounted);
    }

    return () => {
      isMounted = false;
    };
  }, [reloadData, reloadAttachmentsPage]);

  const [genFields, setGenFields] = useState({
    attachments: [],
  });
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // closing of modal
  const closeConfirmDelectionModal = (event, reason) => {
    if (reason && reason == "backdropClick") {
      // console.log("backdropClicked. Not closing dialog.");
      return;
    }
    setConfirmDeletionState((prev) => ({ ...prev, isOpen: false }));
  };

  return (
    <>
      {(!showModalError.status && !lodingModalVisiblity && (
        <>
          <div className="main-attachment-container ">
            <div className="m-3 ">
              <button
                type="button"
                className="btn btn-outline-primary markResolved_button"
                disabled={ticketStatus === "resolved"}
                onClick={() => setEdit(!edit)}
              >
                <Tooltip
                  data-html="true"
                  title={"Click here to add attachments"}
                  arrow={true}
                >
                  Add attachments
                </Tooltip>
              </button>
            </div>

            {edit && (
              <>
                <div className="attachments-section mx-3 mt-2 mb-4">
                  <FilesUpload
                    genFields={genFields}
                    setGenFields={setGenFields}
                    isSmallScreen={isSmallScreen}
                  />
                  {/* <div className="uploadButton pt-3"> */}
                  <FormControlLabel
                    className="d-block pb-2"
                    control={
                      <Checkbox
                        checked={attachmentStatus}
                        onChange={(e) => {
                          setAttachmentStatus(e.target.checked);
                          console.log(
                            "internet status block:",
                            e.target.checked
                          );
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Status (Resolved)"
                  />

                  <LoadingButton
                    size="large"
                    onClick={handleUpload}
                    endIcon={<SendIcon />}
                    loading={loading}
                    disabled={genFields?.attachments?.length === 0}
                    loadingPosition="end"
                    variant="contained"
                  >
                    <span>Add</span>
                  </LoadingButton>
                </div>
                {/* </div> */}
              </>
            )}

            <div className="show-attachments-content px-2">
              <header className="pb-2 previous-comments-header">
                Attachments
              </header>
              {(attachments?.length && (
                <div className="d-sm-flex flex-wrap gap-3">
                  {(attachments &&
                    attachments?.map((attachment, index) => {
                      if (attachment?.filetype) {
                        let fileUrl;
                        const fileType = attachment?.filetype?.toLowerCase();

                        if (["png", "jpg", "jpeg", "webp"].includes(fileType)) {
                          fileUrl = `data:image/${fileType};base64,${attachment?.data}`;
                        } else if (fileType === "pdf") {
                          fileUrl = `data:application/pdf;base64,${attachment.data}`;
                        } else if (["xlsx", "xls"].includes(fileType)) {
                          fileUrl = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${attachment.data}`;
                        } else if (fileType === "csv") {
                          fileUrl = `data:text/csv;base64,${attachment.data}`;
                        } else if (fileType === "mp3") {
                          fileUrl = `data:audio/mp3;base64,${attachment.data}`;
                        } else {
                          return null;
                        }

                        return (
                          <div
                            className="d-flex align-items-center data-variation-elements my-3 my-sm-0"
                            key={index}
                          >
                            <a
                              style={{ overflowWrap: "anywhere" }}
                              href={fileUrl}
                              download={`${attachment?.filename}`}
                            >
                              {attachment?.filename} ({attachment.stage})
                            </a>
                            {ticketStatus === "resolved" ? null : (
                              <div className="ms-auto">
                                <div
                                  className="dropdown ms-2  "
                                  style={{ cursor: "pointer" }}
                                >
                                  <Tooltip
                                    data-html="true"
                                    title={"Click to Delete this attachment"}
                                    arrow={true}
                                  >
                                    <i
                                      className="fa-solid fa-ellipsis-vertical  "
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      style={{ padding: "0px 3px" }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    ></i>
                                  </Tooltip>
                                  {/* Centered dropdown */}
                                  {/* </button> */}
                                  <ul className="dropdown-menu dropdown-menu-end delete-ticket-btn-border">
                                    <li>
                                      <a
                                        className="dropdown-item text-center delete-ticket-btn"
                                        href="#"
                                        onClick={() => {
                                          setConfirmDeletionState((prev) => ({
                                            isOpen: true,
                                            modalType: "tickets_attachment",
                                            ticketId: ticketId,
                                            attachId: attachment.id,
                                          }));
                                        }}
                                      >
                                        Delete
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })) ||
                    "No attachments added yet"}
                </div>
              )) ||
                "No attachments added"}
            </div>
          </div>
          <ConfirmDeletionModal
            deleteModalState={confirmDeletionState}
            handleClose={closeConfirmDelectionModal}
            handleReload={handleReloadData}
          />
        </>
      )) ||
        (showModalError.status && <ModalError />)}
    </>
  );
};

export default AttachmentsContent;
