import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, TableCell } from "@mui/material";
import { IoIosAdd } from "react-icons/io";
import AddIcon from "@mui/icons-material/Add";
import "../../../assets/styles/MoreDetailsdataTable.css";
import { useState } from "react";
import GroupSizesColors from "../ButtonGroup";
import { Modal, Typography } from "@mui/material";
import close from "../../../assets/images/close.svg";
export default function BasicGroupingDemo({ tableStatus, data }) {
  const buttons = [
    { label: "Parts Replaced", clicked: true },
    { label: "Person Visited", clicked: false },
  ];

  const [clickedButtonIndex, setClickedButtonIndex] = useState(0);
  const [tableData, setTableData] = useState({
    Part: true,
    Person: false,
  });

  // function to handle group button click
  const handleButtonClick = (index, label) => {
    setClickedButtonIndex(index);
    if (label === "Parts Replaced") {
      label = "Part";
    }
    if (label === "Person Visited") {
      label = "Person";
    }
    setTableData((prevState) => {
      const updatedState = {};
      for (const key in prevState) {
        updatedState[key] = key === label;
      }
      return updatedState;
    });
  };

  // current date
  const currentDate = new Date().toISOString().split("T")[0];

  // console.log("tableData", tableData);

  const [selectedRow, setSelectedRow] = React.useState(null);
  const [showMoreDetailsModal, setShowMoreDetailsModal] = React.useState(false);

  const handleOpen = () => {
    setShowMoreDetailsModal(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") {
      console.log("backdropClicked. Not closing dialog.");
      return;
    }
    setShowMoreDetailsModal(false);
    handleButtonClick(0, "Part");
  };

  let rows = [];
  let columns = [];
  if (tableStatus.Region && data.region_table_data) {
    rows = data.region_table_data;
    columns = data.region_columns;
  } else if (tableStatus.District && data.district_table_data) {
    rows = data.district_table_data;
    columns = data.district_columns;
  } else if (tableStatus.Machine && data.machine_table_data) {
    rows = data.machine_table_data;
    columns = data.machine_columns;
  }

  // console.log("rows, columns", rows, columns, data);

  return (
    <>
      <Box
        sx={{
          marginBottom: 5,
          width: "100%",
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "rgba(234, 235, 237, 0.95)",
            color: "black",
          },
          "& .MuiDataGrid-row": {
            backgroundColor: "white",
            color: "black",
          },
          // "& .MuiTablePagination-actions": {
          //   marginBottom: 2,
          // },
          // "& .MuiInputBase-root": {
          //   marginBottom: 0,
          // },
        }}
      >
        <DataGrid
          disableRowSelectionOnClick
          autoHeight
          rows={rows}
          initialState={{
            ...data.initialState,
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          pageSizeOptions={[25, 50, 100]}
          columns={[
            {
              field: "actions",
              headerName: "",
              flex: 1,
              maxWidth: 100,
              renderCell: (params) => (
                <TableCell align="center">
                  <IoIosAdd
                    onClick={() => {
                      setSelectedRow(params.row);
                      handleOpen();
                    }}
                    style={{ cursor: "pointer" }}
                    size={24}
                  />
                </TableCell>
              ),
            },
            ...columns,
          ]}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              csvOptions: {
                fileName: `Location_Insights_Data(${currentDate})`,
              },
              printOptions: {
                fileName: `Location_Insights_Data(${currentDate})`,
              },
            },
          }}
        />
      </Box>

      {/* modal to show detailed data  */}
      <Modal
        open={showMoreDetailsModal}
        onClose={handleClose}
        style={{
          position: "fixed",
          overflowY: "scroll",
          height: "100%",
        }}
      >
        <Box
          style={{
            position: "relative",
            right: "0",
            top: "10%",
            left: "0",
            margin: "  auto",
            overflowY: "auto",
            borderRadius: "10px",
            backgroundColor: "#ffffff",
            maxWidth: "1200px",
          }}
        >
          <Typography
            className="d-flex justify-content-between px-3 py-2"
            style={{ borderBottom: "1px solid #f5f5f5" }}
          >
            <p className="fs-5 mb-0">More Details</p>
            <div
              onClick={() => {
                handleClose();
              }}
            >
              <img
                src={close}
                alt="close"
                style={{ width: "20px", cursor: "pointer" }}
              />
            </div>
          </Typography>
          <Typography className="p-3">
            {selectedRow && (
              <>
                <div className="upper-card-basic-info moredetail-gridstyle mb-3">
                  {selectedRow &&
                    Object.entries(selectedRow).map(
                      ([key, value], i) =>
                        key !== "id" &&
                        key !== "detailed_data" && (
                          <p key={`${key}-${i}`} className="m-0">
                            <label
                              htmlFor={`outlined-read-only-input-${key}`}
                              className="basic_details_elements_label_data_table"
                            >
                              {key}
                            </label>{" "}
                            <div
                              id={`outlined-read-only-input-${key}`}
                              className="basic_details_elements_data_content"
                            >
                              {value}
                            </div>
                          </p>
                        )
                    )}
                </div>

                {/* buttons part  */}
                <div className="group-buttons p-0 m-0 mt-4">
                  <GroupSizesColors
                    buttons={buttons}
                    clickedButtonIndex={clickedButtonIndex}
                    handleButtonClick={handleButtonClick}
                  />
                </div>

                {Object.entries(selectedRow?.detailed_data).map(
                  ([key, value], index) =>
                    (tableData.Part && key === "part_replaced") ||
                    (tableData.Person && key === "person_visited") ? (
                      <div key={index}>
                        <Box
                          sx={{
                            marginBottom: 2,
                            marginTop: 2,
                            width: "100%",
                            "& .MuiDataGrid-columnHeader": {
                              // backgroundColor: "rgba(234, 235, 237, 0.95)",
                              color: "black",
                            },
                            // "& .MuiTablePagination-actions": {
                            //   marginBottom: 2,
                            // },
                            // "& .MuiDataGrid-root .MuiInputBase-root": {
                            //   marginBottom: 1.5,
                            // },
                          }}
                        >
                          <DataGrid
                            disableRowSelectionOnClick
                            autoHeight
                            rows={value?.map((row, index) => ({
                              id: index,
                              ...row,
                            }))}
                            initialState={{
                              ...data.initialState,
                              pagination: { paginationModel: { pageSize: 25 } },
                            }}
                            pageSizeOptions={[25, 50, 100]}
                            columns={data.issue_headers[key]}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                              toolbar: {
                                csvOptions: {
                                  fileName: `Location_Insights_More_Info(${currentDate})`,
                                },
                                printOptions: {
                                  fileName: `Location_Insights_More_Info(${currentDate})`,
                                },
                              },
                            }}
                          />
                        </Box>
                      </div>
                    ) : null
                )}
              </>
            )}
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
