import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useContext } from "react";
import ContextState from "../contextApi/ContextState";
import "../../assets/styles/CardModal.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(45, 45, 45, 0.89)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  // Add color based on status
  "&.pending": {
    color: "red",
  },
  "&.ongoing": {
    color: "blue",
  },
  "&.done": {
    color: "green",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TableWrapper = styled(TableContainer)(({ theme }) => ({
  maxHeight: "600px",
  overflowY: "auto",
}));

export default function ShowPullDataTable({ pullDataBackend }) {
  const { formatDate } = useContext(ContextState);
  const { tableColumns, pull_scan_ids } = pullDataBackend;

  // Remove the last element from tableColumns and each row in pull_scan_ids
  const modifiedColumns = tableColumns.slice(0, -1);
  const modifiedData = pull_scan_ids.map((row) => {
    //   console.log("row[4]: ",formatDate(row[4].split(".")[0] + " GMT"),",row[5]: " , formatDate(row[5].split(".")[0]+ " GMT"))
    row[4] = formatDate(row[4].split(".")[0] + " GMT");
    row[5] = formatDate(row[5].split(".")[0] + " GMT");
    row = row.slice(0, -1);
    return row;
  });
  // console.log("modifiedData: ", modifiedData);
  return (
    <TableWrapper component={Paper} className="pull-data-table-div">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              {modifiedColumns.map((column, index) => (
                <StyledTableCell key={index}>{column}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {modifiedData.map((row, rowIndex) => (
              <StyledTableRow key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <StyledTableCell
                    key={cellIndex}
                    align="left"
                    className={cell}
                  >
                    {cell}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
}
