import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ContextStateProvider } from "./Components/contextApi/ContextState";
import reportWebVitals from "./reportWebVitals";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Provider } from "react-redux";
import { store } from "./Components/app/store";

import {
  Provider as RollbarProvider,
  ErrorBoundary as RollbarErrorBoundary,
} from "@rollbar/react";
import { ErrorBoundary } from "react-error-boundary";
import Error from "./Components/error/Error";
import ContextError from "./Components/error/ContextError";
//  for sending error

const rollbarConfig = {
  accessToken: "ab3b846b6a8c46ac89011a6ea3838239",
  environment: "testenv",
};

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary
      FallbackComponent={({ error, resetError }) => {
        console.log("error : ", error);
        return <ContextError />;
      }}
    >
      <ContextStateProvider>
        <RollbarProvider config={rollbarConfig}>
          <RollbarErrorBoundary
            level="warn"
            errorMessage="React error in my app"
            extra={{ additional: "data" }}
            fallbackUI={() => {
              return <Error errorType={"rollBarError"} />;
            }}
          >
            <App />
          </RollbarErrorBoundary>
        </RollbarProvider>
      </ContextStateProvider>
    </ErrorBoundary>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
