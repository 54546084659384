import React, { useContext, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import "../../assets/styles/CardModal.css";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import VisitGeneralPage from "./VisitGeneralPage";

import { BeatLoader } from "react-spinners";
import ContextState from "../contextApi/ContextState";
import RefreshIcon from "@mui/icons-material/Refresh";
import Visits_attachments from "./VisitsAttachments";
import VisitsAttachments from "./VisitsAttachments";
import { Modal, Tooltip } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import { Margin } from "@mui/icons-material";

const VisitDetailModal = ({ isOpen, onClose, data, reloadData }) => {
  const {
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    closeSnackbar,
    lodingModalVisiblity,
    showModalErrorPage,
    showModalError,
  } = useContext(ContextState);
  const customStyles = {
    top: "0%",
    left: "0",
    right: "0",
    margin: "auto",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    minHeight: "95%",
    zIndex: 80,
  };

  const [reloadButtonClicked, setReloadButtonClicked] = useState({
    generalTab: false,
    attachmentsTab: false,
  });

  // this variable is maintained to find the current active tab
  const [activeTab, setActiveTab] = useState("#");

  // to get the sate of reloaded tab
  // const [reloadSameTab, setReloadSameTab] = useState(false);

  // this varibale is to animate the loading icon
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [refreshBtn, setRefreshBtn] = useState(false);

  useEffect(() => {
    setActiveTab("#");
  }, [isOpen]);

  useEffect(() => {
    if (!showModalError.status) {
      if (isOpen) {
        document.title = `${
          activeTab === "#" ? "Visit General Info" : "Attachmets"
        } - NEO`;
      } else {
        document.title = "All Visits - NEO";
      }
    }

    // console.log(showModalError);
    return () => {};
  }, [activeTab, isOpen, showModalError.status]);

  const reloadCorresponsingTab = () => {
    // console.log(reloadButtonClicked)
    setRefreshBtn(true);
    showModalErrorPage(null, null);
    if (activeTab === "#") {
      // console.log("button clciked");
      setReloadButtonClicked((prevState) => ({
        ...prevState,
        generalTab: !prevState.generalTab,
      }));
    } else {
      setReloadButtonClicked((prevState) => ({
        ...prevState,
        attachmentsTab: !prevState.attachmentsTab,
      }));
    }
  };

  const handleTabSelect = (eventKey) => {
    showModalErrorPage(null, null);
    if (activeTab === eventKey) {
      // setReloadSameTab(!reloadSameTab);
    } else {
      setActiveTab(eventKey);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={{
        position: "fixed",
        overflowY: "scroll",
        height: "100%",
        overflowX: "hidden",
        // zIndex: 80,
      }}
    >
      {/* Render specific data within the modal */}
      {data && (
        <>
          {/* <CardDetails/> */}
          <Box style={customStyles} className="col-11">
            <Box>
              <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
                message={snackbarMessage}
                severity={snackbarSeverity}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                action={
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={closeSnackbar}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              >
                <Alert
                  onClose={closeSnackbar}
                  severity={snackbarSeverity}
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  {snackbarMessage}
                </Alert>
              </Snackbar>
            </Box>
            {lodingModalVisiblity && (
              <div
                className="showSpinnerTicketModal"
                style={{ width: "90%", height: "88vh" }}
              >
                <BeatLoader color="#2D97D4" />
              </div>
            )}

            <div className="ModalHeader py-2">
              <Nav
                variant="tabs"
                defaultActiveKey="#"
                activeKey={activeTab}
                onSelect={handleTabSelect}
              >
                <Tooltip
                  data-html="true"
                  title={"General Visit Details Section"}
                  arrow={true}
                >
                  <Nav.Item>
                    <Nav.Link className="general " eventKey="#">
                      Visit Details
                    </Nav.Link>
                  </Nav.Item>
                </Tooltip>

                <Tooltip
                  data-html="true"
                  title={"Attachments Section"}
                  arrow={true}
                >
                  <Nav.Item>
                    <Nav.Link className="general " eventKey="link-1">
                      Attachments
                    </Nav.Link>
                  </Nav.Item>
                </Tooltip>
              </Nav>
              <div className="end-sections-header">
                {/* <div
                onClick={reloadCorresponsingTab}
              >
                Reload
              </div> */}
                <Tooltip
                  data-html="true"
                  title={"Refresh this section"}
                  arrow={true}
                >
                  <RefreshIcon
                    className={`reload-section ${refreshLoading ? "rotate": ""}`}
                    onClick={reloadCorresponsingTab}
                    fontSize=""
                  />
                </Tooltip>

                <Tooltip data-html="true" title={"Close"} arrow={true}>
                  <CloseIcon
                    className="reload-section "
                    onClick={onClose}
                    fontSize=""
                  />
                </Tooltip>
              </div>
            </div>
            <div className="cardContent ">
              {activeTab === "#" && (
                <VisitGeneralPage
                  data={data}
                  reloadAllTicketsData={reloadData}
                  setRefreshLoading={setRefreshLoading}
                  refreshBtn={refreshBtn}
                  setRefreshBtn={setRefreshBtn}
                  // reloadSameTab={reloadSameTab}
                  reloadGeneralPage={reloadButtonClicked.generalTab}
                />
              )}
              {activeTab === "link-1" && (
                <VisitsAttachments
                  data={data}
                  reloadAllTicketsData={reloadData}
                  setRefreshLoading={setRefreshLoading}
                  refreshBtn={refreshBtn}
                  setRefreshBtn={setRefreshBtn}
                  reloadAttachmentsPage={reloadButtonClicked.attachmentsTab}
                />
              )}
            </div>
          </Box>
        </>
      )}
      {/* <button onClick={onClose}>Close Modal</button> */}
    </Modal>
  );
};

export default VisitDetailModal;
