import React, { useRef, useEffect, useState } from "react";
// import { Dropdown } from 'react-bootstrap';
import "../../assets/styles/AllTickets.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Autocomplete, TextField, backdropClasses } from "@mui/material";

import PropTypes from "prop-types";
import { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import {
  Box,
  Tooltip,
  Typography,
  FormControl,
  Select,
  Slider,
  MenuItem,
  InputLabel,
} from "@mui/material";

import { Tooltip as MuiToolTip } from "@material-ui/core";

const Dropdown = ({
  blurTickets,
  filters,
  handleFilterChange,
  handleSaveChanges,
  handleFilterReset,
  filterCount,
  getUserFilters,
  filtersOptions,
  updateDateFilterName,
  updateTimestr,
  assigneeStr,
  showResolvedDate,
  dateStr,
  hideStartDate,
  showDataVariationFilters,
  handelDeleteFilterBtn,
  handelTempRemoveFilterBtn,
  handelAutoSelectFilterBtn,
  showParamDetailedFilter,
  hideParamDetailedFilters,
  startDateStr,
  pastOneMonth,
  pastThreeMonth,
  showSortbyDropdown,
  showStatus,
  searchBoxStr,
  visitsearchBoxStr,
}) => {
  // const access_level = "machine";
  // console.log("Drop Down Filters : ", filtersOptions);
  const [range, setRange] = React.useState([10000, 40000]);
  function handleSetRangeChanges(event, newValue) {
    setRange(newValue);
    handleFilterChange(newValue.join("-"), "totalExpense");
  }
  const [dropdownVisiblity, setdropdownVisiblity] = useState({
    status: false,
    issueType: false,
    subIssueType: false,
    RO: false,
    DO: false,
    machineNumber: false,
    Date: false,
    Sort: false,
    saveFilters: false,
    crop_variation: false,
    params_variation: false,
    assignee: false,
    totalExpense: false,
    moreFilters: false,
    time: false,
    partsReplaced: false,
    commitId: false,
    modelNo: false,
    branchName: false,
    compare: false,
    workingHours: false,
    reasone:false
  });
  const setDropdownVisibility = (selectedKey) => {
    setdropdownVisiblity((prevState) => {
      //   console.log("side key coming: ", selectedKey);
      // selectedKey = "headInstitution"
      // console.log("side key : ", selectedKey)
      const updatedState = { ...prevState };
      if (selectedKey === "ALL") {
        for (const key in updatedState) {
          updatedState[key] = false;
        }
      } else {
        for (const key in prevState) {
          if (key !== selectedKey) updatedState[key] = false;
        }
        updatedState[selectedKey] = !updatedState[selectedKey];
      }
      return updatedState;
    });
  };

  const initialBgColorState = {
    creationTimeAssending: false,
    creationTimeDesending: false,
    resolutionDateAssending: false,
    resolutionDateDesending: false,
    lastOneWeek: false,
    lastMonth: false,
  };

  // Dynamically add selectTime properties
  for (let i = 0; i <= 20; i++) {
    initialBgColorState[`selectTime${i}`] = false;
  }

  const [bgcolor, setbgcolor] = useState(initialBgColorState);

  const setBGcolors = (selectedOption) => {
    setbgcolor((prevbgcolor) => {
      let updateState = { ...prevbgcolor };
      // console.log(updateState[selectedOption]);

      for (const key in updateState) {
        if (key !== selectedOption) {
          updateState[key] = false;
        }
      }
      updateState[selectedOption] = !updateState[selectedOption];
      return updateState;
    });
  };

  const machineNumberWithLocation =
    filtersOptions?.machineNumber
      ?.filter((mNumber) => mNumber && getUserFilters.machines_data[mNumber])
      .map(
        (mNumber) => `${mNumber} (${getUserFilters.machines_data[mNumber]})`
      ) || [];

  // console.log("filtersOptions?.machineNumber : ", filtersOptions?.machineNumber);

  const [searchTerm, setSearchTerm] = useState({
    status: "",
    issueType: "",
    subIssueType: "",
    RO: "",
    DO: "",
    machineNumber: "",
    params_variation: "",
    crop_variation: "",
    assignee: "",
    searchBox: "",
    visitSearchBox: "",
    totalExpense: "",
    partsReplaced: "",
    commitId: "",
    sort: "",
    modelNo: "",
    branchName: "",
    workingHours: "",
    scanIDSearchBox: "",
    reasone:""
  });

  const handleSearch = (e, filterType) => {
    const value = e.target.value;
    setSearchTerm((prevSearchTerm) => ({
      ...prevSearchTerm,
      [filterType]: value,
    }));
  };

  const handleReset = (filtername) => {
    handleFilterReset(filtername);
    setSearchTerm((searchTerm) => {
      const updatesearchTerm = { ...searchTerm };
      updatesearchTerm[filtername] = "";
      return updatesearchTerm;
    });
    // console.log("sendFilters->  ", searchTerm, filters);
  };

  const applyPaddingLeftFilterBackground = useMediaQuery("(max-width:576px)");

  useEffect(() => {
    let show = false;
    for (const key in dropdownVisiblity) {
      if (dropdownVisiblity[key]) {
        show = true;
        break;
      }
    }
    blurTickets(show);
    // sendFilters(filters);
  }, [dropdownVisiblity, blurTickets]);

  function getDateInfo() {
    const currentDate = new Date();

    // Today's date
    const today = currentDate.toISOString().split("T")[0];

    // Yesterday's date
    const yesterdayDate = new Date(currentDate.getTime() - 86400000)
      .toISOString()
      .split("T")[0];

    const fullYear = currentDate.getFullYear();
    const Month = currentDate.getMonth();

    // Start date of the current month
    const currentMonthStartDate = new Date(fullYear, Month, 2)
      .toISOString()
      .split("T")[0];

    // End date of the previous month
    const previousMonthEndDate = new Date(fullYear, Month, 1)
      .toISOString()
      .split("T")[0];

    // Start date of the previous month
    const previousMonthStartDate = new Date(fullYear, Month - 1, 2, 0, 0, 0, 0)
      .toISOString()
      .split("T")[0];

    // three months start date and three months end date
    const threeMonthsAgoMonth = (Month - 3 + 12) % 12;
    // console.log(threeMonthsAgoMonth);
    const threeMonthsAgoYear =
      threeMonthsAgoMonth > Month ? fullYear - 1 : fullYear;
    const threeMonthsStartDate = new Date(
      threeMonthsAgoYear,
      threeMonthsAgoMonth,
      2
    )
      .toISOString()
      .split("T")[0];

    // Start date of the month before the previous month
    const monthBeforePreviousStartDate = new Date(
      fullYear,
      Month - 3,
      2,
      0,
      0,
      0,
      0
    )
      .toISOString()
      .split("T")[0];
    // End date of the month before the previous month
    const monthBeforePreviousEndDate = new Date(fullYear, Month - 1, 1)
      .toISOString()
      .split("T")[0];

    const month12SatrtDate = new Date(fullYear, Month - 12, 2)
      .toISOString()
      .split("T")[0];

    const thirtyDaysBefore = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 28
    )
      .toISOString()
      .split("T")[0];

    const ninetyDaysBefore = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 88
    )
      .toISOString()
      .split("T")[0];

    // dynamic keys for past month && past 30 days
    const oneMonthKey = pastOneMonth ? pastOneMonth : "Past 30 days";

    // dynamic values for last month and last 30days
    const oneMonthValues = pastOneMonth
      ? [previousMonthStartDate, previousMonthEndDate]
      : [thirtyDaysBefore, today];

    // dynamic keys for past 90 days && past 3 months
    const threeMonthKey = pastThreeMonth ? pastThreeMonth : "Past 90 days";
    const threeMonthValues = pastThreeMonth
      ? [threeMonthsStartDate, previousMonthEndDate]
      : [ninetyDaysBefore, today];
    return {
      Today: [today, today],
      Yesterday: [yesterdayDate, yesterdayDate],
      "This Month": [currentMonthStartDate, today],
      [oneMonthKey]: oneMonthValues,
      [threeMonthKey]: threeMonthValues,
    };
  }

  const getDate = getDateInfo();
  // console.log("date data ", getDate);

  const IOSSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.mode === "dark" ? "#0a84ff" : "#007bff",
    height: 5,
    padding: "15px 0",
    "& .MuiSlider-thumb": {
      height: 20,
      width: 20,
      backgroundColor: "#fff",
      boxShadow: "0 0 2px 0px rgba(0, 0, 0, 0.1)",
      "&:focus, &:hover, &.Mui-active": {
        boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.1)",
        "@media (hover: none)": {
          boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.1)",
        },
      },
      "&:before": {
        boxShadow:
          "0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)",
      },
    },
    "& .MuiSlider-valueLabel": {
      fontSize: 12,
      fontWeight: "normal",
      top: -6,
      backgroundColor: "unset",
      color: theme.palette.text.primary,
      "&::before": {
        display: "none",
      },
      "& *": {
        background: "transparent",
        color: theme.palette.mode === "dark" ? "#fff" : "#000",
      },
    },
    "& .MuiSlider-track": {
      border: "none",
      height: 5,
    },
    "& .MuiSlider-rail": {
      opacity: 0.5,
      boxShadow: "inset 0px 0px 4px -2px #000",
      backgroundColor: "#d0d0d0",
    },
  }));

  const [rangeSelector, setRangeSelector] = useState({});

  const handelRangeSelector = (filterKey, filterRangeList) => {
    setRangeSelector((prevRange) => {
      const updatePervRange = { ...prevRange };
      // console.log("before ")
      updatePervRange[filterKey] = filterRangeList;
      return updatePervRange;
    });
  };

  const CustomValueLabel = (props) => {
    const { children, open, value } = props;

    return (
      <MuiToolTip
        open={open}
        enterTouchDelay={0}
        placement="top"
        title={`${value.toFixed(3)}`}
      >
        {children}
      </MuiToolTip>
    );
  };

  //
  const [dropDownsWidth, setDropDownsWidth] = useState(0);
  useEffect(() => {
    const handleWidth = () => {
      const initialWidth = document.getElementById("divWidth").offsetWidth;

      setDropDownsWidth((prev) => initialWidth);
    };

    window.addEventListener("resize", handleWidth);

    handleWidth();

    return () => {
      window.removeEventListener("resize", handleWidth);
    };
  }, []);
  const isDataVariation = filters?.issueType
    ?.join(" ")
    .includes("Data Variation");

  return (
    <>
      <div
        className="filters-background d-flex justify-content-center flex-row"
        id="divWidth"
      >
        <div className="container d-flex align-items-baseline justify-content-between py-1">
          <div className="filters-background-left d-flex gap-3  pt-1">
            {/* Ro Filter */}
            {getUserFilters &&
              getUserFilters.access_level === "institution" &&
              filtersOptions?.RO?.length !== 0 && (
                <div>
                  <button
                    className={`btn ${filterCount.RO ? "filter-selected" : ""}
          ${
            dropdownVisiblity.RO
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                    type="button"
                    id="dropdownMenuButton"
                    aria-expanded="false"
                    onClick={() => {
                      // handleSaveChanges("RO");
                      setDropdownVisibility("RO");
                    }}
                  >
                    Region{filterCount.RO ? ` (${filterCount.RO})` : ""}
                  </button>
                  <div className="">
                    {dropdownVisiblity.RO && (
                      <div
                        className="filter-select-dropdown "
                        // style={{
                        //   paddingLeft: applyPaddingLeftFilterBackground
                        //     ? "50px"
                        //     : "0px",
                        // }}
                      >
                        <div className="modal-header mx-4 my-3">
                          <h3 className="modal-title  " id="exampleModalLabel">
                            Region
                            {filterCount.RO ? ` (${filterCount.RO})` : ""}
                          </h3>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                              setDropdownVisibility("RO");
                              // handleSaveChanges("RO");
                            }}
                          ></button>
                        </div>
                        <ul
                          className="px-4 list-style-none"
                          aria-labelledby=""
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <li>
                            <div className="input-group mb-2">
                              <span
                                className="search-icon-bg input-group-text"
                                id="basic-addon1"
                              >
                                <i className="fa-solid fa-magnifying-glass"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                // ref={inputRefs.RO}
                                value={searchTerm.RO}
                                onChange={(e) => {
                                  handleSearch(e, "RO");
                                  //applyFilterss();
                                }}
                              />
                            </div>
                          </li>
                          {filtersOptions.RO.filter(
                            (item) =>
                              item
                                .toLowerCase()
                                .includes(searchTerm.RO.toLowerCase()) ||
                              searchTerm.RO === ""
                          ).map((item, index) => (
                            <li key={index}>
                              <div className="dropdown-item" href="#">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id={`Checkme${index}`}
                                    checked={
                                      filters.RO && filters.RO.includes(item)
                                    }
                                    onChange={() => {
                                      handleFilterChange(item, "RO");
                                      //applyFilterss();
                                      // console.log("RO filter : ", filters.RO);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`Checkme${index}`}
                                  >
                                    {item}
                                  </label>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                        <hr />
                        <div className="filters-modal-footer px-3 mb-2">
                          <button
                            type="button"
                            className="btn mx-1"
                            onClick={() => {
                              handleReset("RO");
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            // className="btn px-2 btn-primary apply-filter-btn"
                            className="btn px-2 btn-primary"
                            onClick={() => {
                              handleSaveChanges("RO");
                              setDropdownVisibility("RO");
                              //applyFilterss();
                            }}
                          >
                            Apply changes
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

            {/* Do Filter */}
            {getUserFilters &&
              (getUserFilters.access_level === "region" ||
                getUserFilters.access_level === "institution") &&
              filtersOptions?.DO?.length !== 0 && (
                <div>
                  <button
                    className={`btn ${filterCount.DO ? "filter-selected" : ""}
          ${
            dropdownVisiblity.DO
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                    type="button"
                    id="dropdownMenuButton"
                    aria-expanded="false"
                    onClick={() => {
                      setDropdownVisibility("DO");
                      // handleSaveChanges("DO");
                    }}
                  >
                    District{filterCount.DO ? ` (${filterCount.DO})` : ""}
                  </button>
                  <div className="">
                    {dropdownVisiblity.DO && (
                      <div
                        className="filter-select-dropdown "
                        // style={{
                        //   paddingLeft: applyPaddingLeftFilterBackground
                        //     ? "50px"
                        //     : "0px",
                        // }}
                      >
                        <div className="modal-header mx-4 my-3">
                          <h3 className="modal-title  " id="exampleModalLabel">
                            District
                            {filterCount.DO ? ` (${filterCount.DO})` : ""}
                          </h3>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                              setDropdownVisibility("DO");
                              // handleSaveChanges("DO");
                            }}
                          ></button>
                        </div>
                        <ul
                          className="px-4 list-style-none"
                          aria-labelledby=""
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <li>
                            <div className="input-group mb-2">
                              <span
                                className="search-icon-bg input-group-text"
                                id="basic-addon1"
                              >
                                <i className="fa-solid fa-magnifying-glass"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                // ref={inputRefs.DO}
                                value={searchTerm.DO}
                                onChange={(e) => {
                                  handleSearch(e, "DO");
                                  //applyFilterss();
                                }}
                              />
                            </div>
                          </li>
                          {filtersOptions.DO.filter(
                            (item) =>
                              item
                                .toLowerCase()
                                .includes(searchTerm.DO.toLowerCase()) ||
                              searchTerm.DO === ""
                          ).map((item, index) => (
                            <li key={index}>
                              <div className="dropdown-item" href="#">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id={`Checkme${index}`}
                                    checked={
                                      filters.DO && filters.DO.includes(item)
                                    }
                                    onChange={() => {
                                      handleFilterChange(item, "DO");
                                      //applyFilterss();
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`Checkme${index}`}
                                  >
                                    {item}
                                  </label>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                        <hr />
                        <div className="filters-modal-footer px-3 mb-2">
                          <button
                            type="button"
                            className="btn mx-1"
                            onClick={() => {
                              handleReset("DO");
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            // className="btn px-2 btn-primary apply-filter-btn"
                            className="btn px-2 btn-primary"
                            onClick={() => {
                              handleSaveChanges("DO");
                              setDropdownVisibility("DO");
                            }}
                          >
                            Apply changes
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

            {/* machineNumber Filter */}
            {getUserFilters &&
              (getUserFilters?.access_level === "district" ||
                getUserFilters?.access_level === "region" ||
                getUserFilters?.access_level === "institution") &&
              filtersOptions?.machineNumber?.length !== 0 && (
                <div>
                  <button
                    className={`btn ${
                      filterCount?.machineNumber ? "filter-selected" : ""
                    }
          ${
            dropdownVisiblity.machineNumber
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                    type="button"
                    id="dropdownMenuButton"
                    aria-expanded="false"
                    onClick={() => {
                      // handleSaveChanges("machineNumber");
                      setDropdownVisibility("machineNumber");
                    }}
                  >
                    Machine Number
                    {filterCount?.machineNumber
                      ? ` (${filterCount.machineNumber})`
                      : ""}
                  </button>
                  <div className="">
                    {dropdownVisiblity.machineNumber && (
                      <div
                        className="filter-select-dropdown "
                        // style={{
                        //   paddingLeft: applyPaddingLeftFilterBackground
                        //     ? "50px"
                        //     : "0px",
                        // }}
                      >
                        <div className="modal-header mx-4 my-3">
                          <h3 className="modal-title  " id="exampleModalLabel">
                            Machine Number
                            {filterCount?.machineNumber
                              ? ` (${filterCount.machineNumber})`
                              : ""}
                          </h3>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                              setDropdownVisibility("machineNumber");
                              // handleSaveChanges("machineNumber");
                            }}
                          ></button>
                        </div>
                        <ul
                          className="px-4 list-style-none"
                          aria-labelledby=""
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <li>
                            <div className="input-group mb-2">
                              <span
                                className="search-icon-bg input-group-text"
                                id="basic-addon1"
                              >
                                <i className="fa-solid fa-magnifying-glass"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                value={searchTerm.machineNumber}
                                onChange={(e) => {
                                  handleSearch(e, "machineNumber");
                                  //applyFilterss();
                                }}
                              />
                            </div>
                          </li>
                          {machineNumberWithLocation
                            .filter(
                              (item) =>
                                item
                                  .toLowerCase()
                                  .includes(
                                    searchTerm.machineNumber.toLowerCase()
                                  ) || searchTerm.machineNumber === ""
                            )
                            .map((item, index) => (
                              <li key={index}>
                                <div className="dropdown-item" href="#">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id={`Checkme${index}`}
                                      checked={filters?.machineNumber?.includes(
                                        item.match(/\d+/)[0]
                                      )}
                                      onChange={() => {
                                        handleFilterChange(
                                          item.match(/\d+/)[0],
                                          "machineNumber"
                                        );
                                        //applyFilterss();
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`Checkme${index}`}
                                    >
                                      {item}
                                    </label>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                        <hr />
                        <div className="filters-modal-footer px-3 mb-2">
                          <button
                            type="button"
                            className="btn mx-1"
                            onClick={() => {
                              handleReset("machineNumber");
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            // className="btn px-2 btn-primary apply-filter-btn"
                            className="btn px-2 btn-primary"
                            onClick={() => {
                              handleSaveChanges("machineNumber");
                              setDropdownVisibility("machineNumber");
                            }}
                          >
                            Apply changes
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            {/* Assignee */}
            {filtersOptions?.assignee &&
              filtersOptions?.assignee?.length !== 0 && (
                <div>
                  <button
                    className={`btn ${
                      filterCount.assignee ? "filter-selected" : ""
                    }
          ${
            dropdownVisiblity.assignee
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                    type="button"
                    id="dropdownMenuButton"
                    aria-expanded="false"
                    onClick={() => {
                      setDropdownVisibility("assignee");
                      // handleSaveChanges("status");
                    }}
                  >
                    {assigneeStr ? assigneeStr : "Assignee"}
                    {filterCount.assignee ? ` (${filterCount.assignee})` : ""}
                  </button>
                  <div className="">
                    {dropdownVisiblity.assignee && (
                      <div
                        className="filter-select-dropdown "
                        // style={{
                        //   paddingLeft: applyPaddingLeftFilterBackground
                        //     ? "50px"
                        //     : "0px",
                        // }}
                      >
                        <div className="modal-header mx-4 my-3">
                          <h3 className="modal-title  " id="exampleModalLabel">
                            {assigneeStr ? assigneeStr : "Assignee"}
                            {filterCount.assignee
                              ? ` (${filterCount.assignee})`
                              : ""}
                          </h3>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                              // handleSaveChanges("assignee");
                              setDropdownVisibility("assignee");
                            }}
                          ></button>
                        </div>
                        <ul
                          className="px-4 list-style-none"
                          aria-labelledby=""
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <li>
                            <div className="input-group mb-2">
                              <span
                                className="search-icon-bg input-group-text"
                                id="basic-addon1"
                              >
                                <i className="fa-solid fa-magnifying-glass"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                // ref={inputRefs.assignee}
                                value={searchTerm.assignee}
                                onChange={(e) => {
                                  handleSearch(e, "assignee");
                                  //applyFilterss();
                                }}
                              />
                            </div>
                          </li>
                          {filtersOptions?.assignee
                            ?.filter(
                              (item) =>
                                (item &&
                                  item
                                    .toLowerCase()
                                    .includes(
                                      searchTerm.assignee.toLowerCase()
                                    )) ||
                                searchTerm.assignee === ""
                            )
                            .map((item, index) => (
                              <li key={index}>
                                <div className="dropdown-item" href="#">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id={`Checkme${index}`}
                                      checked={
                                        filters.assignee &&
                                        filters.assignee.includes(item)
                                      }
                                      onChange={() => {
                                        handleFilterChange(item, "assignee");
                                        //applyFilterss();
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`Checkme${index}`}
                                    >
                                      {item}
                                    </label>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                        <hr />
                        <div className="filters-modal-footer px-3 mb-2">
                          <button
                            type="button"
                            className="btn mx-1"
                            data-bs-dismiss=""
                            onClick={() => {
                              handleReset("assignee");
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            // className="btn px-2 btn-primary apply-filter-btn"
                            className="btn px-2 btn-primary"
                            onClick={() => {
                              handleSaveChanges("assignee");
                              setDropdownVisibility("assignee");
                            }}
                          >
                            Apply changes
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

            {/* Total Expense  */}
            {filtersOptions?.totalExpense &&
              filtersOptions?.totalExpense?.length !== 0 && (
                <div>
                  <button
                    className={`btn ${
                      filterCount.totalExpense ? "filter-selected" : ""
                    }
          ${
            dropdownVisiblity.totalExpense
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                    type="button"
                    id="dropdownMenuButton"
                    aria-expanded="false"
                    onClick={() => {
                      setDropdownVisibility("totalExpense");
                      // handleSaveChanges("status");
                    }}
                  >
                    Total Expense
                    {filterCount.totalExpense
                      ? ` (${filterCount.totalExpense})`
                      : ""}
                  </button>
                  <div className="">
                    {dropdownVisiblity.totalExpense && (
                      <div
                        className="filter-select-dropdown "
                        // style={{
                        //   paddingLeft: applyPaddingLeftFilterBackground
                        //     ? "50px"
                        //     : "0px",
                        // }}
                      >
                        <div className="modal-header mx-4 my-3">
                          <h3 className="modal-title  " id="exampleModalLabel">
                            Total Expense
                            {filterCount.totalExpense
                              ? ` (${filterCount.totalExpense})`
                              : ""}
                          </h3>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                              // handleSaveChanges("status");
                              setDropdownVisibility("totalExpense");
                            }}
                          ></button>
                        </div>
                        <ul
                          className="px-4 list-style-none"
                          aria-labelledby=""
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <li>
                            <div className="input-group mb-2">
                              <span
                                className="search-icon-bg input-group-text"
                                id="basic-addon1"
                              >
                                <i className="fa-solid fa-magnifying-glass"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                // ref={inputRefs.status}
                                value={searchTerm.totalExpense}
                                onChange={(e) => {
                                  handleSearch(e, "totalExpense");
                                  //applyFilterss();
                                }}
                              />
                            </div>
                          </li>

                          {filtersOptions.totalExpense
                            .filter(
                              (item) =>
                                item
                                  .toLowerCase()
                                  .includes(
                                    searchTerm.totalExpense.toLowerCase()
                                  ) || searchTerm.totalExpense === ""
                            )
                            .map((item, index) => (
                              <li key={index}>
                                <div className="dropdown-item" href="#">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id={`Checkme${index}`}
                                      checked={
                                        filters.totalExpense &&
                                        filters.totalExpense.includes(item)
                                      }
                                      onChange={() => {
                                        handleFilterChange(
                                          item,
                                          "totalExpense"
                                        );
                                        //applyFilterss();
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`Checkme${index}`}
                                    >
                                      {item}
                                    </label>
                                  </div>
                                </div>
                              </li>
                            ))}
                          <li>
                            <div style={{ width: "100%", padding: "10px" }}>
                              <Slider
                                value={range}
                                onChange={handleSetRangeChanges}
                                min={0}
                                max={50000}
                                step={500}
                                valueLabelDisplay="auto"
                              />
                            </div>
                          </li>
                        </ul>
                        <hr />
                        <div className="filters-modal-footer px-3 mb-2">
                          <button
                            type="button"
                            className="btn mx-1"
                            data-bs-dismiss=""
                            onClick={() => {
                              handleReset("totalExpense");
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            // className="btn px-2 btn-primary apply-filter-btn"
                            className="btn px-2 btn-primary"
                            onClick={() => {
                              handleSaveChanges("totalExpense");
                              setDropdownVisibility("totalExpense");
                            }}
                          >
                            Apply changes
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            {/* partsReplaced */}
            {filtersOptions?.partsReplaced &&
              filtersOptions?.partsReplaced?.length !== 0 && (
                <div>
                  <button
                    className={`btn ${
                      filterCount.partsReplaced ? "filter-selected" : ""
                    }
          ${
            dropdownVisiblity.partsReplaced
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                    type="button"
                    id="dropdownMenuButton"
                    aria-expanded="false"
                    onClick={() => {
                      setDropdownVisibility("partsReplaced");
                      // handleSaveChanges("status");
                    }}
                  >
                    Part Replaced
                    {filterCount.partsReplaced
                      ? ` (${filterCount.partsReplaced})`
                      : ""}
                  </button>
                  <div className="">
                    {dropdownVisiblity.partsReplaced && (
                      <div
                        className="filter-select-dropdown "
                        // style={{
                        //   paddingLeft: applyPaddingLeftFilterBackground
                        //     ? "50px"
                        //     : "0px",
                        // }}
                      >
                        <div className="modal-header mx-4 my-3">
                          <h3 className="modal-title  " id="exampleModalLabel">
                            Part Replaced
                            {filterCount.partsReplaced
                              ? ` (${filterCount.partsReplaced})`
                              : ""}
                          </h3>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                              // handleSaveChanges("status");
                              setDropdownVisibility("partsReplaced");
                            }}
                          ></button>
                        </div>
                        <ul
                          className="px-4 list-style-none"
                          aria-labelledby=""
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <li>
                            <div className="input-group mb-2">
                              <span
                                className="search-icon-bg input-group-text"
                                id="basic-addon1"
                              >
                                <i className="fa-solid fa-magnifying-glass"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                // ref={inputRefs.status}
                                value={searchTerm.partsReplaced}
                                onChange={(e) => {
                                  handleSearch(e, "partsReplaced");
                                  //applyFilterss();
                                }}
                              />
                            </div>
                          </li>
                          {filtersOptions.partsReplaced
                            .filter(
                              (item) =>
                                item
                                  .toLowerCase()
                                  .includes(
                                    searchTerm.partsReplaced.toLowerCase()
                                  ) || searchTerm.partsReplaced === ""
                            )
                            .map((item, index) => (
                              <li key={index}>
                                <div className="dropdown-item" href="#">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id={`Checkme${index}`}
                                      checked={
                                        filters.partsReplaced &&
                                        filters.partsReplaced.includes(item)
                                      }
                                      onChange={() => {
                                        handleFilterChange(
                                          item,
                                          "partsReplaced"
                                        );
                                        //applyFilterss();
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`Checkme${index}`}
                                    >
                                      {item}
                                    </label>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                        <hr />
                        <div className="filters-modal-footer px-3 mb-2">
                          <button
                            type="button"
                            className="btn mx-1"
                            data-bs-dismiss=""
                            onClick={() => {
                              handleReset("partsReplaced");
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            // className="btn px-2 btn-primary apply-filter-btn"
                            className="btn px-2 btn-primary"
                            onClick={() => {
                              handleSaveChanges("partsReplaced");
                              setDropdownVisibility("partsReplaced");
                            }}
                          >
                            Apply changes
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

            {/* issueType Filter */}
            {filtersOptions?.issueType &&
              filtersOptions?.issueType?.length !== 0 && (
                <div>
                  <button
                    className={`btn  ${
                      filterCount.issueType ? "filter-selected" : ""
                    }
          ${
            dropdownVisiblity.issueType
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                    type="button"
                    id="dropdownMenuButton"
                    aria-expanded="false"
                    onClick={() => {
                      setDropdownVisibility("issueType");
                    }}
                  >
                    IssueType
                    {filterCount.issueType ? ` (${filterCount.issueType})` : ""}
                  </button>
                  <div className="">
                    {dropdownVisiblity.issueType && (
                      <div
                        className="filter-select-dropdown "
                        // style={{
                        //   paddingLeft: applyPaddingLeftFilterBackground
                        //     ? "50px"
                        //     : "0px",
                        // }}
                      >
                        <div className="modal-header mx-4 my-3">
                          <h3 className="modal-title  " id="exampleModalLabel">
                            IssueType
                            {filterCount.issueType
                              ? ` (${filterCount.issueType})`
                              : ""}
                          </h3>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                              // handleSaveChanges("issueType");
                              setDropdownVisibility("issueType");
                            }}
                          ></button>
                        </div>
                        <ul
                          className="px-4 list-style-none"
                          aria-labelledby=""
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <li>
                            <div className="input-group mb-2">
                              <span
                                className="search-icon-bg input-group-text"
                                id="basic-addon1"
                              >
                                <i className="fa-solid fa-magnifying-glass"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                // ref={inputRefs.issueType}
                                value={searchTerm.issueType}
                                onChange={(e) => {
                                  handleSearch(e, "issueType");
                                  //applyFilterss();
                                }}
                              />
                            </div>
                          </li>
                          {filtersOptions?.issueType
                            ?.filter(
                              (item) =>
                                item
                                  .toLowerCase()
                                  .includes(
                                    searchTerm.issueType.toLowerCase()
                                  ) || searchTerm.issueType === ""
                            )
                            .map((item, index) => (
                              <li key={index}>
                                <div className="dropdown-item" href="#">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id={`Checkme${index}`}
                                      checked={
                                        filters.issueType &&
                                        filters.issueType.includes(item)
                                      }
                                      onChange={() => {
                                        handleFilterChange(item, "issueType");
                                        //applyFilterss();
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`Checkme${index}`}
                                    >
                                      {item}
                                    </label>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                        <hr />
                        <div className="filters-modal-footer px-3 mb-2">
                          <button
                            type="button"
                            className="btn mx-1"
                            data-bs-dismiss=""
                            onClick={() => {
                              handleReset("issueType");
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            // className="btn px-2 btn-primary apply-filter-btn"
                            className="btn px-2 btn-primary"
                            onClick={() => {
                              setDropdownVisibility("issueType");
                              handleSaveChanges("issueType");
                            }}
                          >
                            Apply changes
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            {/* Sub issueType Filter */}
            {filtersOptions?.subIssueType?.length !== 0 &&
              filters?.issueType.length !== 0 && (
                <div>
                  <button
                    className={`btn  ${
                      filterCount.subIssueType ? "filter-selected" : ""
                    }
          ${
            dropdownVisiblity.subIssueType
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                    type="button"
                    id="dropdownSubIssueButton"
                    aria-expanded="false"
                    onClick={() => {
                      setDropdownVisibility("subIssueType");
                    }}
                  >
                    SubIssueType
                    {filterCount.subIssueType
                      ? ` (${filterCount.subIssueType})`
                      : ""}
                  </button>
                  <div className="">
                    {dropdownVisiblity.subIssueType && (
                      <div
                        className="filter-select-dropdown "
                        // style={{
                        //   paddingLeft: applyPaddingLeftFilterBackground
                        //     ? "50px"
                        //     : "0px",
                        // }}
                      >
                        <div className="modal-header mx-4 my-3">
                          <h3 className="modal-title  " id="exampleModalLabel">
                            SubIssueType
                            {filterCount.subIssueType
                              ? ` (${filterCount.subIssueType})`
                              : ""}
                          </h3>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                              setDropdownVisibility("subIssueType");
                              // handleSaveChanges("subIssueType");
                            }}
                          ></button>
                        </div>
                        <ul
                          className="px-4 list-style-none"
                          aria-labelledby=""
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <li>
                            <div className="input-group mb-2">
                              <span
                                className="search-icon-bg input-group-text"
                                id="basic-addon1"
                              >
                                <i className="fa-solid fa-magnifying-glass"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                // ref={inputRefs.issueType}
                                value={searchTerm.subIssueType}
                                onChange={(e) => {
                                  handleSearch(e, "subIssueType");
                                  //applyFilterss();
                                }}
                              />
                            </div>
                          </li>
                          {filtersOptions.subIssueType
                            .filter(
                              (item) =>
                                item
                                  .toLowerCase()
                                  .includes(
                                    searchTerm.subIssueType.toLowerCase()
                                  ) || searchTerm.subIssueType === ""
                            )
                            .map((item, index) => (
                              <li key={index}>
                                <div className="dropdown-item" href="#">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id={`Checkme${index}`}
                                      checked={
                                        filters.subIssueType &&
                                        filters.subIssueType.includes(item)
                                      }
                                      onChange={() => {
                                        handleFilterChange(
                                          item,
                                          "subIssueType"
                                        );
                                        //applyFilterss();
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`Checkme${index}`}
                                    >
                                      {item}
                                    </label>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                        <hr />
                        <div className="filters-modal-footer px-3 mb-2">
                          <button
                            type="button"
                            className="btn mx-1"
                            data-bs-dismiss=""
                            onClick={() => {
                              handleReset("subIssueType");
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            // className="btn px-2 btn-primary apply-filter-btn"
                            className="btn px-2 btn-primary"
                            onClick={() => {
                              setDropdownVisibility("subIssueType");
                              handleSaveChanges("subIssueType");
                            }}
                          >
                            Apply changes
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            {/* Status */}
            {((showStatus &&
              filtersOptions?.status &&
              filtersOptions?.status?.length !== 0) ||
              (filters?.issueType &&
                filters?.issueType?.length !== 0 &&
                filtersOptions?.status &&
                filtersOptions?.status?.length !== 0)) && (
              <div>
                <button
                  className={`btn ${filterCount.status ? "filter-selected" : ""}
          ${
            dropdownVisiblity.status
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                  type="button"
                  id="dropdownMenuButton"
                  aria-expanded="false"
                  onClick={() => {
                    setDropdownVisibility("status");
                    // handleSaveChanges("status");
                  }}
                >
                  Status
                  {filterCount.status ? ` (${filterCount.status})` : ""}
                </button>
                <div className="">
                  {dropdownVisiblity.status && (
                    <div
                      className="filter-select-dropdown "
                      // style={{
                      //   paddingLeft: applyPaddingLeftFilterBackground
                      //     ? "50px"
                      //     : "0px",
                      // }}
                    >
                      <div className="modal-header mx-4 my-3">
                        <h3 className="modal-title  " id="exampleModalLabel">
                          Status
                          {filterCount.status ? ` (${filterCount.status})` : ""}
                        </h3>
                        <button
                          type="button"
                          className="btn-close"
                          aria-label="Close"
                          onClick={() => {
                            // handleSaveChanges("status");
                            setDropdownVisibility("status");
                          }}
                        ></button>
                      </div>
                      <ul
                        className="px-4 list-style-none"
                        aria-labelledby=""
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <li>
                          <div className="input-group mb-2">
                            <span
                              className="search-icon-bg input-group-text"
                              id="basic-addon1"
                            >
                              <i className="fa-solid fa-magnifying-glass"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                              aria-label="Search"
                              aria-describedby="basic-addon1"
                              // ref={inputRefs.status}
                              value={searchTerm.status}
                              onChange={(e) => {
                                handleSearch(e, "status");
                                //applyFilterss();
                              }}
                            />
                          </div>
                        </li>
                        {filtersOptions.status
                          .filter(
                            (item) =>
                              item
                                .toLowerCase()
                                .includes(searchTerm.status.toLowerCase()) ||
                              searchTerm.status === ""
                          )
                          .map((item, index) => (
                            <li key={index}>
                              <div className="dropdown-item" href="#">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id={`Checkme${index}`}
                                    checked={
                                      filters.status &&
                                      filters.status.includes(item)
                                    }
                                    onChange={() => {
                                      handleFilterChange(item, "status");
                                      //applyFilterss();
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`Checkme${index}`}
                                  >
                                    {item}
                                  </label>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                      <hr />
                      <div className="filters-modal-footer px-3 mb-2">
                        <button
                          type="button"
                          className="btn mx-1"
                          data-bs-dismiss=""
                          onClick={() => {
                            handleReset("status");
                          }}
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          // className="btn px-2 btn-primary apply-filter-btn"
                          className="btn px-2 btn-primary"
                          onClick={() => {
                            handleSaveChanges("status");
                            setDropdownVisibility("status");
                          }}
                        >
                          Apply changes
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* reasone */}
            {filtersOptions?.reasone &&
              filtersOptions?.reasone?.length !== 0 && (
                <div>
                  <button
                    className={`btn ${
                      filterCount.reasone ? "filter-selected" : ""
                    }
          ${
            dropdownVisiblity.reasone
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                    type="button"
                    id="dropdownMenuButton"
                    aria-expanded="false"
                    onClick={() => {
                      setDropdownVisibility("reasone");
                      // handleSaveChanges("status");
                    }}
                  >
                    Reason
                    {filterCount.reasone ? ` (${filterCount.reasone})` : ""}
                  </button>
                  <div className="">
                    {dropdownVisiblity.reasone && (
                      <div
                        className="filter-select-dropdown "
                        // style={{
                        //   paddingLeft: applyPaddingLeftFilterBackground
                        //     ? "50px"
                        //     : "0px",
                        // }}
                      >
                        <div className="modal-header mx-4 my-3">
                          <h3 className="modal-title  " id="exampleModalLabel">
                            Reason
                            {filterCount.reasone
                              ? ` (${filterCount.reasone})`
                              : ""}
                          </h3>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                              // handleSaveChanges("status");
                              setDropdownVisibility("reasone");
                            }}
                          ></button>
                        </div>
                        <ul
                          className="px-4 list-style-none"
                          aria-labelledby=""
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <li>
                            <div className="input-group mb-2">
                              <span
                                className="search-icon-bg input-group-text"
                                id="basic-addon1"
                              >
                                <i className="fa-solid fa-magnifying-glass"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                // ref={inputRefs.status}
                                value={searchTerm.reasone}
                                onChange={(e) => {
                                  handleSearch(e, "reasone");
                                  //applyFilterss();
                                }}
                              />
                            </div>
                          </li>
                          {filtersOptions.reasone
                            .filter(
                              (item) =>
                                item
                                  .toLowerCase()
                                  .includes(searchTerm.reasone.toLowerCase()) ||
                                searchTerm.reasone === ""
                            )
                            .map((item, index) => (
                              <li key={index}>
                                <div className="dropdown-item" href="#">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id={`Checkme${index}`}
                                      checked={
                                        filters.reasone &&
                                        filters.reasone.includes(item)
                                      }
                                      onChange={() => {
                                        handleFilterChange(item, "reasone");
                                        //applyFilterss();
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`Checkme${index}`}
                                    >
                                      {item}
                                    </label>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                        <hr />
                        <div className="filters-modal-footer px-3 mb-2">
                          <button
                            type="button"
                            className="btn mx-1"
                            data-bs-dismiss=""
                            onClick={() => {
                              handleReset("reasone");
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            // className="btn px-2 btn-primary apply-filter-btn"
                            className="btn px-2 btn-primary"
                            onClick={() => {
                              handleSaveChanges("reasone");
                              setDropdownVisibility("reasone");
                            }}
                          >
                            Apply changes
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            {/* crops Variation */}
            {(filters?.issueType?.includes("Data Variation") ||
              showDataVariationFilters) &&
              filtersOptions?.crop_variation?.length !== 0 && (
                <div>
                  <button
                    className={`btn ${
                      filterCount.crop_variation ? "filter-selected" : ""
                    }
          ${
            dropdownVisiblity.crop_variation
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                    type="button"
                    id="dropdownMenuButton"
                    aria-expanded="false"
                    onClick={() => {
                      // handleSaveChanges("RO");
                      setDropdownVisibility("crop_variation");
                    }}
                  >
                    Crop
                    {filterCount.crop_variation
                      ? ` (${filterCount.crop_variation})`
                      : ""}
                  </button>
                  <div className="">
                    {dropdownVisiblity.crop_variation && (
                      <div
                        className="filter-select-dropdown "
                        // style={{
                        //   paddingLeft: applyPaddingLeftFilterBackground
                        //     ? "50px"
                        //     : "0px",
                        // }}
                      >
                        <div className="modal-header mx-4 my-3">
                          <h3 className="modal-title  " id="exampleModalLabel">
                            Crop
                            {filterCount.crop_variation
                              ? ` (${filterCount.crop_variation})`
                              : ""}
                          </h3>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                              setDropdownVisibility("crop_variation");
                              // handleSaveChanges("RO");
                            }}
                          ></button>
                        </div>
                        <ul
                          className="px-4 list-style-none"
                          aria-labelledby=""
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <li>
                            <div className="input-group mb-2">
                              <span
                                className="search-icon-bg input-group-text"
                                id="basic-addon1"
                              >
                                <i className="fa-solid fa-magnifying-glass"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                // ref={inputRefs.crop_variation}
                                value={searchTerm.crop_variation}
                                onChange={(e) => {
                                  handleSearch(e, "crop_variation");
                                  //applyFilterss();
                                }}
                              />
                            </div>
                          </li>

                          {filtersOptions?.crop_variation
                            ?.filter(
                              (item) =>
                                item
                                  .toLowerCase()
                                  .includes(
                                    searchTerm.crop_variation.toLowerCase()
                                  ) || searchTerm.crop_variation === ""
                            )
                            .map((item, index) => (
                              <li key={index}>
                                <div className="dropdown-item" href="#">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id={`Checkme${index}`}
                                      checked={
                                        filters.crop_variation &&
                                        filters.crop_variation.includes(item)
                                      }
                                      onChange={() => {
                                        handleFilterChange(
                                          item,
                                          "crop_variation"
                                        );
                                        //applyFilterss();
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`Checkme${index}`}
                                    >
                                      {item}
                                    </label>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                        <hr />
                        <div className="filters-modal-footer px-3 mb-2">
                          <button
                            type="button"
                            className="btn mx-1"
                            onClick={() => {
                              handleReset("crop_variation");
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            // className="btn px-2 btn-primary apply-filter-btn"
                            className="btn px-2 btn-primary"
                            onClick={() => {
                              handleSaveChanges("crop_variation");
                              setDropdownVisibility("crop_variation");
                              //applyFilterss();
                            }}
                          >
                            Apply changes
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            {/* param Variation */}
            {(filters.params_variation &&
            filterCount?.crop_variation &&
            filterCount?.crop_variation?.length !== 0 &&
            (filters?.issueType?.includes("Data Variation") ||
              showDataVariationFilters) ? (
              hideParamDetailedFilters ? null : (
                <div>
                  <button
                    className={`btn ${
                      filterCount.params_variation ? "filter-selected" : ""
                    }
          ${
            dropdownVisiblity.params_variation
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                    type="button"
                    id="dropdownMenuButton"
                    aria-expanded="false"
                    onClick={() => {
                      // handleSaveChanges("params_variation");
                      setDropdownVisibility("params_variation");
                    }}
                  >
                    Parameters
                    {filterCount.params_variation
                      ? ` (${filterCount.params_variation})`
                      : ""}
                  </button>
                  <div className="">
                    {dropdownVisiblity.params_variation && (
                      <div
                        className="filter-select-dropdown "
                        style={{
                          position: "fixed",
                          right:
                            dropDownsWidth < 1465 && isDataVariation
                              ? "25px"
                              : "",
                        }}
                      >
                        <div className="modal-header mx-4 my-3">
                          <h3 className="modal-title  " id="exampleModalLabel">
                            Parameters
                            {filterCount.params_variation
                              ? ` (${filterCount.params_variation})`
                              : ""}
                          </h3>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                              setDropdownVisibility("params_variation");
                              // handleSaveChanges("params_variation");
                            }}
                          ></button>
                        </div>
                        <ul
                          className="px-4 list-style-none"
                          aria-labelledby=""
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <li>
                            <div className="input-group mb-2">
                              <span
                                className="search-icon-bg input-group-text"
                                id="basic-addon1"
                              >
                                <i className="fa-solid fa-magnifying-glass"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                // ref={inputRefs.params_variation}
                                value={searchTerm.params_variation}
                                onChange={(e) => {
                                  handleSearch(e, "params_variation");
                                  //applyFilterss();
                                }}
                              />
                            </div>
                          </li>
                          {filtersOptions?.params_variation
                            ?.filter(
                              (item) =>
                                item
                                  .toLowerCase()
                                  .includes(
                                    searchTerm.params_variation.toLowerCase()
                                  ) || searchTerm.params_variation === ""
                            )
                            .map((item, index) => {
                              let selectedValue =
                                filters.params_variation.filter(
                                  (field) => field.split("_")[0] === item
                                )[0];
                              if (selectedValue) {
                                selectedValue = selectedValue.split("_");
                              }
                              const paramValue =
                                selectedValue && selectedValue[0]
                                  ? selectedValue[0]
                                  : null;
                              const DropdownValue =
                                selectedValue && selectedValue[1]
                                  ? selectedValue[1]
                                  : null;
                              const startRange =
                                selectedValue && selectedValue[2]
                                  ? selectedValue[2]
                                  : null;
                              const endRange =
                                selectedValue && selectedValue[3]
                                  ? selectedValue[3]
                                  : null;
                              const filterValue =
                                selectedValue && selectedValue[4]
                                  ? selectedValue[4]
                                  : null;

                              const FindParamLimt =
                                paramValue &&
                                filtersOptions &&
                                Object.keys(filtersOptions).includes(
                                  "params_limit"
                                ) &&
                                filters["crop_variation"].length !== 0
                                  ? filtersOptions["params_limit"][
                                      filters["crop_variation"]
                                    ]["params"]
                                  : null;

                              const lowerLimit = FindParamLimt
                                ? FindParamLimt[paramValue]["lower_limit"]
                                : null;
                              const upperLimit = FindParamLimt
                                ? FindParamLimt[paramValue]["upper_limit"]
                                : null;
                              // console.log(
                              //   "paramValue->",
                              //   paramValue,
                              //   FindParamLimt,
                              //   lowerLimit,
                              //   upperLimit
                              // );
                              // console.log(
                              //   "selected value :  -> ",
                              //   selectedValue,
                              //   paramValue,
                              //   paramValue && paramValue === item
                              // );
                              return (
                                <li key={index}>
                                  <div className="dropdown-item" href="#">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id={`Checkme${index}`}
                                        // checked={
                                        //   filterCount.params_variation &&
                                        //   paramValue &&
                                        //   paramValue === item
                                        // }
                                        checked={
                                          showParamDetailedFilter
                                            ? paramValue && paramValue === item
                                            : filters.params_variation &&
                                              filters.params_variation.includes(
                                                item
                                              )
                                        }
                                        onChange={() => {
                                          if (showParamDetailedFilter) {
                                            handleFilterChange(
                                              item + "_all",
                                              "params_variation"
                                            );
                                          } else {
                                            handleFilterChange(
                                              item,
                                              "params_variation"
                                            );
                                          }
                                          // console.log("---> ",  item.split("_")[0]+"_"+"all")
                                          //applyFilterss();
                                        }}
                                      />
                                      <div className="d-flex flex-column ">
                                        <label
                                          className="form-check-label"
                                          htmlFor={`Checkme${index}`}
                                        >
                                          {item}
                                        </label>

                                        {showParamDetailedFilter &&
                                          filters.params_variation.some(
                                            (field) =>
                                              field.split("_")[0] === item
                                          ) && (
                                            <div
                                              className="card-Div"
                                              style={{ minHeight: "50px" }}
                                            >
                                              <div className="d-flex flex-column ">
                                                {/*  main selector */}
                                                <FormControl
                                                  // fullWidth
                                                  variant="standard"
                                                  style={{
                                                    zIndex: 10,
                                                    width: 120,
                                                  }}
                                                >
                                                  <InputLabel id="demo-simple-select-label">
                                                    Select Option
                                                  </InputLabel>
                                                  <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={DropdownValue || ""}
                                                    label="Select Option"
                                                    // onChange={handleChange}
                                                    onChange={(event) => {
                                                      // console.log(
                                                      //   "Event : ",
                                                      //   event.target.value,
                                                      //   item.split("-")[0] +
                                                      //     "_" +
                                                      //     event.target.value
                                                      // );
                                                      const sendValue =
                                                        event.target.value ===
                                                        ""
                                                          ? ""
                                                          : event.target
                                                              .value ===
                                                            "SelectRange"
                                                          ? "_" +
                                                            event.target.value +
                                                            "_20" +
                                                            "_70" +
                                                            "_InRange"
                                                          : "_" +
                                                            event.target.value;

                                                      // handelRangeSelector(
                                                      //   paramValue,
                                                      //   [20, 70]
                                                      // );
                                                      handleFilterChange(
                                                        item.split("_")[0] +
                                                          sendValue,
                                                        "params_variation",
                                                        true
                                                      );
                                                    }}
                                                  >
                                                    {/* <MenuItem value="">
                                                    <em>None</em>
                                                  </MenuItem> */}
                                                    <MenuItem value={"all"}>
                                                      All
                                                    </MenuItem>
                                                    <MenuItem value={"InLimit"}>
                                                      In Limit
                                                    </MenuItem>
                                                    <MenuItem
                                                      value={"OutOfLimit"}
                                                    >
                                                      OutOfLimit
                                                    </MenuItem>
                                                    <MenuItem
                                                      value={"SelectRange"}
                                                    >
                                                      Select Range
                                                    </MenuItem>
                                                  </Select>
                                                </FormControl>

                                                {/* selector to select Singel Selector v/s range Selector */}

                                                {DropdownValue &&
                                                  DropdownValue ===
                                                    "SelectRange" && (
                                                    <>
                                                      <Box
                                                        sx={{
                                                          width: "100%",
                                                          marginTop: "20px",
                                                        }}
                                                      >
                                                        <Box className="d-flex flex-row position-relative ">
                                                          <Typography
                                                            gutterBottom
                                                          >
                                                            Select Parameter
                                                            Range
                                                          </Typography>
                                                          {lowerLimit !==
                                                            null &&
                                                            upperLimit !==
                                                              null && (
                                                              <Box
                                                                className="position-absolute end-0 d-flex flex-column justify-content-center"
                                                                style={{
                                                                  borderRadius:
                                                                    "4px",
                                                                  boxShadow:
                                                                    "0px 0px 3px 0px gray",
                                                                  padding:
                                                                    "3px 9px",
                                                                  fontSize:
                                                                    "0.75rem",
                                                                  top: -60,
                                                                }}
                                                              >
                                                                <Box className="d-flex flex-row justify-content-between my-1">
                                                                  <Box>
                                                                    Lo:
                                                                    {lowerLimit}
                                                                  </Box>
                                                                  <Box>
                                                                    Up:
                                                                    {upperLimit}
                                                                  </Box>
                                                                </Box>
                                                                <Box
                                                                  className="param-filter-option m-auto"
                                                                  style={{
                                                                    fontSize:
                                                                      "0.65rem",
                                                                    display:
                                                                      "inline-block",
                                                                    padding:
                                                                      "4px 8px",
                                                                    textAlign:
                                                                      "center",
                                                                  }}
                                                                  onClick={() => {
                                                                    const sendValue =
                                                                      paramValue +
                                                                      "_" +
                                                                      DropdownValue +
                                                                      "_" +
                                                                      lowerLimit +
                                                                      "_" +
                                                                      upperLimit +
                                                                      "_InRange";

                                                                    // handelRangeSelector(
                                                                    //   paramValue,
                                                                    //   [20, 70]
                                                                    // );
                                                                    handleFilterChange(
                                                                      sendValue,
                                                                      "params_variation",
                                                                      true
                                                                    );
                                                                  }}
                                                                >
                                                                  Auto Select
                                                                </Box>
                                                              </Box>
                                                            )}
                                                        </Box>
                                                        <Box
                                                          sx={{
                                                            width: "100%",
                                                            marginTop: "30px",
                                                          }}
                                                        >
                                                          <Slider
                                                            // getAriaLabel={() =>
                                                            //   "Temperature range"
                                                            // }
                                                            value={[
                                                              startRange,
                                                              endRange,
                                                            ]}
                                                            onChange={(
                                                              event
                                                            ) => {
                                                              // handelRangeSelector(
                                                              //   paramValue,
                                                              //   e.target.value
                                                              // );

                                                              const sendValue =
                                                                paramValue +
                                                                "_" +
                                                                DropdownValue +
                                                                "_" +
                                                                event.target
                                                                  .value[0] +
                                                                "_" +
                                                                event.target
                                                                  .value[1] +
                                                                "_InRange";
                                                              handleFilterChange(
                                                                sendValue,
                                                                "params_variation",
                                                                true
                                                              );
                                                            }}
                                                            valueLabelDisplay="on"
                                                            // getAriaValueText={
                                                            //   valuetext
                                                            // }
                                                            min={0}
                                                            max={100}
                                                            step={0.001}
                                                            // valueLabelDisplay="on"
                                                          />
                                                        </Box>

                                                        <Box className="d-flex flex-row justify-content-between mb-2">
                                                          <TextField
                                                            id="outlined-basic-1"
                                                            className="form_float"
                                                            label="start"
                                                            variant="outlined"
                                                            value={
                                                              startRange === 0
                                                                ? ""
                                                                : startRange
                                                            }
                                                            type="number"
                                                            inputProps={{
                                                              min: 0,
                                                              max: 100,
                                                              step: 0.001,
                                                            }}
                                                            onChange={(
                                                              event
                                                            ) => {
                                                              // handelRangeSelector(
                                                              //   paramValue,
                                                              //   e.target.value
                                                              // );
                                                              // console.log(
                                                              //   "--> ",
                                                              //   event.target
                                                              //     .value
                                                              // );
                                                              let Value =
                                                                event.target
                                                                  .value;

                                                              // if (0 > Value) {
                                                              //   Value = 0;
                                                              // } else if (
                                                              //   Value > endRange
                                                              // ) {
                                                              //   Value =
                                                              //     endRange;
                                                              // }
                                                              if (
                                                                Value <= 100 &&
                                                                Value >= 0
                                                              ) {
                                                                const sendValue =
                                                                  paramValue +
                                                                  "_" +
                                                                  DropdownValue +
                                                                  "_" +
                                                                  Math.min(
                                                                    Value,
                                                                    endRange
                                                                  ) +
                                                                  "_" +
                                                                  Math.max(
                                                                    Value,
                                                                    endRange
                                                                  ) +
                                                                  "_InRange";
                                                                handleFilterChange(
                                                                  sendValue,
                                                                  "params_variation",
                                                                  true
                                                                );
                                                              }
                                                            }}
                                                          />
                                                          <TextField
                                                            id="outlined-basic-2"
                                                            className="form_float"
                                                            label="end"
                                                            variant="outlined"
                                                            value={
                                                              endRange === 0
                                                                ? null
                                                                : endRange
                                                            }
                                                            type="number"
                                                            inputProps={{
                                                              min: 0,
                                                              max: 100,
                                                              step: 0.001,
                                                            }}
                                                            onChange={(
                                                              event
                                                            ) => {
                                                              // console.log(
                                                              //   "--> ",
                                                              //   event.target
                                                              //     .value,
                                                              //   typeof event
                                                              //     .target.value
                                                              // );

                                                              let Value =
                                                                event.target
                                                                  .value;

                                                              if (
                                                                Value <= 100 &&
                                                                Value >= 0
                                                              ) {
                                                                const sendValue =
                                                                  paramValue +
                                                                  "_" +
                                                                  DropdownValue +
                                                                  "_" +
                                                                  Math.min(
                                                                    Value,
                                                                    startRange
                                                                  ) +
                                                                  "_" +
                                                                  Math.max(
                                                                    Value,
                                                                    startRange
                                                                  ) +
                                                                  "_InRange";
                                                                handleFilterChange(
                                                                  sendValue,
                                                                  "params_variation",
                                                                  true
                                                                );
                                                              }
                                                            }}
                                                          />
                                                        </Box>
                                                      </Box>

                                                      <div
                                                        className="d-flex flex-row flex-wrap gap-2 justify-content-center"
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                      >
                                                        {/* In Range */}
                                                        <div
                                                          className={`param-filter-option
                                                        ${
                                                          filterValue ===
                                                            "InRange" &&
                                                          "selected-param-filter-option"
                                                        }`}
                                                          onClick={(value) => {
                                                            // console.log(
                                                            //   "In Range"
                                                            // );

                                                            const sendValue =
                                                              paramValue +
                                                              "_" +
                                                              DropdownValue +
                                                              "_" +
                                                              startRange +
                                                              "_" +
                                                              endRange +
                                                              "_InRange";
                                                            handleFilterChange(
                                                              sendValue,
                                                              "params_variation",
                                                              true
                                                            );
                                                          }}
                                                        >
                                                          In Range
                                                        </div>
                                                        {/* Out Of Range */}
                                                        <div
                                                          className={`param-filter-option
                                                          ${
                                                            filterValue ===
                                                              "OutOfRange" &&
                                                            "selected-param-filter-option"
                                                          }`}
                                                          onClick={(e) => {
                                                            // console.log(
                                                            //   "Out Of Range"
                                                            // );

                                                            const sendValue =
                                                              paramValue +
                                                              "_" +
                                                              DropdownValue +
                                                              "_" +
                                                              startRange +
                                                              "_" +
                                                              endRange +
                                                              "_OutOfRange";
                                                            handleFilterChange(
                                                              sendValue,
                                                              "params_variation",
                                                              true
                                                            );
                                                          }}
                                                        >
                                                          Out Of Range
                                                        </div>

                                                        {false &&
                                                          startRange &&
                                                          endRange &&
                                                          startRange ===
                                                            endRange && (
                                                            <>
                                                              {/* Less Then */}
                                                              <div
                                                                className={`param-filter-option
                                                          ${
                                                            filterValue ===
                                                              "LessThen" &&
                                                            "selected-param-filter-option"
                                                          }`}
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  // console.log(
                                                                  //   " Less Then"
                                                                  // );

                                                                  const sendValue =
                                                                    paramValue +
                                                                    "_" +
                                                                    DropdownValue +
                                                                    "_" +
                                                                    startRange +
                                                                    "_" +
                                                                    endRange +
                                                                    "_LessThen";
                                                                  handleFilterChange(
                                                                    sendValue,
                                                                    "params_variation",
                                                                    true
                                                                  );
                                                                }}
                                                              >
                                                                Less Then
                                                              </div>
                                                              {/* Greater Then */}
                                                              <div
                                                                className={`param-filter-option
                                                          ${
                                                            filterValue ===
                                                              "GreaterThen" &&
                                                            "selected-param-filter-option"
                                                          }`}
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  // console.log(
                                                                  //   "Greater Then"
                                                                  // );

                                                                  const sendValue =
                                                                    paramValue +
                                                                    "_" +
                                                                    DropdownValue +
                                                                    "_" +
                                                                    startRange +
                                                                    "_" +
                                                                    endRange +
                                                                    "_GreaterThen";
                                                                  handleFilterChange(
                                                                    sendValue,
                                                                    "params_variation",
                                                                    true
                                                                  );
                                                                }}
                                                              >
                                                                Greater Then
                                                              </div>
                                                              {/* Equals To */}
                                                              <div
                                                                className={`param-filter-option
                                                          ${
                                                            filterValue ===
                                                              "EqualsTo" &&
                                                            "selected-param-filter-option"
                                                          }`}
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  // console.log(
                                                                  //   "Equals To"
                                                                  // );

                                                                  const sendValue =
                                                                    paramValue +
                                                                    "_" +
                                                                    DropdownValue +
                                                                    "_" +
                                                                    startRange +
                                                                    "_" +
                                                                    endRange +
                                                                    "_EqualsTo";
                                                                  handleFilterChange(
                                                                    sendValue,
                                                                    "params_variation",
                                                                    true
                                                                  );
                                                                }}
                                                              >
                                                                Equals To
                                                              </div>
                                                            </>
                                                          )}
                                                      </div>
                                                    </>
                                                  )}

                                                {/* when selector is search */}
                                                {/* <FormControl
                                              fullWidth
                                              // variant="standard"
                                              // style={{ zIndex: 10, width: 120 }}
                                            >
                                              <InputLabel id="demo-simple-select-label-2">
                                                Select
                                              </InputLabel>
                                              <Select
                                                labelId="demo-simple-select-label-2"
                                                id="demo-simple-select-2"
                                                // value={age}
                                                label="Select"
                                                // onChange={handleChange}
                                                onChange={(event) => {
                                                  console.log(
                                                    "Event : ",
                                                    event.target.value
                                                  );
                                                }}
                                              >
                                                <MenuItem value="">
                                                  <em>None</em>
                                                </MenuItem>
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Medium">
                                                  Medium
                                                </MenuItem>
                                                <MenuItem value="Poor">
                                                  Poor
                                                </MenuItem>
                                                <MenuItem value="NA">
                                                  NA
                                                </MenuItem>
                                                <MenuItem value="nan">
                                                  nan
                                                </MenuItem>
                                              </Select>
                                            </FormControl> */}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                        <hr />
                        <div className="filters-modal-footer px-3 mb-2">
                          <button
                            type="button"
                            className="btn mx-1"
                            onClick={() => {
                              handleReset("params_variation");
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            // className="btn px-2 btn-primary apply-filter-btn"
                            className="btn px-2 btn-primary"
                            onClick={() => {
                              handleSaveChanges("params_variation");
                              setDropdownVisibility("params_variation");
                              //applyFilterss();
                            }}
                          >
                            Apply changes
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )
            ) : null) || <></>}
            {/* commitId Filter  */}
            {(filters?.issueType?.includes("Data Variation") ||
              showDataVariationFilters) &&
              filtersOptions?.commitId &&
              filtersOptions?.commitId?.length !== 0 && (
                <div>
                  <button
                    className={`btn ${
                      filterCount.commitId ? "filter-selected" : ""
                    }
          ${
            dropdownVisiblity.commitId
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                    type="button"
                    id="dropdownMenuButton"
                    aria-expanded="false"
                    onClick={() => {
                      setDropdownVisibility("commitId");
                      // handleSaveChanges("status");
                    }}
                  >
                    Commit Id
                    {filterCount.commitId ? ` (${filterCount.commitId})` : ""}
                  </button>
                  <div className="">
                    {dropdownVisiblity.commitId && (
                      <div
                        className="filter-select-dropdown "
                        style={{
                          position: "fixed",
                          right:
                            dropDownsWidth < 1465 && isDataVariation
                              ? "25px"
                              : "",
                        }}
                      >
                        <div className="modal-header mx-4 my-3">
                          <h3 className="modal-title  " id="exampleModalLabel">
                            Commit Id
                            {filterCount.commitId
                              ? ` (${filterCount.commitId})`
                              : ""}
                          </h3>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                              // handleSaveChanges("status");
                              setDropdownVisibility("commitId");
                            }}
                          ></button>
                        </div>
                        <ul
                          className="px-4 list-style-none"
                          aria-labelledby=""
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <li>
                            <div className="input-group mb-2">
                              <span
                                className="search-icon-bg input-group-text"
                                id="basic-addon1"
                              >
                                <i className="fa-solid fa-magnifying-glass"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                // ref={inputRefs.status}
                                value={searchTerm.commitId}
                                onChange={(e) => {
                                  handleSearch(e, "commitId");
                                  //applyFilterss();
                                }}
                              />
                            </div>
                          </li>
                          {filtersOptions.commitId
                            .filter(
                              (item) =>
                                item
                                  .toLowerCase()
                                  .includes(
                                    searchTerm.commitId.toLowerCase()
                                  ) || searchTerm.commitId === ""
                            )
                            .map((item, index) => (
                              <li key={index}>
                                <div className="dropdown-item" href="#">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id={`Checkme${index}`}
                                      checked={
                                        filters.commitId &&
                                        filters.commitId.includes(item)
                                      }
                                      onChange={() => {
                                        handleFilterChange(item, "commitId");
                                        //applyFilterss();
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`Checkme${index}`}
                                    >
                                      {item}
                                    </label>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                        <hr />
                        <div className="filters-modal-footer px-3 mb-2">
                          <button
                            type="button"
                            className="btn mx-1"
                            data-bs-dismiss=""
                            onClick={() => {
                              handleReset("commitId");
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            // className="btn px-2 btn-primary apply-filter-btn"
                            className="btn px-2 btn-primary"
                            onClick={() => {
                              handleSaveChanges("commitId");
                              setDropdownVisibility("commitId");
                            }}
                          >
                            Apply changes
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

            {/* modelNo Filter  */}
            {(filters?.issueType?.includes("Data Variation") ||
              showDataVariationFilters) &&
              filtersOptions?.modelNo &&
              filtersOptions?.modelNo?.length !== 0 && (
                <div>
                  <button
                    className={`btn ${
                      filterCount.modelNo ? "filter-selected" : ""
                    }
          ${
            dropdownVisiblity.modelNo
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                    type="button"
                    id="dropdownMenuButton"
                    aria-expanded="false"
                    onClick={() => {
                      setDropdownVisibility("modelNo");
                      // handleSaveChanges("status");
                    }}
                  >
                    Model No
                    {filterCount.modelNo ? ` (${filterCount.modelNo})` : ""}
                  </button>
                  <div className="">
                    {dropdownVisiblity.modelNo && (
                      <div
                        className="filter-select-dropdown "
                        style={{
                          position: "fixed",
                          right:
                            dropDownsWidth < 1465 && isDataVariation
                              ? "25px"
                              : "",
                        }}
                      >
                        <div className="modal-header mx-4 my-3">
                          <h3 className="modal-title  " id="exampleModalLabel">
                            Model No
                            {filterCount.modelNo
                              ? ` (${filterCount.modelNo})`
                              : ""}
                          </h3>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                              // handleSaveChanges("status");
                              setDropdownVisibility("modelNo");
                            }}
                          ></button>
                        </div>
                        <ul
                          className="px-4 list-style-none"
                          aria-labelledby=""
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <li>
                            <div className="input-group mb-2">
                              <span
                                className="search-icon-bg input-group-text"
                                id="basic-addon1"
                              >
                                <i className="fa-solid fa-magnifying-glass"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                // ref={inputRefs.status}
                                value={searchTerm.modelNo}
                                onChange={(e) => {
                                  handleSearch(e, "modelNo");
                                  //applyFilterss();
                                }}
                              />
                            </div>
                          </li>
                          {filtersOptions.modelNo
                            .filter(
                              (item) =>
                                item
                                  .toLowerCase()
                                  .includes(searchTerm.modelNo.toLowerCase()) ||
                                searchTerm.modelNo === ""
                            )
                            .map((item, index) => (
                              <li key={index}>
                                <div className="dropdown-item" href="#">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id={`Checkme${index}`}
                                      checked={
                                        filters.modelNo &&
                                        filters.modelNo.includes(item)
                                      }
                                      onChange={() => {
                                        handleFilterChange(item, "modelNo");
                                        //applyFilterss();
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`Checkme${index}`}
                                    >
                                      {item}
                                    </label>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                        <hr />
                        <div className="filters-modal-footer px-3 mb-2">
                          <button
                            type="button"
                            className="btn mx-1"
                            data-bs-dismiss=""
                            onClick={() => {
                              handleReset("modelNo");
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            // className="btn px-2 btn-primary apply-filter-btn"
                            className="btn px-2 btn-primary"
                            onClick={() => {
                              handleSaveChanges("modelNo");
                              setDropdownVisibility("modelNo");
                            }}
                          >
                            Apply changes
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

            {/* branchName Filter  */}
            {(filters?.issueType?.includes("Data Variation") ||
              showDataVariationFilters) &&
              filtersOptions?.branchName &&
              filtersOptions?.branchName?.length !== 0 && (
                <div>
                  <button
                    className={`btn ${
                      filterCount.branchName ? "filter-selected" : ""
                    }
          ${
            dropdownVisiblity.branchName
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                    type="button"
                    id="dropdownMenuButton"
                    aria-expanded="false"
                    onClick={() => {
                      setDropdownVisibility("branchName");
                      // handleSaveChanges("status");
                    }}
                  >
                    Branch Name
                    {filterCount.branchName
                      ? ` (${filterCount.branchName})`
                      : ""}
                  </button>
                  <div className="">
                    {dropdownVisiblity.branchName && (
                      <div
                        className="filter-select-dropdown "
                        style={{
                          position: "fixed",
                          right:
                            dropDownsWidth < 1730 && isDataVariation
                              ? "25px"
                              : "",
                        }}
                      >
                        <div className="modal-header mx-4 my-3">
                          <h3 className="modal-title  " id="exampleModalLabel">
                            Branch Name
                            {filterCount.branchName
                              ? ` (${filterCount.branchName})`
                              : ""}
                          </h3>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                              // handleSaveChanges("status");
                              setDropdownVisibility("branchName");
                            }}
                          ></button>
                        </div>
                        <ul
                          className="px-4 list-style-none"
                          aria-labelledby=""
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <li>
                            <div className="input-group mb-2">
                              <span
                                className="search-icon-bg input-group-text"
                                id="basic-addon1"
                              >
                                <i className="fa-solid fa-magnifying-glass"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                // ref={inputRefs.status}
                                value={searchTerm.branchName}
                                onChange={(e) => {
                                  handleSearch(e, "branchName");
                                  //applyFilterss();
                                }}
                              />
                            </div>
                          </li>
                          {filtersOptions.branchName
                            .filter(
                              (item) =>
                                item
                                  .toLowerCase()
                                  .includes(
                                    searchTerm.branchName.toLowerCase()
                                  ) || searchTerm.branchName === ""
                            )
                            .map((item, index) => (
                              <li key={index}>
                                <div className="dropdown-item" href="#">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id={`Checkme${index}`}
                                      checked={
                                        filters.branchName &&
                                        filters.branchName.includes(item)
                                      }
                                      onChange={() => {
                                        handleFilterChange(item, "branchName");
                                        //applyFilterss();
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`Checkme${index}`}
                                    >
                                      {item}
                                    </label>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                        <hr />
                        <div className="filters-modal-footer px-3 mb-2">
                          <button
                            type="button"
                            className="btn mx-1"
                            data-bs-dismiss=""
                            onClick={() => {
                              handleReset("branchName");
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            // className="btn px-2 btn-primary apply-filter-btn"
                            className="btn px-2 btn-primary"
                            onClick={() => {
                              handleSaveChanges("branchName");
                              setDropdownVisibility("branchName");
                            }}
                          >
                            Apply changes
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

            {/* Date Filters */}

            {(!filtersOptions?.time || filtersOptions?.time?.length === 0) && (
              <div>
                <button
                  className={`btn ${
                    filterCount.createdStartDate ||
                    filterCount.createdEndDate ||
                    filterCount.resolvedStartDate ||
                    filterCount.resolvedEndDate
                      ? "filter-selected"
                      : ""
                  }
          ${
            dropdownVisiblity.Date
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                  type="button"
                  id="dropdownMenuButton"
                  aria-expanded="false"
                  onClick={() => {
                    // handleSaveChanges("startDate");
                    // handleSaveChanges("createdEndDate");
                    setDropdownVisibility("Date");
                  }}
                >
                  {updateDateFilterName || "Date"}
                  {filterCount.createdStartDate ||
                  filterCount.createdEndDate ||
                  filterCount.resolvedStartDate ||
                  filterCount.resolvedEndDate
                    ? ` (${
                        (filterCount.createdStartDate
                          ? filterCount.createdStartDate
                          : 0) +
                        (filterCount.createdEndDate
                          ? filterCount.createdEndDate
                          : 0) +
                        (filterCount.resolvedStartDate
                          ? filterCount.resolvedStartDate
                          : 0) +
                        (filterCount.resolvedEndDate
                          ? filterCount.resolvedEndDate
                          : 0)
                      })`
                    : ""}
                </button>
                {dropdownVisiblity.Date && (
                  <div
                    className="filter-select-dropdown "
                    style={{
                      position: "fixed",
                      right:
                        dropDownsWidth < 1985 && isDataVariation ? "25px" : "",
                    }}
                  >
                    <div className="modal-header mx-4 my-3">
                      <h3 className="modal-title  " id="exampleModalLabel">
                        Filter By {updateDateFilterName || "Date"}
                        {filterCount.createdStartDate ||
                        filterCount.createdEndDate ||
                        filterCount.resolvedStartDate ||
                        filterCount.resolvedEndDate
                          ? ` (${
                              (filterCount.createdStartDate
                                ? filterCount.createdStartDate
                                : 0) +
                              (filterCount.createdEndDate
                                ? filterCount.createdEndDate
                                : 0) +
                              (filterCount.resolvedStartDate
                                ? filterCount.resolvedStartDate
                                : 0) +
                              (filterCount.resolvedEndDate
                                ? filterCount.resolvedEndDate
                                : 0)
                            })`
                          : ""}
                      </h3>
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => {
                          // handleSaveChanges("createdStartDate");
                          setDropdownVisibility("Date");
                        }}
                      ></button>
                    </div>

                    <div className=" px-0 date-select-body-div">
                      <ul
                        className="px-4 list-style-none overflow-x-hidden "
                        aria-labelledby=""
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <li>
                          {!hideStartDate && (
                            <div>
                              <div className="row px-3 mb-2  mt-0  pt-0 created-date-heading">
                                {startDateStr ||
                                  (dateStr && dateStr[0]) ||
                                  "Created Date"}
                                :
                              </div>
                              <div className="row createdDateDiv">
                                <div className="col-md-6 createdStartDate mb-2">
                                  <label
                                    className="form-label"
                                    htmlFor="createdStartDate"
                                  >
                                    From:
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    id="createdStartDate"
                                    name="createdStartDate"
                                    value={filters.createdStartDate}
                                    max={filters.createdEndDate}
                                    onChange={(e) => {
                                      handleFilterChange(
                                        e.target.value,
                                        "createdStartDate"
                                      );
                                      //applyFilterss();
                                    }}
                                  />
                                </div>
                                <div className="col-md-6 createdEndDate">
                                  <label
                                    className="form-label"
                                    htmlFor="createdEndDate"
                                  >
                                    To:
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    id="createdEndDate"
                                    name="createdEndDate"
                                    value={filters.createdEndDate}
                                    min={filters.createdStartDate}
                                    onChange={(e) => {
                                      handleFilterChange(
                                        e.target.value,
                                        "createdEndDate"
                                      );
                                      //applyFilterss();
                                    }}
                                  />
                                </div>
                                <div className="date-filter-selectors-div">
                                  {getDate &&
                                    Object.entries(getDate || {}).map(
                                      ([keys, value], selectDateindex) => (
                                        <div
                                          className="col-md-12 date-filter-selectors"
                                          key={`date-filter-selector-${selectDateindex}`}
                                          onClick={() => {
                                            handleFilterChange(
                                              "",
                                              "createdStartDate"
                                            );
                                            handleFilterChange(
                                              "",
                                              "createdEndDate"
                                            );
                                            handleFilterChange(
                                              value[0],
                                              "createdStartDate"
                                            );
                                            handleFilterChange(
                                              value[1],
                                              "createdEndDate"
                                            );
                                          }}
                                        >
                                          {keys}
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>
                            </div>
                          )}
                        </li>
                        <li>
                          {(showResolvedDate ||
                            filters?.status?.some(
                              (status) => status.toLowerCase() === "resolved"
                            )) && (
                            <>
                              <div className="row px-3 my-2 resolved-date-heading">
                                {(dateStr && dateStr[1]) || "Resolution Date"}:
                              </div>
                              <div className="row mt-1 mb-4 resolvedDateDiv">
                                <div className="col-md-6 resolvedStartDate mb-2">
                                  <label
                                    className="form-label"
                                    htmlFor="resolvedStartDate"
                                  >
                                    From:
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    id="resolvedStartDate"
                                    name="resolvedStartDate"
                                    value={filters.resolvedStartDate}
                                    max={filters.resolvedEndDate}
                                    onChange={(e) => {
                                      handleFilterChange(
                                        e.target.value,
                                        "resolvedStartDate"
                                      );
                                      //applyFilterss();
                                    }}
                                  />
                                </div>
                                <div className="col-md-6 endDate">
                                  <label
                                    className="form-label"
                                    htmlFor="resolvedEndDate"
                                  >
                                    To:
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    id="resolvedEndDate"
                                    name="resolvedEndDate"
                                    value={filters.resolvedEndDate}
                                    min={filters.resolvedStartDate}
                                    onChange={(e) => {
                                      handleFilterChange(
                                        e.target.value,
                                        "resolvedEndDate"
                                      );
                                      //applyFilterss();
                                    }}
                                  />
                                </div>
                                <div className="date-filter-selectors-div">
                                  {getDate &&
                                    Object.entries(getDate || {}).map(
                                      ([keys, value], selectDateindex) => (
                                        <div
                                          className="col-md-12 date-filter-selectors"
                                          key={`date-resolved-filter-selector-${selectDateindex}`}
                                          onClick={() => {
                                            handleFilterChange(
                                              "",
                                              "resolvedStartDate"
                                            );
                                            handleFilterChange(
                                              "",
                                              "resolvedEndDate"
                                            );
                                            handleFilterChange(
                                              value[0],
                                              "resolvedStartDate"
                                            );
                                            handleFilterChange(
                                              value[1],
                                              "resolvedEndDate"
                                            );
                                          }}
                                        >
                                          {keys}
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>
                            </>
                          )}
                        </li>
                      </ul>
                    </div>
                    <div className="filters-modal-footer pt-3 px-3 mb-2">
                      <button
                        type="button"
                        className="btn mx-1"
                        onClick={() => {
                          handleReset("dateSelector");
                        }}
                      >
                        Reset
                      </button>
                      <button
                        type="button"
                        // className="btn px-2 btn-primary apply-filter-btn"
                        className="btn px-2 btn-primary"
                        onClick={() => {
                          handleSaveChanges("resolvedStartDate");
                          // handleSaveChanges("resolvedEndDate");
                          setDropdownVisibility("Date");
                        }}
                      >
                        Apply changes
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* Time filter */}
            {filtersOptions?.time && filtersOptions?.time?.length !== 0 && (
              <div>
                <button
                  className={`btn ${filterCount.time ? "filter-selected" : ""}
          ${
            dropdownVisiblity.time
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                  type="button"
                  id="dropdownMenuButton"
                  aria-expanded="false"
                  onClick={() => {
                    // handleSaveChanges("RO");
                    setDropdownVisibility("time");
                  }}
                >
                  {updateTimestr || "Time"}
                  {filterCount.time
                    ? ` (${
                        filterCount.time +
                        (filterCount?.DailyEndDate || 0) +
                        (filterCount?.DailyStartDate || 0) +
                        (filterCount.MonthlyStartDate || 0) +
                        (filterCount?.MonthlyEndDate || 0) +
                        (filterCount?.hourlyStartDate || 0) +
                        (filterCount?.hourlyEndDate || 0)
                      })`
                    : ""}
                </button>
                <div className="">
                  {dropdownVisiblity.time && (
                    <div className="filter-select-dropdown ">
                      <div className="modal-header mx-4 my-3">
                        <h3 className="modal-title  " id="exampleModalLabel">
                          {updateTimestr || "Time"}
                          {filterCount.time ? ` (${filterCount.time})` : ""}
                        </h3>
                        <button
                          type="button"
                          className="btn-close"
                          aria-label="Close"
                          onClick={() => {
                            setDropdownVisibility("time");
                            // handleSaveChanges("RO");
                          }}
                        ></button>
                      </div>
                      <div className="model-body">
                        <FormControl
                          variant="standard"
                          className="mx-4"
                          sx={{ m: 1, minWidth: 120 }}
                        >
                          <InputLabel id="demo-simple-select-standard-label">
                            {updateTimestr || "Time"}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={
                              (filters?.time?.length && filters?.time[0]) || ""
                            }
                            onChange={(e) => {
                              handleFilterChange(e.target.value, "time");
                            }}
                            label="Time"
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {filtersOptions.time.map((timeKey, index) => (
                              <MenuItem value={timeKey} key={index}>
                                {timeKey}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <div className=" px-4 date-select-body-div">
                          <div>
                            <div className="row px-3 mb-2  mt-0  pt-0 created-date-heading">
                              Select Range
                            </div>
                            {/* monthly */}
                            {(filters?.time?.length &&
                              filters?.time[0] === "monthly" && (
                                <div className="row createdDateDiv">
                                  <div className="col-md-6 createdStartDate mb-2">
                                    <label
                                      className="form-label"
                                      htmlFor="createdStartDate"
                                    >
                                      From:
                                    </label>
                                    <input
                                      type="month"
                                      className="form-control"
                                      id="createdStartDate"
                                      name="createdStartDate"
                                      value={filters?.MonthlyStartDate[0] || ""}
                                      max={filters?.MonthlyEndDate[0] || ""}
                                      onChange={(e) => {
                                        handleFilterChange(
                                          e.target.value,
                                          "MonthlyStartDate"
                                        );
                                        //applyFilterss();
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                  <div className="col-md-6 createdEndDate">
                                    <label
                                      className="form-label"
                                      htmlFor="createdEndDate"
                                    >
                                      To:
                                    </label>
                                    <input
                                      type="month"
                                      className="form-control"
                                      id="createdEndDate"
                                      name="createdEndDate"
                                      value={filters?.MonthlyEndDate[0] || ""}
                                      min={filters?.MonthlyStartDate[0] || ""}
                                      onChange={(e) => {
                                        handleFilterChange(
                                          e.target.value,
                                          "MonthlyEndDate"
                                        );
                                        //applyFilterss();
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </div>
                              )) ||
                              ""}
                            {/* Daily */}
                            {(filters?.time?.length &&
                              filters?.time[0] === "daily" && (
                                <div className="row mt-1 resolvedDateDiv">
                                  <div className="col-md-6 resolvedStartDate mb-2">
                                    <label
                                      className="form-label"
                                      htmlFor="resolvedStartDate"
                                    >
                                      From:
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="resolvedStartDate"
                                      name="resolvedStartDate"
                                      value={filters?.DailyStartDate[0] || ""}
                                      max={filters?.DailyEndDate[0] || ""}
                                      onChange={(e) => {
                                        handleFilterChange(
                                          e.target.value,
                                          "DailyStartDate"
                                        );
                                        //applyFilterss();
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                  <div className="col-md-6 endDate">
                                    <label
                                      className="form-label"
                                      htmlFor="resolvedEndDate"
                                    >
                                      To:
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="resolvedEndDate"
                                      name="resolvedEndDate"
                                      value={filters?.DailyEndDate[0] || ""}
                                      min={filters?.DailyStartDate[0] || ""}
                                      onChange={(e) => {
                                        handleFilterChange(
                                          e.target.value,
                                          "DailyEndDate"
                                        );
                                        //applyFilterss();
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </div>
                              )) ||
                              ""}
                            {/* hourly */}
                            {(filters?.time?.length &&
                              filters?.time[0] === "weekly" && (
                                <div className="row mt-1 resolvedDateDiv">
                                  <div className="col-md-6 resolvedStartDate mb-2">
                                    <label
                                      className="form-label"
                                      htmlFor="weeklyStartDate"
                                    >
                                      From:
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="weeklyStartDate"
                                      name="weeklyStartDate"
                                      value={filters?.weeklyStartDate || ""}
                                      max={filters?.weeklyEndDate || ""}
                                      onChange={(e) => {
                                        handleFilterChange(
                                          e.target.value,
                                          "weeklyStartDate"
                                        );
                                        //applyFilterss();
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                  <div className="col-md-6 resolvedStartDate mb-2">
                                    <label
                                      className="form-label"
                                      htmlFor="weeklyEndDate"
                                    >
                                      From:
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="weeklyEndDate"
                                      name="weeklyEndDate"
                                      value={filters?.weeklyEndDate || ""}
                                      min={filters?.weeklyStartDate || ""}
                                      onChange={(e) => {
                                        handleFilterChange(
                                          e.target.value,
                                          "weeklyEndDate"
                                        );
                                        //applyFilterss();
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </div>
                              )) ||
                              ""}
                            {/* hourly */}
                            {(filters?.time?.length &&
                              filters?.time[0] === "hourly" && (
                                <div className="row mt-1 resolvedDateDiv">
                                  <div className="col-md-6 resolvedStartDate mb-2">
                                    <label
                                      className="form-label"
                                      htmlFor="hourlyStartDate"
                                    >
                                      From:
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="hourlyStartDate"
                                      name="hourlyStartDate"
                                      value={filters?.hourlyStartDate || ""}
                                      max={filters?.hourlyEndDate || ""}
                                      onChange={(e) => {
                                        handleFilterChange(
                                          e.target.value,
                                          "hourlyStartDate"
                                        );
                                        //applyFilterss();
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                  <div className="col-md-6 resolvedStartDate mb-2">
                                    <label
                                      className="form-label"
                                      htmlFor="hourlyEndDate"
                                    >
                                      From:
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="hourlyEndDate"
                                      name="hourlyEndDate"
                                      value={filters?.hourlyEndDate || ""}
                                      min={filters?.hourlyStartDate || ""}
                                      onChange={(e) => {
                                        handleFilterChange(
                                          e.target.value,
                                          "hourlyEndDate"
                                        );
                                        //applyFilterss();
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </div>
                              )) ||
                              ""}
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div className="filters-modal-footer px-3 mb-2">
                        <button
                          type="button"
                          className="btn mx-1"
                          onClick={() => {
                            handleReset("time");
                          }}
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          // className="btn px-2 btn-primary apply-filter-btn"
                          className="btn px-2 btn-primary"
                          onClick={() => {
                            handleSaveChanges("time");
                            setDropdownVisibility("time");
                            //applyFilterss();
                          }}
                        >
                          Apply changes
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* Compare Options filter */}
            {filtersOptions?.compare &&
              filtersOptions?.compare?.length !== 0 && (
                <div>
                  <button
                    className={`btn ${
                      filterCount.option ? "filter-selected" : ""
                    }
          ${
            dropdownVisiblity.compare
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                    type="button"
                    id="dropdownMenuButton"
                    aria-expanded="false"
                    onClick={() => {
                      // handleSaveChanges("RO");
                      setDropdownVisibility("compare");
                    }}
                  >
                    Compare
                    {filterCount.option ? ` (${filterCount.option})` : ""}
                  </button>
                  <div className="">
                    {dropdownVisiblity.compare && (
                      <div
                        className="filter-select-dropdown "
                        // style={{
                        //   paddingLeft: applyPaddingLeftFilterBackground
                        //     ? "50px"
                        //     : "0px",
                        // }}
                      >
                        <div className="modal-header mx-4 my-3">
                          <h3 className="modal-title  " id="exampleModalLabel">
                            {"Compare"}
                            {filterCount.option
                              ? ` (${filterCount.option})`
                              : ""}
                          </h3>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                              setDropdownVisibility("compare");
                              // handleSaveChanges("RO");
                            }}
                          ></button>
                        </div>
                        <div className="model-body">
                          {filters?.option && filters?.option?.length !== 0 && (
                            <div className="px-3 pb-3">
                              <div className="shadow p-2 rounded-4" style={{}}>
                                <div className="date-select-body-div">
                                  <div className="row px-3 mb-2  mt-0  pt-0 created-date-heading">
                                    Selected Options :
                                  </div>
                                </div>
                                <div className="chips-wrap flex-wrap gap-1">
                                  {filters?.option?.map((item, index) => (
                                    <div className="chips-filter-div d-flex flex-row m-0 p-0 border rounded m-1">
                                      <span
                                        className="inline-heading"
                                        title={"filter"}
                                      >
                                        <div className="m-0 p-0 text-nowrap">
                                          {item}
                                        </div>
                                        <div className="d-flex fex-row">
                                          <p className="text-nowrap">
                                            option {index}
                                          </p>
                                          {item.includes("machine") && (
                                            <span
                                              className="ms-auto"
                                              style={{
                                                fontSize: "0.75rem",
                                                fontFamily: "Open-Sans-Light",
                                                fontWeight: 600,
                                                padding: "2px 6px",
                                                marginBottom: "5px",
                                                boxShadow:
                                                  "0px 0px 2px 0px gray",
                                                color: "#ffffff",
                                                backgroundColor: "#4f85e9",
                                                borderRadius: "4px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                // console.log("working");
                                                handelAutoSelectFilterBtn(
                                                  "option",
                                                  item
                                                );
                                              }}
                                            >
                                              Auto Select RO & DO
                                            </span>
                                          )}
                                        </div>
                                      </span>
                                      <a
                                        onClick={() => {
                                          handelTempRemoveFilterBtn(
                                            "option",
                                            item
                                          );
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-x cross-icon"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                        </svg>
                                      </a>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                          <FormControl
                            variant="standard"
                            className="mx-4"
                            sx={{ m: 1, minWidth: 120 }}
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              Select
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={
                                (filters?.compare?.length &&
                                  filters?.compare[0]) ||
                                ""
                              }
                              onChange={(e) => {
                                handleFilterChange(e.target.value, "compare");
                              }}
                              label="Compare"
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {filtersOptions.compare.map((timeKey, index) => (
                                <MenuItem value={timeKey} key={index}>
                                  {timeKey}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <div className="py-2 px-4 date-select-body-div">
                            <div>
                              <div className="row px-3 mb-2  mt-0  pt-0 created-date-heading">
                                {filters?.compare &&
                                filters?.compare?.length !== 0
                                  ? filters?.option?.length > 7
                                    ? "Maximum options Selected"
                                    : `Select ${filters?.compare[0]}`
                                  : "Select Option To Compare"}
                              </div>
                              {/* Region */}
                              {(filters?.compare?.length &&
                                filters?.compare[0] === "Region" &&
                                filters?.option?.length <= 7 && (
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={filtersOptions?.Compare_RO || []}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Region"
                                        InputProps={{
                                          ...params.InputProps,
                                          sx: {
                                            height: 50, // Adjust the height as needed
                                            "& .MuiInputBase-input": {
                                              padding: "8px 14px", // Adjust the padding to center the text
                                            },
                                          },
                                        }}
                                        InputLabelProps={{
                                          sx: {
                                            lineHeight: "1.25em", // Adjust the line-height to center the label text
                                          },
                                        }}
                                      />
                                    )}
                                    onChange={(e, value) => {
                                      if (value) {
                                        // console.log(
                                        //   "OnChange : ",
                                        //   `region-${value.label}`
                                        // );
                                        handleFilterChange(
                                          `region-${value.label}`,
                                          "option"
                                        );
                                      }
                                    }}
                                  />
                                )) ||
                                ""}
                              {/* District */}
                              {(filters?.compare?.length &&
                                filters?.compare[0] === "District" &&
                                filters?.option?.length <= 7 && (
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={filtersOptions?.Compare_DO || []}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="District"
                                        InputProps={{
                                          ...params.InputProps,
                                          sx: {
                                            height: 50, // Adjust the height as needed
                                            "& .MuiInputBase-input": {
                                              padding: "8px 14px", // Adjust the padding to center the text
                                            },
                                          },
                                        }}
                                        InputLabelProps={{
                                          sx: {
                                            lineHeight: "1.25em", // Adjust the line-height to center the label text
                                          },
                                        }}
                                      />
                                    )}
                                    onChange={(e, value) => {
                                      if (value) {
                                        // console.log(
                                        //   "OnChange : ",
                                        //   `district-${value.label}`
                                        // );
                                        handleFilterChange(
                                          `district-${value.label}`,
                                          "option"
                                        );
                                      }
                                    }}
                                  />
                                )) ||
                                ""}
                              {/* Machine */}
                              {(filters?.compare?.length &&
                                filters?.compare[0] === "Machine" &&
                                filters?.option?.length <= 7 && (
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={
                                      filtersOptions?.Compare_machineNumber ||
                                      []
                                    }
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Machine"
                                        InputProps={{
                                          ...params.InputProps,
                                          sx: {
                                            height: 50, // Adjust the height as needed
                                            "& .MuiInputBase-input": {
                                              padding: "8px 14px", // Adjust the padding to center the text
                                            },
                                          },
                                        }}
                                        InputLabelProps={{
                                          sx: {
                                            lineHeight: "1.25em", // Adjust the line-height to center the label text
                                          },
                                        }}
                                      />
                                    )}
                                    onChange={(e, value) => {
                                      if (value) {
                                        // console.log(
                                        //   "OnChange : ",
                                        //   `machine-${value.label}`
                                        // );
                                        handleFilterChange(
                                          `machine-${value.label}`,
                                          "option"
                                        );
                                      }
                                    }}
                                  />
                                )) ||
                                ""}
                            </div>
                          </div>
                        </div>

                        <hr />
                        <div className="filters-modal-footer px-3 mb-2">
                          <button
                            type="button"
                            className="btn mx-1"
                            onClick={() => {
                              handleReset("option");
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            // className="btn px-2 btn-primary apply-filter-btn"
                            className="btn px-2 btn-primary"
                            onClick={() => {
                              setDropdownVisibility("compare");
                              handleSaveChanges("option");
                            }}
                          >
                            Apply changes
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            {/* Sorting Filters */}
            {filtersOptions?.sort && filtersOptions?.sort?.length !== 0 && (
              <div>
                <button
                  className={`btn
              ${filterCount.sort ? "filter-selected" : ""}
              
          ${
            dropdownVisiblity.Sort
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                  type="button"
                  id="dropdownMenuButton"
                  aria-expanded="false"
                  onClick={() => {
                    setDropdownVisibility("Sort");
                    // handleSaveChanges("sort");
                  }}
                >
                  Sort By{filterCount.sort ? ` (${filterCount.sort})` : ""}
                </button>
                <div className=""></div>
                {dropdownVisiblity.Sort && (
                  <div
                    className="filter-select-dropdown "
                    style={{
                      position: "fixed",
                      right:
                        dropDownsWidth < 2560 && isDataVariation ? "25px" : "",
                    }}
                  >
                    <div className="modal-header mx-4 my-3">
                      <h3 className="modal-title  " id="exampleModalLabel">
                        Sort By
                        {filterCount.sort ? ` (${filterCount.sort})` : ""}
                      </h3>
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => {
                          setDropdownVisibility("Sort");
                          // handleSaveChanges("sort");
                        }}
                      ></button>
                    </div>

                    {!showSortbyDropdown && (
                      <div className=" px-4 ">
                        <div className="col">
                          <div className="row-md-6">
                            {/* <ResetFilter /> */}
                            <div className="resetFilter d-flex flex-wrap gap-3">
                              {/* creationDateAssending */}
                              {filtersOptions["sort"].map((values, index) => (
                                <div
                                  className={`btn  selectTime${index} ${
                                    bgcolor["selectTime" + index] ||
                                    (filters?.sort?.length &&
                                      filters.sort[0].includes(values))
                                      ? "btn-outline-primary-hover"
                                      : "btn-outline-primary"
                                  } orders`}
                                  key={index}
                                  onClick={() => {
                                    setBGcolors(`selectTime${index}`);

                                    handleFilterChange(`${values}`, "sort");
                                  }}
                                >
                                  {values}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {showSortbyDropdown && (
                      <div className="">
                        {false && (
                          <li className="my-3 ">
                            <div>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  {(filters.sort && filters.sort[0]) ||
                                    "Select Filter"}
                                  {/* Tickets per Page */}
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  // value={age}
                                  label={`${
                                    (filters.sort && filters.sort[0]) ||
                                    "Select Filter"
                                  }`}
                                  sx={{ padding: "3px" }}
                                  // autoWidth
                                  // label="Tickets per Page"
                                >
                                  {filtersOptions?.sort?.map(
                                    (option, index) => (
                                      <MenuItem
                                        key={index}
                                        value={option}
                                        onClick={() =>
                                          handleFilterChange(option, "sort")
                                        }
                                      >
                                        {option}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </div>
                          </li>
                        )}
                        {false && (
                          <li className="my-3">
                            <Tooltip
                              title={
                                (filters?.sort &&
                                  filters?.sort?.length !== 0 &&
                                  filters.sort[0]) ||
                                "Select a filter"
                              }
                              placement="top" // Position of the tooltip
                              arrow // Optional: adds an arrow to the tooltip
                            >
                              <Autocomplete
                                sx={{
                                  "& .MuiAutocomplete-inputRoot": {
                                    // padding: "5px",
                                    width: "100%",
                                  },

                                  "& .MuiAutocomplete-clearIndicator": {
                                    display: "none", // Hide the clear indicator
                                  },
                                  "& .MuiAutocomplete-clearIndicatorHidden": {
                                    display: "none", // Hide the hidden clear indicator
                                  },
                                }}
                                disablePortal
                                size="medium"
                                id="Choose_sort_filter"
                                options={filtersOptions?.sort || []}
                                value={
                                  (filters?.sort &&
                                    filters?.sort?.length !== 0 &&
                                    filters.sort[0]) ||
                                  ""
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Choose_sort_filter"
                                  />
                                )}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    handleFilterChange(newValue, "sort");
                                  }
                                }}
                              />
                            </Tooltip>
                          </li>
                        )}

                        <ul
                          className="px-4 list-style-none"
                          aria-labelledby=""
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <li>
                            <div className="input-group mb-2">
                              <span
                                className="search-icon-bg input-group-text"
                                id="basic-addon1"
                              >
                                <i className="fa-solid fa-magnifying-glass"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                // ref={inputRefs.crop_variation}
                                value={searchTerm.sort}
                                onChange={(e) => {
                                  handleSearch(e, "sort");
                                  //applyFilterss();
                                }}
                              />
                            </div>
                          </li>

                          {filtersOptions?.sort
                            ?.filter(
                              (item) =>
                                item
                                  .toLowerCase()
                                  .includes(searchTerm.sort.toLowerCase()) ||
                                searchTerm.sort === ""
                            )
                            .map((item, index) => (
                              <li key={index}>
                                <div className="dropdown-item" href="#">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id={`Checkme${index}`}
                                      checked={
                                        filters.sort &&
                                        filters.sort.includes(item)
                                      }
                                      onChange={() => {
                                        handleFilterChange(item, "sort");
                                        //applyFilterss();
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`Checkme${index}`}
                                    >
                                      {item}
                                    </label>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                    <hr />
                    <div className="filters-modal-footer pt-3 px-3 pb-2 shadow">
                      <button
                        type="button"
                        className="btn mx-1"
                        onClick={() => {
                          handleReset("sort");
                          setbgcolor((classes) => {
                            const allclasses = { ...classes };
                            for (const i in allclasses) {
                              allclasses[i] = false;
                            }
                            return allclasses;
                          });
                        }}
                      >
                        Reset
                      </button>
                      <button
                        type="button"
                        // className="btn px-2 btn-primary apply-filter-btn"
                        className="btn px-2 btn-primary"
                        onClick={() => {
                          handleSaveChanges("sort");
                          setDropdownVisibility("Sort");
                        }}
                      >
                        Apply changes
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* working hours */}
            {filtersOptions?.workingHours &&
              filtersOptions?.workingHours?.length !== 0 && (
                <div>
                  <button
                    className={`btn  ${
                      filterCount.workingHours ? "filter-selected" : ""
                    }
          ${
            dropdownVisiblity.workingHours
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                    type="button"
                    id="dropdownMenuButton"
                    aria-expanded="false"
                    onClick={() => {
                      setDropdownVisibility("workingHours");
                    }}
                  >
                    workingHours
                    {filterCount.workingHours
                      ? ` (${filterCount.workingHours})`
                      : ""}
                  </button>
                  <div className="">
                    {dropdownVisiblity.workingHours && (
                      <div className="filter-select-dropdown ">
                        <div className="modal-header mx-4 my-3">
                          <h3 className="modal-title  " id="exampleModalLabel">
                            Working Hours
                            {filterCount.workingHours
                              ? ` (${filterCount.workingHours})`
                              : ""}
                          </h3>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                              // handleSaveChanges("issueType");
                              setDropdownVisibility("workingHours");
                            }}
                          ></button>
                        </div>
                        <ul
                          className="px-4 list-style-none"
                          aria-labelledby=""
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <li>
                            <div className="input-group mb-2">
                              <span
                                className="search-icon-bg input-group-text"
                                id="basic-addon1"
                              >
                                <i className="fa-solid fa-magnifying-glass"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                // ref={inputRefs.issueType}
                                value={searchTerm.workingHours}
                                onChange={(e) => {
                                  handleSearch(e, "workingHours");
                                  //applyFilterss();
                                }}
                              />
                            </div>
                          </li>
                          {filtersOptions?.workingHours
                            ?.filter(
                              (item) =>
                                item
                                  .toLowerCase()
                                  .includes(
                                    searchTerm.workingHours.toLowerCase()
                                  ) || searchTerm.workingHours === ""
                            )
                            .map((item, index) => (
                              <li key={index}>
                                <div className="dropdown-item" href="#">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id={`Checkme${index}`}
                                      checked={
                                        filters.workingHours &&
                                        filters.workingHours.includes(item)
                                      }
                                      onChange={() => {
                                        handleFilterChange(
                                          item,
                                          "workingHours"
                                        );
                                        //applyFilterss();
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`Checkme${index}`}
                                    >
                                      {item}
                                    </label>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                        <hr />
                        <div className="filters-modal-footer px-3 mb-2">
                          <button
                            type="button"
                            className="btn mx-1"
                            data-bs-dismiss=""
                            onClick={() => {
                              handleReset("workingHours");
                            }}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            // className="btn px-2 btn-primary apply-filter-btn"
                            className="btn px-2 btn-primary"
                            onClick={() => {
                              setDropdownVisibility("workingHours");
                              handleSaveChanges("workingHours");
                            }}
                          >
                            Apply changes
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

            {/* More Filters */}
            {(filters?.searchBox ||
              filters?.visitSearchBox ||
              (filtersOptions?.moreFilters &&
                filtersOptions?.moreFilters?.length !== 0) ||
              filters?.ticketPerPage) && (
              <div>
                <button
                  className={`btn margin-right-110px me-0
              ${
                filterCount.moreFilters ||
                filterCount.searchBox ||
                filterCount.visitSearchBox ||
                filterCount.ticketPerPage
                  ? "filter-selected"
                  : ""
              }
              
          ${
            dropdownVisiblity.moreFilters
              ? "btn-outline-primary-hover"
              : "btn-outline-primary"
          }
           dropdown-toggle mb-1`}
                  type="button"
                  id="dropdownMenuButton"
                  aria-expanded="false"
                  onClick={() => {
                    setDropdownVisibility("moreFilters");
                    // handleSaveChanges("sort");
                  }}
                >
                  More Filters
                  {filterCount.moreFilters ||
                  filterCount.searchBox ||
                  filterCount.visitSearchBox ||
                  filterCount.ticketPerPage
                    ? ` (${
                        filterCount.moreFilters +
                        (filterCount.searchBox || 0) +
                        (filterCount.visitSearchBox || 0) +
                        filterCount.ticketPerPage
                      })`
                    : ""}
                </button>
                {dropdownVisiblity.moreFilters && (
                  <div
                    className="filter-select-dropdown "
                    style={{
                      position: "fixed",
                      right:
                        dropDownsWidth < 2298 && isDataVariation ? "25px" : "",
                    }}
                  >
                    <div className="modal-header mx-4 my-3">
                      <h3 className="modal-title  " id="exampleModalLabel">
                        More Filters
                        {filterCount.moreFilters ||
                        filterCount.searchBox ||
                        filterCount.visitSearchBox ||
                        filterCount.ticketPerPage ||
                        filterCount.scanIDSearchBox
                          ? ` (${
                              filterCount.moreFilters +
                              (filterCount.searchBox || 0) +
                              (filterCount.scanIDSearchBox || 0) +
                              (filterCount.visitSearchBox || 0) +
                              filterCount.ticketPerPage
                            })`
                          : ""}
                      </h3>
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => {
                          setDropdownVisibility("moreFilters");
                          // handleSaveChanges("sort");
                        }}
                      ></button>
                    </div>
                    <ul
                      className="px-4 list-style-none"
                      aria-labelledby=""
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {filters?.searchBox && (
                        <li>
                          <div className="search-ticketID-box my-1 mb-3">
                            <form
                              className="form-inline d-flex flex-row align-items-center"
                              style={{
                                boxShadow: "0px 0px 2px 0px gray",
                                padding: "7px 8px",
                                background: "#ffffff",
                                borderRadius: "6px",
                              }}
                            >
                              <input
                                className="form-control mr-sm-2 border-0 shadow-none"
                                type="search"
                                placeholder={`${
                                  searchBoxStr || "Search Ticket ID"
                                }`}
                                aria-label="Search"
                                onChange={(e) => {
                                  handleSearch(e, "searchBox");
                                  handleFilterChange(
                                    e.target.value.trim(),
                                    "searchBox"
                                  );
                                }}
                                value={filters?.searchBox[0]}
                              />
                              {/* <button className="btn btn-outline-primary my-2 my-sm-0 rounded-pill " type="submit" onClick={() => handleSaveChanges("searchBox")} style={{
                            padding: "5px 10px",
                            textTransform: "capitalize",
                          }}>Search</button> */}
                            </form>
                          </div>
                        </li>
                      )}
                      {filters?.scanIDSearchBox && (
                        <li>
                          <div className="search-ticketID-box my-1 mb-3">
                            <form
                              className="form-inline d-flex flex-row align-items-center"
                              style={{
                                boxShadow: "0px 0px 2px 0px gray",
                                padding: "7px 8px",
                                background: "#ffffff",
                                borderRadius: "6px",
                              }}
                            >
                              <input
                                className="form-control mr-sm-2 border-0 shadow-none"
                                type="search"
                                placeholder={`${"Search By ScanID"}`}
                                aria-label="Search"
                                onChange={(e) => {
                                  handleSearch(e, "scanIDSearchBox");
                                  handleFilterChange(
                                    e.target.value.trim(),
                                    "scanIDSearchBox"
                                  );
                                }}
                                value={filters?.scanIDSearchBox[0]}
                              />
                              {/* <button className="btn btn-outline-primary my-2 my-sm-0 rounded-pill " type="submit" onClick={() => handleSaveChanges("searchBox")} style={{
                            padding: "5px 10px",
                            textTransform: "capitalize",
                          }}>Search</button> */}
                            </form>
                          </div>
                        </li>
                      )}
                      {filters?.visitSearchBox && (
                        <li>
                          <div className="search-ticketID-box my-1 mb-3">
                            <form
                              className="form-inline d-flex flex-row align-items-center"
                              style={{
                                boxShadow: "0px 0px 2px 0px gray",
                                padding: "7px 8px",
                                background: "#ffffff",
                                borderRadius: "6px",
                              }}
                            >
                              <input
                                className="form-control mr-sm-2 border-0 shadow-none"
                                type="number"
                                placeholder={`${
                                  visitsearchBoxStr || "Search Visit ID"
                                }`}
                                aria-label="Search"
                                onChange={(e) => {
                                  handleSearch(e, "visitSearchBox");
                                  handleFilterChange(
                                    e.target.value.trim(),
                                    "visitSearchBox"
                                  );
                                }}
                                value={filters.visitSearchBox[0] || ""}
                              />
                              {/* <button className="btn btn-outline-primary my-2 my-sm-0 rounded-pill " type="submit" onClick={() => handleSaveChanges("searchBox")} style={{
                              padding: "5px 10px",
                              textTransform: "capitalize",
                            }}>Search</button> */}
                            </form>
                          </div>
                        </li>
                      )}
                      {filtersOptions?.moreFilters &&
                        filtersOptions.moreFilters.map((key, index) => (
                          <li key={index}>
                            <div className="dropdown-item" href="#">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id={`Checkme${index}`}
                                  checked={
                                    filters.moreFilters &&
                                    filters.moreFilters.includes(key)
                                  }
                                  onChange={() => {
                                    // do it
                                    handleFilterChange(key, "moreFilters");
                                    //applyFilterss();
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`Checkme${index}`}
                                >
                                  {key
                                    .split("_")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                                </label>
                              </div>
                            </div>
                          </li>
                        ))}
                      {/* 
                      {filters?.ticketPerPage && (
                        <li className="my-2">
                          <div className="btn-group dropdown">
                            <button
                              className={`btn btn-secondary btn-sm dropdown-toggle ${
                                filters?.ticketPerPage?.length === 0 &&
                                `bg-white`
                              }`}
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Tickets per Page: {filters.ticketPerPage[0] || 20}
                            </button>
                            <ul className="dropdown-menu">
                              {filtersOptions?.ticketPerPage.map(
                                (option, index) => (
                                  <li key={index}>
                                    <button
                                      className="dropdown-item "
                                      onClick={() =>
                                        handleFilterChange(
                                          option,
                                          "ticketPerPage"
                                        )
                                      }
                                    >
                                      {option}
                                    </button>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </li>
                      )} */}
                      {filters?.ticketPerPage && (
                        <li className="my-3 ">
                          <div>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                {(updateDateFilterName &&
                                  updateDateFilterName.includes("Scan") &&
                                  "Scans ") ||
                                  "Tickets "}
                                per Page:
                                {filters.ticketPerPage[0] || 20}
                                {/* Tickets per Page */}
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // value={age}
                                label={`Tickets per Page: ${
                                  filters.ticketPerPage[0] || 20
                                }`}
                                sx={{ padding: "3px" }}
                                // autoWidth
                                // label="Tickets per Page"
                              >
                                {filtersOptions?.ticketPerPage.map(
                                  (option, index) => (
                                    <MenuItem
                                      key={index}
                                      value={option}
                                      onClick={() =>
                                        handleFilterChange(
                                          option,
                                          "ticketPerPage"
                                        )
                                      }
                                    >
                                      {option}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        </li>
                      )}
                    </ul>
                    <div className="filters-modal-footer pt-3 px-3 pb-2 shadow">
                      <button
                        type="button"
                        className="btn mx-1"
                        onClick={() => {
                          handleReset("moreFilters");
                        }}
                      >
                        Reset
                      </button>
                      <button
                        type="button"
                        // className="btn px-2 btn-primary apply-filter-btn"
                        className="btn px-2 btn-primary"
                        onClick={() => {
                          handleSaveChanges("moreFilters");
                          setDropdownVisibility("moreFilters");
                        }}
                      >
                        Apply changes
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* Save all  Filters */}
          </div>
          <div className="filters-background-right all-filter-div ">
            <button
              className={`btn apply_all_filter_btn
            ${
              dropdownVisiblity.saveFilters
                ? "btn-outline-primary-hover"
                : "btn-outline-primary"
            }
              mb-1 text-nowrap`}
              type="button"
              id="apply_all_filter"
              aria-expanded="false"
              onClick={() => {
                handleSaveChanges("ALL");
                setDropdownVisibility("ALL");
              }}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dropdown;
