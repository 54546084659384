import React, { useContext, useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Box, MenuItem, Modal, Tooltip, Typography } from "@mui/material";
import "../../assets/styles/VisitDetails.css";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import ModalError from "../error/ModalError";
import Autocomplete from "@mui/material/Autocomplete";
import FilesUpload from "../RaiseTicketForm/FilesUpload";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ContextState from "../contextApi/ContextState";
import close from "../../assets/images/close.svg";
import ConfirmDeletionModal from "./ConfirmDeletionModal";

// converting backend date format to YYYY-MM-DD
export function formatDateString(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  // getMonth() returns month from 0-11. Add 1 to get the correct month.
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}

function VisitDetails({
  reloadVisitsPage,
  data,
  refreshBtn,
  setRefreshBtn,
  setRefreshLoading,
}) {
  const { ticketId, status } = data;
  const {
    fetchApi,
    showLoadingModal,
    lodingModalVisiblity,
    openSnackbar,
    showModalError,
    showModalErrorPage,
  } = useContext(ContextState);

  const [reloadData, setReloadData] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // visit details intial state
  const [visitDetailsformData, setVisitDetailsFormData] = useState({
    location: "",
    support_person: [],
    total_expenses: "",
    parts_replaced: [],
    distance: null,
    remarks: "",
    start_date: null,
    end_date: null,
    attachments: [],
  });

  // maintaining state variable  for previous visit attachments
  const [prevVisitAttachments, setPrevVisitAttachments] = useState([]);

  // maintaining a state variable for visit_id for individual cards
  const [visitId, setVisitId] = useState(null);

  // console.log("attachments data inside modal we got:", prevVisitAttachments);

  // getting filters data for modal from backend
  const [backendFilterData, setBackendFilterData] = useState({
    support_list: [],
    hardware_list: {},
    machines_list: [],
    support_name_list: [],
    machine_location_list: [],
    hardware_relaced_list: [],
  });

  const [isEditVisitDetails, setIsEditVisitDetails] = useState(true);

  const isEditVisits = () => {
    setIsEditVisitDetails(!isEditVisitDetails);
  };

  // console.log("bakend filter data:", backendFilterData);

  // console.log("---------formdata", visitDetailsformData);

  // maintaining a state variable to store the support visit data
  const [getBackendVisitDetails, setGetBackendVisitDetails] = useState([]);
  // console.log(
  //   "successfully stored backend visit data:",
  //   getBackendVisitDetails
  // );

  // modal state
  const [showVisitDetailsModal, setShowVisitDetailsModal] = useState(false);

  // button loader
  const [loadingButton, setLoadingButton] = useState(false);

  // buttons state
  const [addVisitdetailsButton, setAddVisitdetailsButton] = useState(false);

  // handling visit Details Add button.....addVisitDetails---> new visit Details fields...
  // function required to get iuds of some elements in modal
  const get_machine_code = (check_location) => {
    for (
      let index = 0;
      index < backendFilterData.machines_list?.length;
      index++
    ) {
      const element = backendFilterData.machines_list[index];
      const location = element["location"];
      if (location === check_location) {
        return element["code"];
      }
    }
  };

  const get_support_persons_list = (persons_list) => {
    let updated_persons_list = [];
    for (let index = 0; index < persons_list?.length; index++) {
      let coming_name = persons_list[index];
      for (
        let index1 = 0;
        index1 < backendFilterData?.support_list?.length;
        index1++
      ) {
        const element = backendFilterData.support_list[index1];
        const name = element["name"];
        const id = element["id"];
        if (name === coming_name) {
          updated_persons_list.push(id);
        }
      }
    }
    return updated_persons_list;
  };

  const get_parts_replaced_list = (check_parts) => {
    const ids = [];
    for (const key in backendFilterData?.hardware_list) {
      if (
        Object.prototype.hasOwnProperty.call(
          backendFilterData?.hardware_list,
          key
        )
      ) {
        const value = backendFilterData?.hardware_list[key];
        if (check_parts.includes(value)) {
          ids.push(parseInt(key));
        }
      }
    }
    return ids;
  };

  // click on exsting ----send data as props to form...
  const handleVisitDetailsAddButtonClick = async (selectedData) => {
    try {
      const payload = {
        ticket_id: ticketId,
      };
      const jsonData = await fetchApi(
        "get_visit_filters",
        "POST",
        payload,
        true
      );
      if (jsonData?.statusCode === 200 && jsonData && jsonData?.data) {
        // console.log("data fetch successfully");
        setBackendFilterData((prevState) => ({
          ...prevState,
          support_list: jsonData?.data?.support_list,
          hardware_list: jsonData?.data?.hardware_list,
          machines_list: jsonData?.data?.machines_list,
          support_name_list: jsonData?.data?.support_list.map(
            (element, index) => {
              return element.name;
            }
          ),
          machine_location_list: jsonData?.data?.machines_list.map(
            (element, index) => {
              return element.location;
            }
          ),
          hardware_relaced_list: Object.values(jsonData?.data?.hardware_list),
        }));
      } else {
        openSnackbar(
          "Some error occured, please try again after some time!",
          "error"
        );

        // console.log("data not fetched from backend, some errror occured!");
      }

      if (selectedData) {
        setIsEditVisitDetails(true);
        setVisitDetailsFormData({
          location: selectedData.machine_code,
          support_person: selectedData?.support_person?.map(
            (eachObject) => eachObject.name
          ),
          total_expenses: selectedData?.total_expense,
          parts_replaced: selectedData?.parts_replaced?.map(
            (eachPart) => eachPart.hardware
          ),
          remarks: selectedData.remarks,
          start_date: dayjs(formatDateString(selectedData?.start_date)),
          end_date: dayjs(formatDateString(selectedData?.end_date)),
          attachments: [],
          distance: selectedData?.distance,
        });
        setPrevVisitAttachments(selectedData?.attachments);
        setVisitId(selectedData?.id);

        setAddVisitdetailsButton(false);
      } else {
        setIsEditVisitDetails(false);
        // For a new visit detail, keep start and end date null initially
        setVisitDetailsFormData({
          location: "",
          support_person: [],
          total_expenses: "",
          parts_replaced: [],
          remarks: "",
          start_date: null,
          end_date: null,
          attachments: [],
          distance: null,
        });
        setAddVisitdetailsButton(true);
      }
      setShowVisitDetailsModal(true);
    } catch (error) {
      openSnackbar(
        "Some error occured, please try again after some time!",
        "error"
      );
      // console.log("some error occured!");
    }
  };

  // handle modal
  const handleCloseVisitDetailsModal = (event, reason) => {
    if (reason && reason == "backdropClick") {
      // console.log("backdropClicked. Not closing dialog.");
      return;
    }
    setShowVisitDetailsModal(false);
  };

  const handleSaveChanges = async () => {
    if (!visitDetailsformData.start_date || !visitDetailsformData.end_date) {
      openSnackbar("Please select both start date and end date.");
      return;
    }

    // Check if end date is not before start date
    if (
      dayjs(visitDetailsformData.end_date).isBefore(
        visitDetailsformData.start_date
      )
    ) {
      openSnackbar("End date cannot be before start date.");
      return;
    }

    try {
      // ************from here we are going to hit api's************

      const urlToFileArrayBuffer = async (fileUrl) => {
        try {
          // Fetch the file content as a blob
          const response = await fetch(fileUrl);
          const blob = await response.blob();

          // Read the blob as an array buffer
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              resolve(reader.result);
            };
            reader.onerror = () => {
              reject(new Error("Failed to read file"));
            };
            reader.readAsArrayBuffer(blob);
          });
        } catch (error) {
          console.error("Error fetching file:", error);
          return null;
        }
      };

      const attachmentsData = await Promise.all(
        visitDetailsformData?.attachments.map(async (data) => {
          // console.log(data);
          // Fetch array buffer from URL
          const arrayBuffer = await urlToFileArrayBuffer(data.objectURL);
          // const base64String = btoa(String.fromCharCode.apply(null, arrayBuffer));
          const uint8Array = new Uint8Array(arrayBuffer);

          // console.log(arrayBuffer);
          // Convert array buffer to Blob
          const blob = new Blob([arrayBuffer]);
          // console.log("blob-", blob);
          // Return object with file name and FormData
          return {
            file_name: data.name,
            file_data: Array.from(uint8Array),
          };
        })
      );

      const payload = {
        ticket_id: ticketId,
        start_date: visitDetailsformData.start_date,
        end_date: visitDetailsformData.end_date,
        total_expense: visitDetailsformData.total_expenses
          ? visitDetailsformData.total_expenses
          : null,
        distance: visitDetailsformData.distance
          ? visitDetailsformData.distance
          : null,
        remarks: visitDetailsformData.remarks,
        machine_code: get_machine_code(visitDetailsformData.location),
        support_person: get_support_persons_list(
          visitDetailsformData.support_person
        ),
        parts_replaced: get_parts_replaced_list(
          visitDetailsformData.parts_replaced
        ),
        attachment_data: attachmentsData,
      };
      // console.log("final payload for addding new visit details:", payload);

      if (addVisitdetailsButton) {
        // console.log("we will hit the add visit api");
        setLoadingButton(true);
        const response = await fetchApi(
          "add_support_visit",
          "POST",
          payload,
          true
        );
        if (response.statusCode === 200) {
          // Reload the React component
          // console.log("ticket edited succesfully");
          openSnackbar("visit details added succesfully", "success");
          handleCloseVisitDetailsModal();
          handleReloadData();
        } else {
          console.error(
            "Error editing ticket information:",
            response.statusCode
          );
          openSnackbar(
            "some error occured while saving visit details",
            "error"
          );
        }
      } else {
        console.log("we will hit the edit visit api");
        const payloadEdit = {
          ticket_id: ticketId,
          visit_id: visitId,
          start_date: visitDetailsformData.start_date,
          end_date: visitDetailsformData.end_date,
          total_expense: visitDetailsformData.total_expenses
            ? visitDetailsformData.total_expenses
            : "",
          distance: visitDetailsformData.distance
            ? visitDetailsformData.distance
            : null,
          remarks: visitDetailsformData.remarks,
          machine_code: get_machine_code(visitDetailsformData.location),
          support_person: get_support_persons_list(
            visitDetailsformData.support_person
          ),
          parts_replaced: get_parts_replaced_list(
            visitDetailsformData.parts_replaced
          ),
          attachment_data: attachmentsData,
        };
        // console.log("edit payload", payloadEdit);
        setLoadingButton(true);
        const response = await fetchApi(
          "edit_support_visit",
          "POST",
          payloadEdit,
          true
        );
        if (response.statusCode === 200) {
          // console.log("ticket edited succesfully");
          openSnackbar("visit details edited succesfully", "success");
          handleCloseVisitDetailsModal();
          handleReloadData();
        } else {
          console.error(
            "Some error occured while editing visit details",
            response.statusCode
          );
          openSnackbar("some error occured while saving details", "error");
        }
      }
      setLoadingButton(false);
    } catch (error) {
      console.log("some error occured!");
      openSnackbar("some error occured while saving details", "error");
    }
    // handleCloseVisitDetailsModal();
  };

  // handling onChange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVisitDetailsFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // handle Date Change
  const handleDateChange = (name, value) => {
    // console.log(value, "value");
    // console.log(dayjs(value, "--------------check value"));
    // Convert dayjs object to  format "MM/DD/YYYY"
    const formattedDate = dayjs(value).format("YYYY-MM-DD").toString();
    // console.log(formattedDate, "formattedDate");

    setVisitDetailsFormData((prevState) => ({
      ...prevState,
      [name]: formattedDate,
    }));
  };

  const handleReloadData = () => {
    setReloadData((prevState) => !prevState);
  };

  let is_ticket_data_Mounted;
  const visitTicket = async (is_ticket_data_Mounted) => {
    try {
      const payload = {
        ticket_id: ticketId,
      };

      if (refreshBtn) {
        setRefreshLoading(true);
      }

      const jsonData = await fetchApi(
        "get_support_visits",
        "POST",
        payload,
        is_ticket_data_Mounted
      );
      if (jsonData?.statusCode === 200 && jsonData && jsonData?.data) {
        if (is_ticket_data_Mounted) {
          setGetBackendVisitDetails(jsonData?.data?.visit_data);
        }
      } else {
        // openSnackbar(jsonData.data, "error");
        showModalErrorPage(jsonData.statusCode, jsonData.msg);
        // showLoadingModal(false);
      }
    } catch (error) {
      openSnackbar("Some error occured while getting visits data");
    } finally {
      showLoadingModal(false);
      setRefreshLoading(false);
      setRefreshBtn(false);
    }
  };

  useEffect(() => {
    is_ticket_data_Mounted = true;
    showLoadingModal(true);
    visitTicket(is_ticket_data_Mounted);
    return () => {
      is_ticket_data_Mounted = false;
    };
  }, [reloadData, reloadVisitsPage]);

  // delection modal state
  const [confirmDeletionState, setConfirmDeletionState] = useState({
    isOpen: false,
    modalType: "",
    ticketId: "",
    attachId: "",
  });

  // closing of modal
  const closeConfirmDelectionModal = (event, reason) => {
    if (reason && reason == "backdropClick") {
      // console.log("backdropClicked. Not closing dialog.");
      return;
    }
    setConfirmDeletionState((prev) => ({ ...prev, isOpen: false }));
  };
  return (
    <>
      {(!showModalError.status && !lodingModalVisiblity && (
        <div className="main_visit_container p-3">
          <div>
            <button
              type="button"
              // disabled={ticketStatus === "resolved"}
              className="btn btn-outline-primary markResolved_button "
              onClick={() => {
                handleVisitDetailsAddButtonClick();
              }}
            >
              <Tooltip
                data-html="true"
                title={"Click here to add visit details"}
                arrow={true}
              >
                Add Visit Details
              </Tooltip>
            </button>
          </div>

          <div className="show_previous_vist_details mt-3">
            {(getBackendVisitDetails?.length > 0 && (
              <>
                <h6>Previous Visits Details</h6>
                <div className="d-flex flex-row flex-wrap w-100 ">
                  {getBackendVisitDetails?.map((data) => (
                    <div
                      className="prev_visits_container"
                      onClick={() => handleVisitDetailsAddButtonClick(data)}
                    >
                      <div className="prev_visits_body">
                        {/* visit_id  */}
                        <div className="mb-2">
                          <p className="mb-0">
                            <span className="me-2 ">
                              <i class="bi bi-ticket-fill"></i>
                            </span>
                            {data.id}
                          </p>
                        </div>

                        {/* edit button' */}

                        {/* support_persons */}

                        <div className="">
                          <p className="mb-2 d-flex flex-row flex-nowrap">
                            <span className="me-2">
                              <i className="bi bi-person-fill"></i>
                            </span>

                            <div className="d-flex flex-row flex-wrap">
                              {data?.support_person?.length > 0
                                ? data.support_person.map(
                                    (each_person, index) => (
                                      <React.Fragment key={index}>
                                        <p className="mb-0">
                                          {index > 0 && (
                                            <span>
                                              <i className="bi bi-dot"></i>
                                            </span>
                                          )}
                                          <span className="me-2">
                                            {each_person["name"]
                                              ? each_person["name"]
                                              : ""}
                                          </span>
                                        </p>
                                      </React.Fragment>
                                    )
                                  )
                                : "Not mentioned name"}
                            </div>
                          </p>
                        </div>

                        {/* visit_location */}
                        <div>
                          <p className="mb-2">
                            <span className="me-1">
                              <i className="bi bi-geo-alt-fill"></i>
                            </span>{" "}
                            {data.machine_code} - (
                            {get_machine_code(data.machine_code)})
                          </p>
                        </div>

                        <div className="d-flex flex-row mb-2 custom_small">
                          <div className="me-4 ">
                            {" "}
                            {/* visit distance */}
                            <p className="mb-0">
                              <span className="me-1">
                                <i class="fas fa-running"></i>
                              </span>{" "}
                              {data.distance
                                ? `${data.distance} km`
                                : "Not Mentioned"}{" "}
                            </p>
                          </div>

                          <div className="me-4">
                            {/* Total_expense */}
                            <p className="mb-0">
                              <span className="expense">
                                <i class="bi bi-currency-rupee"></i>
                              </span>
                              {data.total_expense
                                ? data.total_expense
                                : "Not Mentioned"}
                            </p>
                          </div>

                          {/* count of attachments */}
                          <div className="me-4">
                            <p className="mb-0">
                              {" "}
                              <span className="me-2">
                                <i class="bi bi-paperclip"></i>
                              </span>
                              {data?.attachments?.length}
                            </p>
                          </div>
                        </div>

                        {/* parts replaced */}
                        <div className="d-flex flex-row flex-nowrap mb-2">
                          <span className="me-2">
                            <i className="bi bi-gear"></i>
                          </span>

                          <div className="d-flex flex-row flex-wrap">
                            {" "}
                            {data.parts_replaced.length > 0 ? (
                              data.parts_replaced.map((parts, index) => (
                                <p className="mb-0 me-2">
                                  <React.Fragment key={index}>
                                    {index > 0 && <i className="bi bi-dot"></i>}
                                    {parts.hardware}
                                  </React.Fragment>
                                </p>
                              ))
                            ) : (
                              <p className="mb-0 ms-2">Not Mentioned</p>
                            )}
                          </div>
                        </div>

                        {/* remarks */}

                        <div className="mb-2">
                          <p className="mb-0">
                            <span className="me-2">
                              <i class="bi bi-chat-dots"></i>
                            </span>
                            {data.remarks ? data.remarks : "No remarks"}
                          </p>
                        </div>

                        {/* visit start_date - End date  */}
                        <div className="d-flex flex-row justify-content-between mb-2 visit_details_small_screen">
                          {data.start_date && (
                            <div>
                              <p className="m-0 text-wrap">
                                <span className="me-2">
                                  <i class="bi bi-calendar-date"></i>
                                </span>
                                {dayjs(data.start_date).format(
                                  "ddd MMM DD YYYY"
                                )}
                              </p>
                              <p className="date_details">Visited Date</p>
                            </div>
                          )}
                          {data.end_date && (
                            <div>
                              <p className="mb-0">
                                {" "}
                                <span className="me-2">
                                  <i class="bi bi-calendar-date"></i>
                                </span>
                                {dayjs(data.start_date).format(
                                  "ddd MMM DD YYYY"
                                )}
                              </p>

                              <p className="date_details"> Resolved Date</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )) ||
              "No Visit for this ticket yet"}
          </div>

          <Modal
            // size="xl"
            // className="modal-container"
            open={showVisitDetailsModal}
            onClose={handleCloseVisitDetailsModal}
            // backdrop="static"
            style={{
              position: "fixed",
              margin: "10px 0px 10px 10px",
            }}
          >
            <Box
              style={{
                position: "relative",
                zIndex: "1111",
                right: "0",
                top: "4%",
                left: "0",
                margin: "  auto",
                overflowY: "auto",
                borderRadius: "10px",
                backgroundColor: "#ffffff",
                maxWidth: "992px",
                width: "80vw",
                height: "90vh",
              }}
            >
              <div
                className="d-flex justify-content-between align-items-center p-3"
                style={{ borderBottom: "1px solid #f5f5f5" }}
              >
                <p className="m-0">Visit Details</p>
                {/* <span
                  onClick={isEditVisits}
                  className={
                    addVisitdetailsButton
                      ? "d-none edit_visit_icon"
                      : "edit_visit_icon"
                  }
                >
                  {isEditVisitDetails ? (
                    <img src={pensil} alt="" />
                  ) : (
                    <img src={edit_icon} alt="" />
                  )}
                </span> */}
                <div
                  onClick={() => {
                    handleCloseVisitDetailsModal();
                  }}
                >
                  <img
                    src={close}
                    alt="close"
                    style={{ width: "20px", cursor: "pointer" }}
                  />
                </div>
              </div>

              <Box className="px-0 py-0 px-sm-3 py-sm-2 ">
                <div className="main_visit_modal_container">
                  <div className="visit_details_form ">
                    <div className="d-flex flex-column flex-lg-row align-items-center mb-lg-3">
                      <div className="p-2 w-100 modal_elements ">
                        <Autocomplete
                          multiple
                          name="support_person"
                          id="tags-outlined"
                          value={visitDetailsformData.support_person}
                          options={backendFilterData?.support_name_list}
                          getOptionLabel={(option) => option}
                          filterSelectedOptions
                          onChange={(e, selectedOptions) => {
                            setVisitDetailsFormData((prev) => ({
                              ...prev,
                              support_person: selectedOptions.map(
                                (option) => option
                              ),
                            }));
                          }}
                          disabled={isEditVisitDetails}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Support Persons*"
                              placeholder="Support Persons"
                              sx={{
                                "& .MuiButtonBase-input.Mui-disabled": {
                                  opacity: "0.6",
                                },
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="p-2 w-100">
                        <FormControl
                          // sx={{ m: 1, width: "fullwidth" }}
                          size="medium"
                          fullWidth
                        >
                          <InputLabel id="location-label">Location*</InputLabel>
                          <Select
                            disabled={isEditVisitDetails}
                            labelId="location-label"
                            value={visitDetailsformData.location}
                            onChange={handleInputChange}
                            label="Location*"
                            name="location"
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                              },
                            }}
                          >
                            {backendFilterData?.machine_location_list?.map(
                              (location, index) => (
                                // console.log(
                                //   "location one by one:",
                                //   location
                                // );
                                <MenuItem key={index} value={location}>
                                  {location}-({get_machine_code(location)})
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="d-flex flex-column flex-lg-row mb-lg-3">
                      <div className="d-flex flex-row align-items-center w-100 w-lg-50 p-2">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={["DatePicker"]}
                            sx={{
                              overflow: "unset",
                              padding: "0px",
                              width: "100%",
                            }}
                          >
                            <DatePicker
                              label="Start Date*"
                              maxDate={
                                visitDetailsformData?.end_date
                                  ? dayjs(visitDetailsformData.end_date)
                                  : dayjs()
                              }
                              // maxDate={dayjs()}
                              value={visitDetailsformData.start_date}
                              onChange={(date) =>
                                handleDateChange("start_date", date)
                              }
                              className="min_width_unset"
                              disabled={isEditVisitDetails}
                              sx={{
                                "& .MuiStack-root>.MuiTextField-root": {
                                  minWidth: "unset",
                                },
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>

                        <i class="bi bi-arrow-left-right mx-2"></i>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={["DatePicker"]}
                            sx={{
                              overflow: "unset",
                              padding: "0px",
                              width: "100%",
                            }}
                          >
                            <DatePicker
                              className="min_width_unset"
                              label="End Date*"
                              disabled={isEditVisitDetails}
                              minDate={dayjs(visitDetailsformData?.start_date)}
                              maxDate={dayjs()}
                              value={visitDetailsformData.end_date}
                              onChange={(date) =>
                                handleDateChange("end_date", date)
                              }
                              sx={{
                                "& .MuiStack-root>.MuiTextField-root": {
                                  minWidth: "unset",
                                },
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>

                      <div className="p-2 w-100 w-lg-50 modal_elements">
                        <Autocomplete
                          multiple
                          disabled={isEditVisitDetails}
                          name="parts_replaced"
                          id="tags-outlined"
                          value={visitDetailsformData.parts_replaced}
                          options={backendFilterData?.hardware_relaced_list}
                          getOptionLabel={(option) => option}
                          filterSelectedOptions
                          onChange={(e, selectedOptions) => {
                            setVisitDetailsFormData((prev) => ({
                              ...prev,
                              parts_replaced: selectedOptions.map(
                                (option) => option
                              ),
                            }));
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Parts Replaced*"
                              placeholder="Parts Replaced"
                            />
                          )}
                        />
                      </div>
                    </div>

                    {/* distance && expenses section */}
                    <div className="d-flex flex-column flex-lg-row mb-lg-3">
                      {/* distance */}
                      <div className="p-2 w-100">
                        {" "}
                        <TextField
                          value={visitDetailsformData.distance}
                          name="distance"
                          fullWidth
                          id="outlined-basic"
                          label="Distance (km)"
                          variant="outlined"
                          type="number"
                          onChange={handleInputChange}
                          disabled={isEditVisitDetails}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                            },
                          }}
                        />
                      </div>
                      <div className="p-2 w-100">
                        {" "}
                        <TextField
                          value={visitDetailsformData.total_expenses}
                          name="total_expenses"
                          fullWidth
                          id="outlined-basic"
                          label="Total Expenses*"
                          variant="outlined"
                          type="number"
                          disabled={isEditVisitDetails}
                          onChange={handleInputChange}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                            },
                          }}
                        />
                      </div>
                    </div>

                    {/* parts replaced & remarks desp */}

                    <div className="d-flex flex-column flex-lg-row mb-lg-3">
                      <div className="p-2 w-100">
                        <FormControl className="w-100">
                          <TextField
                            name="remarks"
                            label="Remarks*"
                            id="outlined-textarea"
                            disabled={isEditVisitDetails}
                            onChange={handleInputChange}
                            rows={4}
                            multiline
                            value={visitDetailsformData.remarks}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "rgba(0, 0, 0, 0.65)",
                              },
                            }}
                          />
                        </FormControl>
                      </div>
                    </div>

                    {/* Attachements */}
                    {!addVisitdetailsButton && (
                      <div className="show-attachments-content px-2 mb-lg-3">
                        <header className="pb-2 previous-comments-header">
                          Attachments
                        </header>
                        {(prevVisitAttachments?.length && (
                          <div className="d-sm-flex flex-wrap gap-3">
                            {prevVisitAttachments &&
                              prevVisitAttachments?.map((attachment, index) => {
                                if (attachment?.filetype) {
                                  let fileUrl;
                                  const fileType =
                                    attachment?.filetype?.toLowerCase();

                                  if (
                                    ["png", "jpg", "jpeg", "webp"].includes(
                                      fileType
                                    )
                                  ) {
                                    fileUrl = `data:image/${fileType};base64,${attachment?.data}`;
                                  } else if (fileType === "pdf") {
                                    fileUrl = `data:application/pdf;base64,${attachment.data}`;
                                  } else if (
                                    ["xlsx", "xls"].includes(fileType)
                                  ) {
                                    fileUrl = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${attachment.data}`;
                                  } else if (fileType === "csv") {
                                    fileUrl = `data:text/csv;base64,${attachment.data}`;
                                  } else if (fileType === "mp3") {
                                    fileUrl = `data:audio/mp3;base64,${attachment.data}`;
                                  } else {
                                    return null;
                                  }

                                  return (
                                    <div
                                      key={index}
                                      className="d-flex align-items-center data-variation-elements my-3 mx-2 my-sm-0 mx-sm-0"
                                    >
                                      <a
                                        href={fileUrl}
                                        download={`${attachment?.filename}`}
                                      >
                                        {attachment?.filename}
                                      </a>

                                      <div className="ms-auto">
                                        <div
                                          className="dropdown ms-2  "
                                          style={{ cursor: "pointer" }}
                                        >
                                          <Tooltip
                                            data-html="true"
                                            title={
                                              "Click to Delete this attachment"
                                            }
                                            arrow={true}
                                          >
                                            <i
                                              className="fa-solid fa-ellipsis-vertical  "
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                              style={{ padding: "0px 3px" }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                              }}
                                            ></i>
                                          </Tooltip>
                                          {/* Centered dropdown */}
                                          {/* </button> */}
                                          <ul className="dropdown-menu dropdown-menu-end delete-ticket-btn-border">
                                            <li>
                                              <a
                                                className="dropdown-item text-center delete-ticket-btn"
                                                href="#"
                                                onClick={() => {
                                                  setConfirmDeletionState(
                                                    (prev) => ({
                                                      ...prev,
                                                      isOpen: true,
                                                      modalType:
                                                        "visit_attachment",
                                                      ticketId: ticketId,
                                                      attachId: attachment.id,
                                                    })
                                                  );
                                                }}
                                              >
                                                Delete
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                          </div>
                        )) ||
                          "No attachments added"}
                      </div>
                    )}
                  </div>
                </div>
                {/* attachments */}
                {!isEditVisitDetails && (
                  <div style={{ padding: "13px 21px 0px 21px" }}>
                    <p>* Service report and Machine images are mandatory</p>
                    <FilesUpload
                      genFields={visitDetailsformData}
                      setGenFields={setVisitDetailsFormData}
                      isSmallScreen={isSmallScreen}
                    />
                  </div>
                )}

                <Typography className="float-end p-3 px-4">
                  <LoadingButton
                    size="small"
                    onClick={handleSaveChanges}
                    disabled={
                      // ticketStatus === "resolved" ||
                      visitDetailsformData.location === "" ||
                      visitDetailsformData?.total_expenses === "" ||
                      (addVisitdetailsButton &&
                        visitDetailsformData?.attachments.length === 0) ||
                      visitDetailsformData?.start_date === null ||
                      visitDetailsformData?.end_date === null ||
                      visitDetailsformData?.support_person.length === 0 ||
                      visitDetailsformData?.parts_replaced.length === 0 ||
                      visitDetailsformData?.remarks === "" ||
                      isEditVisitDetails
                    }
                    endIcon={<SendIcon />}
                    loading={loadingButton}
                    loadingPosition="end"
                    variant="contained"
                    style={{ height: "36px" }}
                  >
                    <span>{`${
                      addVisitdetailsButton ? "Add Visit " : "Save Changes"
                    }`}</span>
                  </LoadingButton>
                </Typography>
              </Box>
            </Box>
          </Modal>

          <ConfirmDeletionModal
            deleteModalState={confirmDeletionState}
            handleClose={closeConfirmDelectionModal}
            handleReload={handleReloadData}
            closingVisitModal={handleCloseVisitDetailsModal}
          />
        </div>
      )) ||
        (showModalError.status && <ModalError />)}
    </>
  );
}

export default VisitDetails;
