import React, { useContext, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import close from "../../assets/images/close.svg";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import ContextState from "../contextApi/ContextState";

const UpdateModelDetailsModal = ({
  editModalDetails,
  handleClose,
  handleReloadData,
}) => {
  const crop_details = useSelector((state) => state.userFilters?.data.data);
  const { fetchApi, openSnackbar } = useContext(ContextState);
  //   mui media querries
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [modalState, setModalState] = useState({
    machine_code: "",
    crop: "",
    commit_id: "",
    model_number: "",
    git_branch: "",
    remarks: "",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editModalDetails) {
      setModalState({
        machine_code: editModalDetails?.machine_code,
        crop: editModalDetails?.crop,
        commit_id: editModalDetails?.commit_id,
        model_number: editModalDetails?.model_number,
        git_branch: editModalDetails?.git_branch || "",
        remarks: editModalDetails?.remarks || "",
      });
    }
  }, [editModalDetails]);

  const crop_list = Object.keys(crop_details?.param_data || {});
  const commit_id_list = Object.values(crop_details?.commit_id_list || {}).map(
    (item) => item.slice(0, 5)
  );
  const model_no_list = Object.values(crop_details?.model_no_list || {});

  const git_branch_list = Object.values(crop_details?.git_branch_list || {});

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setModalState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getCropId = (cropName) => {
    for (const [key, value] of Object.entries(crop_details?.param_data)) {
      if (value.matt_crop === cropName) {
        return value.crop_id;
      }
    }
    return null;
  };

  // function to get gitBranch id from git branch name
  const getGitBranchId = (gitBranch) => {
    for (const [key, value] of Object.entries(crop_details?.git_branch_list)) {
      if (value === gitBranch) {
        return key;
      }
    }
    return null;
  };

  const fetching_edit_model_api = async () => {
    try {
      setLoading(true);

      const payload = {
        ticket_id: editModalDetails?.ticket_id,
        id: editModalDetails?.id,
        crop_id: getCropId(modalState?.crop),
        machine_code: modalState?.machine_code,
        commit_id: modalState?.commit_id,
        model_no: modalState?.model_number,
        remarks: modalState?.remarks,
        git_branch: getGitBranchId(modalState?.git_branch),
      };

      const response = await fetchApi(
        "add_model_details_log",
        "POST",
        payload,
        true
      );

      if (response.statusCode === 200) {
        openSnackbar(response?.data?.message);
        handleReloadData();
        handleClose();
      } else {
        openSnackbar(response?.msg);
      }
    } catch (error) {
      openSnackbar("Some error occured while Updating Model Details", "error");
    } finally {
      setLoading(false);
    }
  };

  const disabled_button_status =
    modalState?.git_branch !== "" &&
    modalState?.crop !== "" &&
    modalState?.commit_id !== "" &&
    modalState?.machine_code !== "" &&
    modalState?.remarks !== "" &&
    modalState?.model_number !== "";

  return (
    <Modal
      open={editModalDetails?.isOpen}
      onClose={handleClose}
      style={{
        position: "fixed",
        overflowY: "scroll",
        height: "100%",
        margin: "10px 0px 10px 10px",
      }}
    >
      <Box
        style={{
          position: "relative",
          zIndex: "1111",
          right: "0",
          height: "auto",
          top: "3%",
          left: "0",
          margin: "auto",
          borderRadius: "10px",
          backgroundColor: "#ffffff",
          maxWidth: "1200px",
        }}
        className=""
      >
        {/* Modal Header */}
        <div
          style={{
            padding: "16px 24px",
            borderBottom: "1px solid #f5f5f5",
          }}
        >
          <div className="d-flex justify-content-between">
            <h5 className="m-0">Edit Model Details</h5>
            <div
              onClick={() => {
                handleClose();
              }}
            >
              <img
                src={close}
                alt="close"
                style={{ width: "20px", cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
        {/* Modal Header ends... */}

        {/* Modal Body Starts .... */}
        <div className="modal_body" style={{ padding: "24px 20px" }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: isSmallScreen
                ? "1fr"
                : fullScreen
                ? "1fr 1fr"
                : "1fr 1fr 1fr",
              gap: "20px",
            }}
          >
            {/* Git Branch */}
            <FormControl>
              <Autocomplete
                className="w-100"
                disablePortal
                size="medium"
                id="combo-box-demo"
                options={git_branch_list}
                value={modalState.git_branch}
                renderInput={(params) => (
                  <TextField {...params} label="Git Branch" />
                )}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setModalState((prev) => ({
                      ...prev,
                      git_branch: newValue,
                    }));
                  }
                }}
              />
            </FormControl>

            {/* machine code */}
            <FormControl fullWidth>
              <InputLabel id="parameter-select-label">Machine Code</InputLabel>
              <Select
                labelId="parameter-select-label"
                id="parameter-select"
                name="machine_code"
                value={modalState.machine_code}
                label="Machine Code"
                onChange={handleOnChange}
              >
                {[modalState?.machine_code].map((code, index) => (
                  <MenuItem key={index} value={code}>
                    {code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Commit id */}
            <FormControl>
              <Autocomplete
                disablePortal
                size="medium"
                id="combo-box-demo"
                options={commit_id_list}
                value={modalState.commit_id}
                renderInput={(params) => (
                  <TextField {...params} label="Commit Id" />
                )}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setModalState((prev) => ({
                      ...prev,
                      commit_id: newValue,
                    }));
                  }
                }}
              />
            </FormControl>

            {/* Model Number */}

            <FormControl>
              <Autocomplete
                // disablePortal
                size="medium"
                id="combo-box-demo"
                options={model_no_list}
                value={modalState.model_number}
                renderInput={(params) => (
                  <TextField {...params} label="Model Number" />
                )}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setModalState((prev) => ({
                      ...prev,
                      model_number: newValue,
                    }));
                  }
                }}
              />
            </FormControl>

            {/* Crop */}

            <FormControl fullWidth>
              <InputLabel id="parameter-select-label">Crop</InputLabel>
              <Select
                labelId="parameter-select-label"
                id="parameter-select"
                name="crop"
                value={modalState.crop}
                label="Crop"
                onChange={handleOnChange}
              >
                {crop_list.map((para) => (
                  <MenuItem key={para} value={para}>
                    {para}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {/* remarks */}
          <FormControl
            fullWidth
            className="pt-3"
            //   error={fieldError.descError}
          >
            <TextField
              //   error={fieldError.descError}
              fullWidth
              id="outlined-multiline-static"
              label="Remarks  "
              multiline
              name="remarks"
              rows={4}
              labelId="demo-simple-select-label"
              value={modalState.remarks}
              onChange={handleOnChange}
            />
            {/* {fieldError.descError ? (
              <div
                style={{
                  marginTop: "8px",
                  color: "red",
                  fontWeight: 300,
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
              >
                Please enter description
              </div>
            ) : (
              <></>
            )} */}
          </FormControl>
        </div>

        {/* Modal Body ends .... */}
        {/* Modal Footer starts .... */}

        <div className="footer py-2 pb-3 px-3 d-flex justify-content-end">
          {" "}
          <LoadingButton
            size="large"
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
            onClick={fetching_edit_model_api}
            disabled={!disabled_button_status}
          >
            <span>Submit</span>
          </LoadingButton>
        </div>
        {/* Modal Footer ends .... */}
      </Box>
    </Modal>
  );
};

export default UpdateModelDetailsModal;
