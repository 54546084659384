import React, { useContext, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import "../../assets/styles/CardModal.css";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import GeneralContent from "./GeneralContent";
import { BeatLoader } from "react-spinners";
import ContextState from "../contextApi/ContextState";
import RefreshIcon from "@mui/icons-material/Refresh";

import DataPool from "./DataPool";
import Visit from "./VisitDetails";
import AttachmentsContent from "./AttachmentsContent";
import ResultContent from "./ResultContent";
import Comments from "./Comments";
import Timeline from "./Timeline";
import Modal from "@mui/material/Modal";
import ModelDetailsLog from "./ModelDetailsLog";

const CardDetailModal = ({ isOpen, onClose, data, reloadData }) => {
  const {
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    closeSnackbar,
    lodingModalVisiblity,
    showModalErrorPage,
  } = useContext(ContextState);

  const [reloadButtonClicked, setReloadButtonClicked] = useState({
    generalTab: false,
    attachmentsTab: false,
    scanDetailsTab: false,
    timelineTab: false,
    commentsTab: false,
    dataPullTab: false,
    visitsTab: false,
    modelLog: false,
  });
  const [activeTab, setActiveTab] = useState("#");

  // to get the sate of reloaded tab
  // const [reloadSameTab, setReloadSameTab] = useState(false);

  // this varibale is to animate the loading icon
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [refreshBtn, setRefreshBtn] = useState(false);

  useEffect(() => {
    setActiveTab("#");
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.title = `${
        activeTab === "#"
          ? "General Info"
          : activeTab === "link-1"
          ? "Attachments"
          : activeTab === "link-2"
          ? "Scan Details"
          : activeTab === "link-3"
          ? "Timeline"
          : activeTab === "link-4"
          ? "Comments"
          : activeTab === "link-5"
          ? "Visits"
          : activeTab === "link-6"
          ? "Pull Data"
          : activeTab === "link-7"
          ? "Model Log"
          : ""
      } - NEO`;
    } else {
      document.title = "All Tickets - NEO";
    }
    return () => {};
  }, [activeTab, isOpen]);

  const reloadCorresponsingTab = () => {
    // console.log(reloadButtonClicked)
    setRefreshBtn(true);
    showModalErrorPage(null, null);
    if (activeTab === "#") {
      // console.log("button clciked");
      setReloadButtonClicked((prevState) => ({
        ...prevState,
        generalTab: !prevState.generalTab,
      }));
    } else if (activeTab === "link-1") {
      setReloadButtonClicked((prevState) => ({
        ...prevState,
        attachmentsTab: !prevState.attachmentsTab,
      }));
    } else if (activeTab === "link-2") {
      setReloadButtonClicked((prevState) => ({
        ...prevState,
        scanDetailsTab: !prevState.scanDetailsTab,
      }));
    } else if (activeTab === "link-3") {
      setReloadButtonClicked((prevState) => ({
        ...prevState,
        timelineTab: !prevState.timelineTab,
      }));
    } else if (activeTab === "link-4") {
      setReloadButtonClicked((prevState) => ({
        ...prevState,
        commentsTab: !prevState.commentsTab,
      }));
    } else if (activeTab === "link-5") {
      setReloadButtonClicked((prevState) => ({
        ...prevState,
        visitsTab: !prevState.visitsTab,
      }));
    } else if (activeTab === "link-6") {
      setReloadButtonClicked((prevState) => ({
        ...prevState,
        dataPullTab: !prevState.dataPullTab,
      }));
    } else if (activeTab === "link-7") {
      setReloadButtonClicked((prevState) => ({
        ...prevState,
        modelLog: !prevState.modelLog,
      }));
    }
  };

  const handleTabSelect = (eventKey) => {
    if (activeTab === eventKey) {
      showModalErrorPage(null, null);
      // setReloadSameTab(!reloadSameTab);
    } else {
      setActiveTab(eventKey);
      showModalErrorPage(null, null);
    }
  };

  const modalstyle = {
    position: "relative",
    zIndex: "80",
    top: "2%",
    width: "90vw",
    margin: "  auto",
    overflowY: "auto",
    height: "95vh",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    overflowX: "hidden",
  };
  return (
    <Modal
      style={{
        position: "fixed",
        height: "100%",
      }}
      open={isOpen}
      onClose={onClose}
      // contentLabel="Card Detail Modal"
      // ariaHideApp={false}
      // shouldCloseOnOverlayClick={false}
    >
      {/* Render specific data within the modal */}
      <Box sx={modalstyle}>
        {data && (
          <>
            {/* <CardDetails/> */}
            <Box sx={{ width: 500 }}>
              <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
                message={snackbarMessage}
                severity={snackbarSeverity}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                action={
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={closeSnackbar}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              >
                <Alert
                  onClose={closeSnackbar}
                  severity={snackbarSeverity}
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  {snackbarMessage}
                </Alert>
              </Snackbar>
            </Box>
            {lodingModalVisiblity && (
              <div className="showSpinnerTicketModal">
                <BeatLoader color="#2D97D4" />
              </div>
            )}

            <div className="ModalHeader">
              <Nav
                variant="tabs"
                defaultActiveKey="#"
                activeKey={activeTab}
                onSelect={handleTabSelect}
              >
                <Tooltip
                  data-html="true"
                  title={"General Ticket Information Section"}
                  arrow={true}
                >
                  <Nav.Item>
                    <Nav.Link className="general" eventKey="#">
                      General Info
                    </Nav.Link>
                  </Nav.Item>
                </Tooltip>
                <Tooltip
                  data-html="true"
                  title={"Attachments Section"}
                  arrow={true}
                >
                  <Nav.Item>
                    <Nav.Link className={"general"} eventKey="link-1">
                      attachments
                    </Nav.Link>
                  </Nav.Item>
                </Tooltip>
                <Tooltip
                  data-html="true"
                  title={"Data Issues Details Section"}
                  arrow={true}
                >
                  <Nav.Item>
                    <Nav.Link className={"general"} eventKey="link-2">
                      Scan Details
                    </Nav.Link>
                  </Nav.Item>
                </Tooltip>
                <Tooltip
                  data-html="true"
                  title={"Timeline Section"}
                  arrow={true}
                >
                  <Nav.Item>
                    <Nav.Link className={"general"} eventKey="link-3">
                      Timeline
                    </Nav.Link>
                  </Nav.Item>
                </Tooltip>
                <Tooltip
                  data-html="true"
                  title={"Comments Section"}
                  arrow={true}
                >
                  <Nav.Item>
                    <Nav.Link className={"general"} eventKey="link-4">
                      Comments
                    </Nav.Link>
                  </Nav.Item>
                </Tooltip>
                <Tooltip data-html="true" title={"Visit Section"} arrow={true}>
                  <Nav.Item>
                    <Nav.Link className={"general"} eventKey="link-5">
                      Visits
                    </Nav.Link>
                  </Nav.Item>
                </Tooltip>
                <Tooltip
                  data-html="true"
                  title={"Data Pull Section"}
                  arrow={true}
                >
                  <Nav.Item>
                    <Nav.Link className={"general"} eventKey="link-6">
                      Pull Data
                    </Nav.Link>
                  </Nav.Item>
                </Tooltip>

                <Tooltip
                  data-html="true"
                  title={"Model Log Section"}
                  arrow={true}
                >
                  <Nav.Item>
                    <Nav.Link className={"general"} eventKey="link-7">
                      Model Log
                    </Nav.Link>
                  </Nav.Item>
                </Tooltip>
              </Nav>
              <div className="end-sections-header">
                {/* <div
                onClick={reloadCorresponsingTab}
              >
                Reload
              </div> */}
                <Tooltip
                  className="tooltip-inner"
                  data-html="true"
                  title={"Refresh this section"}
                  arrow={true}
                >
                <RefreshIcon
                    className={`reload-section ${refreshLoading ? "rotate": ""}`}
                    onClick={reloadCorresponsingTab}
                    fontSize=""
                  />
                </Tooltip>


                <Tooltip
                  className="tooltip-inner"
                  data-html="true"
                  title={"Close"}
                  arrow={true}
                >
                  <CloseIcon
                    className="reload-section "
                    onClick={onClose}
                    fontSize=""
                  />
                </Tooltip>
              </div>
            </div>
            <div className="cardContent">
              {activeTab === "#" && (
                <GeneralContent
                  data={data}
                  reloadAllTicketsData={reloadData}
                  setRefreshLoading={setRefreshLoading}
                  refreshBtn={refreshBtn}
                  setRefreshBtn={setRefreshBtn}
                  // reloadSameTab={reloadSameTab}
                  reloadGeneralPage={reloadButtonClicked.generalTab}
                />
              )}
              {activeTab === "link-1" && (
                <AttachmentsContent
                  data={data}
                  setRefreshLoading={setRefreshLoading}
                  // reloadSameTab={reloadSameTab}
                  reloadAttachmentsPage={reloadButtonClicked.attachmentsTab}
                  refreshBtn={refreshBtn}
                  setRefreshBtn={setRefreshBtn}
                />
              )}

              {activeTab === "link-2" && (
                <ResultContent
                  data={data}
                  setRefreshLoading={setRefreshLoading}
                  // reloadSameTab={reloadSameTab}
                  reloadScanDetailsPage={reloadButtonClicked.scanDetailsTab}
                  refreshBtn={refreshBtn}
                  setRefreshBtn={setRefreshBtn}
                />
              )}

              {activeTab === "link-3" && (
                <Timeline
                  data={data}
                  setRefreshLoading={setRefreshLoading}
                  refreshBtn={refreshBtn}
                  setRefreshBtn={setRefreshBtn}
                  // reloadSameTab={reloadSameTab}
                  reloadTimelinePage={reloadButtonClicked.timelineTab}
                />
              )}

              {activeTab === "link-4" && (
                <Comments
                  data={data}
                  setRefreshLoading={setRefreshLoading}
                  refreshBtn={refreshBtn}
                  // reloadSameTab={reloadSameTab}
                  reloadCommentsPage={reloadButtonClicked.commentsTab}
                />
              )}

              {activeTab === "link-5" && (
                <Visit
                  data={data}
                  setRefreshLoading={setRefreshLoading}
                  refreshBtn={refreshBtn}
                  setRefreshBtn={setRefreshBtn}
                  reloadVisitsPage={reloadButtonClicked.visitsTab}
                />
              )}

              {activeTab === "link-6" && (
                <DataPool
                  data={data}
                  setRefreshLoading={setRefreshLoading}
                  refreshBtn={refreshBtn}
                  setRefreshBtn={setRefreshBtn}
                  reloadDataPullPage={reloadButtonClicked.dataPullTab}
                />
              )}
              {activeTab === "link-7" && (
                <ModelDetailsLog
                  data={data}
                  setRefreshLoading={setRefreshLoading}
                  refreshBtn={refreshBtn}
                  setRefreshBtn={setRefreshBtn}
                  reloadModelLogPage={reloadButtonClicked.modelLog}
                />
              )}
            </div>
          </>
        )}
      </Box>
      {/* <button onClick={onClose}>Close Modal</button> */}
    </Modal>
  );
};

export default CardDetailModal;
