import React, { useContext, useEffect, useState } from "react";
// import "../assets/styles/Location.css";
import "../../../assets/styles/Location.css";
// import LocationDataMainTable from "./User/Vists_Analytics/LocationDataMainTable";
import LocationDataMainTable from "../Vists_Analytics/LocationDataMainTable";
// import GroupSizesColors from "./User/ButtonGroup";
import {
  Button,
  LinearProgress,
  Box,
  ButtonGroup,
  linearProgressClasses,
  useMediaQuery,
} from "@mui/material";
import { useOutletContext } from "react-router-dom";

import ContextState from "../../contextApi/ContextState";
import noDataIMG from "../../../assets/images/no-data (1).png";

import socket from "../../socket/Socket"

// progress bar
import { styled } from "@mui/material/styles";
import CropCard from "./CropCard";
import CropModal from "./CropModal";
import { BeatLoader } from "react-spinners";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";
const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, colorAccept, colorReject }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: colorReject,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: colorAccept,
      borderRadius: 0,
      // borderRight: "2px solid white",
    },
  })
);

const CropOverview = () => {
  const { fetchApi, formatInIndianRupees } = useContext(ContextState);
  const outletContext = useOutletContext();
  // console.log("context value : ", outletContext);

  // user Details from redux
  const userData = useSelector((state) => state.userDetail);

  const color = outletContext.color;

  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  // console.log("isSmallSmallScreen: ", isSmallSmallScreen);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  // console.log("isSmallScreen: ", isSmallScreen);
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  // console.log("isMediumScreen: ", isMediumScreen);
  const islageScreen = useMediaQuery("(max-width:1400px)");
  // console.log("islageScreen: ", islageScreen);
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");

  const topDivWidth = {
    normal: isSmallScreen ? "280px" : "290px",
    isSmallScreen: "150px",
    isSmallSmallScreen: "250px",
  };

  const pageStyling = {
    imageBackgroundDivRadius: "25%",
    topDivwidth: "33px",

    IconWidth: "25px",
    iconBackgroundWidth: "50px",
    iconBackgroundHeight: "50px",

    monthlyScanDivDescIconWidth: "20px",
    monthlyScanDivDescIconBackgroundWidth: "40px",
    monthlyScanDivDescIconBackgroundHeight: "40px",
  };
  // bule shades for pie chart
  const shadesOfBlue = outletContext.shadesOfBlue;

  const LogoBGcolor = ["#6366f1", "#f04438", "#10b981"];

  const activeMachine = [
    {
      location: "Active Region",
      numerator: 6,
      denominator: 8,
    },
    {
      location: "Active District",
      numerator: 5,
      denominator: 8,
    },
    {
      location: "Active Machines",
      numerator: 11,
      denominator: 16,
    },
  ];

  const buttons = [
    { label: "Region", clicked: true },
    { label: "District", clicked: false },
    { label: "Machine", clicked: false },
  ];
  const [clickedButtonIndex, setClickedButtonIndex] = useState(0);
  const [tableData, setTableData] = useState({
    Region: true,
    District: false,
    Machine: false,
  });

  // state for modal
  const [isOpen, setIsopen] = useState(false);
  const [test, setTest] = useState(false);

  const handleButtonClick = (index, label) => {
    setClickedButtonIndex(index);
    setTableData((prevState) => {
      const updatedState = {};
      for (const key in prevState) {
        updatedState[key] = key === label;
      }
      return updatedState;
    });
  };

  const Data = outletContext.cropData;

  const [showCropModal, setshowCropModal] = useState(false);
  const [loading, showLoading] = useState(true);

  useEffect(() => {
    document.title = "Crop - NEO";
    // showLoading(true);
    let is_user_data_Mounted = true;
    const fetchDataAndUpdateFilters = async () => {
      // fetchFiltersDataApiCall
      setTimeout(async () => {
        const all_filter_data = await fetchApi(
          "get_crop_wise_overview",
          "POST",
          {},
          is_user_data_Mounted
        );
        // console.log("get_basic_details", all_filter_data);
        if (all_filter_data.statusCode === 200) {
          outletContext.handelshowFirstPageError(null, null);
          outletContext.handelCropData(all_filter_data?.data || null);
          // showLoading(false); // Moved here
          setTest((prevTest) => !prevTest);

          // console.log("all_filter_data?.data : ", all_filter_data?.data);
        } else if (
          all_filter_data.statusCode === 500 ||
          all_filter_data.statusCode === 401
        ) {
          // console.log("working");
          outletContext.handelCropData(null);
          outletContext.handelshowFirstPageError(
            all_filter_data.statusCode,
            all_filter_data.msg
          );
        } else {
          outletContext.handelCropData(null);
          // showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
          console.log("Some Error Occured", all_filter_data);
          localStorage.clear();
          window.location.href = "/login";
        }
        showLoading(false);
        return;
      }, 10);
    };

    // current page 
    const curr_page = "crop_overview";

    // Emit the custom 'initial_check' event when the component mounts
    if (Data) {
      // console.log(
      //   "data is already present, so doing initial check for crop page..."
      // );
      socket.emit("initial_check", { curr_page });
    }

    // every 60 seconds, i will be checking if there's any data update
    const intervalId = setInterval(() => {
      // console.log("checking for updates...")
      socket.emit('check_updates', { curr_page });
    }, 45000);

    // Listen for the 'update_<page>' event from the server
    socket.on(`update_${curr_page}`, (value) => {
      if (value === true) {
        console.log(`Reload the data for ${curr_page}...`);
        fetchDataAndUpdateFilters();
      } else {
        console.log(`Don't reload the data for ${curr_page}...`);
      }
    });

    if (!Data) {
      fetchDataAndUpdateFilters();
    } else {
      showLoading(false);
    }

    return () => {
      is_user_data_Mounted = false;
      clearInterval(intervalId);
      socket.off(`update_${curr_page}`);
    };
  }, []);

  const [sendCropDataToURL, setsendCropDataToURL] = useState(null);

  function handelsendCropDataToURL(value) {
    setsendCropDataToURL(value);
  }
  function handelRefreshOfTheCropDataToURL() {
    setsendCropDataToURL(null);
  }

  useEffect(() => {
    // console.log("sendCropDataToURL: ", sendCropDataToURL);
    setshowCropModal(true);
  }, [sendCropDataToURL]);

  const handleOpenmodal = (value) => {
    handelsendCropDataToURL(value);
  };

  const handleClosemodal = () => {
    setIsopen(false);
  };

  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  const [monthAssementsOpacity, setMonthAssementsOpacity] = useState({
    total: 1,
    accepted: 1,
  });
  const formatYAxis = (value) => {
    // Convert the value to a string with a locale-sensitive representation
    const xlablel = value < 0 ? -value : value;
    const formattedValue = xlablel.toLocaleString("en-IN");
    // Append "K" for thousands
    // console.log("formatXAxis: ", xlablel)
    if (xlablel >= 1000) {
      return `${Math.round(xlablel / 1000)}K`;
    }
    return formattedValue;
  };

  const handleMouseEnterLegendMonth = (o) => {
    const { dataKey } = o;
    let value = "";
    if (dataKey === "Total Scans") value = "total";
    else if (dataKey === "Total Accepted") value = "accepted";
    setMonthAssementsOpacity((prevOpacity) => {
      const updatedOpacity = {};
      Object.keys(prevOpacity).forEach((key) => {
        updatedOpacity[key] = key === value ? prevOpacity[key] : 0.1;
      });
      // console.log("value : ", updatedOpacity);
      return updatedOpacity;
    });
  };

  const handleMouseLeaveLegendMonth = (o) => {
    // console.log("Mouse Leave ----->", o);
    const { dataKey } = o;
    let value = "";
    if (dataKey === "Total Scans") value = "total";
    else if (dataKey === "Total Accepted") value = "accepted";
    setMonthAssementsOpacity((prevOpacity) => {
      const updatedOpacity = {};
      Object.keys(prevOpacity).forEach((key) => {
        updatedOpacity[key] = key === value ? prevOpacity[key] : 1;
      });
      return updatedOpacity;
    });
  };

  const CustomTooltip = ({ active, payload, label }) => {
    // console.log("test_custom_tooltip------------->", payload, active, label);
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            background: color?.textWhiteColor,
            // margin: "8px",
            borderRadius: "5px",
            border: "1px solid",
            borderColor: "#e5e6e8",
          }}
        >
          {/* label for tooltip */}
          {label?.length > 1 ? (
            <p
              style={{
                margin: "0px 0px 0px 0px",
                fontSize: "0.75rem",
                borderBottom: "1px solid",
                borderColor: "#e5e6e8",
                padding: "4px 8px",
              }}
            >
              {label.length > 1 ? label : null}
            </p>
          ) : null}
          {payload[0]?.payload?.label && (
            <p
              style={{
                margin: "0px 0px 0px 0px",
                fontSize: "0.75rem",
                borderBottom: "1px solid",
                borderColor: "#e5e6e8",
                padding: "4px 8px",
              }}
            >
              {payload[0]["payload"].label}
            </p>
          )}
          <div style={{ padding: "4px 0px" }}>
            <div style={{ padding: "4px 0" }}>
              {payload &&
                payload?.map((entry, index) => (
                  <div
                    key={`item-${index}`}
                    className="d-flex align-items-center gap-2 px-2 py-1"
                  >
                    <div
                      style={{
                        backgroundColor: entry?.color || entry?.payload?.fill,
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <div style={{ fontSize: "0.83rem", marginRight: "4px" }}>
                      {entry.name}:
                    </div>
                    <div style={{ fontSize: "0.83rem", fontWeight: 600 }}>
                      {formatInIndianRupees(Math.abs(entry.value))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {(loading && (
        <div
          className="showSpinner all-ticket-spinner"
          style={{ position: "relative", height: "85vh" }}
        >
          <BeatLoader color="#2D97D4" />
        </div>
      )) ||
        (Data && !Data?.message && (
          <>
            {false && (
              <div className="m-top-bottom">
                <div className="card-Div ">
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                      data={Data["crop wise data"]}
                      margin={{
                        top: 10,
                        right: 0,
                        left: 5,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid
                        strokeDasharray="0"
                        vertical={false}
                        stroke="#4c4e6614"
                      />
                      <XAxis
                        dataKey="Crop"
                        interval={0}
                        angle={isSmallScreen ? -20 : 0}
                        tick={{ fill: color.taxtHeading, fontSize: 12 }}
                        axisLine={false}
                        tickLine={{ display: "none" }}
                      />
                      <YAxis
                        domain={["auto", "auto"]}
                        tick={{ fill: color.taxtHeading, fontSize: 11 }}
                        axisLine={false}
                        label={{
                          value: "Scans",
                          angle: -90,
                          position: "insideLeft",
                        }}
                        tickFormatter={formatYAxis}
                        tickLine={{ display: "none" }}
                      />
                      <Tooltip
                        content={<CustomTooltip />}
                        cursor={{ fill: color.primary }}
                      />
                      <Legend
                        layout="horizontal"
                        verticalAlign="top"
                        align="right"
                        iconSize={10}
                        iconType="circle"
                        wrapperStyle={{ cursor: "pointer" }}
                        onMouseEnter={handleMouseEnterLegendMonth}
                        onMouseLeave={handleMouseLeaveLegendMonth}
                      />
                      <ReferenceLine y={0} stroke="#ccc" />
                      {/* {Data.show_accepted && ( */}
                      {
                        <Bar
                          dataKey="Total Scans"
                          fill={color.secondary}
                          barSize={20}
                          radius={[5, 5, 0, 0]}
                          minPointSize={15}
                          fillOpacity={monthAssementsOpacity.total}
                        />
                      }
                      <Bar
                        dataKey="Total Accepted"
                        fill={color.green}
                        barSize={20}
                        radius={[5, 5, 0, 0]}
                        minPointSize={10}
                        fillOpacity={monthAssementsOpacity.accepted}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            )}
            {/* Region Card */}
            <div className="cards_ro m-top-bottom mb-5 gap-20 mt-3">
              {Data["crop wise data"]?.map((each_crop_data) => (
                <>
                  <CropCard
                    test={test}
                    color={color}
                    each_crop_data={each_crop_data}
                    handleOpenmodal={handleOpenmodal}
                    showAccepted={showCropModal.show_accepted}
                  />
                </>
              ))}
            </div>

            {sendCropDataToURL && (
              <CropModal
                showCropModal={showCropModal}
                setshowCropModal={setshowCropModal}
                color={color}
                topDivWidth={topDivWidth}
                pageStyling={pageStyling}
                shadesOfBlue={shadesOfBlue}
                isSmallScreen={isSmallScreen}
                islageScreen={islageScreen}
                islagelargeScreen={islagelargeScreen}
                isMediumScreen={isMediumScreen}
                sendCropDataToURL={sendCropDataToURL}
                handelRefreshOfTheCropDataToURL={
                  handelRefreshOfTheCropDataToURL
                }
                handelshowOverviewModalError={
                  outletContext.handelshowOverviewModalError
                }
                showOverviewModalError={outletContext.showOverviewModalError}
              />
            )}
          </>
        )) || (
          <div className="container no-ticket-found-div text-center count_heading_div">
            <div className="d-flex align-items-center justify-content-center flex-column">
              {/* <i
                className="fa-solid fa-face-rolling-eyes fa-2xl inline-block"
                style={{ color: color.secondary }}
              ></i> */}
              <img src={noDataIMG} />
              <p>No Data Found</p>
            </div>
          </div>
        )}

      {/* Machine card */}

      {/* <div className="cards_machine">
        <LocationMachineCard />
      </div> */}
    </>
  );
};

export default CropOverview;
