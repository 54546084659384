import React, { useContext } from "react";
import {
  Button,
  LinearProgress,
  Box,
  ButtonGroup,
  linearProgressClasses,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// images

import assam_img from "../../../assets/images/region/Assam.jpg";
import chandigarh_img from "../../../assets/images/region/chandigarh.jpg";
import amarvathi_img from "../../../assets/images/region/Amarvathi.jpeg";
import panchkula_img from "../../../assets/images/region/Panchkula.jpg";
import lucknow_img from "../../../assets/images/region/Lucknow.jpeg";
import raipur_img from "../../../assets/images/region/raipur1.jpg";
import odisha_img from "../../../assets/images/region/Odisha.jpeg";
import dehradun from "../../../assets/images/region/Dehradun.jpeg";
import bhopal_img from "../../../assets/images/region/Bhopal.jpg";
import charminar from "../../../assets/images/region/hyderabad.jpg";

import TotalMachinesImg from "../../../assets/images/machine-blue.png";
import TotalDistrictImg from "../../../assets/images/district-blue.png";
import TotalRegionImg from "../../../assets/images/region-blue.png";

import allTicketsImg from "../../../assets/images/total-assessment-blue-3.png";
import ContextState from "../../contextApi/ContextState";

const images = {
  Hyderabad: charminar,
  Assam: assam_img,
  Bhopal: bhopal_img,
  Amravati: amarvathi_img,
  Panchkula: panchkula_img,
  Lucknow: lucknow_img,
  Raipur: raipur_img,
  Odisha: odisha_img,
  Dehradun: dehradun,
  Chandigarh: chandigarh_img,
};

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, colorAccept, colorReject }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: colorReject,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: colorAccept,
      borderRadius: 0,
      // borderRight: "2px solid white",
    },
  })
);
const pageStyling = {
  imageBackgroundDivRadius: "25%",
  topDivwidth: "33px",

  IconWidth: "16px",
  iconBackgroundWidth: "35px",
  iconBackgroundHeight: "35px",

  monthlyScanDivDescIconWidth: "20px",
  monthlyScanDivDescIconBackgroundWidth: "40px",
  monthlyScanDivDescIconBackgroundHeight: "40px",
};

const LocationRegionCard = ({
  test,
  each_region,
  color,
  handleOpenmodal,
  show_accepted,
  user_info,
  pageStyling,
}) => {
  const { formatInIndianRupees } = useContext(ContextState);
  // current access id for passing
  const current_access_id =
    user_info?.access_level === "institution"
      ? "region"
      : user_info?.access_level === "region"
        ? "district"
        : user_info?.access_level === "district"
          ? "machine"
          : user_info?.access_level === "machine"
            ? "machine"
            : null;

  // console.log("-------------->", user_info);
  return (
    <div
      className="card-Div"
    // style={{
    //   borderRadius: "6px",
    //   // minHeight: "100px",
    //   backgroundColor: "#ffffff",
    //   boxShadow: "0px 0px 1px 0px gray",
    //   padding: "12px",
    //   maxHeight: "fit-content",
    //   fontWeight: "600",
    //   // minWidth: "280px",
    // }}
    >
      {/* image container */}
      {/* <div
          className="background_location_img_dim"
          style={{
            width: "auto",
            height: "200px",
            backgroundImage: `url(${images[each_region?.Region]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            borderRadius: "5px",
          }}
        > */}
      {/* background iamge */}
      {user_info?.access_level === "institution" && (
        <div className="region-img">
          <img
            className="background-location-img img-fluid "
            src={images[each_region?.Region]}
            alt="Region"
          />
        </div>
      )}
      {/* title && more deatils btn */}
      <div className="d-flex align-items-center my-2">
        {user_info?.access_level === "institution" && (
          <p
            className="location_nums me-auto mb-0"
            style={{ color: color.taxtHeading }}
          >
            {each_region?.Region}
          </p>
        )}

        <div
          className={`me-auto d-flex align-items-center ${user_info?.access_level === "region" ||
              user_info?.access_level === "district" ||
              user_info.access_level === "machine"
              ? "d-block"
              : "d-none"
            }`}
        >
          <div
            className="d-flex justify-content-center align-items-center me-2 "
            style={{
              backgroundColor: color.iconBackgroundBlue,
              width: pageStyling.location_main_icon_background_width,
              height: pageStyling.location_main_icon_background_height,
              borderRadius: pageStyling.location_background_borderRadius,
            }}
          >
            <img
              src={`${user_info?.access_level === "region"
                  ? TotalDistrictImg
                  : user_info?.access_level === "district" ||
                    user_info.access_level === "machine"
                    ? TotalMachinesImg
                    : null
                }`}
              // src={TotalDistrictImg}
              alt="machine img"
              // className="location_icon_img"
              style={{ width: pageStyling.location_main_icon }}
            />
          </div>
          <p
            className="location_nums m-0 d-inline text-wrap"
            style={{ color: color.taxtHeading }}
          >
            {user_info.access_level === "institution"
              ? each_region?.Region
              : user_info.access_level === "region"
                ? each_region?.District
                : user_info.access_level === "district"
                  ? each_region["Location"]
                  : user_info.access_level === "machine"
                    ? each_region["Location"]
                    : null}
          </p>
        </div>

        <div>
          <Tooltip
            data-html="true"
            title={"Click to view detailed data"}
            arrow={true}
          >

            <button
              style={{ color: color.taxtHeadingLight }}
              className="location_moredetails_btn ms-auto align-self-center "
              onClick={() => {
                // handelsendLocationDataToURL("region", each_region?.Region);

                const select_access_id =
                  user_info?.access_level === "institution"
                    ? each_region.Region
                    : user_info?.access_level === "region"
                      ? each_region?.District
                      : user_info?.access_level === "district"
                        ? each_region["Machine"]
                        : user_info?.access_level === "machine"
                          ? each_region["Machine"]
                          : null;

                handleOpenmodal(current_access_id, select_access_id);
              }}
            >
              More<i class="fa-solid fa-chevron-right ps-1"></i>
            </button>
          </Tooltip>
        </div>
      </div>
      {/* {user_info?.access_level !== "institution" && (
        <div class="barChartDivDescHorizontalSeperator mt-3"></div>
      )} */}
      {/* total assements && total Ditsricts && total nmachines */}
      <div className="d-flex  justify-content-evenly my-3  ">
        {/* total assements */}
        <div className="text-center">
          <div className="d-flex justify-content-center py-1">
            <div
              className={`d-flex justify-content-center align-items-center  ${user_info.access_level === "region" ||
                  user_info.access_level === "district" ||
                  user_info.access_level === "machine"
                  ? "d-none"
                  : ""
                }`}
              style={{
                backgroundColor: color.iconBackgroundBlue,
                width: pageStyling.location_icon_background_width,
                height: pageStyling.location_icon_background_height,
                borderRadius: pageStyling.location_background_borderRadius,
              }}
            // className="ms-auto"
            >
              <img
                src={allTicketsImg}
                alt="All Assesment img"
                // className=""
                style={{ width: pageStyling.location_icon }}
              />
            </div>
          </div>
          <p
            className="mb-0 location_headings"
            style={{ color: color.tableRowHeading }}
          >
            Total Scans
          </p>
          <p key={test} className="m-0 location_nums rotatingText-adjective-small" style={{ color: color.taxtHeading }}>
            {formatInIndianRupees(each_region["Total Scans"])}
          </p>
        </div>
        <div
          className={`barChartDivDescVerticalSeperator ${user_info.access_level === "district" ||
              user_info.access_level === "machine"
              ? "d-none"
              : ""
            }`}
          style={{
            height:
              user_info.access_level === "region"
                ? pageStyling.locationVerticalLineHeight
                : "60px",
          }}
        ></div>

        {/* region && ditsrict */}
        <div
          className={`text-center ${user_info.access_level === "region" ||
              user_info.access_level === "district" ||
              user_info?.access_level === "machine"
              ? "d-none"
              : ""
            }`}
        >
          <div className="d-flex justify-content-center py-1">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: color.iconBackgroundBlue,
                width: pageStyling.location_icon_background_width,
                height: pageStyling.location_icon_background_height,
                borderRadius: pageStyling.location_background_borderRadius,
              }}
            // className="ms-auto"
            >
              <img
                src={`${user_info?.access_level === "institution"
                    ? TotalDistrictImg
                    : TotalRegionImg
                  }`}
                alt="machine img"
                style={{ width: pageStyling.location_icon }}
              />
            </div>
          </div>

          <p
            className="mb-0 location_headings"
            style={{ color: color.tableRowHeading }}
          >
            {`${user_info?.access_level === "institution"
                ? "Total District"
                : "Region"
              }`}
          </p>
          {/* <p className="m-0 location_nums">{each_region["Districts"]}</p> */}
          <p key={test} className="m-0 location_nums rotatingText-adjective-small" style={{ color: color.taxtHeading }}>
            {user_info?.access_level === "institution"
              ? each_region["Districts"]
              : user_info?.access_level === "region"
                ? user_info["region"]
                : user_info?.access_level === "district"
                  ? user_info?.region
                  : user_info?.access_level === "machine"
                    ? user_info?.region
                    : null}
          </p>
        </div>
        <div
          className={`barChartDivDescVerticalSeperator ${user_info.access_level === "region" ||
              user_info.access_level === "district" ||
              user_info.access_level === "machine"
              ? "d-none"
              : ""
            }`}
        ></div>
        <div
          className={`text-center ${user_info.access_level === "district" ||
              user_info.access_level == "machine"
              ? "d-none"
              : ""
            }`}
        >
          <div className="d-flex justify-content-center py-1">
            <div
              className={`d-flex justify-content-center align-items-center  ${user_info.access_level === "region" ? "d-none" : ""
                }`}
              style={{
                backgroundColor: color.iconBackgroundBlue,
                width: pageStyling.location_icon_background_width,
                height: pageStyling.location_icon_background_height,
                borderRadius: pageStyling.location_background_borderRadius,
              }}
            >
              <img
                src={`${user_info?.access_level === "district" ||
                    user_info.access_level === "machine"
                    ? TotalDistrictImg
                    : TotalMachinesImg
                  }`}
                alt="machine img"
                style={{ width: pageStyling.location_icon }}
              />
            </div>
          </div>

          <p
            className="m-0 location_headings"
            style={{ color: color.tableRowHeading }}
          >
            {user_info?.access_level === "district"
              ? "District"
              : "Total Machine"}
          </p>
          <p key={test} className="m-0 location_nums rotatingText-adjective-small" style={{ color: color.taxtHeading }}>
            {user_info?.access_level === "district" ||
              user_info.access_level === "machine"
              ? user_info?.district
              : each_region.Machines}
          </p>
        </div>
      </div>
      <div class="barChartDivDescHorizontalSeperator"></div>
      {/* top crops && top scans */}
      <div className="location_heading_sub py-1 mt-2">
        {/* machine no for district level & machine level */}
        {each_region["Machine"] && (
          <div className="d-flex justify-content-between mb-1">
            <div
              className="location_headings"
              style={{ color: color.tableRowHeading }}
            >
              Machine
            </div>
            <div
              className="location_headings_sub m-0 text-wrap"
              style={{ color: color.taxtHeading }}
            >
              {each_region["Machine"]}
            </div>
          </div>
        )}
        {each_region["Top District"] && (
          <div className="d-flex justify-content-between mb-1">
            <div
              className="location_headings"
              style={{ color: color.tableRowHeading }}
            >
              Top District
            </div>
            <div
              key={test}
              className="location_headings_sub  m-0 rotatingText-adjective-small"
              style={{ color: color.taxtHeading }}
            >
              {each_region["Top District"]}
            </div>
          </div>
        )}
        {each_region["Top Location"] && (
          <div className="d-flex justify-content-between mb-1">
            <div
              className="location_headings m-0"
              style={{ color: color.tableRowHeading }}
            >
              Top Location
            </div>
            <div
              key={test}
              className="location_headings_sub rotatingText-adjective-small"
              style={{ color: color.taxtHeading }}
            >
              {each_region["Top Location"]}
            </div>
          </div>
        )}
        {each_region["Top Crop"] && (
          <div className="d-flex justify-content-between mb-1">
            <div
              className="location_headings"
              style={{ color: color.tableRowHeading }}
            >
              Top Crop
            </div>
            <div
              key={test}
              className="location_headings_sub m-0 rotatingText-adjective-small"
              style={{ color: color.taxtHeading }}
            >
              {each_region["Top Crop"]}
            </div>
          </div>
        )}
      </div>
      {/* accepted scans progress bar */}
      {show_accepted && (
        <div className="py-1">
          <div className="">
            <div>
              <p
                className="location_headings"
                style={{
                  fontSize: "0.88rem",
                  margin: "0px 0px 0px 0px",
                  color: color.tableRowHeading,
                }}
              >
                Accepted Scans
              </p>
            </div>
          </div>
          <div className="d-flex flex-row mb-1">
            <div className="d-inline-block me-auto">
              <span
                key={test}
                style={{
                  // fontSize: "1rem",
                  color: color.tableRowHeading,
                }}
                className="location_nums rotatingText-adjective-small"
              >
                {formatInIndianRupees(each_region["Accepted Scans"])}
              </span>
              {/* <span
              // style={{ fontSize: "0.65rem", color: color.taxtHeadingLight }}
              className="location_headings"
            >
              {" "}
              /{formatInIndianRupees(each_region["Total Scans"])}
            </span> */}
            </div>
            <div className="d-inline-block align-self-end">
              <p
                key={test}
                className="location_nums m-0 rotatingText-adjective-small"
                style={{ color: color.taxtHeading, fontSize: "0.8rem" }}
              >
                {each_region["Accepted Percent"]}%
              </p>
            </div>
          </div>
          <BorderLinearProgress
            variant="determinate"
            value={each_region["Accepted Percent"]}
            colorAccept={color.green}
            colorReject={color.iconBackgroundgreen}
          />
        </div>
      )}
    </div>
  );
};

export default LocationRegionCard;
