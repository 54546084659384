import React, { useContext, useEffect, useRef, useState } from "react";
import "../../../assets/styles/FirstPage.css";

import { Nav, Button, Dropdown } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import { CiExport } from "react-icons/ci";

// import Overview from "./Overview";
// import Location from "./Location";
import { Outlet, useNavigate } from "react-router-dom";
import ContextState from "../../contextApi/ContextState";
import FCI_Icon from "../../../assets/images/Food_Corporation_of_India.svg.png";
import bg2 from "../../../assets/images/bg-header.jpg";
import Error from "../../error/Error";

import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import useMediaQuery from "@mui/material/useMediaQuery"; // conditional renderring of media query
import { useSelector } from "react-redux";

const FirstPage = () => {
  const isSmallSmallScreen = useMediaQuery("(max-width:576px)");
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const isMediumScreen = useMediaQuery("(max-width:1200px)");
  const islageScreen = useMediaQuery("(max-width:1400px)");
  const islagelargeScreen = useMediaQuery("(max-width:1700px)");
  // navigate
  const navigate = useNavigate();

  const Greetdate = new Date();
  const floatDrawer1 = useMediaQuery("(min-width:992px)");

  const topDivWidth = {
    normal: isSmallScreen ? "280px" : "290px",
    isSmallScreen: "150px",
    isSmallSmallScreen: "250px",
  };
  const pageStyling = {
    imageBackgroundDivRadius: "25%",
    topDivwidth: "33px",

    IconWidth: "62px",
    iconBackgroundWidth: "45px",
    iconBackgroundHeight: "45px",
  };

  // bule shades for pie chart
  const shadesOfBlue = [
    // pure blue
    // "#004c6d",
    // "#23617e",
    // "#3c7690",
    // "#548ca1",
    // "#6da2b3",
    // "#86b9c5",
    // "#a1d0d8",
    // "#bce7eb",
    // "#d8ffff",

    // earlier bar graph
    // "#6366f1",
    // "#8874f0",
    // "#a484f0",
    // "#bb95f0",
    // "#cfa8f1",
    // "#dfbbf2",
    // "#eccff5",
    // "#f7e4f9",
    // "#fffaff",

    // "#826af9",
    // "#9e79f7",
    // "#b588f6",
    // "#c899f5",
    // "#d7abf5",
    // "#e5bef6",
    // "#f0d1f8",
    // "#f8e5fb",
    // "#fff9ff",

    // "#0098e9",
    // "#49a1e8",
    // "#69aae8",
    // "#82b3e7",
    // "#98bce6",
    // "#acc5e5",
    // "#bfcfe4",
    // "#d1d8e3",
    // "#e2e2e2",

    "#666bff",
    "#7c79ff",
    "#8e87ff",
    "#9f95ff",
    "#afa3ff",
    "#bdb2ff",
    "#cbc1fe",
    "#d8d0fe",
    "#e5dffd",
  ];

  // color
  const color = {
    primary: "#f7f7f9", // website background
    // secondary: "#8884d8",
    // secondary: "#826af9", // blue color in dashboard
    // secondary: "#0098e9", // blue color in dashboard
    secondary: "#666bff", // blue color in dashboard
    green: "#82ca9d", // green color in dashboard
    red: "#ff4d49d9", // red color in dashboard
    navBorderBottom: "#4c4e6422",
    taxtHeadingLight: "#4c4e6499", // normal text heading light color
    taxtHeading: "#4c4e64e6", // normal text heading dark color (76,78,100,0.97)
    tableRowHeading: "#4c4e64de", // (76,78,100,0.87)
    iconBackgroundBlue: "#666bff39",
    iconBackgroundWhite: "#ffffffe6",
    iconBackgroundgreen: "#82ca9d39",
    iconBackgroundred: "#ff4d4939",
    textWhiteColor: "#ffffff",
    textDescBackgroundRegionDiv: "#5b61e5",
    locationTextHeading: "#4c4e64fc", //location heeading color
  };

  // context
  const { showError, showErrorPage, formatDate } = useContext(ContextState);

  // frequency dropdown
  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const handelDropDown = (value) => {
    setShowDropdown(value);
  };

  // *********** Date Filters ***********
  const [dateFilters, setDateFilters] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);

  const handleDateChange = (dateArr) => {
    // // console.log("Selected Date : ", date);
    setSelectedDates([...dateArr]);
    // console.log("[...dateArr] : ", [...dateArr]);
    // Close the DatePicker automatically when two dates are selected
    if (selectedDates.length === 2) {
      setSelectedDates(false);
    }
  };

  // ******* filters **********
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    time: [],
    MonthlyStartDate: [],
    MonthlyEndDate: [],
    DailyStartDate: [],
    DailyEndDate: [],
  });
  const [filterCount, setFilterCount] = useState({
    time: 0,
    MonthlyStartDate: 0,
    MonthlyEndDate: 0,
    DailyStartDate: 0,
    DailyEndDate: 0,
  });
  const [filtersOptions, setfiltersOptions] = useState({
    time: ["monthly", "daily"],
  });

  const updateFilters = (Filters, clickedPageNumber = 0, updateURL = true) => {
    setFilters(Filters);
    if (updateURL) {
      updateURLParams(Filters, clickedPageNumber);
      // // console.log("Url Is Updating ");
    }
    updateFilterCount(Filters);
    fetchData(Filters, clickedPageNumber);
  };

  const updateFilterCount = (farr) => {
    for (const key in farr) {
      const count = farr[key].length;
      filterCount[key] = count;
    }
    setFilterCount(filterCount);
  };

  const updateURLParams = (params, page) => {
    const urlSearchParams = new URLSearchParams();
    Object.keys(params).forEach((key) => {
      // // console.log(key, params[key]);
      if (params[key]) {
        urlSearchParams.set(key, params[key]);
      } else {
        urlSearchParams.delete(key);
      }
    });
    const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;

    if (window.location.pathname === "/" && !overviewData) {
      // Check if the state already exists
      // // console.log("Replace state Wroking");
      const currentState = window.history.state;
      if (!currentState || !currentState.filters) {
        // Replace the initial state if no filters exist
        window.history.replaceState(
          {
            filters: params,
          },
          "",
          newUrl
        );
      }
    } else {
      // Push state for subsequent changes
      window.history.pushState(
        {
          filters: params,
        },
        "",
        newUrl
      );
    }
  };

  const handleFilterChange = (filter, filterType) => {
    setFilters((prevFilters) => {
      const currentFilters = { ...prevFilters };
      // const filterList = currentFilters[filterType];
      if (filterType === "time") {
        if (filter !== "") {
          currentFilters[filterType] = [filter];
        } else {
          currentFilters[filterType] = [];
        }
        if (filter === "daily") {
          currentFilters["MonthlyStartDate"] = [];
          currentFilters["MonthlyEndDate"] = [];
        } else if (filter === "monthly") {
          currentFilters["DailyStartDate"] = [];
          currentFilters["DailyEndDate"] = [];
        } else {
          currentFilters["MonthlyStartDate"] = [];
          currentFilters["MonthlyEndDate"] = [];

          currentFilters["DailyStartDate"] = [];
          currentFilters["DailyEndDate"] = [];
        }
      } else {
        currentFilters[filterType] = [filter];
      }

      console.log(
        "update filter 1 -->",
        filter,
        filterType,
        "filters:",
        currentFilters
      );
      return currentFilters;
    });
  };

  const handleFilterReset = (filtername) => {
    // console.log(filtername, filters);

    setFilters((prevFilters) => {
      const currentFilters = { ...prevFilters };
      const filterList = currentFilters[filtername];
      if (filterList && filtername === "time") {
        currentFilters["MonthlyStartDate"] = [];
        currentFilters["MonthlyEndDate"] = [];
        currentFilters["DailyStartDate"] = [];
        currentFilters["DailyEndDate"] = [];
        currentFilters["time"] = [];
      }
      return currentFilters;
    });
  };
  const handleSaveChanges = () => {
    // console.log("sendFilters->  ", filtername);
    updateFilters(filters, 0);
  };

  function generateMonthsList(fromDate, toDate) {
    const startDate = new Date(fromDate + "-01"); // Convert fromDate to Date object
    const endDate = new Date(toDate + "-01"); // Convert toDate to Date object

    const monthsList = [];
    let currentDate = startDate;

    // Loop until currentDate is less than or equal to endDate
    while (currentDate <= endDate) {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // getMonth() returns zero-based month index
      const formattedMonth = month < 10 ? "0" + month : month.toString(); // Format month with leading zero if needed
      const formattedDate = year + "-" + formattedMonth;
      monthsList.push(formattedDate);

      // Move currentDate to the next month
      currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    }

    return monthsList;
  }
  // fetching the data
  const [overviewData, setOverviewData] = useState(null); // Overwiew page data
  const [locationData, setLocationData] = useState(null); // Location page data
  const [cropData, setCropData] = useState(null); // Location page data

  const handelOverviewData = (data) => {
    setOverviewData(data);
  };
  const handelLocationData = (data) => {
    setLocationData(data);
  };
  const handelCropData = (data) => {
    setCropData(data);
  };
  const fetchData = async (F, Page = 0, reloadData = false) => {
    // if (window.location.pathname === "/overview") {
    setOverviewData(null);
    // }
    // if (window.location.pathname === "/overview/location") {
    setLocationData(null);
    // }
    // console.log("data set to empty List - 3");
    if (!reloadData) {
      setLoading(true);
    }
    // showLoading(true);
    // data from backend
    // total number of cards
    // cards filter show how many pages perpage (cardsPerPage : 15)

    const payload = {};

    //  filters for dashboard
    if (F["time"]?.length !== 0) {
      payload["frequency"] = F["time"][0];
    } else {
      payload["frequency"] = "daily";
    }
    if (F["hourlyStartDate"]?.length && F["time"].includes("hourly")) {
      payload["from_date"] = F["hourlyStartDate"][0];
    }
    if (F["hourlyEndDate"]?.length && F["time"].includes("hourly")) {
      payload["to_date"] = F["hourlyEndDate"][0];
    }
    if (F["DailyStartDate"]?.length && F["time"].includes("daily")) {
      payload["from_date"] = F["DailyStartDate"][0];
    }
    if (F["DailyEndDate"]?.length && F["time"].includes("daily")) {
      payload["to_date"] = F["DailyEndDate"][0];
    }
    if (F["MonthlyStartDate"]?.length && F["time"].includes("monthly")) {
      payload["from_date"] = F["MonthlyStartDate"][0];
    }
    if (F["MonthlyEndDate"]?.length && F["time"].includes("monthly")) {
      payload["to_date"] = F["MonthlyEndDate"][0];
    }
    if (
      F["MonthlyStartDate"]?.length &&
      F["time"].includes("monthly") &&
      F["MonthlyEndDate"]?.length &&
      F["time"].includes("monthly")
    ) {
      payload["months_list"] = generateMonthsList(
        F["MonthlyStartDate"],
        F["MonthlyEndDate"]
      );
    }

    let is_all_ticket_data_Mounted = true;

    if (!reloadData) {
      setTimeout(() => {
        setLoading(false);
        // showLoading(false);
      }, 300);
    }
    return () => {
      is_all_ticket_data_Mounted = false;
    };
  };
  //  *************** filters ended******************

  const reloadMainPageData = () => {
    // updateFilters(filters, 0, true);
    updateURLParams(filters);
    fetchData(filters, 0, true);
  };

  // useEffect(() => {
  //   // document.title = "Home - NEO";
  //   const params = new URLSearchParams(window.location.search);

  //   const timeFilterData =
  //     params.get("time") !== "" && params.get("time") !== null
  //       ? params.get("time").split(",")
  //       : [];
  //   const hourlyStartDateFilterData =
  //     params.get("hourlyStartDate") !== "" &&
  //     params.get("hourlyStartDate") !== null
  //       ? params.get("hourlyStartDate").split(",")
  //       : [];
  //   const hourlyEndDateFilterData =
  //     params.get("hourlyEndDate") !== "" && params.get("hourlyEndDate") !== null
  //       ? params.get("hourlyEndDate").split(",")
  //       : [];
  //   const DailyStartDateFilterData =
  //     params.get("DailyStartDate") !== "" &&
  //     params.get("DailyStartDate") !== null
  //       ? params.get("DailyStartDate").split(",")
  //       : [];
  //   const DailyEndDateFilterData =
  //     params.get("DailyEndDate") !== "" && params.get("DailyEndDate") !== null
  //       ? params.get("DailyEndDate").split(",")
  //       : [];
  //   const MonthlyStartDateFilterData =
  //     params.get("MonthlyStartDate") !== "" &&
  //     params.get("MonthlyStartDate") !== null
  //       ? params.get("MonthlyStartDate").split(",")
  //       : [];
  //   const MonthlyEndDateFilterData =
  //     params.get("MonthlyEndDate") !== "" &&
  //     params.get("MonthlyEndDate") !== null
  //       ? params.get("MonthlyEndDate").split(",")
  //       : [];
  //   const initalFilters = {
  //     time: timeFilterData,
  //     hourlyStartDate: hourlyStartDateFilterData,
  //     hourlyEndDate: hourlyEndDateFilterData,
  //     DailyStartDate: DailyStartDateFilterData,
  //     DailyEndDate: DailyEndDateFilterData,
  //     MonthlyStartDate: MonthlyStartDateFilterData,
  //     MonthlyEndDate: MonthlyEndDateFilterData,
  //   };
  //   let is_all_filter_data_Mounted = true;
  //   updateFilters(initalFilters, 0, true);
  //   return () => {
  //     is_all_filter_data_Mounted = false;
  //   };
  // }, []);

  const MyComponent = () => {
    const a = null;
    return a.first_name();
  };
  // date picker
  const datePickerRef = useRef();
  const handleCancel = () => {
    console.log("close Date Picker :", datePickerRef.current);
    datePickerRef.current.closeCalendar();
    // if (state?.dates?.length === 0 || state?.dates?.length > 1) {
    //   datePickerRef.current.closeCalendar();
    // }
  };
  const userDetails = useSelector((state) => state.userDetail);

  const [showOverviewModalError, setshowOverviewModalError] = useState({
    status: null,
    msg: null,
  });
  const [showFirstPageError, setshowFirstPageError] = useState({
    status: null,
    msg: null,
  });

  const handelshowOverviewModalError = (status, msg) => {
    setshowOverviewModalError({ status: status, msg: msg });
  };
  const handelshowFirstPageError = (status, msg) => {
    setshowFirstPageError({ status: status, msg: msg });
  };

  // console.log("test ------------->", filters?.time[0]);
  return (
    <>
      {(!showFirstPageError.status && (
        <div className="container mt-4">
          {/* title heading div  */}
          {
            <div
              className="position-relative m-0"
              style={
                {
                  // backgroundImage: `url(${bg2})`,
                  // backgroundColor: 'rgba()'
                }
              }
            >
              {/* logo style */}
              <div
                className="position-absolute left-0 "
                style={{
                  // backgroundColor: color.iconBackgroundWhite,
                  borderRadius: pageStyling.imageBackgroundDivRadius,
                  paddingLeft: "0.1%",
                }}
              >
                <img
                  src={FCI_Icon}
                  className="ms-1 fci-icon-width"
                  alt="FCI Icon img"
                  // style={{ width: pageStyling.IconWidth }}
                />
              </div>

              {/* text content header style  */}
              <div
                className="text-content-header"
                style={{
                  color: color.taxtHeading,
                  // marginLeft: floatDrawer2? "75px": "100px"
                }}
              >
                <p
                  className="client-title-heading"
                  style={{
                    color: "rgba(26, 25, 25, 0.98)",
                    fontWeight: "600",
                  }}
                >
                  {userDetails?.data?.title}
                </p>

                {floatDrawer1 && (
                  <div>
                    {(userDetails?.data?.access_level !== "institution" && (
                      <p className="client-info">
                        {userDetails?.data?.region &&
                        userDetails?.data?.district &&
                        userDetails?.data?.machine_code?.length > 0 ? (
                          <>
                            {userDetails.data.region}
                            <span className="center-dot">
                              <i className="bi bi-dot px-0"></i>
                            </span>
                            {userDetails.data.district}
                            <span className="center-dot">
                              <i className="bi bi-dot px-0"></i>
                            </span>
                            {userDetails.data.total_machines} Machines
                            <span className="center-dot">
                              <i className="bi bi-dot px-0"></i>
                            </span>
                            {overviewData?.total_crops} Crops
                          </>
                        ) : userDetails?.data?.region &&
                          userDetails?.data?.district ? (
                          <>
                            {userDetails?.data?.region}
                            <span className="center-dot">
                              <i className="bi bi-dot px-0"></i>
                            </span>
                            {userDetails?.data?.district}
                            <span className="center-dot">
                              <i className="bi bi-dot px-0"></i>
                            </span>
                            {userDetails.data.total_machines} Machines
                            <span className="center-dot">
                              <i className="bi bi-dot px-0"></i>
                            </span>
                            {userDetails?.data?.total_crops} Crops
                          </>
                        ) : userDetails?.data?.region ? (
                          <>
                            {userDetails.data.region}
                            <span className="center-dot">
                              <i className="bi bi-dot px-0"></i>
                            </span>
                            {userDetails?.data?.total_districts} Districts
                            <span className="center-dot">
                              <i className="bi bi-dot px-0"></i>
                            </span>
                            {userDetails?.data?.total_machines} Machines
                            <span className="center-dot">
                              <i className="bi bi-dot px-0"></i>
                            </span>
                            {userDetails?.data?.total_crops} Crops
                          </>
                        ) : null}
                      </p>
                    )) || (
                      <p className="client-info">
                        {userDetails?.data?.total_regions} Regions
                        <span className="center-dot">
                          <i className="bi bi-dot px-0"></i>
                        </span>
                        {userDetails?.data?.total_districts} Districts
                        <span className="center-dot">
                          <i className="bi bi-dot px-0"></i>
                        </span>
                        {userDetails?.data?.total_machines} Machines
                        <span className="center-dot">
                          <i className="bi bi-dot  px-0"></i>
                        </span>
                        {userDetails?.data?.total_crops} Crops
                      </p>
                    )}
                  </div>
                )}
              </div>

              {floatDrawer1 && (
                <div className="todays_date position-absolute top-0 end-0">
                  <p className="mt-0 mb-0 mx-3 " style={{ fontSize: "18px" }}>
                    {new Date().toLocaleDateString("en-US", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </p>
                </div>
              )}
            </div>
          }

          {/* nav links  */}
          {
            <div className="d-flex flex-row flex-wrap">
              <div className="nav-link-div flex-grow-1 flex-nowrap overflow-auto style-scrollbar">
                <Nav
                  variant="underline"
                  defaultActiveKey="/"
                  style={{
                    borderBottom: "1px solid " + color.navBorderBottom,
                    margin: "10px",
                    textWrap: "nowrap",
                    flexWrap: "nowrap",
                  }}
                >
                  <Tooltip
                    data-html="true"
                    title={"Overview Section"}
                    arrow={true}
                  >
                    <Nav.Item>
                      <Nav.Link
                        // href="/fcidash"
                        active={window.location.pathname === "/"}
                        style={{
                          color:
                            window.location.pathname === "/"
                              ? color.secondary
                              : "inherit",
                          borderColor:
                            window.location.pathname === "/" && color.secondary,
                          minWidth: "100px",
                          boxShadow: "none",
                        }}
                        onClick={() => {
                          navigate("/");
                        }}
                        onMouseEnter={(e) => {
                          if (window.location.pathname !== "/") {
                            e.currentTarget.style.borderColor = color.secondary;
                            e.currentTarget.style.borderWidth = "2px";
                          }
                        }}
                        onMouseLeave={(e) => {
                          if (window.location.pathname !== "/") {
                            e.currentTarget.style.borderWidth = "0px";
                          }
                        }}
                      >
                        Overview
                      </Nav.Link>
                    </Nav.Item>
                  </Tooltip>
                  <Tooltip
                    data-html="true"
                    title={"Location wise overview section"}
                    arrow={true}
                  >
                    <Nav.Item>
                      <Nav.Link
                        // href="/location"
                        active={window.location.pathname === "/location"}
                        style={{
                          color:
                            window.location.pathname === "/location"
                              ? color.secondary
                              : "inherit",
                          borderColor:
                            window.location.pathname === "/location" &&
                            color.secondary,
                          boxShadow: "none",

                          minWidth: "100px",
                          // "&:hover": {
                          //   borderColor: "blue",
                          // },
                        }}
                        onClick={() => {
                          navigate("location");
                        }}
                        onMouseEnter={(e) => {
                          if (window.location.pathname !== "/location") {
                            e.currentTarget.style.borderColor = color.secondary;
                            e.currentTarget.style.borderWidth = "2px";
                          }
                        }}
                        onMouseLeave={(e) => {
                          if (window.location.pathname !== "/location") {
                            e.currentTarget.style.borderWidth = "0px";
                          }
                        }}
                      >
                        Location
                      </Nav.Link>
                    </Nav.Item>
                  </Tooltip>
                  <Tooltip
                    data-html="true"
                    title={"Crop wise overiew section"}
                    arrow={true}
                  >
                    <Nav.Item>
                      <Nav.Link
                        // href="/location"
                        active={window.location.pathname === "/crop"}
                        style={{
                          color:
                            window.location.pathname === "/crop"
                              ? color.secondary
                              : "inherit",
                          borderColor:
                            window.location.pathname === "/crop" &&
                            color.secondary,
                          boxShadow: "none",

                          minWidth: "100px",
                          // "&:hover": {
                          //   borderColor: "blue",
                          // },
                        }}
                        onClick={() => {
                          navigate("crop");
                        }}
                        onMouseEnter={(e) => {
                          if (window.location.pathname !== "/crop") {
                            e.currentTarget.style.borderColor = color.secondary;
                            e.currentTarget.style.borderWidth = "2px";
                          }
                        }}
                        onMouseLeave={(e) => {
                          if (window.location.pathname !== "/crop") {
                            e.currentTarget.style.borderWidth = "0px";
                          }
                        }}
                      >
                        Crop
                      </Nav.Link>
                    </Nav.Item>
                  </Tooltip>
                </Nav>
              </div>

              {false && (
                <div className="button-div ms-auto text-end d-inline-block align-items-center d-flex flex-row gap-1">
                  <Dropdown align={"end"} title={` All Time Data `}>
                    <Dropdown.Toggle
                      id=""
                      variant="secondary"
                      className="custom-dropdown-toggle" // Add the custom class here
                      style={{
                        backgroundColor: "#ffffff",
                        boxShadow: "0px 0px 2px 1px #80808033",
                        border: "none",
                        borderRadius: "5px",
                      }}
                    >
                      All Time Data
                    </Dropdown.Toggle>
                  </Dropdown>
                  {false && (
                    <Dropdown
                      // as={ButtonGroup}
                      align={"end"}
                      title={` Last 10 Days `}
                      show={showDropdown}
                      autoClose={false}
                      onClick={(e) => {
                        handelDropDown(!showDropdown);
                      }}
                    >
                      <Dropdown.Toggle
                        id="dropdown-autoclose-true"
                        variant="secondary"
                        style={{
                          backgroundColor: "#ffffff",
                          boxShadow: "0px 0px 2px 1px #80808033",
                          border: "none",
                          borderRadius: "5px",
                        }}
                      >
                        {(filters.time && filters.time[0]) || "Last 10 days"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {/* <div
                      className="py-3 "
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <FormControl
                        variant="standard"
                        className="mx-4"
                        sx={{ m: 1, minWidth: 120 }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Time
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={
                            (filters?.time?.length && filters?.time[0]) || ""
                          }
                          onChange={(e) => {
                            handleFilterChange(e.target.value, "time");
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          label="Time"
                        >
                          <MenuItem
                            value=""
                            onClick={() => {
                              handelDropDown(false);
                            }}
                          >
                            <em>None</em>
                          </MenuItem>
                          {filtersOptions.time.map((timeKey, index) => (
                            <MenuItem value={timeKey} key={index}>
                              {timeKey}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div> */}

                        {true && (
                          <div
                            className="date_range_container"
                            style={{
                              backgroundColor: "#f3f7fd67",
                              boxShadow: "0px 0px 1px 0px gray",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            {/* monthly selector */}
                            <div className="d-flex date_button_container">
                              <button
                                className={`date_range_button ${
                                  filters?.time[0] === "monthly"
                                    ? "active_btn_"
                                    : ""
                                }`}
                                // onClick={() => handleModeChange("monthly")}
                                onClick={() =>
                                  handleFilterChange("monthly", "time")
                                }
                              >
                                Monthly
                              </button>
                              <button
                                className={`date_range_button ${
                                  filters?.time[0] === "daily"
                                    ? "active_btn_"
                                    : ""
                                }`}
                                // onClick={() => handleModeChange("daily")}
                                onClick={() =>
                                  handleFilterChange("daily", "time")
                                }
                              >
                                Daily
                              </button>
                              <div
                                className={`animation_button ${
                                  filters?.time && filters?.time[0] === "daily"
                                    ? "animation_daily"
                                    : ""
                                }`}
                                style={{
                                  backgroundColor: color.secondary,
                                }}
                              ></div>
                            </div>
                            <div className="text-center mt-2">
                              {filters.time.length > 0 ? (
                                filters.time[0] === "monthly" ? (
                                  <p className="mb-0">
                                    Select Start-End Month{" "}
                                  </p>
                                ) : filters.time[0] === "daily" ? (
                                  <p className="mb-0">Select Start-End Date </p>
                                ) : null
                              ) : (
                                <p className="mb-0">Select Time </p>
                              )}
                            </div>

                            {filters?.time && filters?.time[0] === "daily" && (
                              <DatePicker
                                ref={datePickerRef}
                                onChange={(e, dates) => {
                                  console.log("Daily selected Date : ", dates);
                                  if (dates?.validatedValue) {
                                    if (dates?.validatedValue.length > 0) {
                                      handleFilterChange(
                                        dates?.validatedValue[0].replaceAll(
                                          "/",
                                          "-"
                                        ),
                                        "DailyStartDate"
                                      );
                                    }
                                    if (dates?.validatedValue.length > 1) {
                                      handleFilterChange(
                                        dates?.validatedValue[1].replaceAll(
                                          "/",
                                          "-"
                                        ),
                                        "DailyEndDate"
                                      );
                                      handleCancel();
                                    } else {
                                      handleFilterChange(
                                        dates?.validatedValue[0].replaceAll(
                                          "/",
                                          "-"
                                        ),
                                        "DailyEndDate"
                                      );
                                    }
                                  }
                                }}
                                range
                                rangeHover
                                format={"DD/MM/YYYY"}
                                dateSeparator=" to "
                                render={<InputIcon />}
                                // onClose={
                                //   filters?.DailyEndDate?.length > 1
                                //     ? () => false
                                //     : () => true
                                // }
                              >
                                <button
                                  className={`cancel_button ${
                                    // state?.dates?.length > 0 ? "disabled" : ""
                                    ""
                                  }`}
                                  onClick={handleCancel}
                                >
                                  Cancel
                                </button>
                              </DatePicker>
                            )}

                            {filters?.time &&
                              filters?.time[0] === "monthly" && (
                                <DatePicker
                                  ref={datePickerRef}
                                  onlyMonthPicker
                                  range
                                  rangeHover
                                  dateSeparator=" to "
                                  render={<InputIcon />}
                                  format={"MM/YYYY"}
                                  onChange={(e, dates) => {
                                    // console.log(
                                    //   "Daily selected Date : ",
                                    //   dates,
                                    //   dates.validatedValue[0],
                                    //   dates.validatedValue[1]
                                    // );
                                    if (dates?.validatedValue) {
                                      if (dates?.validatedValue.length > 0) {
                                        handleFilterChange(
                                          dates?.validatedValue[0].replaceAll(
                                            "/",
                                            "-"
                                          ),
                                          "MonthlyStartDate"
                                        );
                                      }
                                      if (dates?.validatedValue.length > 1) {
                                        handleFilterChange(
                                          dates?.validatedValue[1].replaceAll(
                                            "/",
                                            "-"
                                          ),
                                          "MonthlyEndDate"
                                        );
                                        handleCancel();
                                      } else {
                                        handleFilterChange(
                                          dates?.validatedValue[0].replaceAll(
                                            "/",
                                            "-"
                                          ),
                                          "MonthlyEndDate"
                                        );
                                      }
                                    }
                                  }}
                                  // onClose={
                                  //   // state?.dates?.length > 1
                                  //   //   ? () => true
                                  //   //   : state?.dates?.length === 0
                                  //   //   ? () => true
                                  //   //   :
                                  //   // () => false
                                  // }
                                >
                                  <button
                                    className={`cancel_button ${
                                      // state?.dates?.length > 0 ? "disabled" : ""
                                      ""
                                    }`}
                                    onClick={handleCancel}
                                  >
                                    Cancel
                                  </button>
                                </DatePicker>
                              )}

                            {/* <div className="mt-2 d-flex justify-content-center">
                          <button className="reset_save" onClick={resetState}>
                            Reset
                          </button>
                          <button className="reset_save" onClick={saveValues}>
                            Save
                          </button>
                        </div> */}
                            <div className="text-end mt-3 mb-1 d-flex gap-1 justify-content-end">
                              <Button
                                variant="primary"
                                style={{
                                  padding: "3px 12px",
                                  backgroundColor: color.primary,
                                  color: color.secondary,
                                }}
                                onClick={() => {
                                  // handelDropDown(!showDropdown);
                                  // console.log("reseting");
                                  handleFilterReset("time");
                                }}
                              >
                                <div className="export-text-div ">Reset</div>
                              </Button>
                              <Button
                                variant="primary"
                                style={{
                                  padding: "3px 12px",
                                  backgroundColor: color.secondary,
                                }}
                                onClick={() => {
                                  handelDropDown(!showDropdown);
                                  handleSaveChanges();
                                }}
                              >
                                <div className="export-text-div ">Submit</div>
                              </Button>
                            </div>
                          </div>
                        )}
                        {/* {filters?.time?.length !== 0 && (
                      <div
                        className=" px-0 py-2 date-select-body-div"
                        onClick={(e) => {
                          e.stopPropagation();
                          // e.preventDefault();
                        }}
                        style={{
                          borderTop: "1px solid " + color.primary,
                          borderTopWidth: "95%",
                        }}
                      >
                        <div>
                          <div className="row px-0 mb-2  mt-0  pt-0 created-date-heading">
                            Select Range
                          </div>
                          {filters?.time[0] === "monthly" && (
                            <div className="row createdDateDiv">
                              <div className="col-md-6 createdStartDate mb-2">
                                <label
                                  className="form-label"
                                  htmlFor="createdStartDate"
                                >
                                  From:
                                </label>
                                <input
                                  type="month"
                                  className="form-control"
                                  id="createdStartDate"
                                  name="createdStartDate"
                                  value={filters?.MonthlyStartDate[0] || ""}
                                  max={filters?.MonthlyEndDate[0] || ""}
                                  onChange={(e) => {
                                    handleFilterChange(
                                      e.target.value,
                                      "MonthlyStartDate"
                                    );
                                    //applyFilterss();
                                  }}
                                />
                              </div>
                              <div className="col-md-6 createdEndDate">
                                <label
                                  className="form-label"
                                  htmlFor="createdEndDate"
                                >
                                  To:
                                </label>
                                <input
                                  type="month"
                                  className="form-control"
                                  id="createdEndDate"
                                  name="createdEndDate"
                                  value={filters?.MonthlyEndDate[0] || ""}
                                  min={filters?.MonthlyStartDate[0] || ""}
                                  onChange={(e) => {
                                    handleFilterChange(
                                      e.target.value,
                                      "MonthlyEndDate"
                                    );
                                    //applyFilterss();
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          {filters?.time[0] === "daily" && (
                            <div className="row mt-1 resolvedDateDiv">
                              <div className="col-md-6 resolvedStartDate mb-2">
                                <label
                                  className="form-label"
                                  htmlFor="resolvedStartDate"
                                >
                                  From:
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="resolvedStartDate"
                                  name="resolvedStartDate"
                                  value={filters?.DailyStartDate[0] || ""}
                                  max={filters?.DailyEndDate[0] || ""}
                                  onChange={(e) => {
                                    handleFilterChange(
                                      e.target.value,
                                      "DailyStartDate"
                                    );
                                    //applyFilterss();
                                  }}
                                />
                              </div>
                              <div className="col-md-6 endDate">
                                <label
                                  className="form-label"
                                  htmlFor="resolvedEndDate"
                                >
                                  To:
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="resolvedEndDate"
                                  name="resolvedEndDate"
                                  value={filters?.DailyEndDate[0] || ""}
                                  min={filters?.DailyStartDate[0] || ""}
                                  onChange={(e) => {
                                    handleFilterChange(
                                      e.target.value,
                                      "DailyEndDate"
                                    );
                                    //applyFilterss();
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          <div className="text-end mt-3 mb-1">
                            <Button
                              variant="primary"
                              style={{
                                padding: "7px 12px",
                                backgroundColor: color.primary,
                                color: color.secondary,
                              }}
                              onClick={() => {
                                // handelDropDown(!showDropdown);
                                // console.log("reseting");
                                handleFilterReset("time");
                              }}
                            >
                              <div className="export-text-div ">Reset</div>
                            </Button>
                            <Button
                              variant="primary"
                              style={{
                                padding: "7px 12px",
                                backgroundColor: color.secondary,
                              }}
                              onClick={() => {
                                handelDropDown(!showDropdown);
                                handleSaveChanges();
                              }}
                            >
                              <div className="export-text-div ">Submit</div>
                            </Button>
                          </div>
                        </div>
                      </div>
                    )} */}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}

                  <Button
                    variant="primary"
                    style={{
                      padding: "8px 12px",
                      backgroundColor: color.secondary,
                    }}
                  >
                    <div className="export-text-div">
                      <CiExport />
                      <p className="p-0 m-0">Export</p>
                    </div>
                  </Button>
                </div>
              )}
            </div>
          }

          {/* {(loading && (
            <div
              className="showSpinner all-ticket-spinner"
              style={{
                height: "100vh",
              }}
            >
              <BeatLoader color="#2D97D4" />
            </div>
          )) || ( */}
          <Outlet
            context={{
              color,
              shadesOfBlue,
              overviewData,
              locationData,
              cropData,
              handelOverviewData,
              handelLocationData,
              handelCropData,
              showOverviewModalError,
              handelshowOverviewModalError,
              handelshowFirstPageError,
              showFirstPageError,
            }}
          />
          {/* )} */}
        </div>
      )) ||
        (showFirstPageError.status && (
          <Error
            code={showFirstPageError.status}
            msg={showFirstPageError.msg}
          />
        )) || <Error code={500} />}
    </>
  );
};

export default FirstPage;
