import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Switch from "@mui/material/Switch";
import { Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import "./MainForm.css";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return null;
}
const reporting_list = ["Whatsapp", "Phone", "Mail", "Portal"];
function GenDetails({
  genFields,
  setGenFields,
  machines_data,
  issue_list,
  assignee_list,
  fieldError,
  isSmallScreen,
}) {
  const theme = useTheme();
  useEffect(() => {
    // console.log("props- ", genFields.machines);
    // console.log("issue_list-", issue_list);
  }, [genFields.machines]);
  const handleChange = (event, value) => {
    let allCodes = value.map((data) => {
      return data.code;
    });
    // console.log(allCodes)
    setGenFields((prevState) => ({
      ...prevState,
      machines: allCodes,
    }));
  };

  const handleSubmit = (event) => {
    // event.preventDefault();
    // console.log("Selected values:");
  };
  const [subIssueList, setsubIssueList] = useState([]);
  const handleIssueChange = (event) => {
    setGenFields((prev) => ({
      ...prev,
      issue: event.target.value,
      subIssue: "",
    }));
  };
  const handleSubIssueChange = (event) => {
    setGenFields((prev) => ({
      ...prev,
      subIssue: event.target.value,
    }));
  };
  const handleVisitChange = (event) => {
    setGenFields((prev) => ({
      ...prev,
      visit: !prev.visit,
    }));
  };
  const handleAssigneeChange = (event) => {
    setGenFields((prev) => ({
      ...prev,
      assignee: event.target.value,
    }));
  };
  useEffect(() => {
    Object.entries(issue_list).forEach(([key, value]) => {
      if (value.id === genFields.issue) {
        setsubIssueList(value.sub_issue);
      }
    });
  }, [genFields.issue]);
  useEffect(() => {
    // console.log("subIssueList-", subIssueList);
  }, [subIssueList]);

  // Map the selected codes to their corresponding machine objects
  const [selectedMachines, setSelectedMachines] = useState([]);
  useEffect(() => {
    const selectedMachines =
      genFields?.machines?.map((code) =>
        machines_data.find((item) => item.code === code)
      ) || [];
    setSelectedMachines(selectedMachines);
  }, [genFields?.machines]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "20px",
        paddingBottom: "20px",
        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <FormControl
          helperText="Please select machine"
          error={fieldError.machineError}
          sx={{ width: "100%" }}
          color="primary"
        >
          {/* <InputLabel id="multi-select-label">Machines</InputLabel> */}
          <Autocomplete
            multiple
            id="multi-select-label"
            options={machines_data}
            getOptionLabel={(option) => `${option.code}(${option.location})`}
            filterSelectedOptions
            onChange={handleChange}
            value={selectedMachines}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Machines"
                placeholder="Select Machine"
                variant="outlined"
              />
            )}
          />
          {fieldError.machineError ? (
            <div
              style={{
                marginTop: "8px",
                color: "red",
                fontWeight: 300,
                fontSize: "14px",
                marginLeft: "10px",
              }}
            >
              Please select machine
            </div>
          ) : (
            <></>
          )}
        </FormControl>
        <div
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "25px",
            gap: 25,
          }}
        >
          <FormControl fullWidth error={fieldError.issueError}>
            <InputLabel id="demo-simple-select-label">Issue</InputLabel>
            <Select
              sx={{ borderRadius: "8px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={genFields.issue}
              label="Issue"
              onChange={handleIssueChange}
            >
              {issue_list &&
                Object.entries(issue_list)?.map(([issue, value]) => (
                  <MenuItem
                    key={issue}
                    value={value.id}
                    style={getStyles(issue, genFields.machines, theme)}
                  >
                    {issue}
                  </MenuItem>
                ))}
            </Select>
            {fieldError.issueError ? (
              <div
                style={{
                  marginTop: "8px",
                  color: "red",
                  fontWeight: 300,
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
              >
                Please select issue
              </div>
            ) : (
              <></>
            )}
          </FormControl>
          <FormControl fullWidth error={fieldError.subIssueError}>
            <InputLabel id="demo-simple-select-label">Sub Issue</InputLabel>
            <Select
              sx={{ borderRadius: "8px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={genFields.subIssue}
              label="Sub Issue"
              onChange={handleSubIssueChange}
            >
              {subIssueList.map((data) => (
                <MenuItem
                  key={data[0]}
                  value={data[0]}
                  style={getStyles(data[1], genFields.machines, theme)}
                >
                  {data[1]}
                </MenuItem>
              ))}
            </Select>
            {fieldError.subIssueError ? (
              <div
                style={{
                  marginTop: "8px",
                  color: "red",
                  fontWeight: 300,
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
              >
                Please select Sub Issue
              </div>
            ) : (
              <></>
            )}
          </FormControl>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "25px",
            gap: 25,
            flexDirection: isSmallScreen ? "column" : "row",
          }}
        >
          <FormControl fullWidth error={fieldError.assigneeError}>
            <InputLabel id="demo-simple-select-label">Assignee</InputLabel>
            <Select
              sx={{ borderRadius: "8px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={genFields.assignee}
              label="Assignee"
              onChange={handleAssigneeChange}
            >
              {assignee_list.map((data) => (
                <MenuItem
                  key={data.id}
                  value={data.id}
                  style={getStyles(data.name, genFields.machines, theme)}
                >
                  {data.name}
                </MenuItem>
              ))}
            </Select>
            {fieldError.assigneeError ? (
              <div
                style={{
                  marginTop: "8px",
                  color: "red",
                  fontWeight: 300,
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
              >
                Please select assignee
              </div>
            ) : (
              <></>
            )}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Issue Reporting
            </InputLabel>
            <Select
              sx={{ borderRadius: "8px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={genFields.reporting}
              label="Issue Reporting"
              onChange={(event) => {
                setGenFields((prev) => ({
                  ...prev,
                  reporting: event.target.value,
                }));
              }}
            >
              {reporting_list.map((data) => (
                <MenuItem
                  key={data}
                  value={data}
                  style={getStyles(data, genFields.machines, theme)}
                >
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "25px",
            //   gap: 25,
          }}
        >
          <FormControl fullWidth error={fieldError.descError}>
            <TextField
              error={fieldError.descError}
              fullWidth
              id="outlined-multiline-static"
              label="Description"
              multiline
              rows={4}
              labelId="demo-simple-select-label"
              value={genFields.description}
              onChange={(event) => {
                setGenFields((prev) => ({
                  ...prev,
                  description: event.target.value,
                }));
              }}
            />
            {fieldError.descError ? (
              <div
                style={{
                  marginTop: "8px",
                  color: "red",
                  fontWeight: 300,
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
              >
                Please enter description
              </div>
            ) : (
              <></>
            )}
          </FormControl>
          <FormControl fullWidth style={{ marginTop: "10px" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={genFields.visit}
                  onChange={handleVisitChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={
                <Typography
                  variant="body1"
                  style={{ fontWeight: 600, color: "rgba(76,78,100,0.87)" }}
                >
                  Visit Required
                </Typography>
              }
            />
          </FormControl>
        </div>
      </div>
    </div>
  );
}

export default GenDetails;
