import React, { useEffect, useState, useContext, useCallback } from "react";
import Tickets from "./Tickets";
import Dropdown from "./filters/Dropdown";
import "../assets/styles/AllTickets.css";
import AllFilters from "./filters/AllFilters";
import ContextState from "./contextApi/ContextState";
import { useDispatch, useSelector } from "react-redux";
import { addFilters } from "./features/filtersSlice/filterSlice";
import { BeatLoader } from "react-spinners";
import Error from "./error/Error";
import * as XLSX from "xlsx";
import { GiConsoleController } from "react-icons/gi";

const AllTickets = () => {
  const { fetchApi, showLoading, showError, showErrorPage, openSnackbar } =
    useContext(ContextState);
  const dispatch = useDispatch();
  const userFilters = useSelector((state) => state.userFilters);

  // blur all tickets
  const [blurTickets, setblurTickets] = useState("");
  const [liveMachinesData, setliveMachinesData] = useState(null);

  const handelPageBlur = (blur_ticket) => {
    if (blur_ticket) {
      setblurTickets("all-ticket-content-blur");
    } else {
      setblurTickets("");
    }
  };

  // loding page when data to be fetched
  const [loading, setLoading] = useState(true);

  const [allFiltersDisplay, setallFilterDisplay] = useState(false);
  // filters
  const [filters, setFilters] = useState({
    status: [],
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    machineNumber: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    sort: [],
    crop_variation: [],
    params_variation: [],
    assignee: [],
    moreFilters: [],
    searchBox: [],
    ticketPerPage: [],
    commitId: [],
    modelNo: [],
    branchName: [],
  });
  const [showAllfilters, setshowAllFilters] = useState({
    status: [],
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    machineNumber: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    sort: [],
    crop_variation: [],
    params_variation: [],
    assignee: [],
    moreFilters: [],
    searchBox: [],
    ticketPerPage: [],
    commitId: [],
    modelNo: [],
    branchName: [],
  });
  const [filterCount, setFilterCount] = useState({
    status: 0,
    issueType: 0,
    subIssueType: 0,
    RO: 0,
    DO: 0,
    machineNumber: 0,
    createdStartDate: 0,
    createdEndDate: 0,
    resolvedStartDate: 0,
    resolvedEndDate: 0,
    sort: 0,
    crop_variation: 0,
    params_variation: 0,
    assignee: 0,
    moreFilters: 0,
    searchBox: 0,
    ticketPerPage: 0,
    commitId: 0,
    modelNo: 0,
    branchName: 0,
  });
  // Function to update filters
  const updateFilters = (Filters, clickedPageNumber, updateURL = true) => {
    // console.log("update filter 0 -->", Filters, clickedPageNumber);
    Filters = applyCoustomChecksFilter(Filters);
    setFilters(Filters);
    setCurrentPage(clickedPageNumber);
    if (updateURL) {
      updateURLParams(Filters, clickedPageNumber);
      // console.log("Url Is Updating ");
    } else {
      // console.log("Url Is Not Updating ");
    }
    updateFilterCount(Filters);
    if (getUserFilters?.issues) {
      applyIssueTypeFilters(Filters);
    }
    showAllFilters(Filters);
    fetchData(Filters, clickedPageNumber, cardsPerPage);
  };

  const updateFilterCount = (farr) => {
    for (const key in farr) {
      const count = farr[key].length;
      filterCount[key] = count;
    }
    setFilterCount(filterCount);
  };

  const updateURLParams = (params, page) => {
    const urlSearchParams = new URLSearchParams();
    Object.keys(params).forEach((key) => {
      // console.log(key, params[key]);
      if (params[key]) {
        urlSearchParams.set(key, params[key]);
      } else {
        urlSearchParams.delete(key);
      }
    });
    urlSearchParams.set("currentPage", parseInt(page) + 1);
    // urlSearchParams.set("cardsPerPage", cardsPerPage);
    const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;

    if (window.location.pathname === "/allticket" && !Data) {
      // Check if the state already exists
      // console.log("Replace state Wroking");
      const currentState = window.history.state;
      if (!currentState || !currentState.filters) {
        // Replace the initial state if no filters exist
        window.history.replaceState(
          {
            filters: params,
            Page: parseInt(page) + 1,
          },
          "",
          newUrl
        );
      }
    } else {
      // Push state for subsequent changes
      window.history.pushState(
        {
          filters: params,
          Page: parseInt(page) + 1,
        },
        "",
        newUrl
      );
    }
  };

  // set page parameter to render
  const [currentPage, setCurrentPage] = useState(0); // Initialize currentPage to 0
  const [totalCardsCount, settotalCardsCount] = useState(20); // Initialize total vards from backend
  // Number of cards to display per page
  const [cardsPerPage, setCardsPerPage] = useState(20);

  const handelCurrentPage = (clickedPageNumber) => {
    // console.log(
    //   "current Page ----------->",
    //   clickedPageNumber,
    //   parseInt(clickedPageNumber) + 1,
    //   currentPage
    // );
    // setCurrentPage(parseInt(clickedPageNumber) + 1);
    updateFilters(filters, parseInt(clickedPageNumber));
  };

  const [Data, setData] = useState(null);
  //  State for the Extra details for Export Data...

  const fetchData = async (F, Page, PerPage, reloadData = true) => {
    if (reloadData) {
      setData(null);
      // console.log("data set to empty List - 3");

      setLoading(true);
      showLoading(true);
    }
    // data from backend
    // total number of cards
    // cards filter show how many pages perpage (cardsPerPage : 15)

    const payload = {};
    payload["page_num"] = parseInt(Page) + 1;
    if (F["createdStartDate"] && F["createdStartDate"].length) {
      payload["creation_from"] = F["createdStartDate"][0];
    }
    if (F["createdEndDate"] && F["createdEndDate"].length) {
      payload["creation_to"] = F["createdEndDate"][0];
    }
    if (
      F["resolvedStartDate"] &&
      F["resolvedStartDate"].length &&
      F["status"].some((value) => value.toLowerCase() === "resolved")
    ) {
      payload["resolved_from"] = F["resolvedStartDate"][0];
    }
    if (
      F["resolvedEndDate"] &&
      F["resolvedEndDate"].length &&
      F["status"].some((value) => value.toLowerCase() === "resolved")
    ) {
      payload["resolved_to"] = F["resolvedEndDate"][0];
    } else {
    }
    if (F["issueType"] && F["issueType"].length) {
      payload["issue"] = F["issueType"];
    }
    if (F["subIssueType"] && F["subIssueType"].length) {
      payload["sub_issue"] = F["subIssueType"];
    }
    if (F["status"] && F["status"].length) {
      payload["status"] = F["status"].map((e) => e.toLowerCase());
    }
    if (F["machineNumber"] && F["machineNumber"].length) {
      payload["machines"] = F["machineNumber"];
    }
    if (F["DO"] && F["DO"].length) {
      payload["districts"] = F["DO"];
    }
    if (F["RO"] && F["RO"].length) {
      payload["regions"] = F["RO"];
    }
    if (F["sort"] && F["sort"].length) {
      const sortDictManual = {
        "Creation time- Ascending": "creation_time:ASC",
        "Creation time- Descending": "creation_time:DESC",
        "Updated time- Descending": "last_updated:DESC",
      };
      const sortArr =
        (sortDictManual[filtersOptions["sortDict"][0]] &&
          F["sort"].map((item) => filtersOptions["sortDict"][item])) ||
        sortDictManual[F["sort"][0]];
      payload["Sort"] = sortArr;
    }
    if (F["crop_variation"] && F["crop_variation"].length) {
      payload["crop_variation"] = F["crop_variation"];
    }
    if (F["params_variation"] && F["params_variation"].length) {
      payload["params_variation"] = F["params_variation"];
    }
    if (F["assignee"] && F["assignee"].length) {
      payload["assignee"] = F["assignee"];
    }
    if (F["commitId"] && F["commitId"].length) {
      payload["commit_id"] = F["commitId"];
    }
    if (F["modelNo"] && F["modelNo"].length) {
      payload["model_no"] = F["modelNo"];
    }
    if (F["branchName"] && F["branchName"].length) {
      payload["branchName"] = F["branchName"];
    }
    if (F["moreFilters"] && F["moreFilters"].length) {
      F["moreFilters"].forEach((item) => {
        payload[item] = true;
      });
    }
    if (F["searchBox"] && F["searchBox"].length && F["searchBox"][0] !== "") {
      payload["ticket_id"] = F["searchBox"][0];
    }
    if (
      F["ticketPerPage"] &&
      F["ticketPerPage"].length &&
      F["ticketPerPage"][0] !== ""
    ) {
      payload["tickets_limit"] = F["ticketPerPage"][0];
    }

    let is_all_ticket_data_Mounted = true;
    const all_tickets_data = await fetchApi(
      "get_all_tickets_data",
      "POST",
      payload,
      is_all_ticket_data_Mounted
    );
    // console.log("all_ticket_data : ", all_tickets_data, payload);

    if (all_tickets_data.statusCode === 200) {
      showErrorPage(null, null);
      setData(all_tickets_data?.data || null);
      settotalCardsCount(all_tickets_data?.data?.total_count || 1);

      if (all_tickets_data?.data?.tickets_limit === "All") {
        setCardsPerPage(all_tickets_data?.data?.total_count || 1);
      } else {
        setCardsPerPage(all_tickets_data?.data?.tickets_limit || 1);
      }
    } else if (
      all_tickets_data.statusCode === 500 ||
      all_tickets_data.statusCode === 401
    ) {
      setData(null);
      showErrorPage(all_tickets_data.statusCode, all_tickets_data.msg);
    } else {
      setData(null);
      // showErrorPage(all_tickets_data.statusCode, all_tickets_data.msg);
      localStorage.clear();
      window.location.href = "/login";
    }

    if (reloadData) {
      setLoading(false);
      showLoading(false);
    }

    return () => {
      is_all_ticket_data_Mounted = false;
    };
  };
  // to reload the data
  async function reloadData() {
    await fetchData(filters, currentPage, cardsPerPage, false);
  }

  const handleFilterChange = (filter, filterType) => {
    setFilters((prevFilters) => {
      const currentFilters = { ...prevFilters };
      const filterList = currentFilters[filterType];

      if (filterList && filterType === "searchBox") {
        if (filter !== "") currentFilters[filterType] = [filter];
        else currentFilters[filterType] = [];
      } else if (filterList && filterList.includes(filter)) {
        currentFilters[filterType] = filterList.filter(
          (prevFilter) => prevFilter !== filter
        );
      } else if (
        filterList &&
        (filterType === "createdStartDate" ||
          filterType === "createdEndDate" ||
          filterType === "resolvedStartDate" ||
          filterType === "resolvedEndDate")
      ) {
        currentFilters[filterType] = [filter];
      } else if (
        (filterList && filterType === "sort") ||
        filterType === "ticketPerPage"
      ) {
        currentFilters[filterType] = [filter];
      } else {
        currentFilters[filterType] = [...(filterList || []), filter];
        if (filterList && filterType === "issueType") {
          currentFilters["subIssueType"] = [];
          currentFilters["status"] = [];
          currentFilters["crop_variation"] = [];
          currentFilters["params_variation"] = [];
        }
      }
      if (filterType === "RO") {
        applyROFilters(currentFilters);
      } else if (filterType === "machineNumber") {
        applyMachineNumberFilters(currentFilters);
      } else if (filterType === "DO") {
        applyDOFilters(currentFilters);
      }
      applyIssueTypeFilters(currentFilters);
      updateFilterCount(currentFilters);
      applyCropVariationFilters(currentFilters);
      // console.log(
      //   "update filter 1 -->",
      //   prevFilters,
      //   currentFilters,
      //   filter,
      //   filterType
      // );
      return currentFilters;
    });
  };

  const handleFilterReset = (filtername) => {
    // const filters = { ...filters };
    if (Object.keys(filters || {}).includes(filtername)) {
      filters[filtername] = [];
    }
    if (filtername === "moreFilters") {
      filters["searchBox"] = [];
      updateFilters["ticketPerPage"] = [];
    }
    if (filtername === "issueType") {
      filters["subIssueType"] = [];
    }
    if (filtername === "dateSelector") {
      filters["resolvedStartDate"] = [];
      filters["resolvedEndDate"] = [];
      filters["createdStartDate"] = [];
      filters["createdEndDate"] = [];
    }
    // console.log("working", filtername, filtername === "dateSelector", filters);
    setFilters(filters);
    // console.log(filtername);
    if (filtername === "RO") {
      applyROFilters(filters);
    } else if (filtername === "machineNumber") {
      applyMachineNumberFilters(filters);
    } else if (filtername === "DO") {
      applyDOFilters(filters);
    }
    // console.log(filters, "updateFilterCount");
    applyIssueTypeFilters(filters);
    updateFilterCount(filters);
  };

  const handleSaveChanges = (filtername) => {
    // console.log("sendFilters->  ", filtername);
    const filterAfterCoustomChange = applyCoustomChecksFilter(filters);
    updateFilters(filterAfterCoustomChange, 0);
    setshowAllFilters(filterAfterCoustomChange);
  };

  const handelResetAllBtn = () => {
    // console.log("working");
    for (const key in filters) {
      filters[key] = [];
    }
    // console.log("updated filter ", filters);
    updateFilters(filters, 0);

    setfiltersOptions((prevState) => ({
      ...prevState,
      DO: filtersOptionsCopy["DO"],
      machineNumber: filtersOptionsCopy["machineNumber"],
      RO: filtersOptionsCopy["RO"],
    }));
  };

  const handelDeleteFilterBtn = (filterType, subfilter) => {
    // console.log("working", filterType, subfilter);

    // Check if the filterKey exists in the filters state
    if (filters.hasOwnProperty(filterType)) {
      // Remove the specified filterValue from the filterKey array
      filters[filterType] = filters[filterType].filter(
        (filter) => filter !== subfilter
      );
    }
    if (filterType === "status" && subfilter === "Resolved") {
      filters["resolvedEndDate"] = [];
      filters["resolvedStartDate"] = [];
    }
    if (filterType === "issueType") {
      filters["subIssueType"] = [];
      filters["status"] = [];
      if (subfilter === "Data Variation") {
        filters["crop_variation"] = [];
        filters["params_variation"] = [];
        filters["modelNo"] = [];
        filters["commitId"] = [];
        filters["branchName"] = [];
      }
    }
    if (filterType === "crop_variation") {
      filters["params_variation"] = [];
    }

    if (filterType === "RO") {
      applyROFilters(filters);
    } else if (filterType === "machineNumber") {
      applyMachineNumberFilters(filters);
    } else if (filterType === "DO") {
      applyDOFilters(filters);
    }
    updateFilters(filters, 0);
    // console.log("Deleted single filter --->", filters);
  };

  function showAllFilters(FiltersDict) {
    for (const i in FiltersDict) {
      if (FiltersDict[i].length !== 0) {
        // console.log("show filter");
        setallFilterDisplay(true);
        return;
      }
    }
    setallFilterDisplay(false);
  }

  const [getUserFilters, setgetUserFilters] = useState(null);

  // filters to show

  const [filtersOptions, setfiltersOptions] = useState({
    status: [],
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    machineNumber: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    sort: [],
    sortDict: [],
    crop_variation: [],
    params_variation: [],
    assignee: [],
    commitId: [],
    modelNo: [],
    branchName: [],
    moreFilters: ["visit_required", "internet_issue"],
    ticketPerPage: ["20", "50", "100", "All"],
  });
  const [filtersOptionsCopy, setfiltersOptionsCopy] = useState({
    status: [],
    issueType: [],
    subIssueType: [],
    RO: [],
    DO: [],
    machineNumber: [],
    createdStartDate: [],
    createdEndDate: [],
    resolvedStartDate: [],
    resolvedEndDate: [],
    sort: [],
    sortDict: [],
    crop_variation: [],
    params_variation: [],
    assignee: [],
    commitId: [],
    modelNo: [],
    branchName: [],
    moreFilters: ["visit_required", "internet_issue"],
    ticketPerPage: ["20", "50", "100", "All"],
  });
  // filters sorting according to selected filter
  const getFilterResponse = async (response) => {
    // setLoading(true);
    // showLoading(true);
    try {
      // calling refresh token whenever loading the page
      // console.log("get_user_filters--->", response);
      if (response.statusCode === 200) {
        // console.log("get_user_filters Data Fetched Successfully");
        const data = response.data;
        // console.log("data", new Date().toLocaleString(), data);
        setgetUserFilters(data);

        // Extract RO# keys from  filters_data
        const roKeys =
          data && data.access_level === "institution"
            ? Object.keys(data?.filters_data || {})
            : [];

        // Extract DO# keys from RO objects in filters_data
        const doKeys =
          data && data.access_level === "institution"
            ? Object.values(data?.filters_data || {}).flatMap((Do) =>
                Object.keys(Do)
              )
            : data && data.access_level === "region"
            ? Object.keys(data.filters_data)
            : [];

        // Populate machineNumber array with unique machine numbers
        const uniquedoKeys = Array.from(new Set(doKeys));

        // Extract machine numbers from DO keys
        const machineNumbervalue =
          data && data.access_level === "institution"
            ? Object.values(data?.filters_data || {})
                .flatMap((ro) => Object.values(ro))
                .flatMap((doData) => doData)
                .flatMap((machine) => machine)
                .concat(Object.values(data?.ro_machines || {}))
            : data && data.access_level === "region"
            ? Object.values(data?.filters_data || {})
                .flatMap((doList) => {
                  return Object.values(doList);
                })
                .flat()
            : data && data.access_level === "district"
            ? data.filters_data
            : [];
        // Populate machineNumber array with unique machine numbers
        const uniqueMachineNumbers = Array.from(new Set(machineNumbervalue));

        // populate subissuetype in filter option
        const subIssueTypes = Object.values(data.issues[0]).flatMap((issue) =>
          issue.sub_issue.map((subIssue) => subIssue[1])
        );
        // console.log(
        //   "Sub issues : ",
        //   subIssueTypes,
        //   typeof subIssueTypes,
        //   subIssueTypes.length
        // );
        // populating sort in filters Option
        const sortArr =
          (data && data.sort_list.map((item) => item.value)) || [];
        const sortDictArr =
          (data &&
            data.sort_list.reduce((acc, item) => {
              acc[item.value] = item.id;
              return acc;
            }, {})) ||
          [];

        const assigneeType =
          (data && data.assignee_list?.map((item) => item.name)) || [];
        // console.log("assigneeType", assigneeType);
        const cropvariationTypes = data ? Object.keys(data.param_data) : [];

        let commitIdArr = data && Object.values(data.commit_id_list || {});

        commitIdArr = commitIdArr.map((commitIds, index) => {
          return commitIds.slice(0, 5);
        });

        const modelNoArr = data && Object.values(data.model_no_list || {});
        const branchNameArr = data && Object.values(data.git_branch_list || []);
        const dataParamsTypes =
          Array.from(
            new Set(
              data &&
                Object.values(data.param_data).flatMap((item) =>
                  Object.keys(item.client_params)
                )
            )
          ) || [];

        // console.log("cropvariationTypes", cropvariationTypes, dataParamsTypes);
        const statusTypes = Array.from(
          new Set(Object.values(data?.states_list || {}).flat())
        );
        const issueTypeArr = Object.keys(data?.states_list || {});
        // console.log("statusTypes:", statusTypes, issueTypeArr);

        setfiltersOptions((eachFilterToShow) => ({
          ...eachFilterToShow,
          RO: roKeys,
          DO: uniquedoKeys,
          machineNumber: uniqueMachineNumbers,
          subIssueType: subIssueTypes,
          sortDict: sortDictArr,
          sort: sortArr,
          crop_variation: cropvariationTypes,
          params_variation: dataParamsTypes,
          assignee: assigneeType,
          modelNo: modelNoArr,
          commitId: commitIdArr,
          branchName: branchNameArr,
          issueType: issueTypeArr,
          status: statusTypes,
        }));
        setfiltersOptionsCopy((eachFilterToShow) => ({
          ...eachFilterToShow,
          RO: roKeys,
          DO: uniquedoKeys,
          machineNumber: uniqueMachineNumbers,
          subIssueType: subIssueTypes,
          status: statusTypes,
          sort: sortArr,
          sortDict: sortDictArr,
          crop_variation: cropvariationTypes,
          params_variation: dataParamsTypes,
          assignee: assigneeType,
          modelNo: modelNoArr,
          commitId: commitIdArr,
          branchName: branchNameArr,
          issueType: issueTypeArr,
        }));
      } else {
        // Handle non-successful responses
        console.error("Error:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const applyROFilters = (filtersDict) => {
    const roFilters = filtersDict.RO;

    // Extract all DO keys from selected RO filters
    const filteredDO = roFilters.flatMap((ro) =>
      getUserFilters?.filters_data[ro]
        ? Object.keys(getUserFilters?.filters_data[ro] || {})
        : []
    );

    // console.log("filteredDO", filteredDO, roFilters);

    // Extract all machine numbers from selected RO filters and DO keys
    const filteredMachineNumbers = roFilters.flatMap((ro) =>
      getUserFilters?.filters_data[ro]
        ? Object.values(getUserFilters?.filters_data[ro] || {})
            .flatMap((doValues) => doValues)
            .concat(
              getUserFilters?.ro_machines[ro]
                ? getUserFilters?.ro_machines[ro]
                : []
            )
        : []
    );

    // Update filtersOptions with unique filtered DO values and machine numbers
    const uniqueDO = Array.from(new Set(filteredDO));
    const uniqueMachineNumbers = Array.from(new Set(filteredMachineNumbers));

    if (roFilters.length === 0) {
      const newFilter = { ...filters };
      newFilter["DO"] = [];
      newFilter["RO"] = [];
      newFilter["machineNumber"] = [];
      // setFilters(newFilter);
      // updateFilterCount(newFilter);
      // showAllFilters(newFilter);
      setfiltersOptions((prevState) => ({
        ...prevState,
        DO: filtersOptionsCopy["DO"],
        machineNumber: filtersOptionsCopy["machineNumber"],
      }));
    } else {
      const newFilter = { ...filters };
      newFilter["DO"] = [];
      newFilter["machineNumber"] = [];
      // console.log("uniqueMachineNumber --> ", uniqueMachineNumbers);
      // setFilters(newFilter);
      // updateFilterCount(newFilter);
      // showAllFilters(newFilter);

      setfiltersOptions((prevState) => ({
        ...prevState,
        DO: uniqueDO,
        machineNumber: uniqueMachineNumbers,
      }));
    }
  };

  const applyDOFilters = (filtersDict) => {
    const doFilters = filtersDict.DO;
    const roFilters = filtersDict.RO;
    if (getUserFilters.access_level === "institution") {
      // Find corresponding RO keys for selected DO keys
      const roKeys = [];
      Object.entries(getUserFilters?.filters_data || {}).forEach(
        ([roKey, doValues]) => {
          Object.keys(doValues).forEach((doKey) => {
            if (doFilters.includes(doKey)) {
              roKeys.push(roKey);
            }
          });
        }
      );

      // Extract all machine numbers from selected DO keys
      const filteredMachineNumbers = doFilters.flatMap((doKey) =>
        Object.values(getUserFilters.filters_data)
          .filter((ro) => ro[doKey])
          .flatMap((ro) => ro[doKey])
      );
      // Update filtersOptions with unique filtered machine numbers and corresponding RO keys
      const uniqueMachineNumbers = Array.from(new Set(filteredMachineNumbers));
      const uniqueRO = Array.from(new Set(roKeys));
      if (doFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: filtersOptionsCopy["machineNumber"],
          RO: filtersOptionsCopy["RO"],
        }));
      } else {
        // //filters["machineNumber"] = uniqueMachineNumbers;
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: uniqueMachineNumbers,
          RO: roFilters.length === 0 ? uniqueRO : prevState.RO,
        }));
      }
    } else if (getUserFilters.access_level === "region") {
      // Extract machine numbers for selected DO keys
      const filteredMachineNumbers = doFilters.reduce((acc, doKey) => {
        if (getUserFilters.filters_data[doKey]) {
          acc.push(...getUserFilters.filters_data[doKey]);
        }
        return acc;
      }, []);

      if (doFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: filtersOptionsCopy["machineNumber"],
        }));
      } else {
        // Update filtersOptions machineNumber with unique filtered machine numbers
        setfiltersOptions((prevState) => ({
          ...prevState,
          machineNumber: Array.from(new Set(filteredMachineNumbers)),
        }));
      }
    }
  };

  const applyMachineNumberFilters = (filtersDict) => {
    const roFilters = filtersDict.RO;
    const doFilters = filtersDict.DO;
    const machineNumberFilters = filtersDict.machineNumber;
    if (getUserFilters.access_level === "institution") {
      // Extract corresponding RO and DO keys for selected machine numbers
      const filteredRODO = Object.entries(
        getUserFilters?.filters_data || {}
      ).reduce((acc, [ro, roData]) => {
        Object.entries(roData || {}).forEach(([doKey, machines]) => {
          const matchingMachines = machines.filter((machine) =>
            machineNumberFilters.includes(machine)
          );
          if (matchingMachines.length > 0) {
            acc.push({ RO: ro, DO: doKey });
          }
        });
        return acc;
      }, []);

      // Extract unique RO and DO keys
      const uniqueRO = Array.from(
        new Set(filteredRODO.map((entry) => entry.RO))
      );
      const uniqueDO = Array.from(
        new Set(filteredRODO.map((entry) => entry.DO))
      );

      // Update filtersOptions with unique RO and DO keys
      if (machineNumberFilters.length === 0) {
        // console.log(
        //   "machineNumberFilters.length",
        //   machineNumberFilters.length,
        //   filtersOptions
        // );
        setfiltersOptions((prevState) => ({
          ...prevState,
          RO: filtersOptionsCopy["RO"],
          DO: filtersOptionsCopy["DO"],
        }));
      } else {
        setfiltersOptions((prevState) => ({
          ...prevState,
          RO: roFilters.length === 0 ? uniqueRO : prevState.RO,
          DO: doFilters.length === 0 ? uniqueDO : prevState.DO,
        }));
      }
    } else if (getUserFilters.access_level === "region") {
      // Extract DO keys for selected machine numbers
      const filteredDOKeys = Object.keys(getUserFilters.filters_data).filter(
        (doKey) => {
          const machineNumbers = getUserFilters.filters_data[doKey];
          return machineNumbers.some((number) =>
            machineNumberFilters.includes(number)
          );
        }
      );

      if (machineNumberFilters.length === 0) {
        setfiltersOptions((prevState) => ({
          ...prevState,
          DO: filtersOptionsCopy["DO"],
        }));
      } else {
        // Update filtersOptions DO with unique filtered DO keys
        setfiltersOptions((prevState) => ({
          ...prevState,
          DO:
            doFilters.length === 0
              ? Array.from(new Set(filteredDOKeys))
              : prevState.RO,
        }));
      }
    }
  };

  // const applyIssueTypeFilters = (filterDict) => {
  // Function to update filtersOptions based on selected issueType

  // Function to update filtersOptions based on selected issueType
  const applyIssueTypeFilters = (filterDict) => {
    if (filterDict?.issueType) {
      if (filterDict.issueType.length !== 0) {
        // Filter subIssueType values based on the selected issueType
        const filteredSubIssueType =
          getUserFilters?.issues?.reduce((acc, issue) => {
            const selectedIssueTypes = filterDict.issueType;
            for (const issueType of selectedIssueTypes) {
              if (issue[issueType]) {
                acc.push(
                  ...issue[issueType].sub_issue?.map((subIssue) => subIssue[1])
                );
              }
            }
            return acc;
          }, []) || [];
        // Update filtersOptions state with the filtered subIssueType values
        setfiltersOptions((prevState) => ({
          ...prevState,
          subIssueType: Array.from(
            new Set([...(filters.subIssueType || []), ...filteredSubIssueType])
          ),
        }));
      } else {
        setfiltersOptions((prevState) => ({
          ...prevState,
          subIssueType: filtersOptionsCopy.subIssueType,
        }));
      }
    }
  };

  const applyCropVariationFilters = (filterDict) => {
    // Get the list of param_data keys from filters.crop_variation
    const selectedCrops = filterDict.crop_variation;

    // Filter client_params keys based on selectedCrops
    const clientParamsKeysArray = Object.entries(
      getUserFilters?.param_data || {}
    )
      .filter(([key]) => selectedCrops.includes(key)) // Filter based on selectedCrops
      .flatMap(([key, value]) => Object.keys(value.client_params));

    // Update filtersOptions.params_variation
    const updatedParamsVariation = Array.from(
      new Set([...(filters.params_variation || []), ...clientParamsKeysArray])
    );

    // console.log("updatedParamsVariation", updatedParamsVariation);
    if (selectedCrops.length) {
      setfiltersOptions((prevState) => ({
        ...prevState,
        params_variation: updatedParamsVariation,
      }));
    } else {
      setfiltersOptions((prevState) => ({
        ...prevState,
        params_variation: filtersOptionsCopy["params_variation"],
      }));
    }
  };

  const applyCoustomChecksFilter = (filterDict) => {
    if (
      filterDict["status"] &&
      !filterDict["status"].some(
        (status) => status.toLowerCase() === "resolved"
      )
    ) {
      filterDict["resolvedEndDate"] = [];
      filterDict["resolvedStartDate"] = [];
    }

    if (
      filterDict?.issueType &&
      !filterDict?.issueType?.includes("Data Variation")
    ) {
      filterDict["params_variation"] = [];
      filterDict["crop_variation"] = [];
      filterDict["modelNo"] = [];
      filterDict["commitId"] = [];
      filterDict["branchName"] = [];
    }

    if (filterDict?.issueType && filterDict?.issueType?.length === 0) {
      filterDict.subIssueType = [];
      filterDict.status = [];
    }

    if (
      filterDict?.crop_variation &&
      filterDict?.crop_variation?.length === 0
    ) {
      filterDict.params_variation = [];
    }

    return filterDict;
  };

  // useEffect(() => {
  //   console.log(filterCount);
  // }, [filterCount.searchBox]);
  
  // Memoized function to fetch live machine data
  const get_live_machine_data = useCallback(async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_SERVER + "get_live_machines_status",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        }
      );
      const data = await response.text();
      setliveMachinesData(JSON.parse(data));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  // async function get_live_machine_data() {
  //   // Replace 'your_api_url' with the actual URL of your API
  //   await fetch(
  //     process.env.REACT_APP_BACKEND_SERVER + "get_live_machines_status",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({}),
  //     }
  //   )
  //     .then((response) => response.text())
  //     .then((data) => {
  //       // Use the fetched data as needed
  //       // console.log("***********", "data fetched");
  //       setliveMachinesData(JSON.parse(data));
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }

  useEffect(() => {
    showLoading(true);
    setData(null);
    // console.log("data set to empty List - 1");
    const params = new URLSearchParams(window.location.search);
    // console.log("prams -->", params);
    const statusFilterData =
      params.get("status") !== "" && params.get("status") !== null
        ? params.get("status").split(",")
        : [];

    const issueTypeFilterData =
      params.get("issueType") !== "" && params.get("issueType") !== null
        ? params.get("issueType").split(",")
        : [];
    const ROFilterData =
      params.get("RO") !== "" && params.get("RO") !== null
        ? params.get("RO").split(",")
        : [];
    const DOFilterData =
      params.get("DO") !== "" && params.get("DO") !== null
        ? params.get("DO").split(",")
        : [];
    const machineNumberFilterData =
      params.get("machineNumber") !== "" && params.get("machineNumber") !== null
        ? params.get("machineNumber").split(",")
        : [];
    const createdStartDateFilterData =
      params.get("createdStartDate") !== "" &&
      params.get("createdStartDate") !== null
        ? params.get("createdStartDate").split(",")
        : [];
    const createdEndDateFilterData =
      params.get("createdEndDate") !== "" &&
      params.get("createdEndDate") !== null
        ? params.get("createdEndDate").split(",")
        : [];
    const resolvedStartDateDateFilterData =
      params.get("resolvedStartDate") !== "" &&
      params.get("resolvedStartDate") !== null
        ? params.get("resolvedStartDate").split(",")
        : [];
    const resolvedEndDateFilterData =
      params.get("resolvedEndDate") !== "" &&
      params.get("resolvedEndDate") !== null
        ? params.get("resolvedEndDate").split(",")
        : [];
    const assigneeFilterData =
      params.get("assignee") !== "" && params.get("assignee") !== null
        ? params.get("assignee").split(",")
        : [];
    const commitIdFilterData =
      params.get("commitId") !== "" && params.get("commitId") !== null
        ? params.get("commitId").split(",")
        : [];
    const modelNoFilterData =
      params.get("modelNo") !== "" && params.get("modelNo") !== null
        ? params.get("modelNo").split(",")
        : [];
    const branchNameFilterData =
      params.get("branchName") !== "" && params.get("branchName") !== null
        ? params.get("branchName").split(",")
        : [];
    const subIssueTypeFilterData =
      params.get("subIssueType") !== "" && params.get("subIssueType") !== null
        ? params.get("subIssueType").split(",")
        : [];
    const sortFilterData =
      params.get("sort") !== "" && params.get("sort") !== null
        ? params.get("sort").split(",")
        : [];
    const searchBoxFilterData =
      params.get("searchBox") !== "" && params.get("searchBox") !== null
        ? params.get("searchBox").split(",")
        : [];
    const Page =
      params.get("currentPage") !== "" && params.get("currentPage") !== null
        ? params.get("currentPage")
        : 1;
    const ticketPerPageFilterData =
      params.get("ticketPerPage") !== "" && params.get("ticketPerPage") !== null
        ? [params.get("ticketPerPage")]
        : [];
    // console.log("ticketPerPageFilterData", ticketPerPageFilterData);
    if (
      ticketPerPageFilterData.length ||
      ticketPerPageFilterData[0] === "20" ||
      ticketPerPageFilterData[0] === "50" ||
      ticketPerPageFilterData[0] === "100"
    ) {
      setCardsPerPage(parseInt(ticketPerPageFilterData[0]));
    }
    // console.log("searchBoxFilterData: ", searchBoxFilterData);
    const cropVariationFilterData =
      params.get("crop_variation") !== "" &&
      params.get("crop_variation") !== null
        ? params.get("crop_variation").split(",")
        : [];
    const paramVariationFilterData =
      params.get("params_variation") !== "" &&
      params.get("params_variation") !== null
        ? params.get("params_variation").split(",")
        : [];

    const moreFiltersFilterData =
      params.get("moreFilters") !== "" && params.get("moreFilters") !== null
        ? params.get("moreFilters").split(",")
        : [];
    // console.log("moreFiltersFilterData", moreFiltersFilterData);
    // machineNumber: [],
    // createdStartDate: [],
    // createdEndDate: [],
    // resolvedStartDate: [],
    // resolvedEndDate: [],
    // sort: [],
    // crop_variation: [],
    // params_variation: [],

    // console.log("------------->", Page);
    const initalFilters = {
      status: statusFilterData,
      issueType: issueTypeFilterData,
      RO: ROFilterData,
      DO: DOFilterData,
      machineNumber: machineNumberFilterData,
      createdStartDate: createdStartDateFilterData,
      createdEndDate: createdEndDateFilterData,
      resolvedStartDate: resolvedStartDateDateFilterData,
      resolvedEndDate: resolvedEndDateFilterData,
      sort: sortFilterData,
      crop_variation: cropVariationFilterData,
      params_variation: paramVariationFilterData,
      moreFilters: moreFiltersFilterData,
      searchBox: searchBoxFilterData,
      ticketPerPage: ticketPerPageFilterData,
      assignee: assigneeFilterData,
      subIssueType: subIssueTypeFilterData,
      commitId: commitIdFilterData,
      modelNo: modelNoFilterData,
      branchName: branchNameFilterData,
    };
    // console.log("insitial filter --->", initalFilters);

    let is_all_filter_data_Mounted = true;

    if (is_all_filter_data_Mounted) {
      get_live_machine_data();
    }
    const intervalId = setInterval(async () => {
      await get_live_machine_data();
    }, 45000);

    const fetchDataAndUpdateFilters = async (
      initalFilters,
      Page,
      updateURL = true
    ) => {
      const currentTime = Date.now();
      const lastDataFetchTimestamp = userFilters?.lastDataFetchTimestamp;

      if (
        !lastDataFetchTimestamp ||
        currentTime - lastDataFetchTimestamp >= 30 * 60 * 1000
      ) {
        // fetchFiltersDataApiCall
        const all_filter_data = await fetchApi(
          "get_user_filters",
          "POST",
          {},
          is_all_filter_data_Mounted
        );
        // console.log("all_filter_data in All Ticket : ", all_filter_data);

        if (all_filter_data.statusCode === 200) {
          dispatch(addFilters(all_filter_data));
          getFilterResponse(all_filter_data);
          showErrorPage(null, null);
        } else if (
          all_filter_data.statusCode === 500 ||
          all_filter_data.statusCode === 401
        ) {
          setData(null);
          showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
        } else {
          setData(null);
          // showErrorPage(all_filter_data.statusCode, all_filter_data.msg);
          // console.log("Some Error Occured", all_filter_data);
          localStorage.clear();
          window.location.href = "/login";
        }
      } else {
        // console.log("Data fetch from redux ************", userFilters?.data);
        getFilterResponse(userFilters?.data);
      }
      updateFilters(initalFilters, parseInt(Page) - 1, updateURL);
      setshowAllFilters(initalFilters);
      // showLoading(false);
    };
    fetchDataAndUpdateFilters(initalFilters, Page);
    // console.log("data set to empty List - 2");

    const handlePopState = () => {
      // Your logic to handle popstate event
      // console.log("mount", "User navigated back or forward");
      const previousPageURL = window.history.state || null;
      // console.log("Previous page URL:", previousPageURL);
      if (previousPageURL && previousPageURL.filters && previousPageURL.Page) {
        showLoading(true);
        setLoading(true);
        fetchDataAndUpdateFilters(
          previousPageURL.filters,
          previousPageURL.Page,
          false
        );
      }
    };

    window.addEventListener("popstate", handlePopState);

    // set scroll to top

    return () => {
      is_all_filter_data_Mounted = false;
      window.removeEventListener("popstate", handlePopState);
      clearInterval(intervalId);
      // console.log("unmount");
    };
  }, [get_live_machine_data]);

  const onExportLocal = (title) => {
    const headings = Data?.ticket_list?.map((each_item) =>
      Object.keys(each_item)
    );
    const headingsData = Data?.ticket_list?.map((each_item) =>
      Object.keys(each_item).map((key) => {
        // 'internet_issue'
        if (key === "internet_issue") {
          return each_item[key] ? "true" : "false";
        } else if (key === "machines") {
          return each_item[key] ? each_item[key].join(",") : "";
        } else {
          return each_item[key] ? each_item[key] : null;
        }
      })
    );

    const wsData = [headings[0], ...headingsData];

    // Find index of the column where headings === "machines"
    let machinesIndex = -1;
    headings[0].forEach((heading, index) => {
      if (heading === "machines") {
        machinesIndex = index;
      }
    });

    if (machinesIndex !== -1) {
      // Extract "machines" column
      const machinesColumn = wsData.map((row) => row[machinesIndex]);

      // Remove "machines" column from its original position
      wsData.forEach((row) => row.splice(machinesIndex, 1));

      // Insert "machines" column at the second index
      wsData.forEach((row, index) => row.splice(1, 0, machinesColumn[index]));
    }

    // console.log("Export Data ---> ", headings, headingsData, wsData);

    // Convert the data to a worksheet
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "AllTickets  ");

    // Generate and download the Excel file
    XLSX.writeFile(
      wb,
      `AllTicketsExportData(${title})_${new Date()
        .toString()
        .slice(0, 24)
        .replaceAll(" ", "_")}.xlsx`
    );
  };

  // detailed tickets export data

  const [moreDetailsSpinner, setMoreDetailsSpinner] = useState({
    spinner: false,
    icon: true,
  });

  const onDetailedExportLocal = async (title) => {
    const payload = {};
    payload["page_num"] = parseInt(currentPage) + 1;
    const F = filters;

    // spinner for more details download
    setMoreDetailsSpinner((prev) => ({
      icon: false,
      spinner: true,
    }));

    // Populate payload with filter data
    if (F["createdStartDate"] && F["createdStartDate"].length) {
      payload["creation_from"] = F["createdStartDate"][0];
    }
    if (F["createdEndDate"] && F["createdEndDate"].length) {
      payload["creation_to"] = F["createdEndDate"][0];
    }
    if (
      F["resolvedStartDate"] &&
      F["resolvedStartDate"].length &&
      F["status"].some((value) => value.toLowerCase() === "resolved")
    ) {
      payload["resolved_from"] = F["resolvedStartDate"][0];
    }
    if (
      F["resolvedEndDate"] &&
      F["resolvedEndDate"].length &&
      F["status"].some((value) => value.toLowerCase() === "resolved")
    ) {
      payload["resolved_to"] = F["resolvedEndDate"][0];
    }
    if (F["issueType"] && F["issueType"].length) {
      payload["issue"] = F["issueType"];
    }
    if (F["subIssueType"] && F["subIssueType"].length) {
      payload["sub_issue"] = F["subIssueType"];
    }
    if (F["status"] && F["status"].length) {
      payload["status"] = F["status"].map((e) => e.toLowerCase());
    }
    if (F["machineNumber"] && F["machineNumber"].length) {
      payload["machines"] = F["machineNumber"];
    }
    if (F["DO"] && F["DO"].length) {
      payload["districts"] = F["DO"];
    }
    if (F["RO"] && F["RO"].length) {
      payload["regions"] = F["RO"];
    }
    if (F["sort"] && F["sort"].length) {
      const sortDictManual = {
        "Creation time- Ascending": "creation_time:ASC",
        "Creation time- Descending": "creation_time:DESC",
        "Updated time- Descending": "last_updated:DESC",
      };
      const sortArr =
        sortDictManual[F["sort"][0]] ||
        F["sort"].map((item) => filtersOptions["sortDict"][item]);
      payload["Sort"] = sortArr;
    }
    if (F["crop_variation"] && F["crop_variation"].length) {
      payload["crop_variation"] = F["crop_variation"];
    }
    if (F["params_variation"] && F["params_variation"].length) {
      payload["params_variation"] = F["params_variation"];
    }
    if (F["assignee"] && F["assignee"].length) {
      payload["assignee"] = F["assignee"];
    }
    if (F["commitId"] && F["commitId"].length) {
      payload["commit_id"] = F["commitId"];
    }
    if (F["modelNo"] && F["modelNo"].length) {
      payload["model_no"] = F["modelNo"];
    }
    if (F["branchName"] && F["branchName"].length) {
      payload["branchName"] = F["branchName"];
    }
    if (F["moreFilters"] && F["moreFilters"].length) {
      F["moreFilters"].forEach((item) => {
        payload[item] = true;
      });
    }
    if (F["searchBox"] && F["searchBox"].length && F["searchBox"][0] !== "") {
      payload["ticket_id"] = F["searchBox"][0];
    }
    if (
      F["ticketPerPage"] &&
      F["ticketPerPage"].length &&
      F["ticketPerPage"][0] !== ""
    ) {
      payload["tickets_limit"] = F["ticketPerPage"][0];
    }

    let is_all_ticket_data_Mounted = true;

    // fetching API for detailed export data
    let detailed_data = [];
    const detailed_all_tickets_data = await fetchApi(
      "get_detailed_tickets_data",
      "POST",
      payload,
      is_all_ticket_data_Mounted
    );
    if (detailed_all_tickets_data.statusCode === 200) {
      detailed_data = detailed_all_tickets_data?.data || [];
      openSnackbar("Fetched Detailed Tickets successfully");
      // Process the data only after fetching it successfully
      const headings = detailed_data?.data?.map((each_item) =>
        Object.keys(each_item)
      );
      const headingsData = detailed_data?.data?.map((each_item) =>
        Object.keys(each_item).map((key) => {
          if (key === "machines") {
            return each_item[key] ? each_item[key].join(",") : "";
          } else if (key === "data_variation" || key === "model_details") {
            return each_item[key]
              ? each_item[key].map((item) => `(${item.join(", ")})`).join(", ")
              : "";
          } else {
            return each_item[key] ? each_item[key] : null;
          }
        })
      );

      const wsData = [headings[0], ...headingsData];

      // Find index of the column where headings === "machines"
      let machinesIndex = -1;
      headings[0].forEach((heading, index) => {
        if (heading === "machines") {
          machinesIndex = index;
        }
      });

      if (machinesIndex !== -1) {
        // Extract "machines" column
        const machinesColumn = wsData.map((row) => row[machinesIndex]);

        // Remove "machines" column from its original position
        wsData.forEach((row) => row.splice(machinesIndex, 1));

        // Insert "machines" column at the second index
        wsData.forEach((row, index) => row.splice(1, 0, machinesColumn[index]));
      }

      // Convert the data to a worksheet
      const ws = XLSX.utils.aoa_to_sheet(wsData);

      // Create a new workbook and append the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "AllTickets  ");

      // Generate and download the Excel file
      XLSX.writeFile(
        wb,
        `AllTicketsDetailedExportData(${title})_${new Date()
          .toString()
          .slice(0, 24)
          .replaceAll(" ", "_")}.xlsx`
      );

      // closing spinner

      setMoreDetailsSpinner((prev) => ({
        icon: true,
        spinner: false,
      }));
    } else if (
      detailed_all_tickets_data.statusCode === 500 ||
      detailed_all_tickets_data.statusCode === 401
    ) {
      openSnackbar(detailed_all_tickets_data?.msg);
    }

    setMoreDetailsSpinner((prev) => ({
      icon: true,
      spinner: false,
    }));
  };

  // console.log("testing export data--------->", detailedExportData);

  return (
    <>
      {(!showError.status && (
        <>
          <Dropdown
            blurTickets={handelPageBlur}
            filters={filters}
            filterCount={filterCount}
            handleFilterChange={handleFilterChange}
            handleSaveChanges={handleSaveChanges}
            handleFilterReset={handleFilterReset}
            getUserFilters={getUserFilters}
            getFilterResponse={getFilterResponse}
            filtersOptions={filtersOptions}
          />
          {allFiltersDisplay && (
            <AllFilters
              filters={showAllfilters}
              handelResetAllBtn={handelResetAllBtn}
              handelDeleteFilterBtn={handelDeleteFilterBtn}
              getUserFilters={getUserFilters}
            />
          )}

          <div
            className={`${blurTickets} all-ticket-content ${
              allFiltersDisplay ? "m-top-0 p-top-0" : " "
            }`}
          >
            {(loading && (
              // {lodingVisiblity && (
              <div className="showSpinner all-ticket-spinner">
                <BeatLoader color="#2D97D4" />
              </div>
            )) ||
              (Data && (
                <Tickets
                  ticketData={Data}
                  currentPage={currentPage}
                  liveMachinesData={liveMachinesData}
                  handelCurrentPage={handelCurrentPage}
                  cardsPerPage={cardsPerPage}
                  totalCardsCount={totalCardsCount}
                  reloadData={reloadData}
                  onExportLocal={onExportLocal}
                  onDetailedExportLocal={onDetailedExportLocal}
                  moreDetailsSpinner={moreDetailsSpinner}
                />
              )) || (
                <div className="container no-ticket-found-div text-center count_heading_div">
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    <i className="fa-solid fa-face-rolling-eyes fa-2xl inline-block"></i>
                    <p>No Data Found</p>
                  </div>
                </div>
              )}
          </div>
        </>
      )) ||
        (showError.status && <Error />) || <Error code={500} />}
    </>
  );
};

export default AllTickets;
