import React, { useState, useEffect, useContext } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import close from "../../assets/images/close.svg";
import { DataGrid } from "@mui/x-data-grid";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import useMediaQuery from "@mui/material/useMediaQuery";
import ContextState from "../contextApi/ContextState";

const modalstyle = {
  position: "relative",
  zIndex: "80",
  top: "10%",
  width: "95%",
  margin: "  auto",
  overflowY: "auto",
  height: "auto",
  borderRadius: "10px",
  backgroundColor: "#ffffff",
  overflowX: "hidden",
  maxWidth: "1400px",
};

const VerifyScanIdsModal = ({
  isOpen,
  onClose,
  column,
  row,
  color,
  checkedRows,
  reloadData,
}) => {
  const { fetchApi, openSnackbar } = useContext(ContextState);
  const [finalSelectedRows, setFinalSelectedRows] = useState(null);
  const [loading, setLoading] = useState(false);

  const [pullState, setPullState] = useState({
    finalDataPullScanids: "",
    pull_reason: "",
  });

  const pull_reason_list = ["Model Training", "Image Verification"];

  const isMediumScreen = useMediaQuery("(max-width:768px)");
  const isSmallScreen = useMediaQuery("(max-width:468px)");

  // Update finalSelectedRows and finalDataPullScanids when checkedRows changes
  useEffect(() => {
    setFinalSelectedRows([...checkedRows]);
    // Update the finalDataPullScanids based on the checkedRows
    const selectedRows = checkedRows.map((id) => {
      const finalrow = row.find((r) => r.id === id);
      return {
        scan_id: finalrow?.scan_id,
        machine_code: finalrow?.machine,
      };
    });

    setPullState((prev) => ({ ...prev, finalDataPullScanids: selectedRows }));
  }, [checkedRows, row]);

  function modifiedColumns(col) {
    if (!col) return [];
    // Filter and modify matched columns
    const matchedColumns = col
      .filter((each_col) => {
        const { field } = each_col;

        return ["scan_id", "machine", "status", "expand"].includes(field);
      })
      .map((each_col) => {
        const { field } = each_col;

        if (field === "scan_id") {
          return {
            ...each_col,
            flex: 0,
          };
        } else if (["machine", "status"].includes(field)) {
          return {
            ...each_col,
            flex: isMediumScreen ? 0 : 1,
          };
        }

        return each_col;
      });
    return matchedColumns;
  }

  // fetching api call
  const sendScanIdsForPulling = async (scanIdsData) => {
    // console.log("Api send for datapulling", scanIdsData);

    try {
      setLoading(true);
      let payload = {
        pull_data: pullState?.finalDataPullScanids,
        pull_reason: pullState?.pull_reason,
      };

      // Send the payload to the backend route
      const response = await fetchApi(
        "add_scanids_page_pull_data",
        "POST",
        payload,
        true
      );

      console.log(response);
      if (response.statusCode === 200) {
        // console.log("pull data added successfully");
        setLoading(false);
        onClose(); // modal closing
        openSnackbar(response.data.message, "success");
        reloadData();
      } else {
        // console.log("Error occured during edit ticket information:", error);
        setLoading(false);
        openSnackbar(response.msg, "error");
      }
    } catch (error) {
      // console.log("Error occured during edit ticket information:", error);
      openSnackbar("some error occured while saving details", "error");
    } finally {
      // setShowDataPullLoading(false);
    }
  };

  return (
    <Modal
      style={{
        position: "fixed",
        height: "100%",
      }}
      open={isOpen}
      onClose={onClose}
    >
      <Box sx={modalstyle}>
        {/* Modal Header Starts */}
        <Box style={{ padding: "16px", borderBottom: "1px solid #f5f5f5" }}>
          <div className="d-flex justify-content-between">
            <h5 className="m-0">Pull Data</h5>
            <div
              onClick={() => {
                onClose();
              }}
            >
              <img
                src={close}
                alt="close"
                style={{ width: "20px", cursor: "pointer" }}
              />
            </div>
          </div>
        </Box>
        {/* Modal Header Ends */}

        {/* Modal Body Starts */}
        <Box style={{ padding: "4px 16px" }}>
          <div className="d-flex justify-content-between align-items-center flex-wrap gap-2 m-1 my-2 ">
            <div className="fs-5 my-1">
              Selected ScanIds : {pullState?.finalDataPullScanids?.length}
            </div>
            <div
              style={{ width: isSmallScreen ? "100%" : "180px" }}
              className="my-1 "
            >
              <FormControl size="small" className="w-100">
                <InputLabel id="demo-simple-select-label">
                  Pull Reason
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={pullState.pull_reason}
                  label="Pull Reason"
                  onChange={(e) => {
                    setPullState((prev) => ({
                      ...prev,
                      pull_reason: e.target.value,
                    }));
                  }}
                >
                  {pull_reason_list?.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="card-Div" style={{ height: "55vh" }}>
            <DataGrid
              rows={row}
              columns={modifiedColumns(column)}
              density="standard"
              paginationMode="client"
              pageSize={101}
              disableSelectionOnClick
              disableRowSelectionOnClick
              rowSelectionModel={finalSelectedRows}
              checkboxSelection
              onRowSelectionModelChange={(newRowSelectionModel) => {
                const selectedRows = newRowSelectionModel.map((id) => {
                  const finalrow = row.find((r) => r.id === id);

                  return {
                    scan_id: finalrow?.scan_id,
                    machine_code: finalrow?.machine,
                  };
                });

                setFinalSelectedRows(newRowSelectionModel);
                // setFinalDataPullScanids(selectedRows);
                setPullState((prev) => ({
                  ...prev,
                  finalDataPullScanids: selectedRows,
                }));
              }}
              isRowSelectable={(params) => params.row.data_pulled === false}
              sx={{
                "&.MuiDataGrid-root": {
                  border: "none",
                  borderRadius: "5px", // Adjust border radius as needed
                  overflow: "hidden", // Ensure content respects the border radius,
                  padding: "0px",
                  margin: "0px",
                  marginTop: "-10px",
                },
                ".MuiDataGrid-columnHeader": {
                  backgroundColor: color.textWhiteColor,
                },
                ".MuiDataGrid-filler": {
                  backgroundColor: color.textWhiteColor,
                },
                ".MuiDataGrid-columnHeaderTitle": {
                  fontFamily: "Open-Sans-Light",
                  fontWeight: "bold",
                  padding: "5px 0px",
                  fontSize: "0.85rem",
                  textTransform: "capitalize",
                  color: color.taxtHeading,
                },
                ".MuiDataGrid-columnHeader--alignCenter": {
                  headerAlign: "left",
                },
                ".MuiDataGrid-row": {
                  border: "none", // Remove border from rows
                  backgroundColor: color.textWhiteColor, // Set rows background color to white
                  "&:hover": {
                    backgroundColor: color.primary, // Ensure background color remains white on hover
                    // cursor: "pointer",
                  },
                },
                ".MuiDataGrid-cell": {
                  // backgroundColor: color.textWhiteColor, // Set background color to red for out-of-range values
                  outline: "none",
                  "&:focus": {
                    outline: "none", // Remove the blue outline when a cell is focused
                  },
                },
                ".MuiDataGrid-footerContainer": {
                  display: "none",
                },
                // Custom CSS to hide the "Rows per page" component
                ".MuiTablePagination-root": {
                  ".MuiToolbar-root": {
                    marginTop: "0px",
                  },
                },
                "&.selected-row": {
                  backgroundColor: "red", // Highlight color for the selected row
                  "&:hover": {
                    backgroundColor: "red ", // Ensure highlight color remains on hover
                  },
                },
                ".MuiDataGrid-selectedRowCount": {
                  // display: "none", // Hide the selected row count div
                },
                ".MuiTablePagination-toolbar": {
                  padding: "2px 16px", // You can adjust padding as well if needed
                  marginTop: "-15px",
                },
              }}
            />
          </div>
        </Box>
        {/* Modal Body Ends */}
        {/* Modal Footer Starts */}

        <div className="d-flex justify-content-end my-2 py-2 px-4">
          <LoadingButton
            onClick={() => {
              sendScanIdsForPulling();
            }}
            disabled={
              pullState.finalDataPullScanids.length > 0 &&
              pullState.pull_reason !== ""
                ? false
                : true
            }
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
          >
            Pull Data
          </LoadingButton>
        </div>
        {/* Modal Footer Ends */}
      </Box>
    </Modal>
  );
};

export default VerifyScanIdsModal;
