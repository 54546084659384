import React from "react";
import FilesUpload from "./FilesUpload";

function Attachments({genFields, setGenFields, selectedFiles, setSelectedFiles, isSmallScreen}) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "20px",
        paddingBottom: "20px",
        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        justifyContent: "space-between",
      }}
    >
      <FilesUpload genFields={genFields} setGenFields={setGenFields} isSmallScreen={isSmallScreen}/>
    </div>
  );
}

export default Attachments;
